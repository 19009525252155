<template>
  <!-- 导航搜索 input -->
  <div class="input-search">
    <!-- 手机搜索 -->
    <img v-if="$vuetify.breakpoint.smAndDown" src="/imgs/search.png" class="search-icon" alt="search" @click="search" />
    <!-- 搜索框 -->
    <input id="search-input" ref="search" v-model="text" type="text" autocomplete="off" :placeholder="placeholderText[type]" @input="comSearch" @keyup.enter="search" />
    <!-- 手机清除 -->
    <img v-if="$vuetify.breakpoint.smAndDown" v-show="text" src="/imgs/close.png" class="clear-icon" alt="clear" @click="text = ''" />
    <!-- pc搜索 -->
    <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="searct-btn" rounded color="#3067F2" @click="search">
      <img src="/imgs/search3.png" class="search-icon pc" alt="clear" />
    </v-btn>
  </div>
</template>

<script>
import { debounce } from '@/assets/utils/toolFn'
// setLocalStorage
export default {
  name: 'LSearch',
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      text: '',
      placeholderText: ['Search by name, shape, color, ...', 'Search your question']
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.$refs.search.focus()
  },
  methods: {
    // 跳转搜索  需要保存历史记录
    search() {
      this.$emit('search', this.text)
    },
    // 防抖 弹窗搜索
    comSearch: debounce(function () {
      this.$emit('comSearch', this.text)
    }, 600)
  }
}
</script>

<style lang="scss" scoped>
.input-search {
  height: 50px;
  width: 100%;
  margin: 30px auto;
  border-radius: 25px;
  background: #6b7e991a;
  display: flex;
  align-items: center;

  .searct-btn {
    color: #fff;
    font-size: 16px !important;
    width: 100px;
    height: 50px !important;
    line-height: 50px !important;
  }
  .search-icon.pc {
    width: 20px;
    height: 20px;
  }
  input {
    flex: 1;
    height: 40px;
    font-size: 20px;
    margin-left: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    padding: 0 15px;
    border-radius: 30px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .input-search {
    height: 48px;
    width: 94%;
    margin: 0 auto 10px;
    border-radius: 25px;
    background: #6b7e991a;
    display: flex;
    align-items: center;
    .search-icon {
      width: 14px;
      height: 14px;
      margin: 0 15px;
    }
    .clear-icon {
      height: 12px;
      margin: 0 15px;
    }
    input {
      flex: 1;
      height: 16px;
      font-size: 14px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: 0;
      padding: 0 8px;
    }
    input {
      border-radius: 30px;
    }
  }
}
</style>
