<template>
  <div class="dialog-type-box">
    <template v-if="$vuetify.breakpoint.mobile">
      <v-bottom-sheet :value="value" persistent no-click-animation>
        <v-sheet class="text-right" height="auto">
          <v-spacer />
          <v-btn icon dark class="header-close" @click="closeDialog">
            <v-icon color="#000">mdi-close</v-icon>
          </v-btn>
          <div class="content-detail">
            <div class="goods-item">
              <div class="item">
                <div v-if="info.image" class="banner" :class="{ img2h: info.category === 'eye' ? false : true }" @click="goPage">
                  <v-img contain transition="scale-transition" lazy-src="/imgs/imgLazy.jpg" :src="info.image[0].path" />
                </div>
                <div class="content" title="">{{ info.title }}</div>
                <div class="price">
                  <template v-if="info.price != info.original">
                    <span class="current-price">{{ currencyIcon }}{{ info.price }}</span>
                    <span class="old-price">{{ currencyIcon }}{{ info.original }}</span>
                  </template>
                  <template v-else>
                    <span class="only-price">{{ currencyIcon }}{{ info.price }}</span>
                  </template>
                </div>
                <div class="option">
                  <v-btn class="my-btn blue-btn" color="#3067F2" :disabled="!info.stock" tile depressed block @click="addCart">Add To Cart</v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </template>
    <template v-else>
      <v-dialog :value="value" class="dialogs-com" persistent :fullscreen="$vuetify.breakpoint.mobile">
        <div class="dialog-body">
          <div class="dialog-header d-flex">
            <v-spacer />
            <v-btn icon dark @click="closeDialog">
              <v-icon color="#000">mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="content-detail">
            <div class="goods-item">
              <div class="item">
                <div v-if="info.image" class="banner" :class="{ img2h: info.category === 'eye' ? false : true }" @click="goPage">
                  <v-img contain transition="scale-transition" lazy-src="/imgs/imgLazy.jpg" :src="info.image[0].path" />
                </div>
                <div class="content" title="">{{ info.title }}</div>
                <div class="price">
                  <template v-if="info.price != info.original">
                    <span class="current-price">{{ currencyIcon }}{{ info.price }}</span>
                    <span class="old-price">{{ currencyIcon }}{{ info.original }}</span>
                  </template>
                  <template v-else>
                    <span class="only-price">{{ currencyIcon }}{{ info.price }}</span>
                  </template>
                </div>
                <div class="option">
                  <v-btn class="my-btn blue-btn" color="#3067F2" :disabled="!info.stock" tile depressed block @click="addCart">Add To Cart</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'productInfoDialog',
  props: {
    value: {
      type: Boolean,
      default: true
    },
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      info: {}
    }
  },
  computed: {
    ...mapGetters('system', ['currencyIcon'])
  },
  watch: {
    value(val) {
      if (val) {
        this.getInfo()
      }
    }
  },
  methods: {
    getInfo(code) {
      this.$api.product.getProductInfo({ code: this.code }).then(res => {
        if (res.code === 200) {
          this.info = res.data
        }
      })
    },
    addCart() {
      this.$api.shoppingCart.addToCart({ code: this.code }).then(res => {
        if (res.code === 200) {
          this.$emit('refresh')
          this.closeDialog()
          this.$store.dispatch('system/getCollectionsAndCartsNum', { axios: this.$api })
        }
      })
    },
    goPage() {
      this.closeDialog()
      const page = this.$router.resolve({
        name: 'details-code',
        params: { code: this.code }
      })
      window.open(page.href, '_blank')

      // this.$router.push({
      //   name: 'details-code',
      //   params: { code: this.code }
      // })
    },
    closeDialog() {
      // this.$parent.sizeDialogs = false
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog {
// }
.dialogs-com,
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  background: #fff;
  margin: 0 auto;
}
.v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  width: 410px;
}
::v-deep .v-toolbar {
  height: 50px;
}
.content-detail {
  padding: 0 16px;
  min-height: 375px;
  background: #fff;
  display: flex;
  align-items: center;
}
.goods-item {
  background: #fff;
  width: 100%;
  .item {
    width: calc(100% - 6px);
    height: 100%;
    margin: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
    transition: box-shadow 1s;

    @media screen and (min-width: 1200px) {
      .right:hover {
        opacity: 0.7;
      }
    }

    .banner {
      width: 86%;
      height: 170px;
      margin: 0 auto 10px;
      cursor: pointer;
      img,
      .v-image {
        height: 100%;
        object-fit: cover;
      }
    }
    .img2h {
      height: 170px;
    }
    .content {
      width: 100%;
      font-size: 18px;
      text-align: center;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .price {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      .current-price {
        color: red;
        font-size: 18px;
        font-weight: 600;
      }
      .old-price {
        color: #6b6b6b;
        font-size: 18px;
        margin-left: 5px;
        position: relative;
        font-weight: 600;
        line-height: 1;
        text-decoration: line-through;
      }
      .only-price {
        color: #252323;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .blue-btn {
    color: #ffffff !important;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    width: 100%;
  }
  .header-close {
    margin: 16px 16px 0 0;
  }
}
</style>
