<template>
  <div class="center-body">
    <div class="content-main">
      <v-form ref="info" class="content-body">
        <div class="item">
          <div class="left">Gender</div>
          <div class="right gender">
            <v-radio-group v-model="info.gender" hide-details row>
              <v-radio label="Mr." value="Mr" />
              <v-radio label="Ms." value="Ms" />
              <v-radio label="Mx." value="Mx" />
            </v-radio-group>
            <!-- <label><input v-model="vs" class="checkbox-item" name="gender" type="radio" /> Mr.</label>
            <label><input v-model="vs" class="checkbox-item" name="gender" type="radio" /> Ms.</label>
            <label><input v-model="vs" class="checkbox-item" name="gender" type="radio" /> Mx.</label> -->
          </div>
        </div>
        <div class="item">
          <div class="left">First Name</div>
          <div class="right">
            <v-text-field v-model="info.firstname" required class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" :rules="[rules.name]" />
          </div>
        </div>
        <div class="item">
          <div class="left">Last Name</div>
          <div class="right">
            <v-text-field v-model="info.lastname" required class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" :rules="[rules.name]" />
          </div>
        </div>
        <div class="item">
          <div class="left">Email Address</div>
          <div class="right">
            <v-text-field v-model="info.email" required class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" :rules="[rules.email]" />
          </div>
        </div>
        <div class="item">
          <div class="left">Date of Birth</div>
          <div class="right">
            <v-text-field v-model="info.birthday" class="input-item" background-color="#fff" color="#3067F2" outlined required hide-details="auto" placeholder="02/17/1980" :rules="[rules.date]" />
          </div>
        </div>
        <div class="item">
          <div class="left">Telephone</div>
          <div class="right tel">
            <div class="tel-left">
              <div v-if="countryTel.length" class="text-center language-phone">
                <v-menu offset-y top attach=".language-phone">
                  <template #activator="{ on, attrs }">
                    <div class="language" v-bind="attrs" v-on="on">
                      <span>{{ info.code }}</span>
                      <img class="open" src="/imgs/open.png" alt="countrySelect" />
                    </div>
                  </template>
                  <v-list class="currency">
                    <v-list-item-group v-model="selectedItem" color="#3067f2">
                      <v-list-item v-for="(item, index) in countryTel" :key="index">
                        <v-list-item-title>
                          {{ item.code ? item.code : item.name }}
                          <img v-if="item.img" class="country" :src="`${item.img}`" />
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div class="tel-right"><v-text-field v-model="info.tel" required class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" /></div>
          </div>
        </div>
      </v-form>
      <div class="btn-box">
        <v-btn class="my-btn btn-option" tile depressed block @click="edit"> UPDATE </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmail, isName, checkDate } from '@/assets/utils/validate'
export default {
  name: 'personalInfo',
  data() {
    return {
      info: {
        gender: undefined,
        firstname: undefined,
        lastname: undefined,
        email: undefined,
        birthday: '00/00/0000',
        code: +1,
        tel: undefined,
        telephone: undefined
      },
      countryTel: [],
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          return isEmail(value) || 'Invalid e-mail.'
        },
        name: value => {
          return isName(value) || 'Invalid name, please use english.'
        },
        date: value => {
          return checkDate(value) || 'Invalid date,Month-Day-Year'
        }
      }
    }
  },
  computed: {
    selectedItem: {
      get() {
        if (!this.info.code) {
          return 0
        }
        const index = this.countryTel.findIndex(item => {
          return item.code === this.info.code
        })
        return index
      },
      set(value) {
        if (this.countryTel[value].code) {
          this.info.code = this.countryTel[value].code
        } else {
          this.info.code = this.countryTel[value].name
        }
      }
    }
    // selectedItem1: {
    //   get() {
    //     if (!this.phone1.code) {
    //       return 0
    //     }
    //     const index = this.countryTel.findIndex(item => {
    //       return item.code === this.phone1.code
    //     })
    //     if (index === -1) {
    //       return this.countryTel.length - 1
    //     }
    //     return index
    //   },
    //   set(value) {
    //     if (this.countryTel[value].code !== '') {
    //       this.phone1.code = this.countryTel[value].code
    //     } else {
    //       this.phone1.code = this.countryTel[value].name
    //     }
    //   }
    // },
  },
  watch: {},
  created() {},
  mounted() {
    this.$api.userCenter.userInfo().then(res => {
      // console.log(res)
      if (res.code === 200) {
        this.info = res.data
        // this.info.code = '+1'
        // this.info.tel = undefined
        if (this.info.telephone) {
          const phoneArr = this.info.telephone.split(' ')
          if (phoneArr.length > 1) {
            this.info.code = phoneArr[0]
            this.info.tel = phoneArr[1]
          }
        }
      }
    })
    this.getCountryCode()
  },
  methods: {
    edit() {
      if (this.$refs.info.validate()) {
        this.setInfo()
      }
    },
    setInfo() {
      if (this.info.code) {
        this.info.telephone = this.info.code + ' ' + this.info.tel
      }
      this.$api.userCenter.setUserInfo(this.info).then(res => {
        if (res.code === 200) {
          this.$tips({ msg: 'Information set successfully.' })
        }
      })
    },
    // 获取国家号段
    getCountryCode() {
      this.$api.userCenter.getCountryCode().then(res => {
        if (res.code === 200) {
          this.countryTel = res.data
          if (this.info.telephone) {
            const phoneArr = this.info.telephone.split(' ')
            if (phoneArr.length > 1) {
              this.info.code = phoneArr[0]
              this.info.tel = phoneArr[1]
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-body {
  margin-top: 20px;
}
.content-body > .item {
  display: flex;
  align-items: center;
  height: 44px;
  margin-bottom: 20px;
  .left {
    width: 180px;
    margin-right: 16px;
    font-size: 14px;
    color: #525861;
  }
  .right {
    height: 100%;
    width: 280px;
    ::v-deep .v-input__control {
      flex-wrap: nowrap;
    }
  }
}
.tel {
  display: flex;
  align-items: center;
  .tel-left,
  .tel-right {
    height: 100%;
  }
}
.gender {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  label {
    margin-right: 15px;
  }
}
.tel-left {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #525861;
  margin-right: 15px;
}
.tel-right .item-input {
  width: 165px;
}
// .input-item ::v-deep .v-text-field--outlined
.language {
  display: flex;
  align-items: center;
  justify-content: center;
  .language-icon {
    width: auto;
    height: 14px;
    margin-left: 5px;
  }
  .open {
    height: 6px;
    margin-left: 8px;
  }
  .language-text {
    font-size: 14px;
    margin: 0 5px;
  }
}
// 切换货币
.currency {
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  .v-list-item__title {
    text-align: center;
  }
  .v-list-item:hover {
    background: #dce8ff;
    color: #3067f2;
  }
  .v-list-item--active {
    background: #dce8ff;
  }
  .country {
    position: relative;
    top: 2px;
    width: auto;
    height: 14px;
  }
}
.btn-box {
  margin-top: 60px;
  width: 300px;
  height: 46px;
  .btn-option {
    color: #fff !important;
    height: 100% !important;
    border: 0;
    background: #222426 !important;
  }
}
.language-phone {
  ::v-deep .v-menu__content {
    max-height: 280px;
    background: #fff;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .content-body {
    .item {
      height: 65px;
      flex-direction: column;
      align-items: flex-start;
      &:first-child {
        margin-bottom: 5px;
      }
      .left {
        margin-bottom: 6px;
      }
      .right {
        width: 100%;
      }
      .tel-right {
        flex: 1;
      }
    }
  }
  .btn-box {
    margin: 38px auto;
  }
}
</style>
