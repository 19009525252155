<template>
  <div class="sizeFilter">
    <div class="left">
      <v-radio-group v-model="current" hide-details @change="change">
        <v-radio v-for="val in list[type]" :key="val.id" color="#222426" :label="val.name" :value="val" />
      </v-radio-group>
      <!-- <v-checkbox v-model="current" class="checkbox-item" :label="val.name" color="#222426" :value="val" hide-details @change="change" /> -->
    </div>
    <div class="right">
      <div class="item">
        <p class="slider-title">
          <span>Frame Width</span>
          <img class="width-icon" src="/imgs/size/ic_fw.png" alt="Frame Width" />
        </p>
        <v-range-slider
          v-model="FrameValue"
          :disabled="disabled"
          max="160"
          min="100"
          color="#3067f2"
          hide-details
          thumb-color="#3067f2"
          thumb-size="25"
          :thumb-label="thumb"
          @mousedown="thumb = true"
          @mouseup="thumb = false"
          @change="sliderChange"
        />
        <p class="slider-number">{{ `${FrameValue[0]}-${FrameValue[1]} mm` }}</p>
      </div>
      <div class="item">
        <p class="slider-title">
          <span>Temple Width</span>
          <img class="width-icon" src="/imgs/size/ic_tl.png" alt="temple width" />
        </p>
        <v-range-slider
          v-model="TempleValue"
          :disabled="disabled"
          max="150"
          min="100"
          color="#3067f2"
          hide-details
          thumb-color="#3067f2"
          thumb-size="25"
          :thumb-label="thumb"
          @mousedown="thumb = true"
          @mouseup="thumb = false"
          @change="sliderChange"
        />
        <p class="slider-number">{{ `${TempleValue[0]}-${TempleValue[1]} mm` }}</p>
      </div>
      <div class="item">
        <p class="slider-title">
          <span>Lens Width</span>
          <img class="width-icon" src="/imgs/size/ic_lw.png" alt="size_lens_width" />
        </p>
        <v-range-slider
          v-model="lensW"
          :disabled="disabled"
          max="80"
          min="40"
          color="#3067f2"
          hide-details
          thumb-color="#3067f2"
          thumb-size="25"
          :thumb-label="thumb"
          @mousedown="thumb = true"
          @mouseup="thumb = false"
          @change="sliderChange"
        />
        <p class="slider-number">{{ `${lensW[0]}-${lensW[1]} mm` }}</p>
      </div>
      <div class="item">
        <p class="slider-title">
          <span>Lens Height</span>
          <img class="width-icon" src="/imgs/size/ic_lh.png" alt="size_lens_height" />
        </p>
        <v-range-slider
          v-model="lensH"
          :disabled="disabled"
          max="55"
          min="15"
          color="#3067f2"
          hide-details
          thumb-color="#3067f2"
          thumb-size="25"
          :thumb-label="thumb"
          @mousedown="thumb = true"
          @mouseup="thumb = false"
          @change="sliderChange"
        />
        <p class="slider-number">{{ `${lensH[0]}-${lensH[1]} mm` }}</p>
      </div>
      <div class="item">
        <p class="slider-title">
          <span>Bridge Width</span>
          <img class="width-icon" src="/imgs/size/ic_bw.png" alt="size_bridge_width" />
        </p>
        <v-range-slider
          v-model="bridgeW"
          :disabled="disabled"
          max="30"
          min="9"
          color="#3067f2"
          hide-details
          thumb-color="#3067f2"
          thumb-size="25"
          :thumb-label="thumb"
          @mousedown="thumb = true"
          @mouseup="thumb = false"
          @change="sliderChange"
        />
        <p class="slider-number">{{ `${bridgeW[0]}-${bridgeW[1]} mm` }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sizeFilter',
  props: {
    // 筛选类型
    type: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: () => {}
    },
    check: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      thumb: false,
      current: [],
      FrameValue: [100, 160],
      TempleValue: [100, 150],
      lensW: [40, 80],
      lensH: [15, 55],
      bridgeW: [9, 30],
      disabled: true
    }
  },
  computed: {},
  watch: {
    check: {
      handler(newValue) {
        if (!newValue[this.type] || !newValue[this.type].length) {
          this.current = []
          this.FrameValue = [100, 160]
          this.TempleValue = [100, 150]
          this.lensW = [40, 80]
          this.lensH = [15, 55]
          this.bridgeW = [9, 30]
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    sliderChange() {
      const that = this
      this.$emit('dataFn', {
        selected: {
          FrameValue: that.FrameValue,
          TempleValue: that.TempleValue,
          lensW: that.lensW,
          lensH: that.lensH,
          bridgeW: that.bridgeW
        },
        type: 'width'
      })
    },
    change(val) {
      // 设置自由尺寸
      if (val.name !== 'Custom') {
        this.disabled = true
      } else {
        this.disabled = false
      }
      this.$emit('dataFn', { selected: [this.current], type: this.type })
    }
    // 查看是否存在 数据
    // findData(val, data) {
    //   const index = data.findIndex(function (item) {
    //     return item.type === val
    //   })
    //   return index
    // }
  }
}
</script>

<style lang="scss" scoped>
.sizeFilter {
  display: flex;
}
.left {
  width: 260px;
}
.right {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.6);
  & > .item {
    width: 48%;
  }
}
.slider-title,
.slider-number {
  text-align: center;
  margin-bottom: 0;
}
.slider-title {
  height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .width-icon {
    width: 68px;
    object-fit: contain;
  }
}
.left {
  ::v-deep .v-input--radio-group__input {
    height: 100%;
    justify-content: space-around;
  }
  ::v-deep .v-input--radio-group {
    height: 100%;
    .v-input__control {
      height: 100%;
      flex-direction: row;
    }
  }
}
.right {
  ::v-deep .v-input__slot {
    height: 20px !important;
    min-height: 20px !important;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .sizeFilter {
    display: block;
  }
  .left {
    width: 100%;
    margin-bottom: 15px;
  }
  .right {
    .item {
      width: 100%;
    }
  }
}
</style>
