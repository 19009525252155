<template>
  <v-dialog :value="dialog" class="dialogs-com" persistent :fullscreen="$vuetify.breakpoint.mobile">
    <div class="dialog-body">
      <div class="dialog-header d-flex">
        <v-spacer />
        <v-btn icon dark @click="closeDialog">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="content-detail">
        <h2 class="reset-title">Reset Password</h2>
        <v-form ref="reset" class="form-box">
          <v-text-field v-model="reset.email" label="Email" hide-details="auto" required class="form-input-item" background-color="#F2F3F5" color="#3067F2" outlined :rules="[rules.email]" />
          <div class="code-box">
            <v-text-field v-model="reset.auth_code" label="Code" hide-details="auto" required class="form-input-item" background-color="#F2F3F5" color="#3067F2" outlined :rules="[rules.codeLimit]" />
            <v-btn class="my-btn btn-send" :disabled="countdown > 0" color="#3067F2" @click="sendCode">{{ countdown > 0 ? countdown + 's' : 'Send Code' }}</v-btn>
          </div>
          <v-text-field
            v-model="reset.password1"
            label="Password"
            hide-details="auto"
            required
            class="form-input-item"
            background-color="#F2F3F5"
            color="#3067F2"
            outlined
            :rules="[rules.passWord]"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
          />
          <v-text-field
            v-model="reset.password2"
            label="Confirm Password"
            hide-details="auto"
            required
            class="form-input-item"
            background-color="#F2F3F5"
            color="#3067F2"
            outlined
            :rules="[rules.passWord]"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
          />
          <v-btn block class="my-btn btn-confirm" color="#3067F2" @click="confirm"> Confirm </v-btn>
        </v-form>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { isEmail, isPassWord2, validateCode } from '@/assets/utils/validate'

export default {
  name: 'resetPassword',
  data() {
    return {
      dialog: false,
      codeloading: false,
      countdown: 0, // 进度读秒
      timer: null, // 计时器
      show1: false,
      showPass: false,
      reset: {
        email: undefined,
        auth_code: undefined,
        password1: undefined,
        password2: undefined
      },
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          return isEmail(value) || 'Invalid e-mail.'
        },
        codeLimit: value => {
          return validateCode(value) || 'Four digit verification code.'
        },
        passWord: value => {
          return isPassWord2(value) || 'Please enter a password of at least 8 characters.'
        }
      }
    }
  },
  computed: {
    ...mapState({
      resetDialog: state => state.system.resetDialog
    })
  },
  watch: {
    resetDialog(newValue) {
      this.dialog = newValue
      if (!newValue) {
        this.$refs.reset.resetValidation()
      }
      if (!newValue) {
        clearInterval(this.timer) // 清除计时器
      }
    }
  },
  methods: {
    sendCode() {
      if (!isEmail(this.reset.email)) {
        this.$tips({ msg: 'Please fill in the correct email address.' })
        return false
      }
      this.$api.system.getCodePassWord({ email: this.reset.email }).then(res => {
        if (res.code === 200) {
          this.$tips({ msg: 'The verification code has been sent.' })
          this.codeTime()
        }
      })
    },
    codeTime() {
      if (this.countdown > 0) {
        return false
      }
      this.countdown = 60
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    confirm() {
      if (this.$refs.reset.validate()) {
        if (this.reset.password1 !== this.reset.password2) {
          this.$tips({ msg: 'The two passwords do not match.' })
          return false
        } else {
          this.confirmFn()
        }
      }
    },
    confirmFn() {
      // if (this.reset.password1 !== this.reset.password2) {
      //   this.$tips({ msg: 'The two passwords do not match.' })
      //   return false
      // }
      this.$confirm({ msg: 'Are you sure to change the password?' }).then(res => {
        if (res === 'success') {
          this.$api.system.setPassword(this.reset).then(res => {
            if (res.code === 200) {
              this.$tips({ msg: 'The account information has been saved.' })
              this.closeDialog()
              setTimeout(() => {
                this.$store.commit('system/toggleLogin', true)
              }, 200)
            }
          })
        }
      })
    },
    closeDialog() {
      this.initData()
      // this.$parent.sizeDialogs = false
      this.$store.commit('system/toggleReset', false)

      // this.$emit('input', false)
    },
    initData() {
      Object.assign(this.$data, this.$options.data())
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog {
// }
.dialogs-com,
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
::v-deep .v-text-field {
  margin-bottom: 8px !important;
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #f2f3f5 inset !important;
  }
}
::v-deep .v-text-field--outlined fieldset {
  border: none !important;
}
::v-deep .v-slide-group__wrapper {
  padding-left: 50px;
}
::v-deep .v-input__slot {
  min-height: 48px !important;
}
::v-deep .v-input__append-inner {
  margin-top: 12px !important;
}
::v-deep .v-text-field--outlined .v-label:not(.v-label--active) {
  top: 15px;
}
.v-dialog__content ::v-deep .v-dialog {
  background: #fff;
  margin: 0 auto;
}
.v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  width: 470px;
}
::v-deep .v-toolbar {
  height: 50px;
}
.content-detail {
  padding: 0 16px;
  min-height: 375px;
  background: #fff;
}
.reset-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
}
.code-box {
  display: flex;
}
.btn-confirm,
.btn-send {
  height: 48px !important;
  color: #fff !important;
}
.btn-send {
  min-width: 120px !important;
  margin-left: 15px;
}
.form-box {
  // width: 480px;
  // margin: 30px auto 50px;
  margin-bottom: 35px;
  .input-label {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 4px;
  }
  .input-item {
    height: 48px;
    margin-bottom: 20px;
  }

  .btn-login {
    height: 48px;
    color: #fff;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
}
</style>
