<template>
  <!-- 首页 活动 -->
  <div class="body-page">
    <div class="content-box activity">
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <div class="item item-left" @click="toPage('flash-sale')">
              <!-- contain -->
              <v-img class="img" lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_flash_sale.webp`" />
              <div class="activity-title">Clearance Sale</div>
              <div class="describe">Limited stock</div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="item item-right" @click="toPage('best-sellers')">
              <v-img class="img" lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_best_sellers.webp`" />
              <div class="activity-title">Oversized Glasses</div>
              <div class="describe">Make a sassy, funky fashion statement</div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LActivity',
  methods: {
    toPage(type) {
      this.$router.push({
        name: 'search-type',
        params: {
          type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.body-page {
  // margin-top: $box-top;
  // margin-bottom: 20px;
  padding-bottom: 70px;
}
.item {
  cursor: pointer;
  text-align: center;
  .img {
    margin-bottom: 20px;
  }
  .activity-title {
    letter-spacing: 1px;
    font-size: 18px;
    color: #2b2e33;
    line-height: 21px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .describe {
    font-size: 16px;
    font-weight: 400;
    color: #6a6d70;
    line-height: 22px;
  }
  // ::v-deep .v-image__image {
  //   background-size: 100% 100%;
  // }
}
.item-left {
  margin-right: 10px;
}
.item-right {
  margin-left: 10px;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  // .body-page,
  // .content-box {
  //   background: #f9fafc;
  // }
  .activity {
    .item-left {
      margin-right: 10 !important;
    }
    .item-right {
      margin-left: 10 !important;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 600px) {
  .body-page {
    // padding-top: $box-top-min;
    padding-bottom: 48px;
    background: #f9fafc;
  }
  .body-page,
  .content-box {
    background: #f9fafc;
  }
  .content-box {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
  }
  .activity {
    .row > div:not(:last-child) {
      margin-bottom: 38px;
    }
    .item {
      ::v-deep .v-image__image {
        background-size: 100% 100%;
      }
    }

    .item-left {
      margin-right: 0 !important;
    }
    .item-right {
      margin-left: 0 !important;
    }
  }
}
</style>
