<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.mobile" persistent background="#fff" hide-overlay>
    <div class="dialog-body">
      <v-toolbar color="#fff">
        <v-spacer />
        <v-btn icon dark @click="close">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="content">
        <userTicket @success="close" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import userTicket from '@/pages/centerMain/tickets/my-tickets'
export default {
  name: 'ticketDialog',
  components: {
    userTicket
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {},
  watch: {
    show(newValue) {
      this.dialog = newValue
    }
  },
  methods: {
    close() {
      this.$parent.showTicket = false
      // this.$emit('close')
      //   Object.assign(this.$data, this.$options.data())
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog {
//   width: 580px;
//   height: 80%;
//   margin: 0 auto;
//   overflow-y: auto;
//   background: #fff;
// }
.v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  width: 580px;
  height: 80vh;
  margin: 0 auto;
  overflow-y: hidden;
}
.dialog-body {
  font-size: 16px;
  height: 100%;
  background: #fff;
}
::v-deep .v-toolbar {
  height: 50px;
}
::v-deep .v-rating {
  margin-left: -8px;
}
::v-deep .v-sheet.v-toolbar {
  box-shadow: none !important;
}
::v-deep .v-rating .v-icon {
  padding: 5px !important;
}

.content {
  height: 88%;
  overflow-y: auto;
  padding: 0 50px;

  ::v-deep .center-body {
    margin: 30px 0;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  // .v-dialog__content ::v-deep .v-dialog {
  //   width: 630px;
  //   height: 100%;
  //   max-height: 100%;
  //   margin: 0 auto;
  // }
}
</style>
