<template>
  <div class="goodsDetails">
    <headerBreadcrumbs v-if="$vuetify.breakpoint.mdAndUp" :name="info.title" />
    <div class="goods-content">
      <goodsinfo :info="info" @jumpReviews="jumpReviews" />
      <introduction ref="introduction" :info="info" @jumpReviews="jumpReviews" />
    </div>
    <LgoTop v-if="$vuetify.breakpoint.smAndDown" />
  </div>
</template>

<script>
import headerBreadcrumbs from '@/components/Ldetails/headerBreadcrumbs'
import goodsinfo from '@/components/Ldetails/goodsinfo'
import introduction from '@/components/Ldetails/introduction'
import LgoTop from '@/components/LgoTop/index.vue'

// import tem1 from '@/static/imgs/SKU-ST0117/temp-1.png'
// import tem5 from '@/static/imgs/SKU-ST0117/temp-5.jpg'
// import tem4 from '@/static/imgs/SKU-ST0117/temp-4.png'
export default {
  name: 'goodsDetails',
  components: {
    headerBreadcrumbs,
    goodsinfo,
    introduction,
    LgoTop
  },
  validate({ params }) {
    // return的结果为布尔值，如果为false则404
    // return /^\d+$/.test(params.code)
    if (!params.code) {
      return false
    } else {
      return true
    }
  },
  scrollToTop: true,
  async asyncData({ route, $api, redirect, error }) {
    try {
      const {
        code,
        data: info,
        page: head
      } = await $api.product.getProductInfo({
        code: route.params.code
      })
      if (code !== 200) {
        return error({ statusCode: 404 })
      }
      return { info, head }
    } catch (error) {
      error({ statusCode: 404 })
    }
  },
  data() {
    return {
      info: {},
      head: {}
    }
  },
  head() {
    return {
      title: this.head.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.head.description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.head.keyword
        }
      ]
    }
  },
  computed: {},
  watch: {},
  mounted() {
    // this.$api.product.FlowNext({
    //   product_id: 97,
    //   step_data: [
    //     { type: 'lens_use', value: 'A' }
    //     // { type: 'add_ones', value: 'B' }
    //   ]
    // })
  },
  // async mounted() {
  //   const { data: info } = await this.$api.product.getProductInfo({
  //     product_id: 1
  //   })
  //   console.log(info)
  // },
  methods: {
    // 切换到评论视图
    jumpReviews() {
      this.$refs.introduction.tab = 1
      document.getElementsByClassName('introduction')[0].scrollIntoView(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.goodsDetails {
  font-size: 16px;
  .goods-content {
    padding: 0;
    background: #fff;
    max-width: 1200px;
    min-width: 735px;
    margin: 0 auto;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .goodsDetails {
    .goods-content {
      padding: 16px;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .goodsDetails {
    .goods-content {
      padding: 0;
      overflow: hidden;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
    }
  }
}
</style>
