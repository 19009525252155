<template>
  <div class="LhomeDialog">
    <component :is="comName" v-if="type" :type="type" :list="list" @close="close" />
    <!-- <typeList v-if="type" :type="type" :list="list" /> -->
    <!-- <v-progress-circular v-else indeterminate color="primary" size="50" /> -->
  </div>
</template>

<script>
// import search from '@/pages/control/search.vue'
// import typeList from '@/components/LhomeDialog/typeList.vue'
const typeList = () => import('@/components/LhomeDialog/typeList.vue')
const search = () => import('@/pages/control/search.vue')
// const eyeglasses = () => import('@/components/LhomeDialog/eyeglasses.vue')
// const sunglasses = () => import('@/components/LhomeDialog/sunglasses.vue')
export default {
  name: 'LhomeDialog',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      list: {}
    }
  },
  computed: {
    comName() {
      let name
      switch (this.type) {
        case 'search':
          name = search
          break
        default:
          name = typeList
          break
      }
      return name
    }
  },
  // watch: {
  //   type(newValue, oldValue) {
  //     if (newValue !== oldValue && newValue !== null) {
  //       this.getType()
  //     }
  //   }
  // },
  mounted() {
    this.navControl()
    this.getType()
  },
  methods: {
    close() {
      this.$emit('hide')
    },
    async getType() {
      const { data: typeList } = await this.$api.system.getGoodsType({
        type: 'All'
      })
      this.list = typeList
    },
    navControl() {
      // 根据通知栏状态 控制导航栏
      //  nobileNav
      this.$nextTick(() => {
        const navPc = document.querySelector('.nav-box-pc')
        document.querySelector('.LhomeDialog').style.top = navPc.offsetHeight + 'px'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.LhomeDialog {
  transform-origin: top;
  width: 100%;
  max-height: 538px;
  min-height: 380px;
  background: #fff;
  position: fixed;
  overflow-y: auto;
  // top: 110px;
  left: 0;
  right: 0;
  z-index: 99;
}
</style>
