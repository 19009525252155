<!-- 眼镜参数 -->
<template>
  <div class="step-body">
    <!-- ------------------------------------ Plan ----------------------------------------- -->
    <div class="option-header">
      <div class="left">
        <template v-if="userToken">
          <div class="select-list">
            <v-select v-model="currentPlan" class="select-prescription" label="- Select RX -" :items="items" item-text="title" item-value="id" color="#525861" outlined dense hide-details @change="planChange" />
          </div>
          <!-- <div class="add" @click="addNew"><span>+</span> Add New</div> -->
        </template>
        <template v-else>
          <div class="d-flex align-center login-text" style="color: #3067f2; cursor: pointer" @click="goLogin">Sign in to select a RX</div>
        </template>
      </div>
      <!-- <div class="right"><img class="upload-icon" src="/imgs/upload.png" alt="upload" /> Upload Prescription</div> -->
      <div v-if="planType" class="right" @click="planType = 0"><img class="upload-icon" src="/imgs/upload.png" alt="upload" />Or Upload RX</div>
      <div v-else class="right" @click="planType = 1">Fill in the RX form</div>
    </div>
    <template v-if="planType">
      <div class="rescription form">
        <img class="th-img" src="/imgs/question_orange.png" alt="description" @click="openDialog('add')" />
        <!-- ------------------------------------ Col Nmae ----------------------------------------- -->
        <div v-if="!$vuetify.breakpoint.smAndDown" class="form-header">
          <div class="space item"></div>
          <div class="item">Sphere(SPH)</div>
          <div class="item">Cylinder(CYL)</div>
          <div class="item" :style="{ color: !Raxios || !Laxios ? '#2B2E33' : '#AAACAD' }">AXIS</div>
          <div v-if="!addState" class="item" :style="{ color: !addState ? '#2B2E33' : '#AAACAD' }">ADD</div>
        </div>
        <div class="form-body">
          <!-- ------------------------------------ Right Eye ----------------------------------------- -->
          <div class="list">
            <div class="type item">Right Eye (OD):</div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">SPH</div>
              <v-select v-model="info.od_sph" class="selects" :items="SPH" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">CYL</div>
              <v-select v-model="info.od_cyl" class="selects" :items="CYL" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title" :style="{ color: !Raxios ? '#2B2E33' : '#AAACAD' }">AXIS</div>
              <v-select v-model="info.od_axis" class="selects" :disabled="Raxios" :items="Axis" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div v-if="!$vuetify.breakpoint.smAndDown && !addState" class="item">
              <v-select v-model="info.od_add" class="selects" :items="ADD" dense hide-details :disabled="addState" placeholder="None" :error="addStateError" />
            </div>
          </div>
          <!-- ------------------------------------ Left Eye ----------------------------------------- -->
          <div class="list">
            <div class="type item">Left Eye (OS):</div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">SPH</div>
              <v-select v-model="info.os_sph" class="selects" :items="SPH" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">CYL</div>
              <v-select v-model="info.os_cyl" class="selects" :items="CYL" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title" :style="{ color: !Laxios ? '#2B2E33' : '#AAACAD' }">AXIS</div>
              <v-select v-model="info.os_axis" class="selects" :disabled="Laxios" :items="Axis" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div v-if="!$vuetify.breakpoint.smAndDown && !addState" class="item">
              <v-select v-model="info.os_add" class="selects" :items="ADD" dense hide-details :disabled="addState" placeholder="None" :error="addStateError" />
            </div>
          </div>
          <!-- ------------------------------------ Mobile - ADD ----------------------------------------- -->
          <div v-if="$vuetify.breakpoint.smAndDown && !addState" class="list mobile-list">
            <div class="type item">Addition (near) ADD:</div>
            <div class="item">
              <div class="col-title" :style="{ color: !addState ? '#2B2E33' : '#AAACAD' }">Right Eye (OD)</div>
              <v-select v-model="info.od_add" class="selects" :items="ADD" dense hide-details :disabled="addState" outlined placeholder="None" :error="addStateError" />
            </div>
            <div class="item">
              <div class="col-title" :style="{ color: !addState ? '#2B2E33' : '#AAACAD' }">Left Eye (OS)</div>
              <v-select v-model="info.os_add" class="selects" :items="ADD" dense hide-details :disabled="addState" outlined placeholder="None" :error="addStateError" />
            </div>
          </div>
          <div v-if="addStateError" class="error-message">
            <span>Add corrects near vision for reading. If you see one number listed for ADD, use it for both eyes. If you have two different numbers, the prescription could not be processed.</span>
          </div>
          <!-- ------------------------------------ Pupillary Distance ----------------------------------------- -->
          <div class="list mobile-list end-list">
            <div class="type item">PD:<img class="tips-img" src="/imgs/question_orange.png" alt="tips" @click="openDialog('pd')" /></div>
            <template v-if="twoPd">
              <div class="item">
                <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Right</div>
                <v-select v-model="info.pd_right" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="Right" />
              </div>
              <div class="item">
                <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Left</div>
                <v-select v-model="info.pd_left" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="Left" />
              </div>
            </template>
            <template v-else>
              <div class="item one-pd" :class="{ threecol: addState }">
                <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Select PD</div>
                <v-select v-model="info.pd_right" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
              </div>
            </template>
            <div class="item select-box" :class="{ threecol: addState, moblie: $vuetify.breakpoint.smAndDown }">
              <v-checkbox v-model="twoPd" class="input-item" color="#3067F2" label="Have two PDs" @change="changeTwoPd" />
            </div>
            <!-- <div class="item tips-item">
              <img class="tips-img" src="/imgs/question_orange.png" alt="tips" @click="openDialog('pd')" />
            </div> -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="uploadImg-box">
        <!-- ------------------------------------- upload img -------------------------------------------------- -->
        <div class="upload-box">
          <uploadImg ref="imgCom" up-type="prescription" :online="[info.img_path]" @clear="info.img_path = ''" />
        </div>
        <!-- ------------------------------------ Pupillary Distance ----------------------------------------- -->
        <div class="list mobile-list end-list">
          <div class="type item">PD:<img class="tips-img" src="/imgs/question_orange.png" alt="tips" @click="openDialog('pd')" /></div>
          <template v-if="twoPd">
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Right</div>
              <v-select v-model="info.pd_right" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="Right" />
            </div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Left</div>
              <v-select v-model="info.pd_left" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="Left" />
            </div>
          </template>
          <template v-else>
            <div class="item one-pd">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Select PD</div>
              <v-select v-model="info.pd_right" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
          </template>
          <div class="item select-box">
            <v-checkbox v-model="twoPd" class="input-item" color="#3067F2" label="Have two PDs" @change="changeTwoPd" />
          </div>
          <!-- <div class="item tips-item">
            <img class="tips-img" src="/imgs/question_orange.png" alt="tips" @click="openDialog('pd')" />
          </div> -->
        </div>
      </div>
    </template>
    <!-- ------------------------------------ Save plan ----------------------------------------- -->
    <div v-if="userToken" class="option-foot">
      <div class="save">
        <div class="checkbox">
          <v-checkbox v-model="save" color="#3067F2" :label="saveTitle" />
        </div>
        <div v-show="save" class="prescription-text">
          <input v-model="info.title" type="text" class="my-prescription" placeholder="Save this Prescription as" />
        </div>
      </div>
      <p class="illustrate" @click="openDialog('illustrate')">How to fill out the prescription?</p>
    </div>
    <components :is="dialogName" :show="showDialog" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import uploadImg from '@/components/toolCom/uploadImg'
const addDialog = () => import('@/components/Lproduct/dialogs/addDialog.vue')
const pdDialog = () => import('@/components/Lproduct/dialogs/pdDialog.vue')
const illustrateDialog = () => import('@/components/Lproduct/dialogs/illustrateDialog.vue')

export default {
  name: 'prescriptionCom',
  components: {
    uploadImg
  },
  data() {
    return {
      saveTitle: 'Save My Prescription',
      planType: 1, // 方案填写方式 ( 1 form  0 图片  )
      showDialog: false, // 说明弹窗显示
      dialogName: null, // 弹窗说明  组件切换
      save: true, // 是否保存方案
      twoPd: false, // true -- 是否有两个pd值
      addState: true, // 是否禁用add
      SPH: [], // 镜面数据
      CYL: [], // 镜面数据
      PD: [], // 镜面数据
      pdOnlyData: [],
      pdTwoData: [],
      ADD: [], // 镜面数据
      Axis: [], // 镜面数据
      prescriptionName: '', // 选定方案名称
      items: [], // 方案列表
      currentPlan: undefined, // 当前方案
      info: {
        od_sph: '0.00',
        od_cyl: '0.00',
        od_axis: null,
        od_add: null,
        os_sph: '0.00',
        os_cyl: '0.00',
        os_axis: null,
        os_add: null,
        pd_left: null,
        pd_right: null,
        is_two_pd: 0,
        title: null,
        img_path: null
      },
      Raxios: true, // 禁用右axios
      Laxios: true // 禁用左axios
    }
  },
  computed: {
    ...mapState({
      limit: state => state.productConfig.prescriptionData,
      currentStep: state => state.productConfig.step.currentStep,
      nameStep: state => state.productConfig.step.name,
      userToken: state => state.system.userToken
    }),
    // 确保add一致
    addStateError() {
      const odAdd = this.info.od_add
      const osAdd = this.info.os_add
      if (odAdd !== osAdd) {
        return true
      }
      return false
    }
  },
  watch: {
    // 切换到登录状态
    userToken(val) {
      // 不会退出
      if (val) {
        this.initPageData()
        this.getUserPlan()
      }
    },
    // 监听当前方案 切换保存提示
    currentPlan: {
      handler(val) {
        if (val === 'add' || !val) {
          this.saveTitle = 'Save My Prescription'
        } else {
          this.saveTitle = 'Update My Prescription'
        }
        if (!val) {
          this.save = false
        }
      },
      immediate: true
    },
    'info.pd_right': {
      handler(val) {
        // 只有一个PD
        if (!this.twoPd) {
          this.info.pd_left = val
        }
      },
      deep: true
    },
    'info.od_cyl': {
      handler(val) {
        // cyl 为 0 时
        if (val && val !== '0.00') {
          this.Raxios = false
        } else {
          this.Raxios = true
          this.info.od_axis = ''
        }
      },
      deep: true,
      immediate: true
    },
    'info.os_cyl': {
      handler(val) {
        // cyl 为 0 时
        if (val && val !== '0.00') {
          this.Laxios = false
        } else {
          this.Laxios = true
          this.info.os_axis = ''
        }
      },
      deep: true,
      immediate: true
    },
    // pd 数量
    twoPd(val) {
      // 单个
      if (!val) {
        // 一个PD
        this.info.is_two_pd = 0
        this.PD = this.pdOnlyData
      } else {
        this.info.is_two_pd = 1
        // this.info.pd_left = ''
        // this.info.pd_right = ''
        this.PD = this.pdTwoData
      }
      this.setPdDefault()
    },
    currentStep: {
      handler(val, old) {
        // 从上一步到下一步
        if (val > old && this.currentStep === 2) {
          this.initPageData()
          this.getUserPlan()
        } else if (this.currentStep === 2 && val < old && this.save) {
          this.getUserPlan(true)
          this.save = false
        }
      },
      immediate: true,
      deep: true
    }
  },
  activated() {
    if (!this.currentPlan) {
      setTimeout(() => {
        this.save = false
      }, 0)
    }
  },
  mounted() {
    this.getUserPlan()
  },
  methods: {
    goLogin() {
      this.$store.commit('system/toggleLogin', true)
    },
    // 手动切换pd  清空值
    changeTwoPd() {
      this.info.pd_left = ''
      this.info.pd_right = ''
    },
    // 页面级初始化
    initPageData() {
      Object.assign(this.$data, this.$options.data())
      this.setPdDefault()
    },
    // 获取方案 unset 是否设置第一个方案
    getUserPlan(unset) {
      // 获取方案-----------------
      if (this.userToken) {
        this.$api.userCenter.prescriptionList().then(res => {
          if (res.code === 200) {
            const list = res.data
            const planList = []
            for (let index = 0; index < list.length; index++) {
              const element = list[index]
              planList.push({
                title: element.title,
                id: element.id
              })
            }
            this.items = planList
            if (!unset) {
              // 默认加载第一个
              if (planList.length && this.$route.query.type !== 'edit') {
                this.currentPlan = planList[0].id
                this.planChange(planList[0].id)
              }
            }

            this.items.push({ title: 'Add New RX', id: 'add' })
          }
        })
      } else {
        this.setPdDefault()
      }
      // eslint-disable-next-line no-console
      setTimeout(() => {
        if (this.limit.ADD === false) {
          this.addState = true
          // 不可选
        } else {
          this.addState = false
          this.ADD = this.renderData('-Select ADD-', 0.25, this.limit.ADD.min, this.limit.ADD.max)
        }
        this.SPH = this.renderData('None/SPH', 0.25, this.limit.SPH.min, this.limit.SPH.max)
        this.CYL = this.renderData('None/CYL', 0.25, this.limit.CYL.min, this.limit.CYL.max)
        this.Axis = this.renderData('-Choose Axis-', 1, 1, 180, true)
        // this.year = this.renderYear('-Select-')
        this.pdOnlyData = this.renderData('-Select PD-', 1, 54, 78, true)
        this.pdTwoData = this.renderData('-Select PD-', 1, 25, 41, true)
        // this.PD = this.renderData('-Select PD-', 1, 54, 78, true)
        if (this.twoPd) {
          this.PD = this.pdTwoData
        } else {
          this.PD = this.pdOnlyData
        }
        // 回显数据
        if (this.$route.query.type === 'edit') {
          this.echoConfigData()
        }
      }, 0)
    },
    // 切换方案
    planChange(val) {
      if (!val) {
        return false
      }
      if (this.$refs.imgCom) {
        this.$refs.imgCom.clearFile()
        this.info.img_path = ''
      }
      // 添加新方案
      if (val === 'add') {
        this.addNew()
        return false
      }
      this.$api.userCenter
        .prescriptionDetiles({
          prescription_id: val
        })
        .then(res => {
          if (res.code === 200) {
            this.info = res.data
            this.renderPlanDataLimit(this.info)
          }
        })
    },
    // 回显流程数据
    echoConfigData() {
      const data = this.$store.state.productConfig.RXShowData
      const newData = { ...data }
      this.info = newData
      this.set_sph_syl_default()
      this.renderPlanDataLimit(newData)
    },
    // 切换方案的设置
    renderPlanDataLimit(res) {
      // 设置模式
      if (res.img_path) {
        this.planType = 0
      } else {
        this.planType = 1
      }
      // 设置PD数量
      if (res.is_two_pd === 1) {
        this.twoPd = false
      } else if (res.is_two_pd === 0) {
        this.twoPd = true
      }
      this.setPdDefault()
      // 限制
      if (res.od_sph - 0 < this.limit.SPH.min - 0 || res.od_sph - 0 > this.limit.SPH.max - 0) {
        this.info.od_sph = '0.00'
      }
      if (res.os_sph - 0 < this.limit.SPH.min - 0 || res.od_sph - 0 > this.limit.SPH.max - 0) {
        this.info.os_sph = '0.00'
      }
      if (res.od_cyl - 0 < this.limit.CYL.min - 0 || res.od_sph - 0 > this.limit.CYL.max - 0) {
        this.info.od_cyl = '0.00'
      }
      if (res.os_cyl - 0 < this.limit.CYL.min - 0 || res.od_sph - 0 > this.limit.CYL.max - 0) {
        this.info.os_cyl = '0.00'
      }
    },
    // 设置默认pd值
    setPdDefault() {
      setTimeout(() => {
        if (this.twoPd) {
          if (!this.info.pd_left) {
            this.info.pd_left = '32'
          }
          if (!this.info.pd_right) {
            this.info.pd_right = '32'
          }
        } else {
          if (!this.info.pd_left) {
            this.info.pd_left = '64'
          }
          if (!this.info.pd_right) {
            this.info.pd_right = '64'
          }
        }
      }, 0)
    },
    set_sph_syl_default() {
      setTimeout(() => {
        if (!this.info.od_sph) {
          this.info.od_sph = '0.00'
        }
        if (!this.info.od_cyl) {
          this.info.od_cyl = '0.00'
        }
        if (!this.info.os_sph) {
          this.info.os_sph = '0.00'
        }
        if (!this.info.os_cyl) {
          this.info.os_cyl = '0.00'
        }
      }, 0)
    },
    // 切换处方方式(图片) 重置数据
    changeInitData() {
      this.info.od_sph = '0.00'
      this.info.od_cyl = '0.00'
      this.info.od_axis = null
      this.info.od_add = null
      this.info.os_sph = '0.00'
      this.info.os_cyl = '0.00'
      this.info.os_axis = null
      this.info.os_add = null
    },
    // 获取保存的数据
    savefn() {
      if (this.planType === 1) {
        if (!this.Laxios && !this.info.os_axis) {
          this.$tips({ msg: 'Both CYL and AXIS must have a number or both must be 0. Please check your entries.' })
          return false
        }
        if (!this.Raxios && !this.info.od_axis) {
          this.$tips({ msg: 'Both CYL and AXIS must have a number or both must be 0. Please check your entries.' })
          return false
        }
      }
      if (this.addStateError) {
        this.$tips({ msg: 'Please check if the add option is correct.' })
        return false
      }
      if (!this.info.pd_right || !this.info.pd_left) {
        this.$tips({ msg: 'Please enter your PD value.' })
        return false
      }
      if (this.userToken && this.save && !this.info.title) {
        this.$tips({ msg: 'please enter your prescription name.' })
        return false
      }
      if (this.userToken) {
        this.$set(this.info, 'savePlan', this.save)
      }
      this.filterSaveData()
      let file = null
      if (this.planType === 0) {
        file = this.$refs.imgCom.formData
      }
      return { info: this.info, file }
    },
    // 保存前处理数据
    filterSaveData() {
      //  处方类型  / 数据 / 图片
      if (this.planType === 1) {
        this.info.is_img_path = 1
        this.info.img_path = null
      } else if (this.planType === 0) {
        this.changeInitData()
        this.info.is_img_path = 0
      }
      // PD转换
      if (this.twoPd) {
        this.info.is_two_pd = 0
      } else {
        this.info.is_two_pd = 1
      }
      // add数据
      if (this.addState) {
        this.info.od_add = ''
        this.info.os_add = ''
      }
      if (!this.info.od_cyl) {
        this.info.od_cyl = ''
      }
      if (!this.info.os_cyl) {
        this.info.os_cyl = ''
      }
      if (!this.info.od_sph) {
        this.info.od_sph = ''
      }
      if (!this.info.os_sph) {
        this.info.os_sph = ''
      }
    },
    // 说明性弹窗
    openDialog(type) {
      switch (type) {
        case 'add':
          this.dialogName = addDialog
          break
        case 'pd':
          this.dialogName = pdDialog
          break
        case 'illustrate':
          this.dialogName = illustrateDialog
          break
        default:
          this.dialogName = illustrateDialog
          break
      }
      // type === 'add' ? (this.dialogName = addDialog) : (this.dialogName = pdDialog)
      this.showDialog = true
    },
    // 添加新方案
    addNew() {
      // Object.assign(this.$data, this.$options.data().info)
      this.info = this.$options.data().info
      // this.currentPlan = undefined
      this.currentPlan = 'add'
      // 切换到填写
      this.planType = 1
      this.twoPd = false
      this.setPdDefault()
      this.$set(this.info, 'id', 0)
    },
    // tips:  try-on 模块pd数据
    // 生成镜片数据
    renderData(defaultVla, interval = 0.25, negativeMin = -20, positiveMax = 12, simple) {
      // simple false  +1.00  true 1
      // 数字间隔
      // const interval = 0.25
      // let negativeMin = -18
      // const positiveMax = 10
      const list = []
      for (negativeMin; negativeMin <= positiveMax; negativeMin = negativeMin + interval) {
        if (!simple) {
          let resNum = negativeMin.toFixed(2)
          if (resNum > 0) {
            resNum = '+' + resNum
          }
          list.push(resNum)
        } else {
          list.push(negativeMin + '')
        }
      }
      return list
    }
    // 生成年份数据
    // renderYear(defaultVal) {
    //   const year = new Date().getFullYear()
    //   const end = year - 5 // 当前年份 - 5
    //   let start = end - 98
    //   const arr = [defaultVal]
    //   for (start; start <= end; start++) {
    //     // const element = array[index];
    //     arr.push(start)
    //   }
    //   return arr
    // }
  }
}
</script>

<style lang="scss" scoped>
.step-body {
  height: 100%;
  .option-header {
    min-height: 44px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    .upload-icon {
      height: 13px;
      margin-right: 10px;
    }
  }
  .left {
    display: flex;

    .select-list {
      max-width: 224px;
      height: 100%;
      margin-right: 20px;
      ::v-deep .v-input__slot {
        min-height: 44px !important;
        .v-label {
          color: #2b2e33 !important;
          top: 12px;
        }
      }
      ::v-deep .v-input__append-inner {
        margin-top: 10px !important;
      }
    }
    .add {
      text-align: center;
      width: 142px;
      height: 100%;
      font-size: 14px;
      line-height: 40px;
      color: #2b2e33;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      border: 1px solid #9e9e9e;
      cursor: pointer;
      span {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
  .login-text {
    font-size: 14px;
    color: #3067f2;
    line-height: 44px;
    cursor: pointer;
  }
  .right {
    font-size: 14px;
    color: #3067f2;
    line-height: 44px;
    cursor: pointer;
  }
  .option-foot {
    margin-top: 20px;
    padding-left: 32px;
    .birth,
    .save {
      height: 30px;
      display: flex;
      // align-items: flex-end;
      margin-bottom: 20px;
    }
    .checkbox {
      width: 200px;
      margin-right: 60px;
    }
    .prescription-text {
      border-bottom: 1px solid #949494;
      input {
        outline: none;
      }
    }
    .select-date,
    .prescription-text {
      width: 200px;
      position: relative;
      // top: -8px;
    }
  }
  .rescription {
    position: relative;
    max-width: 1200px;
    height: auto;
    min-height: 305px;
    border: 1px solid #525861;
    // box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.06);
    padding: 0 3%;

    .th-img {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 1;
      height: 18px;
      cursor: pointer;
    }
  }
  .form-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 40px;
    .item {
      font-size: 14px;
      color: #2b2e33;
      font-weight: 600;
      // flex-shrink: 0;
      width: 15%;
      position: relative;
    }
  }
  .form-body {
    margin-bottom: 40px;
  }
  .type.item,
  .col-title {
    font-weight: 600;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 40px;
    margin-top: 28px;
    height: 35px;
    .type {
      font-size: 14px;
      color: #2b2e33;
      display: flex;
      align-items: flex-end;
    }
    .item {
      width: 15%;
      height: auto;
      position: relative;
    }
    .one-pd {
      width: calc(36% + 2px);
      height: auto;
    }
    .threecol.one-pd {
      width: calc(43% + 5px);
    }
    .select-box {
      height: 55%;
      width: 36%;
      position: relative;
    }
    .threecol.select-box {
      width: 15%;
    }
    .threecol.moblie.select-box {
      width: 50%;
    }
  }
}
.tips-img {
  position: absolute;
  top: 0;
  left: 30px;
  height: 18px;
  cursor: pointer;
}
.uploadImg-box {
  ::v-deep .upload-box {
    justify-content: center;
  }
  .list {
    justify-content: normal;
    .type {
      font-size: 16px;
      font-weight: 400;
      color: #212121;
    }
    .item {
      width: 200px;
      margin-right: 60px;
    }
    .select-box {
      position: relative;
    }
    .tips-img {
      position: absolute;
      top: 2px !important;
    }
  }
}
.error-message {
  display: flex;
  flex-direction: row-reverse;
  span {
    display: inline-block;
    width: 79%;
    margin-top: 20px;
    color: red;
  }
}
.illustrate {
  display: inline-block;
  padding-left: 3px;
  margin-bottom: 0;
  font-size: 15px;
  color: #2b2e33;
  text-decoration: underline;
  cursor: pointer;
}
::v-deep .v-input__slot {
  height: 30px;
  min-height: 30px !important;
}
::v-deep .v-input__append-inner {
  margin-top: 4px !important;
}
::v-deep .v-input,
::v-deep .v-label {
  font-size: 15px !important;
  color: #2b2e33 !important;
}
</style>
<!-- <style scoped lang="scss">
@media screen and (max-width: 1250px) {
  .step-body {
  }
}
</style> -->
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .step-body {
    .left .select-list {
      margin-right: 14px;
    }
    .form-body {
      margin-bottom: 0;
    }
    // .option-header {
    //   flex-direction: row-reverse;
    // }
    .rescription {
      // max-width: 1200px;
      // height: 305px;
      padding: 0;
      border-top: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
      border-left: none;
      border-right: none;
    }

    .type.item,
    .col-title {
      font-size: 13px;
    }
    .col-title {
      margin-bottom: 5px;
    }
    .type.item {
      margin-top: 5px;
    }
    .list {
      height: auto;
      // margin-bottom: 20px;
      margin-top: 10px;
      .item {
        width: 31%;
        ::v-deep .v-input__slot {
          height: 44px;
          // min-height: 30px !important;
        }
        ::v-deep .v-select__slot .v-input {
          font-size: 13px !important;
        }
        ::v-deep .v-input__append-inner {
          margin-top: 10px !important;
        }
        ::v-deep .v-input,
        ::v-deep .v-label {
          font-size: 13px !important;
        }
        // .v-label {
        //   font-size: 13px;
        // }
      }
      .type {
        width: 100%;
      }
      .one-pd {
        width: 65.5%;
        // height: auto;
      }
      .select-box {
        // height: 45px;
        height: 24px;
        width: 50%;
        margin-top: 10px;
      }
      .tips-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
      }
      .tips-img {
        top: 0;
        left: 30px;
        height: 16px;
      }
    }
    .mobile-list {
      display: flex;
      justify-content: flex-start;
      .item {
        margin-right: 3.5%;
      }
    }
    .end-list {
      margin-bottom: 10px;
    }
    .option-foot {
      margin-top: 12px;
      padding-left: 0;
      margin-bottom: 80px;
      overflow: hidden;
      .checkbox {
        width: 115px;
        margin-right: 30px;
        ::v-deep .v-input__slot {
          height: 35px !important;
        }
      }
      .select-date,
      .prescription-text {
        flex: 1;
        width: auto;
      }
    }
  }
  .uploadImg-box {
    .list {
      justify-content: normal;
      .type {
        font-size: 16px;
        font-weight: 400;
        color: #212121;
      }
      .item {
        width: 100%;
        margin-right: 0;
      }
    }
  }
  .error-message {
    span {
      width: 100%;
      font-size: 14px;
      margin-top: 0;
    }
  }
}
</style>
