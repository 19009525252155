<!-- 购买产品流程 -->
<template>
  <div class="page">
    <!-- <LNavbar /> -->
    <div class="nav-header">
      <div class="empty"></div>
      <div class="logo">
        <img src="/imgs/logo.png" alt="logo" />
      </div>
      <div class="close">
        <img src="/imgs/close.png" alt="close" @click="back" />
      </div>
    </div>
    <Nuxt />
  </div>
</template>

<script>
// 导航栏
// import LNavbar from '@/components/Lnav/Lnavbar/index.vue'

export default {
  name: 'IndexPage',
  components: {
    // LNavbar
  },
  scrollToTop: true,
  data() {
    return {}
  },
  methods: {
    back() {
      this.$confirm({ msg: 'Do you want to exit the lenses choosing process?' }).then(res => {
        if (res === 'success') {
          this.$router.back()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
$headerHeight: 60px;
.page {
  height: 100%;
  position: relative;
  ::v-deep .product-config {
    margin-top: $headerHeight;
  }
  ::v-deep .config-box {
    height: calc(100% - #{$headerHeight});
  }
}
.nav-header {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  height: $headerHeight;
  background: #ffffff;
  border-bottom: 1px solid #e4e4e4;
}
.logo,
.close {
  display: flex;
  img {
    height: 100%;
    // width: 100%;
  }
}
.logo {
  height: 40px;
}
.close {
  height: 18px;
  cursor: pointer;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 600px) {
  $headerHeight: 46px;
  .page {
    ::v-deep .product-config {
      margin-top: $headerHeight;
    }
    ::v-deep .config-box {
      height: calc(100% - #{$headerHeight});
    }
  }
  .nav-header {
    padding: 0 16px;
    height: $headerHeight;
  }
  .logo {
    height: 28px;
  }
  .close {
    height: 14px;
  }
}
</style>
