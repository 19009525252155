<!-- 个人中心 -->
<template>
  <div class="page">
    <!-- <template v-if="$vuetify.breakpoint.name !== 'xs'">
      <div class="page-content">
        <menuLeft />
        <Nuxt />
      </div>
    </template>
    <template v-else>
      <mobileMenu />
    </template>
    <Lfooter v-if="$vuetify.breakpoint.name !== 'xs'" /> -->
    <mobilePageHeaderBack v-if="$vuetify.breakpoint.smAndDown" />

    <div class="page-content">
      <menuLeft v-if="$vuetify.breakpoint.mdAndUp" />
      <div class="main-right">
        <mobilePageHeaderBack v-if="$vuetify.breakpoint.mdAndUp" />
        <!-- ceshimingz -->
        <Nuxt />
      </div>
    </div>
    <Lfooter />
  </div>
</template>

<script>
import menuLeft from '@/components/LcenterMain/menu'
import mobilePageHeaderBack from '@/components/LcenterMain/mobilePageHeaderBack'
// import mobileMenu from '@/components/LcenterMain/mobileMenu'
import Lfooter from '@/components/Lfooter/index.vue'

export default {
  name: 'user-center',
  components: {
    menuLeft,
    mobilePageHeaderBack,
    // mobileMenu,
    Lfooter
  },
  scrollToTop: true,
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
  // background: #f7f7f7;
}
.page-content {
  // height: 100%;
  padding-top: 40px;
  max-width: 1200px;
  margin: 0 auto 50px;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}
.main-right {
  margin-left: 40px;
  flex: 1;
}
// ::v-deep .center-body {

// }
// ::v-deep .back-box {
//   margin-left: 40px;
//   flex: 1;
// }
@media screen and (max-width: 1480px) {
  .page-content {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }
}
@media screen and (max-width: 960px) {
  .page {
    background: #ffffff;
  }
  .page-content {
    padding: 0;
    margin: 0;
  }
  ::v-deep .main-right {
    margin-left: 0;
    flex: 1;
    padding: 0 16px;
  }
}
</style>
