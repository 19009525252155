<template>
  <div class="step-body">
    <div class="list">
      <p v-for="(item, index) in magnification" :key="index" class="item" :class="{ active: index == current }" @click="select(index)">{{ item }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import flow from '@/components/Lproduct/mixin/getFlow'
export default {
  name: 'lensReaders',
  mixins: [flow],
  data() {
    return {
      current: null, // 当前选中类型
      magnification: []
    }
  },
  computed: {
    ...mapState({
      limit: state => state.productConfig.renderData
    })
  },
  watch: {},
  created() {},
  activated() {
    this.magnification = this.renderData(null, 0.25, this.limit.min, this.limit.max)
  },
  mounted() {},
  methods: {
    renderData(defaultVla, interval = 0.25, negativeMin = -20, positiveMax = 12) {
      // 数字间隔
      // const interval = 0.25
      // let negativeMin = -18
      // const positiveMax = 10
      const list = []
      //   if (negativeMin > 0) {
      //     list.push(defaultVla)
      //   }
      for (negativeMin; negativeMin <= positiveMax; negativeMin = negativeMin + interval) {
        if (negativeMin === 0 && defaultVla) {
          list.push(defaultVla)
        } else {
          let resNum = negativeMin.toFixed(2)
          if (resNum > 0) {
            resNum = '+' + resNum
          }
          list.push(resNum)
          // list.push(negativeMin.toFixed(2))
        }
      }
      return list
    },
    select(index) {
      this.current = index
      this.$emit('lensesReaders', {
        data: { value: this.magnification[index], money: 20 },
        type: 'magnification'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// .enter-prescription .readers ul li[data-v-f2fc3078]:nth-child(4n) {
//     margin-right: 0;
// }
.step-body {
  .list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 165px;
      height: 50px;
      margin-right: 6px;
      border: 1px solid #525861;
      border-radius: 30px;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        background: #3067f2;
        border: 1px solid #3067f2;
        color: #fff;
      }
    }
    .active {
      background: #3067f2;
      border: 1px solid #3067f2;
      color: #fff;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .step-body .list .item {
    margin-right: 28px;
  }
}
@media screen and (max-width: 1189px) {
  .step-body .list .item {
    margin-right: 25px;
  }
}
@media screen and (max-width: 600px) {
  .step-body {
    .list {
      justify-content: space-around;
      .item {
        width: 30%;
        height: 40px;
        margin-right: 0;
      }
    }
  }
}
</style>
