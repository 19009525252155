<template>
  <div class="left-menu">
    <div v-if="$vuetify.breakpoint.smAndDown" class="user-box" @click="toPage">
      <div class="user-info">
        <div class="right">
          <div class="top">{{ userInfo.firstname }} {{ userInfo.lastname }}</div>
          <div class="bottom">{{ userInfo.email }}</div>
        </div>
      </div>
      <div class="user-more">
        <img class="more" src="/imgs/to.png" alt="My Account" />
      </div>
    </div>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="item account">
      <nuxt-link :to="{ name: 'centerMain-account' }">My Account</nuxt-link>
    </div>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <div class="item">
        <h4 class="item-title">My Order</h4>
        <div class="item-link-box">
          <nuxt-link class="item-link" :to="{ name: 'centerMain-order-order' }">Order List</nuxt-link>
        </div>
      </div>
      <div class="item">
        <h4 class="item-title">Account Settings</h4>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="item-link-box">
          <nuxt-link class="item-link" :to="{ name: 'centerMain-settings-Info' }">Personal Info</nuxt-link>
        </div>
        <div class="item-link-box">
          <nuxt-link class="item-link" :to="{ name: 'centerMain-settings-address' }">Address Management</nuxt-link>
        </div>
        <div class="item-link-box">
          <nuxt-link class="item-link" :to="{ name: 'centerMain-settings-password' }">Change Password</nuxt-link>
        </div>
        <!-- <div class="item-link-box">
        <nuxt-link class="item-link" :to="{ name: 'centerMain-settings-subscription' }">E-Mail Subscription</nuxt-link>
      </div> -->
        <div class="item-link-box">
          <nuxt-link class="item-link" :to="{ name: 'centerMain-settings-prescription' }">My Prescription</nuxt-link>
        </div>
      </div>
      <div class="item">
        <h4 class="item-title">My Wallet</h4>
        <!-- <div class="item-link-box">
        <nuxt-link class="item-link" :to="{ name: 'centerMain-wallet-wallet' }">My Wallet</nuxt-link>
      </div> -->
        <div class="item-link-box">
          <nuxt-link class="item-link" :to="{ name: 'centerMain-wallet-coupons' }">My Coupons</nuxt-link>
        </div>
      </div>
      <div class="item">
        <h4 class="item-title">My Personalization</h4>
        <div class="item-link-box">
          <nuxt-link class="item-link" :to="{ name: 'centerMain-personalization-wishlist' }">My Wishlist</nuxt-link>
        </div>
      </div>
      <div class="item">
        <h4 class="item-title">My Tickets</h4>
        <!-- <div class="item-link-box">
        <nuxt-link class="item-link" :to="{ name: 'centerMain-tickets-my-tickets', query: { type: 0 } }">My Tickets</nuxt-link>
      </div> -->
        <div class="item-link-box">
          <nuxt-link class="item-link" :to="{ name: 'centerMain-tickets-tickets-history' }">Tickets History</nuxt-link>
        </div>
      </div>
    </template>
    <template v-if="$vuetify.breakpoint.smAndDown">
      <div class="mobile-box">
        <nuxt-link class="item-link" :to="{ name: 'centerMain-order-order' }">
          <img class="left-img" src="/imgs/center/order-sty.png" alt="" />
          <div class="right">
            <p class="page-name">My Orders</p>
            <p class="page-tips">View order details</p>
          </div>
        </nuxt-link>
        <nuxt-link class="item-link" :to="{ name: 'centerMain-settings-address' }">
          <img class="left-img" src="/imgs/center/address-sty.png" alt="" />
          <div class="right">
            <p class="page-name">My Address</p>
            <p class="page-tips">Manage addresses</p>
          </div>
        </nuxt-link>
        <nuxt-link class="item-link" :to="{ name: 'centerMain-settings-prescription' }">
          <img class="left-img" src="/imgs/center/rx-sty.png" alt="" />
          <div class="right">
            <p class="page-name">Prescriptions</p>
            <p class="page-tips">Manage prescriptions</p>
          </div>
        </nuxt-link>
        <nuxt-link class="item-link" :to="{ name: 'centerMain-personalization-wishlist' }">
          <img class="left-img" src="/imgs/center/wishlist-sty.png" alt="" />
          <div class="right">
            <p class="page-name">My Wishlist</p>
            <p class="page-tips">Manage wishlist</p>
          </div>
        </nuxt-link>
        <nuxt-link class="item-link" :to="{ name: 'centerMain-wallet-coupons' }">
          <img class="left-img" src="/imgs/center/coupons-sty.png" alt="" />
          <div class="right">
            <p class="page-name">My Coupons</p>
            <p class="page-tips">View available coupons</p>
          </div>
        </nuxt-link>
        <nuxt-link class="item-link" :to="{ name: 'centerMain-settings-password' }">
          <img class="left-img" src="/imgs/center/password-sty.png" alt="" />
          <div class="right">
            <p class="page-name">Password</p>
            <p class="page-tips">Manage password</p>
          </div>
        </nuxt-link>
        <nuxt-link class="item-link" :to="{ name: 'centerMain-tickets-tickets-history' }">
          <img class="left-img" src="/imgs/center/tip-sty.png" alt="" />
          <div class="right">
            <p class="page-name">Tickets History</p>
            <p class="page-tips">View tickets history</p>
          </div>
        </nuxt-link>
      </div>
    </template>
    <v-btn v-if="$vuetify.breakpoint.smAndDown" block outlined color="indigo" class="my-btn out-btn" @click="out">Log out</v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'menuBox',
  data() {
    return {}
  },
  computed: {
    ...mapState('system', ['userInfo'])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    toPage() {
      this.$router.push({
        name: 'centerMain-settings-Info'
      })
      // this.$emit('close')
    },
    out() {
      this.$store.commit('system/logOut')
      // this.$router.replace({
      //   name: '/'
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.left-menu {
  width: 280px;
  height: 100%;
}
.account {
  padding: 15px 0 10px 20px;
  background: #3067f2;
  a {
    color: #fff !important;
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
  }
}
.item-title {
  color: #41464d;
  font-size: 15px;
  background: #eaecf1;
  padding: 15px 0 10px 20px;
}
.item-link-box {
  padding: 10px 0 0px 20px;
  a {
    color: #525861;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
  }
  a:hover {
    color: #3067f2;
  }
}
.item-link-box:last-child {
  padding: 10px 0 10px 20px;
}
.item-link-box a.nuxt-link-exact-active {
  color: #3067f2 !important;
  text-decoration: underline;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .out-btn {
    height: 46px !important;
  }
  .mobile-box {
    .item-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-bottom: 3%;
      padding: 2%;
      border-radius: 8px;
      background: #f0f8ff;
    }
    .left-img {
      width: 16%;
      margin-right: 3%;
    }
    .page-name {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 6px;
      color: #1e1e1e;
    }
    .page-tips {
      margin-bottom: 0;
      color: #404040;
    }
  }
  .left-menu {
    width: 100%;
    margin-bottom: 30px;
  }
  .item {
    width: 100%;
    margin: 0 auto 8px;
    border: 1px solid #eaecf1;
  }
  .account {
    background: #fff;
  }
  .account > a {
    color: #000 !important;
  }
  .item-title {
    background: #eaecf1;
    color: #3067f2;
    padding: 8px 0 8px 20px !important;
  }
  .item-link-box {
    background: #fff;
    padding: 8px 0 8px 20px !important;
    border-bottom: 1px solid #e8e8e8 !important;
  }

  .item-link-box:last-child {
    border-bottom: none;
  }
  .item-link-box > a {
    color: #000;
  }
  .user-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    margin-bottom: 8px;
    background: #3067f2;
    color: #fff;
    border-radius: 10px;
  }
  .user-info {
    display: flex;
    align-items: center;
    flex: 1;
    .user {
      height: 50px;
    }

    .left {
      display: flex;
    }
    .right > .top {
      font-size: 26px;
    }
    .right > .bottom {
      margin-top: 14px;
    }
  }
  .more {
    height: 18px;
  }
}
</style>
