<template>
  <div class="count-body">
    <div id="count-content" class="count-content">
      <div class="count-box">
        <ul class="list">
          <li>Items:{{ total.length == 0 ? '' : total.length }}</li>
          <!-- <li>
            <span class="left">Subtotal</span>
            <span class="right">${{ total.subtotal }}</span>
          </li> -->
        </ul>
        <div class="option">
          <div class="count">
            <span class="left">Grand Total:</span>
            <span class="right">${{ total.subtotal }}</span>
          </div>
          <div class="btn-box">
            <v-btn class="my-btn option-btn blue-btn" color="#3067F2" :disabled="!total.subtotal || loading" :loading="loading" tile depressed block @click="goPay">Proceed to Checkout</v-btn>
            <p class="go-shopping"><span @click="goHome">Continue Shopping</span></p>
            <!-- <v-btn class="my-btn option-btn white-btn" color="#fff" tile depressed block >Continue Shopping</v-btn> -->
          </div>
        </div>
        <div class="text">
          <p><img class="icon" src="/imgs/transport.png" alt="icon" />Free shipping over US $69.00</p>
          <p><img class="icon" src="/imgs/30.png" alt="icon" />30-day Return&Exchange</p>
          <p><img class="icon" src="/imgs/repair2.png" alt="icon" />Customized by Craftsmen with Care</p>
        </div>
        <div class="accept">
          <p class="accept-title">We accept:</p>
          <img class="accept-icon" src="/imgs/accept.png" alt="accept-list" />
          <p class="accept-tips">We've also integrated digital wallets like Apple Pay, Google Pay and Cash App Pay, by which you don't need to type in card information during checkout.</p>
        </div>
      </div>
    </div>
    <div class="flexd-option">
      <div class="count">
        <span class="left">Grand Total:</span>
        <span class="right">${{ total.subtotal }}</span>
      </div>
      <div class="btn-box">
        <v-btn class="my-btn option-btn blue-btn" color="#3067F2" :disabled="!total.subtotal || loading" :loading="loading" tile depressed block @click="goPay">Proceed to Checkout</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/assets/utils/toolFn'
export default {
  name: 'countCom',
  props: {
    // 已选商品 数量,价格
    total: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    // setInterval(() => {
    //   console.log(this.$vuetify.breakpoint.width)
    // }, 200)
    // if (this.$vuetify.breakpoint.width < 960) {
    const flexdDom = document.querySelector('.flexd-option')
    const countDom = document.querySelector('#count-content')

    const observer = new IntersectionObserver(
      entries => {
        if (this.$vuetify.breakpoint.width <= 1200) {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              flexdDom.style.display = 'none'
            } else {
              flexdDom.style.display = 'block'
            }
          })
        }
      },
      { threshold: 0.25 }
    )
    observer.observe(countDom)
    // }
  },
  methods: {
    goHome() {
      this.$router.push({
        name: 'index'
      })
    },
    // goPay() {
    //   this.$emit('pay')
    // }
    goPay: debounce(function () {
      this.$emit('pay')
    }, 200)
  }
}
</script>

<style lang="scss" scoped>
// @media screen and (min-width: 1280px) {
//   .count-content {
//     position: sticky;
//     top: 0;
//   }
// }
.accept {
  margin-top: 10px;
  .accept-title {
    font-size: 14px;
    margin-bottom: 0;
  }
  .accept-icon {
    width: 100%;
    max-width: 380px;
  }
  .accept-tips {
    font-size: 12px;
    color: #9a9a9a;
  }
}
.go-shopping {
  font-size: 14px;
  text-align: center;
  color: #3067f2;
  margin-top: 18px;
  span {
    cursor: pointer;
  }
}
.count-body {
  width: 430px;
  .flexd-option {
    display: none;
    z-index: 1;
  }
}
.count-content {
  position: sticky;
  top: 110px;
}
.count-box {
  padding: 30px 25px;
  background: #fff;
}
.list {
  list-style: none;
  padding-left: 0 !important;
  li {
    font-size: 16px;
    color: #2b2e33;
    font-weight: 600;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
}
.option {
  padding-top: 15px;
  padding-bottom: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #2b2e33;
  font-weight: 600;
  margin-bottom: 15px;
}
.option-btn {
  height: 52px !important;
}
.blue-btn {
  color: #fff !important;
  margin-bottom: 10px;
}
.text {
  overflow: hidden;
  margin-top: 16px;
  p {
    font-size: 14px;
    // font-weight: 600;
    color: #2b2e33;
    margin-top: 8px;
    margin-bottom: 0;
  }
  .icon {
    height: 18px;
    margin-right: 5px;
    position: relative;
    top: 4px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .count-body {
    width: 100%;
    .count-box {
      padding: 15px;
    }
    .count {
      justify-content: flex-start;
      margin-bottom: 20px;
      .left {
        margin-right: 6px;
      }
    }
    .flexd-option {
      display: block;
      position: fixed;
      bottom: 0;
      padding: 15px;
      width: 100%;
      background: #fff;
    }
  }
  .accept {
    .accept-tips {
      font-size: 14px;
    }
  }
}
</style>
