<template>
  <div class="center-body">
    <!-- 退款商品 -->
    <div class="goods">
      <div class="left">Refund Item:</div>
      <div class="right"></div>
    </div>
    <!-- 服务类型 -->
    <div class="goods-type">
      <div class="left">Service Type:</div>
      <div class="right">
        <v-radio-group v-model="serviceType" hide-details row>
          <v-radio label="Refund Only" value="0" />
          <v-radio label="Returns and Refunds" value="1" />
        </v-radio-group>
      </div>
    </div>
    <!-- 货物状态 -->
    <div class="goods-status">
      <div class="left">Cargo status:</div>
      <div class="right">
        <v-radio-group v-model="cargoStatus" hide-details row>
          <v-radio label="Unreceived Goods" value="0" />
          <v-radio label="Received Goods" value="1" />
        </v-radio-group>
      </div>
    </div>
    <!-- 退款金额 -->
    <div class="goods-price">
      <div class="left">refund amount:</div>
      <div class="right">
        <v-text-field v-model="amount" type="number" class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" title="Refund Amount" placeholder="Refund Amount" />
      </div>
    </div>
    <!-- 退款说明 -->
    <div class="goods-explain">
      <div class="left">Refund instructions:</div>
      <div class="right">
        <v-textarea v-model="explain" class="input-item" hide-details="auto" outlined title="Refund Instructions" placeholder="Refund Instructions" />
      </div>
    </div>
    <!-- 上传图片 -->
    <div class="goods-img">
      <div class="left">Upload images:</div>
      <div class="right">
        <upImg :length="3" />
      </div>
    </div>
    <!-- 提交退款 -->
    <div class="goods-btn">
      <v-btn class="my-btn submit" color="#3067F2" :disabled="disabled" tile depressed block @click="submit">Submit</v-btn>
    </div>
  </div>
</template>

<script>
import upImg from '@/components/toolCom/uploadImg.vue'
export default {
  name: 'afterSales',
  components: {
    upImg
  },
  data() {
    return {
      serviceType: 0,
      cargoStatus: 0,
      amount: '',
      explain: '',
      disabled: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.center-body {
  padding: 16px;
}
.center-body > div {
  display: flex;
  align-items: center;
  min-height: 50px;
}
.left {
  width: 180px;
  margin-right: 16px;
  font-size: 16px;
  color: #525861;
}
.right {
  ::v-deep .v-input__control {
    flex-wrap: nowrap;
  }
}
.goods-img {
  margin-top: 12px;
  margin-bottom: 12px;
}
.goods-btn {
  width: 200px;
  .submit {
    color: #fff !important;
    height: 42px;
  }
}
</style>
