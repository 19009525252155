<template>
  <div class="list">
    <div class="header-option">
      <h3>Shipping Address</h3>
    </div>
    <div class="content">
      <div class="default">
        <template v-if="JSON.stringify(currentAddress) !== '{}'">
          <div class="left">
            <p class="top">{{ currentAddress.firstname }} {{ currentAddress.lastname }} {{ currentAddress.phone }}</p>
            <div class="bottom">
              <p class="detail">
                <span>{{ currentAddress.address }}, </span>
                <template v-if="currentAddress.apt_suite">
                  <span>{{ currentAddress.apt_suite }}, </span> </template
                >{{ currentAddress.city }}, {{ currentAddress.state }}, {{ currentAddress.zip_code }}, {{ currentAddress.country }}
              </p>
              <span class="edit-btn" @click="editAddress">
                <img class="edit-icon" src="/imgs/edit.png" alt="edit" />
                Edit
              </span>
            </div>
          </div>
          <p class="more-btn" @click="getMoreList('shipping')">More</p>
        </template>
        <template v-else>
          <div class="empty-address">
            <p>You don't have a saved address yet!</p>
            <div class="btn-box">
              <v-btn class="my-btn option-btn blue-btn" color="#3067f2" tile depressed block @click="openDialogAdd">Add an address</v-btn>
            </div>
          </div>
        </template>
      </div>
      <div v-if="JSON.stringify(currentAddress) !== '{}'" class="other">
        <v-checkbox v-model="billing" label="Also use as billing address" class="input-item" color="#3067f2" :ripple="false" />
      </div>
      <!-- billing ------------------------------------- -->
      <template v-if="!billing">
        <div class="header-option space-top">
          <h3>Billing Address</h3>
        </div>
        <div class="default">
          <template v-if="JSON.stringify(billingAddress) !== '{}'">
            <div class="left">
              <p class="top">{{ billingAddress.firstname }} {{ billingAddress.lastname }} {{ billingAddress.phone }}</p>
              <div class="bottom">
                <p class="detail">
                  <span>{{ billingAddress.address }}, </span>
                  <template v-if="billingAddress.apt_suite">
                    <span>{{ billingAddress.apt_suite }}, </span> </template
                  >{{ billingAddress.city }}, {{ billingAddress.state }}, {{ billingAddress.zip_code }}, {{ billingAddress.country }}
                </p>
                <span class="edit-btn" @click="editAddressBilling">
                  <img class="edit-icon" src="/imgs/edit.png" alt="edit" />
                  Edit
                </span>
              </div>
            </div>
            <p class="more-btn" @click="getMoreList('billing')">More</p>
          </template>
        </div>
      </template>
      <addressForm :show="addressForm" :info="info" @close="addressForm = false" @save="saveForm" />
      <!-- 收货 -->
      <addresslist :show="moreDialog" :type="listType" @close="moreDialog = false" @setAddress="setAddress" />
      <!-- 订单 -->
      <addressForm :show="billingForm" type="billing" :info="billingAddress" @close="billingForm = false" @save="saveFormBilling" />
    </div>
  </div>
</template>

<script>
import addressForm from '@/components/LcenterMain/address/addressDialog'
import addresslist from '@/components/Lpayment/address/addressListDialog'
export default {
  name: 'addressList',
  components: {
    addressForm,
    addresslist
  },
  data() {
    return {
      listType: 'shipping', // 列表类型 (billing)
      // 当前地址 收货
      currentAddress: {},
      // 单条弹窗
      addressForm: false,
      // 新增地址
      info: {
        gender: undefined,
        firstname: undefined,
        lastname: undefined,
        country: undefined,
        address: undefined,
        apt_suite: undefined,
        city: undefined,
        zip_code: undefined,
        state: undefined,
        phone: undefined,
        backup_phone: undefined,
        is_primary: undefined,
        address_id: 0,
        id: undefined
      },
      // 地址列表
      moreDialog: false,
      list: [], // 地址列表
      //  订单地址---------------------
      // true 默认地址  flase 自主地址
      billing: true,
      billingForm: false,
      billingAddress: {}
    }
  },
  computed: {},
  watch: {
    // 是否需要订单
    billing(val) {
      this.billingAddress = { ...this.currentAddress }
      // if (!val) {
      //   this.billingAddress = { ...this.currentAddress }
      // } else {
      //   this.billingAddress = {}
      // }
    },
    // 监听地址变化
    currentAddress: {
      handler(val) {
        this.$emit('addressChange', val)
      },
      deep: true
    },
    billingAddress: {
      handler(val) {
        this.$emit('billing', val)
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    // 获取地址列表
    getList() {
      this.$api.userCenter.getAddressList().then(res => {
        if (res.code === 200) {
          this.list = res.data
          this.currentAddress = this.getDefaultAddress(res.data)
          // 默认使用派送地址
          if (this.billing) {
            this.billingAddress = this.currentAddress
          }
        }
      })
    },
    // 寻找默认地址  否则第一项
    getDefaultAddress(list) {
      if (!list.length) {
        return {}
      }
      const index = list.findIndex(address => address.is_primary === '1')
      if (index > -1) {
        return list[index]
      }
      return list[0]
      // return {}
    },
    initInfo() {
      Object.assign(this.$data.info, this.$options.data().info)
    },
    // 打开地址编辑 新增
    openDialogAdd() {
      this.initInfo()
      this.addressForm = true
    },
    // 编辑当前地址
    editAddress() {
      this.info = this.currentAddress
      this.addressForm = true
    },
    // 打开地址列表
    getMoreList(type) {
      this.listType = type
      this.moreDialog = true
    },
    // 设置当前地址
    setAddress(data) {
      if (data.type === 'shipping') {
        this.currentAddress = { ...data.data }
      } else {
        this.billingAddress = { ...data.data }
      }
      this.moreDialog = false
    },
    saveForm(data) {
      this.moreDialog = false
      this.$api.userCenter.saveAddress(data).then(res => {
        if (res.code !== 200) {
          this.getList()
        } else {
          // 使用保存的地址
          this.currentAddress = data
        }
      })
    },
    // ---------------------------------------
    editAddressBilling() {
      this.billingForm = true
    },
    saveFormBilling(data) {
      this.moreDialog = false
      this.$api.userCenter.saveAddress(data).then(res => {
        if (res.code !== 200) {
          this.getList()
        } else {
          // 使用保存的地址
          this.billingAddress = data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-address {
  font-size: 16px;
  color: #f13a3a;
  margin-bottom: 0;
}
.btn-box {
  width: 180px;
  .my-btn {
    height: 44px;
    color: #fff !important;
  }
}
.header-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h3 {
    font-size: 20px;
    border-bottom: 4px solid #3067f2;
  }
  .edit {
    color: #3067f2;
  }
}
.space-top {
  margin-top: 15px;
}
.default {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  .top {
    color: #000;
    margin-bottom: 8px;
  }
  .bottom {
    .detail {
      color: #2c2a2a;
      margin-bottom: 6px;
      margin-right: 10px;
    }
    .edit-btn {
      color: #3067f2;
      cursor: pointer;
      .edit-icon {
        height: 12px;
      }
    }
  }
  .more-btn {
    font-weight: 600;
    margin-bottom: 0;
    cursor: pointer;
  }
}
.other {
  display: inline-block;
  margin-top: 10px;
  height: 25px;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .header-option {
    h3 {
      font-size: 18px;
      border-bottom: none;
    }
  }
  .default {
    padding: 12px;
  }
}
</style>
