<template>
  <!-- 首页 footer -->
  <div class="footer-box footer">
    <!-- 联系 -->
    <div class="remind-box" :class="showIndex">
      <div class="remind-body">
        <v-container fluid class="remind-conrainer">
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="4" class="d-flex align-center justify-left mobile-text-center">
              <div class="remind-text">SIGN UP & GET <span class="price">50%</span> OFF for Second Frame</div>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="d-flex align-center justify-center">
              <div class="remind-input">
                <img src="/imgs/mail.png" class="search-icon" alt="search" />
                <input v-model="email" class="email-input" type="text" placeholder="Enter your e-mail address" />
                <v-btn class="my-btn" rounded color="#fff" @click="subscribe">Subscribe</v-btn>
              </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="0" md="4" class="d-flex align-center justify-right">
              <div class="remind-call">
                <v-tooltip v-for="(item, index) in link" :key="index" top color="#222426">
                  <template #activator="{ on, attrs }">
                    <a :href="item.link" rel="nofollow" target="_blank" v-bind="attrs" v-on="on"><img :src="item.src" :alt="item.name" /></a>
                  </template>
                  <span>{{ item.name }}</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- 手机联系 -->
    <!-- <div v-if="$vuetify.breakpoint.smAndDown" class="contact">
      <div class="contact-body">
        <h3 class="question_title">If any question?</h3>
        <div class="contact_item"><img class="contact-img2" src="/imgs/email_m.png" alt="" /> <a class="info_text link-url" href="mailto:support@stylool.com">support@stylool.com</a></div>
        <div class="contact_item"><img class="contact-img3" src="/imgs/livechat_m.png" alt="" /><a class="info_text link-url" @click="openChat">Live Chat</a></div>
      </div>
    </div> -->
    <div class="other">
      <!-- 承诺 -->
      <div class="service" :class="showIndex">
        <div class="service-body">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" md="4">
                <div class="item">
                  <div class="item-top">
                    <img class="img1" src="/imgs/cycle.png" alt="" />
                    <span class="describe">30-day Return & Exchange</span>
                  </div>
                  <div class="item-bottom">
                    <p>Unsatisfactory glasses can be exchanged or refunded within 30 days of being received.</p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="item">
                  <div class="item-top">
                    <img class="img2" src="/imgs/guard.png" alt="" />
                    <span class="describe">Vision Insurance</span>
                  </div>
                  <div class="item-bottom">
                    <p>Cover the cost of prescription eyewear by using your FSA or HSA card.</p>
                  </div>
                </div>
              </v-col>
              <v-col id="service-email" cols="12" md="4">
                <div class="item">
                  <div class="item-top">
                    <img class="img3" src="/imgs/chat3.png" alt="" />
                    <span class="describe">
                      <a class="info_text link-url" style="color: #fff" href="mailto:support@stylool.com">Contact Us</a>
                    </span>
                  </div>
                  <div class="item-bottom">
                    <p>We are always online for you.</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <!-- pc 站点-->
      <div v-if="$vuetify.breakpoint.mdAndUp" class="site-map d-flex">
        <div v-for="item in map" :key="item.page" class="item">
          <!-- {{  }} -->
          <p class="item-title">{{ item.title }}</p>
          <template v-if="item.page == 'faq'">
            <nuxt-link v-for="(item2, index) in item.list" :key="index" :to="{ name: item2.page, params: { articles: item2.type } }" class="foot-link">
              {{ item2.content }}
            </nuxt-link>
          </template>
          <template v-else-if="item.page == 'search'">
            <nuxt-link
              v-for="(item2, index) in item.list"
              :key="index"
              :to="{ name: item2.page, params: { type: item2.type, sort: item2.sort ? item2.sort : 'Default' }, query: { ...(item2.search ? { search: item2.search } : {}) } }"
              class="foot-link"
            >
              {{ item2.content }}
            </nuxt-link>
          </template>
          <template v-else>
            <nuxt-link v-for="(item2, index) in item.list" :key="index" :to="{ name: item2.page, params: { type: item2.type, sort: item2.sort ? item2.sort : 'Default' } }" class="foot-link">
              {{ item2.content }}
            </nuxt-link>
          </template>
        </div>
      </div>
      <!-- mobile 站点 -->
      <div v-if="$vuetify.breakpoint.smAndDown" class="site-map-mobile">
        <div>
          <v-expansion-panels accordion flat tile>
            <v-expansion-panel v-for="item in map" :key="item.page">
              <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <div class="link-box">
                  <template v-if="item.page == 'faq'">
                    <nuxt-link v-for="(item2, index) in item.list" :key="index" :to="{ name: item2.page, params: { articles: item2.type } }" class="foot-link" target="_blank">{{ item2.content }}</nuxt-link>
                  </template>
                  <template v-else-if="item.page == 'search'">
                    <nuxt-link
                      v-for="(item2, index) in item.list"
                      :key="index"
                      :to="{ name: item2.page, params: { type: item2.type, sort: item2.sort ? item2.sort : 'Default' }, query: { ...(item2.search ? { search: item2.search } : {}) } }"
                      class="foot-link"
                      target="_blank"
                      >{{ item2.content }}</nuxt-link
                    >
                  </template>
                  <template v-else>
                    <nuxt-link v-for="(item2, index) in item.list" :key="index" :to="{ name: item2.page, params: { type: item2.type, sort: item2.sort ? item2.sort : 'Default' } }" class="foot-link" target="_blank">{{
                      item2.content
                    }}</nuxt-link>
                  </template>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
    <!-- pc 版权 -->
    <div v-if="$vuetify.breakpoint.mdAndUp" class="copyright-pc d-flex">
      <p class="copyright-text">Copyright ©{{ new Date().getFullYear() }} Stylool. All Rights Reserved</p>
    </div>
    <!-- mobile 友链  版权 -->
    <div v-if="$vuetify.breakpoint.smAndDown" class="copyright-mobile">
      <div class="remind-call">
        <!-- <img src="/imgs/facebook.png" alt="" />
        <img src="/imgs/linkd.png" alt="" />
        <img src="/imgs/twitter.png" alt="" />
        <img src="/imgs/pin.png" alt="" />
        <img src="/imgs/ins.png" alt="" />
        <img src="/imgs/youtube.png" alt="" /> -->
        <a v-for="(item, index) in link" :key="index" :href="item.link" rel="nofollow" target="_blank"><img :src="item.src" :alt="item.name" /></a>
      </div>
      <p class="copyright-text">Copyright ©{{ new Date().getFullYear() }} Stylool. All Rights Reserved</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import facebook from '@/static/imgs/facebook.png'
import email from '@/static/imgs/email-link.png'
import twitter from '@/static/imgs/twitter.png'
import ins from '@/static/imgs/ins.png'
import youtube from '@/static/imgs/youtube.png'
import { isEmail } from '@/assets/utils/validate'
export default {
  name: 'LFooter',
  data() {
    return {
      email: '',
      link: [
        { src: facebook, link: 'https://www.facebook.com/stylooloptical', name: 'Facebook' },
        { src: ins, link: 'https://www.instagram.com/stylool/', name: 'Instagram' },
        { src: twitter, link: 'https://twitter.com/stylool', name: 'Twitter' },
        { src: youtube, link: 'https://www.youtube.com/@StyloolOptical', name: 'Youtube' },
        { src: email, link: 'mailto:support@stylool.com', name: 'E-mail' }
      ],
      map: {
        Product: {
          title: 'Product',
          page: 'search',
          list: [
            {
              page: 'search-type',
              type: 'eyeglasses',
              search: 'women',
              content: "Women's Eyeglasses"
            },
            {
              page: 'search-type',
              type: 'eyeglasses',
              search: 'men',
              content: "Men's Eyeglasses"
            },
            {
              page: 'search-type',
              type: 'sunglasses',
              search: 'women',
              content: "Women's Sunglasses"
            },
            {
              page: 'search-type',
              type: 'sunglasses',
              search: 'men',
              content: "Men's Sunglasses"
            },
            {
              page: 'search-accessories',
              type: '',
              content: 'Accessories'
            }
          ]
        },
        'Great Deals': {
          title: 'Great Deals',
          page: 'search2',
          list: [
            { page: 'search-type', type: 'best-sellers', content: 'Best Sellers' },
            { page: 'search-type', type: 'new-arrivals', content: 'New Arrivals' },
            { page: 'search-type', type: 'flash-sale', content: 'Flash Sale' },
            { page: 'search-type', type: 'search', sort: 'Price: Low to High', content: 'Glasses Under $10' }
          ]
        },
        'About Us': {
          title: 'About Us',
          page: 'about-us',
          list: [
            // { page: 'index-about-us', type: 'about-stylool', content: 'About Stylool' },
            { page: 'index-about-us', type: 'our-story', content: 'Our Story' },
            { page: 'why-choose-stylool', type: 'why-choose', content: 'Why Choose Stylool' },
            { page: 'contact-us', content: 'Contact Stylool' }
            // { page: 'return-exchange', content: 'Return & Exchange' },
            // { content: 'Our Story' },
            // { page: 'privacy-policy', content: 'Privacy Policy' },
            // { page: 'terms-conditions', content: 'Terms & Conditions' }
          ]
        },
        Policies: {
          title: 'Policies',
          page: 'policies',
          list: [
            { page: 'type', type: 'promise', content: 'Stylool Promise' },
            { page: 'type', type: 'return-exchange', content: 'Return & Exchange' },
            { page: 'type', type: 'privacy-policy', content: 'Privacy Policy' },
            { page: 'type', type: 'terms-conditions', content: 'Terms & Conditions' }
          ]
        },
        'Help Center': {
          page: 'faq',
          title: 'Help Center',
          list: [
            { page: 'index-faq-articles', type: 'Reading-Your-Frame-Size', content: 'How to Read Frame Size?' },
            { page: 'index-faq-articles', type: 'How-Eyeglass-Lens-is-Made', content: 'How Eyeglasses Lens is Made?' },
            { page: 'index-faq-articles', type: 'How-to-Read-Prescription', content: 'How to Read Prescription?' },
            { page: 'index-faq-articles', type: 'How-to-Measure-Your-PD', content: 'How to Measure Your PD?' },
            { page: 'index-faq-articles', type: 'How-to-Take-Care-of-Your-Eyeglasses', content: 'How to Take Care of Your Eyeglasses?' }
          ]
        }
      }
    }
  },
  computed: {
    ...mapState({
      userToken: state => state.system.userToken
    }),
    showIndex() {
      // 显示页面底部
      const none = {
        'd-none': true
      }
      // pc状态页面底部
      const pcShow = {
        'd-block': true
      }
      // 需要显示底部蓝条的页面
      const list = ['index']
      if (list.includes(this.$route.name)) {
        return pcShow
      }
      return none
    }
  },
  methods: {
    openChat() {
      // eslint-disable-next-line no-undef
      if (FB) {
        // eslint-disable-next-line no-undef
        FB.CustomerChat.showDialog()
      }
    },
    subscribe() {
      if (this.email && isEmail(this.email)) {
        if (this.userToken) {
          this.subscribeFn(1)
        } else {
          this.subscribeFn(0)
        }
      } else {
        this.$tips({ msg: 'Invalid e-mail.' })
      }
    },
    subscribeFn(type) {
      // 已登录
      if (type) {
        this.$api.userCenter.subscriptionE({ email: this.email })
        this.$router.push({
          name: 'centerMain-account'
        })
      } else {
        this.$store.commit('system/toggleLogin', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.site-map > .item {
  p:not(:first-child):hover {
    cursor: pointer;
    color: #fff;
  }
}
.footer {
  position: relative;
  z-index: 1;
  .foot-link {
    display: block;
    text-decoration: none;
    margin-bottom: 16px;
    &:hover {
      color: #dcd9d9 !important;
    }
  }
  //   display: flex;
  //   align-items: center;
  // position: absolute;
  // bottom: 0;
  // 提醒条目
  .remind-box {
    height: 80px;
    background: url(/imgs/remind_bg.png) no-repeat;
    background-size: 100% 100%;
    // 提醒主体
    .remind-body {
      height: 100%;
    }
    .remind-conrainer {
      height: 100% !important;
    }
    .remind-text {
      color: #fff;
      font-size: 18px;
      .price {
        font-size: 25px;
        font-weight: 600;
        font-style: italic;
        margin: 0 3px;
      }
    }
    .remind-input {
      height: 40px;
      width: 390px;
      border: 1px solid #ffffff;
      border-radius: 25px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search-icon {
        width: 16px;
        height: 12px;
        margin: 0 15px;
      }
      .email-input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 1px;
        outline: 0;
        border-radius: 30px;
        color: #fff;
        height: 22px;
        font-size: 14px;
        flex: 1;
      }
      .v-btn {
        height: 100%;
        width: 100px;
        color: #3067f2;
        font-size: 14px !important;
        padding: 0 8px !important;
        font-weight: 600;
        // margin-left: -34px;
        .btn-icon {
          // transform: rotate(270deg);
          display: inline-block;
          width: 8px;
          margin-left: 2px;
        }
      }
      ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #ffffff !important;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #ffffff !important;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #ffffff !important;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ffffff !important;
      }
    }

    .remind-call {
      width: 100%;
      display: flex;
      // justify-content: flex-end;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        height: 40px;
        width: 40px;
        margin-left: 10px;
        border-radius: 50%;
        transition: background 0.5s;
        &:hover {
          background: rgb(52, 48, 48, 79%);
        }
      }
      img {
        width: 20px;
        height: 20px;
        // margin-left: 35px;
        // padding: 0 12px;
      }
    }
  }
  @media screen and (min-width: 960px) {
    .remind-call {
      margin-left: 40px;
    }
    #service-email {
      padding-left: 58px !important;
    }
  }
  .contact {
    background: #f0f2f5;
    padding: 25px 0;
    .question_title {
      font-size: 18px;
      text-align: center;
    }
    .contact_item {
      display: flex;
      align-items: center;
      margin: 20px 0 10px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 16px;
      }
      .contact-img2 {
        height: 14px;
      }
      .info_text {
        font-size: 14px;
      }
    }
  }

  .other {
    color: #fff;
    background: #222426;
    overflow: hidden;
    .service {
      border-bottom: 1px solid #3f4142;
      .item {
        padding-right: 54px;
        .item-top {
          display: flex;
          align-items: center;
          margin: 20px 0 10px;
          img {
            margin-right: 16px;
            position: relative;
          }
          .img1 {
            width: 24px;
            // height: 21px;
          }
          .img2 {
            width: 19px;
            // height: 22px;
            top: 1px;
            margin-right: 21px;
          }
          .img3 {
            width: 23px;
            // height: 16px;
            top: 2px;
          }
          .describe {
            color: #fff;
            font-size: 16px;
          }
        }
        .item-bottom {
          font-size: 14px;
          color: #ababab;
          padding-left: 40px;
        }
      }
    }
    .site-map {
      margin-top: 35px;
      margin-bottom: 20px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      .item,
      .foot-link {
        color: #ababab;
        font-size: 14px;
        .item-title {
          font-size: 16px;
          color: #fff;
        }
      }
    }
    .v-expansion-panel-header {
      padding: 0;
      font-size: 16px;
    }
    .v-expansion-panels .v-expansion-panel {
      background: #222426;
      color: #fff;
    }
    ::v-deep .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
      color: #fff;
    }
    ::v-deep .v-expansion-panel-content__wrap {
      padding: 0 16px;
    }
    .link-box > p {
      border-bottom: 1px solid #3f4142;
      font-size: 14px;
      line-height: 50px;
      margin-bottom: 0;
    }
  }
  .copyright-pc {
    background: #17181a;
    height: 52px;
    .copyright-text {
      width: 100%;
      text-align: center;
      line-height: 52px;
      font-size: 12px;
      color: #ababab;
    }
  }
  .copyright-mobile {
    height: 113px;
    background: #17181a;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    .remind-call {
      width: 80%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .copyright-text {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: #ababab;
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .contact-body,
  .remind-body,
  .service-body,
  .site-map {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
  }
  .footer-box {
    .foot-link {
      color: #fff;
      font-size: 14px;
    }
    .remind-box {
      background-size: cover;
      // padding: 0 17px !important;
      height: 80px;
      .remind-text {
        font-size: 17px;
        // margin-top: 16px;
      }
      .mobile-text-center {
        justify-content: center !important;
      }
      .remind-input {
        height: 50px;
        width: 342px;
        border-radius: 32px;
      }
    }
    .other {
      // padding: 0 17px !important;
    }
    .site-map-mobile {
      padding: 0 20px;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .remind-box {
    height: 150px !important;
    .remind-text {
      margin-top: -10px;
    }
    .remind-input {
      margin-top: -50px;
    }
  }
  // .mobile-text-center {
  //   margin-bottom: -32px;
  // }
  .service {
    padding: 20px 0 30px;
  }
  .item-bottom {
    p {
      margin-bottom: 0;
    }
  }
}
</style>
