<template>
  <div class="center-body">
    <div class="select-box">
      <v-select v-model="selected" class="input-item" :items="selectList" item-text="title" item-value="value" outlined hide-details @change="changeFliter" />
    </div>
    <template v-if="list.length && empty">
      <orderCom :list="list" @open="openDialog" @del="del" />
    </template>
    <template v-else-if="!empty">
      <div class="loading" style="height: 50vh">
        <v-progress-circular indeterminate color="#3067f2" />
      </div>
    </template>
    <template v-else>No orders found.</template>
    <template v-if="list.length && option.count > option.limit">
      <div class="pagination-box mt-4 mb-4 d-flex position">
        <v-pagination v-model="option.page" color="#3067f2" :total-visible="5" :length="setPageLength" @input="change" />
      </div>
    </template>
  </div>
</template>

<script>
// import reviewDialog from '@/components/LcenterMain/order/reviewDialog'
import orderCom from '@/components/LcenterMain/order/index'
// const pcOrder = () => import('@/components/LcenterMain/order/pcOrder')
// const mobileOrder = () => import('@/components/LcenterMain/order/mobileOrder')
export default {
  name: 'center-index',
  components: {
    // reviewDialog
    orderCom
  },
  data() {
    return {
      selectList: [
        {
          title: 'All',
          value: 'all'
        },
        {
          title: 'Unpaid',
          value: 0
        },
        {
          title: 'Not shipped',
          value: 1
        },
        {
          title: 'Shipped',
          value: 2
        },
        {
          title: 'Completed',
          value: 4
        },
        {
          title: 'Refunded',
          value: 5
        },
        {
          title: 'Deleted',
          value: 6
        }
      ],
      selected: 'all',
      option: {
        page: 1,
        limit: 10,
        status: 'all',
        count: undefined
      },
      dialog: false,
      dialogData: null,
      list: [],
      // 加载中显示
      empty: false
    }
  },
  computed: {
    // comName() {
    //   if (this.$vuetify.breakpoint.smAndDown) {
    //     // return mobileOrder
    //     return pcOrder
    //   } else {
    //     return pcOrder
    //   }
    // },
    // 设置分页数量
    setPageLength() {
      if (this.option) {
        return Math.ceil(this.option.count / this.option.limit)
      }
      return 1
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getOrderList()
  },
  methods: {
    changeFliter(val) {
      this.option.page = 1
      this.option.limit = 10
      this.option.status = val
      this.count = undefined
      this.getOrderList()
    },
    getOrderList() {
      // this.$loading().open()
      this.$api.userCenter.getOrderList(this.option).then(res => {
        if (res.code === 200) {
          this.list = res.data
          this.option.count = res.count
        }
        this.empty = true
      })
    },
    del(val) {
      this.$confirm({ msg: 'Are you sure to delete this item?' }).then(res => {
        if (res === 'success') {
          this.delFn(val)
        }
      })
    },
    delFn(val) {
      this.$api.userCenter.getOrderDel({ code: val }).then(res => {
        if (res.code === 200) {
          this.getOrderList()
        }
      })
    },
    openDialog(val) {
      this.dialog = true
      this.dialogData = val.data.No
    },
    change() {
      this.getOrderList()
      // this.$emit('page', val)
    }
  }
}
</script>

<style lang="scss" scoped>
// table.table-list td {

// }
.center-body {
  position: relative;
}
.select-box {
  width: 30%;
  height: 36px;
  margin-top: 15px;
  margin-bottom: 15px;
  .input-item ::v-deep .v-input__slot .v-input__append-inner {
    margin-top: 8px !important;
  }
  .input-item ::v-deep .v-input__slot fieldset {
    border: 1px solid #f0f2f4 !important;
  }
}
.position {
  justify-content: flex-end;
}
@media screen and (max-width: 960px) {
  .center-body {
    background: #fff;
  }
  .select-box {
    width: 100%;
  }
  .position {
    justify-content: center;
  }
}
</style>
