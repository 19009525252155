<template>
  <v-dialog v-model="dialog" persistent background="#fff" hide-overlay :fullscreen="$vuetify.breakpoint.mobile">
    <div class="dialog-body">
      <v-toolbar color="#fff">
        <p class="dialog-title">What Is PD(Pupil Distance)?</p>
        <v-spacer />
        <v-btn icon dark @click="close">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="content">
        <p>PD (Pupil Distance) is the distance between the centers of your pupils in millimeters, which determines where you look through the lens of your glasses. It should be as accurate as possible.</p>
        <p>
          Since faces are not always symmetrical, so some people will have two PDs, also known as “Dual PD”. Dual PD is the pupillary distance between each pupil's center to the nose's bridge. It is written in the
          following notation: 32/30. The first number is the right eye.
        </p>
        <div class="pd-img">
          <img src="/imgs/pd-img.png" alt="pd" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'pdDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {},
  watch: {
    show: {
      handler(newValue) {
        this.dialog = newValue
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$parent.showDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  background: #fff;
}
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  width: 30%;
  height: auto;
  margin: 0 auto;
}
::v-deep .v-toolbar {
  height: 50px !important;
}
::v-deep .v-rating {
  margin-left: -8px;
}
::v-deep .v-sheet.v-toolbar {
  box-shadow: none !important;
}
::v-deep .v-rating .v-icon {
  padding: 5px !important;
}
.dialog-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.content {
  padding: 0 16px;
}
.pd-img {
  img {
    width: 100%;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .v-dialog__content ::v-deep .v-dialog {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}
</style>
