<template>
  <div class="center-body">
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <!-- order -->
      <div class="item">
        <div class="item-title">
          <h3>Order List</h3>
        </div>
        <div class="item-content">
          <template v-if="orderList.length">
            <div v-for="item in orderList" :key="item.id" class="list">
              <span class="left">{{ item.order_time }}:</span>
              <span class="code-text">#{{ item.code }}</span>
              <span class="option-page" @click="goPage(item.code)">{{ renderStatus(item.status) }}</span>
            </div>
          </template>
          <template v-else> You don't have an existing order yet. </template>
          <div class="options">
            <div class="btn-box">
              <v-btn class="my-btn" tile depressed block @click="toPage('Order List')">Details</v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- Personal Info -->
      <div class="item">
        <div class="item-title">
          <h3>Personal Info</h3>
        </div>
        <div class="item-content">
          <template v-if="info">
            <div class="list">
              <span class="left">First Name:</span>
              <span class="state">{{ info?.firstname }}</span>
            </div>
            <div class="list">
              <span class="left">Last Name:</span>
              <span class="state">{{ info?.lastname }}</span>
            </div>
            <div class="list">
              <span class="left">Email Address:</span>
              <span class="state">{{ info?.email }}</span>
            </div>
          </template>
          <!-- <div class="list">
            <span class="left">Date of birth:</span>
            <span class="state">{{ info.birthday }}</span>
          </div>
          <div class="list">
            <span class="left">Telephone:</span>
            <span class="state">{{ info.telephone }}</span>
          </div> -->
          <div class="options">
            <div class="btn-box">
              <v-btn class="my-btn" tile depressed block @click="toPage('Personal Info')">Details</v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- Personal Info -->
      <div class="item">
        <div class="item-title">
          <h3>Address Management</h3>
        </div>
        <div class="item-content">
          <template v-if="JSON.stringify(address) !== '{}'">
            <div class="list">
              <span class="left">Name:</span>
              <span class="state">{{ address.firstname }} {{ address.lastname }}</span>
            </div>
            <div class="list">
              <span class="left">Address:</span>
              <span class="state address-text">
                <span>{{ address.address }}, </span>
                <template v-if="address.apt_suite"
                  ><span>{{ address.apt_suite }}, </span></template
                >{{ address.city }}, {{ address.state }}, {{ address.zip_code }}, {{ address.country }}</span
              >
            </div>
            <div class="list">
              <span class="left">Telephone:</span>
              <span class="state">{{ address.phone }}</span>
            </div>
            <div class="list">
              <span class="left">Zip Code:</span>
              <span class="state">{{ address.zip_code }}</span>
            </div>
          </template>
          <template v-else>You have not saved any addresses.</template>
          <div class="options">
            <div class="btn-box">
              <v-btn class="my-btn" tile depressed block @click="toPage('Address Management')">Details</v-btn>
            </div>
            <!-- <div class="btn-box">
              <v-btn class="my-btn" tile depressed block @click="toPage('Address Management')">Add</v-btn>
            </div> -->
          </div>
        </div>
      </div>
      <!-- Personal Info -->
    </template>
    <template v-else>
      <menuList />
    </template>
  </div>
</template>

<script>
import menuList from '@/components/LcenterMain/menu'
import { renderStatus } from '@/assets/utils/renderData'

export default {
  name: 'center-index',
  components: {
    menuList
  },
  data() {
    return {
      info: {},
      address: {},
      orderList: []
    }
  },
  computed: {
    renderStatus() {
      return function (val) {
        return renderStatus(val)
      }
    }
  },
  watch: {},
  created() {
    if (process.client) {
      this.getUser()
      this.getAddress()
      this.getOrder()
    }
  },
  methods: {
    getUser() {
      this.$api.userCenter.userInfo().then(res => {
        if (res.code === 200) {
          this.info = res.data
        }
      })
    },
    getAddress() {
      this.$api.userCenter.getAddressList().then(res => {
        if (res.code === 200 && res.data.length) {
          const index = res.data.findIndex(item => item.is_primary === '1')
          if (index !== -1) {
            this.address = res.data[index]
          } else {
            this.address = res.data[0]
          }
        }
      })
    },
    getOrder() {
      this.$api.userCenter.getOrderList({ page: 1, limit: 5 }).then(res => {
        if (res.code === 200 && res.data.length) {
          this.orderList = res.data
        }
      })
    },
    toPage(name) {
      let pathName
      switch (name) {
        case 'Order List':
          pathName = 'centerMain-order-order'
          break
        case 'Personal Info':
          pathName = 'centerMain-settings-Info'
          break
        case 'Address Management':
          pathName = 'centerMain-settings-address'
          break
        case 'My Prescription':
          pathName = 'centerMain-settings-prescription'
          break
        default:
          pathName = ''
          break
      }
      this.$router.push({
        name: pathName
      })
    },
    goPage(code) {
      this.$router.push({
        name: 'centerMain-order-order-detail',
        query: { code }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  margin-bottom: 50px;
}
.item-title {
  position: relative;
  padding-left: 12px;
  padding-bottom: 12px;
  font-size: 18px;
  color: #363a40;
  margin-top: 12px;
  border-bottom: 1px solid #e6e9ed;
}
.item-title::before {
  display: block;
  content: '';
  width: 4px;
  height: 20px;
  background: #3067f2;
  position: absolute;
  top: 6px;
  left: 0;
}
.item-content {
  margin-top: 20px;
  .list {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .code-text {
    color: #3067f2;
  }
  span {
    margin-right: 15px;
  }
}
.address-text span {
  margin-right: 0;
}
.options {
  margin-top: 25px;
  display: flex;
}
.btn-box {
  width: 110px;
  height: 36px;
  border: 1px solid #3067f2;
  margin-right: 20px;
}
.my-btn {
  height: 100% !important;
  color: #3067f2 !important;
}
.option-page {
  cursor: pointer;
}
// @media screen and (max-width: 960px) {
//   .center-body {
//     padding: 0 !important;
//   }
// }
</style>
