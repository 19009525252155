<template>
  <!-- 首页 轮播     -->
  <v-container fluid class="px-0 py-0 carousel-box">
    <!-- cycle interval="5000"  :touchless="false" -->
    <v-carousel class="my-carousel" :hide-delimiters="showDelimiters" hide-delimiter-background :show-arrows="false" cycle :interval="6000">
      <v-carousel-item v-for="(slide, i) in list" :key="i">
        <!-- <img class="item-img" style="object-fill:" :src="slide.img" /> -->
        <!-- <p class="index-img">{{ i + 1 }}.....</p> -->
        <nuxt-link :to="slide.url">
          <v-img lazy-src="/imgs/imgLazy.jpg" class="carousel-img" contain :src="slide.image" alt="background" height="auto" eager />
        </nuxt-link>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  name: 'LCarousels',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // bglist: []
    }
  },
  computed: {
    showDelimiters() {
      if (this.list.length > 1) {
        return false
      }
      return true
    }
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
.my-carousel {
  ::v-deep .v-btn--active::before {
    opacity: 0 !important;
  }
}
.index-img {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 30px;
  z-index: 10;
}
.carousel-box {
  width: 100%;
  margin: 0 auto !important;
  min-height: 500px;
  max-height: 720px;
  .my-carousel {
    max-height: 720px;

    height: auto !important;
    ::v-deep .v-image {
      // width: 100%;
      max-height: 720px;

      height: auto !important;
      .carousel-img {
        // width: 100%;
        // aspect-ratio: 96/35;
        // height: auto !important;
      }
    }
  }
}

.my-carousel ::v-deep .v-carousel__controls {
  // top: 645px;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1100px) {
  // height: 520px !important;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 760px) {
  .carousel-box {
    min-height: auto;
  }
  .my-carousel {
    ::v-deep .v-carousel__controls {
      height: 35px;
    }
    ::v-deep .v-btn--icon.v-size--small {
      width: 14px;
    }
    ::v-deep .v-icon.notranslate.mdi.mdi-circle {
      font-size: 12px !important;
    }
    ::v-deep .v-ripple__container {
      display: none;
    }
    ::v-deep .v-btn--active::before {
      display: none;
    }
  }
  // min-height: 220px !important;
}
</style>
<!-- <style scoped lang="scss">
@media screen and (max-width: 414px) {
  // min-height: 220px !important;
  .my-carousel {
    // height: 720px !important;
    // height: 192px !important;
    ::v-deep .v-image {
      // width: 100%;
      // height: 192px !important;
      .carousel-img {
        // width: 100%;
        // aspect-ratio: 96/35;
        // height: 192px !important;
      }
    }
  }
}
</style> -->
