<template>
  <div class="center-body">
    <div class="content-main">
      <div class="content-body">
        <div class="tips">
          <p>Please note that "Edit/Delete" the address won't affect your existing order. Please contact support@stylool.com if you want to change the shipping address in your order.</p>
        </div>
        <addressItem :list="list" @remove="remove" @edit="getInfo" @add="add" />
      </div>
    </div>
    <addressDialog :show="dialog" :info="info" @save="save" @close="dialog = false" />
  </div>
</template>

<script>
import addressDialog from '@/components/LcenterMain/address/addressDialog'
import addressItem from '@/components/LcenterMain/address/addressItem'
export default {
  name: 'addressManagement',
  components: {
    addressDialog,
    addressItem
  },
  data() {
    return {
      dialog: false,
      list: [],
      info: {
        gender: undefined,
        firstname: undefined,
        lastname: undefined,
        country: undefined,
        address: undefined,
        apt_suite: undefined,
        city: undefined,
        zip_code: undefined,
        state: undefined,
        phone: undefined,
        backup_phone: undefined,
        is_primary: undefined,
        address_id: 0,
        id: undefined
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    add() {
      this.info = {
        gender: undefined,
        firstname: undefined,
        lastname: undefined,
        country: undefined,
        address: undefined,
        apt_suite: undefined,
        city: undefined,
        zip_code: undefined,
        state: undefined,
        phone: undefined,
        backup_phone: undefined,
        is_primary: undefined,
        address_id: 0,
        id: undefined
      }
      this.dialog = true
    },
    // 获取地址列表
    getList() {
      this.$api.userCenter.getAddressList().then(res => {
        if (res.code === 200) {
          this.list = res.data
        }
      })
    },
    getInfo(val) {
      this.$api.userCenter.getAddressInfo({ address_id: val }).then(res => {
        if (res.code === 200) {
          this.info = res.data
          this.info.address_id = res.data.id
          this.dialog = true
        }
      })
    },
    save(val) {
      this.$api.userCenter.saveAddress(val).then(res => {
        if (res.code === 200) {
          this.$tips({ msg: 'Address saved successfully.' })
          this.getList()
        }
      })
    },
    remove(val) {
      this.$confirm({ msg: 'Are you sure to delete this item?' }).then(res => {
        if (res === 'success') {
          this.$api.userCenter.removeAddress({ address_id: val }).then(res => {
            if (res.code === 200) {
              this.$tips({ msg: 'Deleted successfully.' })
              this.getList()
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-body {
  margin-top: 20px;
}
.tips {
  background: #faf7ec;
  padding: 20px;
  p {
    font-size: 14px;
    font-weight: 400;
    color: #845f2e;
    line-height: 24px;
    margin-bottom: 0;
  }
}
</style>
