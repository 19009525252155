<template>
  <div class="video-player">
    <video
      ref="videoElement"
      :src="videoUrl"
      webkit-playsinline="true"
      controls="controls"
      controlslist="nodownload noplaybackrate fullscreen noremoteplayback"
      playsinline="true"
      disablepictureinpicture="true"
      preload="auto"
      muted="muted"
      @play="onPlay"
    ></video>
      poster="https://api.stylool.com/upfiles/20230531/f5031af3d35e2d0fd604af95b9132e21.jpg"

    <!-- <button v-if="!isPlaying" @click="playVideo">播放</button> -->
  </div>
</template>

<script>
export default {
  name: 'videoPlayer',
  props: {
    videoUrl: {
      type: String,
      required: true
    },
    // 是否结束
    isEnd: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    // isPlaying() {
    //   return !this.$refs.videoElement.paused
    // }
  },
  watch: {
    // isEnd(newValue, oldValue) {
    //   if (newValue !== oldValue && newValue) {
    //     // 切换到别的栏目时
    //     this.stopAndResetVideo()
    //   }
    // }
  },
  mounted() {
    // const video = this.$refs.videoElement
    // video.addEventListener('canplay', () => {
    //   this.playVideo() // 自动播放视频
    // })
  },
  methods: {
    stopAndResetVideo() {
      try {
        const videoPlayer = this.$refs.videoElement
        videoPlayer.pause() // 停止视频播放
        videoPlayer.currentTime = 0 // 重置播放时间为0
      } catch (error) {}
    },
    playVideo() {
      this.$refs.videoElement.play()
      // this.$refs.videoElement.muted = false
    },
    onPlay() {
      // 做一些播放开始后的操作
    }
  }
}
</script>

<style scoped>
video::-webkit-media-controls-download-button {
  display: none;
}
video::-webkit-media-controls-picture-in-picture-button {
  display: none;
}
.video-player {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 视频宽高比 */
}

.video-player video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  /* object-fit: scale-down; */
}
</style>
