<template>
  <div class="couponMobile">
    <div class="coupon-module" @click="sheet = true">
      <div class="left">
        <template v-if="couponState === 'result'">
          <p class="result-text-nav">Coupon Applied</p>
        </template>
        <template v-else>Code/Coupon</template>
      </div>
      <div class="right"><img class="open-icon" src="/imgs/more.png" alt="open" /></div>
    </div>
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="sheet-box" height="528px">
        <div class="module-header">
          <div class="left">Code/Coupon</div>
          <div class="right" @click="sheet = false"><img class="close-icon" src="/imgs/close.png" alt="close" /></div>
        </div>
        <div class="code-box">
          <!-- <input class="code-input" type="text" placeholder="Enter or select a code/coupon" />
          <v-btn class="my-btn code-btn" color="#2B2E33" tile disabled depressed>Apply</v-btn> -->
          <template v-if="couponState === 'wait'">
            <!-- @focus="coupon(true)" @blur="coupon(false)"  -->
            <input v-model="code" class="code-input" type="text" placeholder="Enter or select a code/coupon" />
            <v-btn class="my-btn code-btn" color="#2B2E33" tile :disabled="codeState" depressed @click="apply">Apply</v-btn>
          </template>
          <template v-else-if="couponState === 'loading'">
            <v-btn class="my-btn loading-btn" block color="#2B2E33" loading tile depressed />
          </template>
          <template v-else>
            <p class="result-text"><v-icon color="#3067f2">mdi-check</v-icon>Coupon Applied.</p>
            <v-btn class="my-btn code-btn" color="#2B2E33" tile depressed @click="cancel">Cancel</v-btn>
          </template>
        </div>
        <div class="list">
          <div class="coupon-box">
            <template v-if="couponList.length">
              <div v-for="(item, index) in couponList" :key="index" class="coupon-item" :class="{ unavailable: !item.coupon_use }" @click="selectCode(item.coupon_code, item.coupon_use)">
                <div class="left">
                  <p class="state-price">${{ item.price }}</p>
                  <p class="state-text">OFF</p>
                </div>
                <div class="center"></div>
                <div class="right">
                  <p class="subtitle">{{ item.subtitle }}</p>
                  <p class="time">{{ item.use_end_time }}</p>
                </div>
                <p v-if="item.is_first_use" class="tips">Only for first order</p>
              </div>
            </template>
            <!-- <template v-else>
              <p>There are currently no available coupons available.</p>
            </template> -->
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { coupon } from '@/components/Lpayment/count/couponCom/mixin/coupon'
export default {
  name: 'couponMobile',
  mixins: [coupon],
  data() {
    return {
      sheet: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.coupon-module {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 15px;
  background: #ffffff;
  margin-bottom: 10px;
  font-size: 16px;
  .right {
    height: 7px;
  }
  .open-icon {
    height: 100%;
    transform: rotate(270deg);
  }
}

.module-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  height: 56px;
  .close-icon {
    height: 14px;
  }
}
.list {
  display: flex;
  justify-content: center;
  padding: 0 16px;
  background: #ffffff;
  height: calc(528px - 110px);
  overflow-y: auto;
}
.coupon-box {
  width: 100%;
  .coupon-item {
    position: relative;
    display: flex;
    height: 70px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 10px;
    border-radius: 10px;
    background: rgb(67, 112, 225, 0.77);
    .tips {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: #f5c723;
      font-size: 12px;
      border-radius: 5px;
      padding: 0 5px;
    }
  }

  .left {
    width: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #3067f2;
    p {
      text-align: center;
      margin-bottom: 0;
    }
  }
  .unavailable {
    background: #a8a8a8;
    .left {
      background: #8b8a8a;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #ffffff;
    margin-left: 15px;
  }
  p {
    margin-bottom: 0;
  }
  .subtitle {
    margin-bottom: 5px;
  }
}
.code-box {
  height: 46px;
  margin-bottom: 8px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.code-input {
  height: 100%;
  width: 280px;
  outline: none;
  border: 1px solid #ebedf0;
  padding: 6px;
  font-size: 14px;
  color: #adafb2;
  letter-spacing: 1px;
  flex: 1;
  margin-right: 10px;
}
.result-text {
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 0;
  margin-right: 5px;
  padding-left: 5px;
  font-size: 16px;
  color: #3067f2;
  border: 1px solid #ebedf0;
}
.result-text-nav {
  margin-bottom: 0;
  color: #2b2e33;
  font-size: 16px;
}
.code-btn,
.loading-btn {
  height: 100% !important;
  color: #fff !important;
}
.loading-btn {
  ::v-deep .v-progress-circular {
    margin: 0 auto !important;
  }
}
</style>
