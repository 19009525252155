<template>
  <div class="center-body">
    <div class="content-main">
      <v-form ref="password" class="content-body">
        <div class="item">
          <div class="left">Current Password</div>
          <div class="right">
            <v-text-field v-model="info.current" required class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" :rules="[rules.passWord]" />
          </div>
        </div>
        <div class="item">
          <div class="left">New Password</div>
          <div class="right">
            <v-text-field v-model="info.new" required class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" :rules="[rules.passWord]" />
          </div>
        </div>
        <div class="item">
          <div class="left">Confirm Password</div>
          <div class="right">
            <v-text-field v-model="info.confirm" required class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" :rules="[rules.passWord]" />
          </div>
        </div>
      </v-form>
      <div class="btn-box">
        <v-btn class="my-btn btn-option" tile depressed block @click="setPassword"> Confirm Edit </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { isPassWord2 } from '@/assets/utils/validate'
export default {
  name: 'changePassword',
  data() {
    return {
      info: {
        current: '',
        new: '',
        confirm: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        passWord: value => {
          return isPassWord2(value) || 'Please enter a password of at least 8 characters.'
        }
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    setPassword() {
      if (this.$refs.password.validate()) {
        if (this.info.new !== this.info.confirm) {
          this.$tips({ msg: 'The two passwords do not match.' })
          return false
        }
        this.$api.userCenter.setPassword(this.info).then(res => {
          if (res.code === 200) {
            this.$tips({ msg: 'The account information has been saved.' })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-body {
  margin-top: 20px;
}
.content-body > .item {
  display: flex;
  align-items: center;
  height: 44px;
  margin-bottom: 20px;
  .left {
    width: 180px;
    margin-right: 16px;
    font-size: 14px;
    color: #525861;
  }
  .right {
    height: 100%;
    width: 280px;
    ::v-deep .v-input__control {
      flex-wrap: nowrap;
    }
  }
}
.tel {
  display: flex;
  align-items: center;
  .tel-left,
  .tel-right {
    height: 100%;
  }
}

.btn-box {
  margin-top: 60px;
  width: 300px;
  height: 46px;
  .btn-option {
    color: #fff !important;
    height: 100% !important;
    border: 0;
    background: #222426 !important;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .content-body {
    .item {
      height: 65px;
      flex-direction: column;
      align-items: flex-start;
      .left {
        margin-bottom: 6px;
      }
      .right {
        width: 100%;
      }
    }
  }
  .btn-box {
    margin: 38px auto;
  }
}
</style>
