<template>
  <!-- 关于 指引 -->
  <div class="activity">
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <div class="item item-left">
            <!-- contain -->
            <v-img class="img" lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_our_values.jpg`" aspect-ratio="1.7" />
            <div class="activity-title">Our Values</div>
            <div class="describe">Everyone has their own style</div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="item item-right">
            <v-img class="img" lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_help_center.jpg`" aspect-ratio="1.7" />
            <div class="activity-title">Help Center</div>
            <div class="describe">Help you to select stylool glasses better</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'aboutGuide'
}
</script>

<style lang="scss" scoped>
.item {
  margin: 0 auto 20px;
  text-align: center;
  .img {
    margin-bottom: 20px;
  }
  .activity-title {
    font-size: 18px;
    color: #2b2e33;
    line-height: 21px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .describe {
    font-size: 16px;
    font-weight: 400;
    color: #6a6d70;
    line-height: 22px;
  }
}
.item-left {
  margin-right: 10px;
}
.item-right {
  margin-left: 10px;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .activity {
    .item {
      margin: 0 0 38px;
    }
    .item-left {
      margin-right: 0 !important;
    }
    .item-right {
      margin-left: 0 !important;
    }
  }
}
</style>
