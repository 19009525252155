<!-- 购物车 -->
<template>
  <div class="page">
    <!-- <LNavbar /> -->
    <Nuxt />
    <Lfooter />
  </div>
</template>

<script>
import Lfooter from '@/components/Lfooter/index.vue'
export default {
  name: 'shoppingCart',
  components: {
    Lfooter
  },
  scrollToTop: true,
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;

  background: #f7f7f7;
}
</style>
