<template>
  <div class="help-center">
    <v-btn block outlined color="indigo" class="my-btn help-btn" @click="goPage">Help Center</v-btn>
    <div class="contact">
      <!-- <div class="contact_item"><img src="/imgs/call_m.png" alt="" /> <span class="info_text">1-949-508-0322</span></div> -->
      <div class="contact_item"><img class="email" src="/imgs/email_m.png" alt="" /> <a class="info_text link-url" href="mailto:support@stylool.com">support@stylool.com</a></div>
      <div class="contact_item"><img src="/imgs/livechat_m.png" alt="" /> <a class="info_text link-url" @click="openChat">Live Chat</a></div>
    </div>
    <v-divider vertical />
  </div>
</template>

<script>
export default {
  name: 'LmobileHelp',
  methods: {
    goPage() {
      this.$router.push({
        name: 'index-faq-articles',
        params: { articles: 'list' }
      })
    },
    openChat() {
      // eslint-disable-next-line no-undef
      if (FB) {
        // eslint-disable-next-line no-undef
        FB.CustomerChat.showDialog()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.help-center {
  margin-top: 20px;
  padding: 16px;
  background: #fff;
  .help-btn {
    height: 44px;
    // color: #000;
  }
  .contact {
    // background: #f0f2f5;
    padding: 0;
    .contact_item {
      display: flex;
      align-items: center;
      margin: 15px 0 10px;
      img {
        width: 16px;
        // height: 24px;
        margin-right: 16px;
      }
      .email {
        // height: 20px;
      }
      .info_text {
        color: #2b2e33;
        font-size: 14px;
      }
    }
  }
}
</style>
