<template>
  <div class="process">
    <div v-if="$vuetify.breakpoint.mdAndUp" class="pc-process d-flex">
      <div class="node">
        <div class="img-item start"></div>
        <p class="text">Order</p>
      </div>
      <div class="connect">
        <div class="text-item">Processing Time</div>
        <div class="line"></div>
        <div class="text-item">5~7 Business Days</div>
      </div>
      <div class="node">
        <div class="img-item car"></div>
        <p class="text">Shipped</p>
      </div>
      <div class="connect">
        <div class="text-item">Shipping Time</div>
        <div class="line"></div>
        <div class="text-item">5~7 Business Days</div>
      </div>
      <div class="node">
        <div class="img-item end"></div>
        <p class="text">Delivered</p>
      </div>
    </div>
    <div v-else class="mobile-process">
      <div class="fixed-center">
        <div class="row-top">
          <div class="node">
            <div class="img-item start"></div>
            <p class="text">Order</p>
          </div>
          <div class="connect">
            <div class="text-item">Processing Time</div>
            <div class="line"></div>
            <div class="text-item">5~7 Business Days</div>
          </div>
          <div class="node">
            <div class="img-item car"></div>
            <p class="text">Shipped</p>
          </div>
        </div>

        <!-- <br /> -->
        <div class="row-bottom">
          <div class="node">
            <div class="img-item end"></div>
            <p class="text">Delivered</p>
          </div>
          <div class="connect line-box">
            <div class="text-item">Shipping Time</div>
            <div class="line"></div>
            <div class="text-item">5~7 Business Days</div>
            <div class="vertical"></div>
            <!-- <div class="line vertical"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'processInfo',
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.pc-process {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3067f2;
  padding: 20px 0;
  .node {
    width: 75px;
    .img-item {
      width: 55px;

      height: 55px;
      // border: 1px dashed #9d9898;
      margin: 0 auto 14px;
    }
    .text {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      color: #fff;
      line-height: 19px;
      margin-bottom: 0;
    }
  }
  .connect {
    // width: 380px;
    flex: 1;
    text-align: center;
    margin: -6px 40px 0;
    .text-item {
      color: #fff;
    }
    .line {
      width: 100%;
      height: 1px;
      border: 1px dashed #fff;
      margin: 10px 0;
    }
    .text {
      font-size: 15px;
      font-weight: 400;
      color: #fff;
      line-height: 18px;
    }
  }
  .start {
    background: url(/imgs/order.png) no-repeat;
  }
  .car {
    background: url(/imgs/car.png) no-repeat;
  }
  .end {
    background: url(/imgs/end.png) no-repeat;
  }
  .start,
  .car,
  .end {
    background-size: 100% 100%;
    background-position: center center;
  }
}
.vertical {
  height: 53px;
  width: 1px;
  background: linear-gradient(to bottom, transparent 0%, transparent 50%, #9d9898 50%, #9d9898 100%);
  background-size: 1px 10px;
  background-repeat: repeat-y;
  // margin-top: 10px;
  // float: right;
  // margin-right: 28px;
  position: absolute;
  right: 0;
  top: -20px;
}
.mobile-process {
  background: #3067f2;
  // padding: 0 33px;
  margin-top: 4px;
  padding: 16px 0;
  overflow: auto;
  .row-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .row-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
  }
  .node {
    width: 63px;
    .img-item {
      height: 53px;
      width: 53px;
      // border: 1px dashed #9d9898;
      margin: 0 auto 7px;
    }
    .text {
      color: #fff;
      font-size: 13px;
      text-align: center;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 0;
    }
  }
  .row-top .connect {
    width: 186px;
    text-align: center;
    margin: -6px 10px 0;
    .text-item {
      color: #fff;
      font-size: 13px;
    }
  }
  .row-bottom .connect {
    width: 210px;
    text-align: center;
    margin: -6px 0 0 10px;
    .text-item {
      color: #fff;
      font-size: 13px;
    }
  }
  .line-box {
    position: relative;
  }
  .connect {
    .line {
      width: 100%;
      height: 1px;
      margin: 10px 0;
      background: linear-gradient(to left, transparent 0%, transparent 50%, #9d9898 50%, #9d9898 100%);
      background-size: 10px 1px;
      background-repeat: repeat-x;
    }
    .text {
      font-size: 13px;
      font-weight: 400;
      color: #fff;
      line-height: 16px;
    }
  }
  .start {
    background: url(/imgs/order.png) no-repeat;
  }
  .car {
    background: url(/imgs/car.png) no-repeat;
  }
  .end {
    background: url(/imgs/end.png) no-repeat;
  }
  .start,
  .car,
  .end {
    background-size: 100% 100%;
    background-position: center center;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .pc-process {
    margin: 0 20px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .fixed-center {
    // display: flex;
    // flex-direction: column;
    /* justify-content: center; */
    // align-items: center;
    width: 310px;
    margin: 0 auto;
  }
  .mobile-process {
    margin: 0 auto;
  }
}
</style>
