<template>
  <div class="language">
    <div class="tips">
      <h3>Currency</h3>
      <!-- <p class="text-content">Choose your currency</p> -->
      <p class="text-content">Current currency</p>
    </div>
    <div class="select">
      <!-- <v-select v-model="currency" :items="$store.state.system.currencyList" item-text="value" item-value="value" color="#3067f2" dense outlined hide-details /> -->
      <span>{{ currency.name }}</span>
      <img class="currency-img" :src="currency.icon" alt="currency" />
    </div>
    <!-- <v-btn class="my-btn btn-width" outlined>SELECT</v-btn> -->
  </div>
</template>

<script>
export default {
  name: 'language',
  data() {
    return {}
  },
  computed: {
    currency: {
      get() {
        return this.$store.state.system.currencyList[this.$store.state.system.currencyIndx]
      },
      set(value) {
        this.$store.commit('system/toggleCurrency', this.findIndexArr(value))
      }
    }
  },
  watch: {
    // // 系统货币设置
    // currency(newValue) {
    //   console.log(this.findIndexArr(newValue))
    //   this.$store.commit('system/toggleCurrency', this.findIndexArr(newValue))
    // }
  },
  methods: {
    findIndexArr(val) {
      const list = this.$store.state.system.currencyList
      for (let index = 0; index < list.length; index++) {
        const element = list[index].value
        if (val === element) {
          return index
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  height: 44px;
  min-height: 44px !important;
}
::v-deep .v-input__append-inner {
  margin-top: 12px !important;
}
.select {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 8px;
  }
}
.language {
  font-size: 16px;
  background: #fff;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .tips {
    text-align: center;
    color: #000;
    margin-top: 5%;
    .text-content {
      color: #525861;
    }
  }
  .btn-width {
    width: 320px;
  }
}
</style>
