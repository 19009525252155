<template>
  <!-- :fullscreen="$vuetify.breakpoint.mobile" -->
  <v-dialog v-model="dialog" persistent background="#fff">
    <div class="dialog-body">
      <v-toolbar color="#fff">
        <v-spacer />
        <v-btn icon dark @click="close('Edit')">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="content">
        <!-- <h2 class="title-text">Does this match your prescription?</h2> -->
        <h2 class="title-text">Prescription Type: {{ getInventory.prescription[0].item }}</h2>
        <tableEdit class="confirmTable" :list="list" :obj-state="{ sphState, cylState }" />
        <div class="tips">
          <template v-if="sphState || cylState">
            <p v-if="sphState">You entered an unuaual value for your SPH.</p>
            <p v-if="cylState">You entred an unuaual value for your CYL.</p>
            <p>Are you sure they are correct?</p>
          </template>
          <template v-else>
            <p>&nbsp;</p>
          </template>
        </div>
      </div>
      <div class="footer-option">
        <v-btn class="my-btn option-btn white-btn" color="#fff" tile depressed @click="close('Edit')">Edit</v-btn>
        <v-btn class="my-btn option-btn blue-btn" color="#3067F2" tile depressed @click="close('Confirm')">Confirm</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import tableEdit from '@/components/LshoppingCart/item/tableEdit'
export default {
  name: 'confirmDialog',
  components: {
    tableEdit
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    list: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
      sphState: false, // sph值正常
      cylState: false // cyl值正常
    }
  },
  computed: {
    ...mapGetters('productConfig', ['getInventory'])
  },
  watch: {
    show: {
      handler(newValue) {
        this.dialog = newValue
      },
      immediate: true
    },
    list: {
      handler(newValue) {
        this.checkTips(newValue)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    close(type) {
      if (type === 'Edit') {
        this.$parent.showConfirmDialog = false
      } else {
        this.$parent.showConfirmDialog = false
        // 数据
        const list = this.list
        list.prescription_id = list.id
        // 是否保存方案
        if (list.savePlan) {
          this.savePaln(list)
        }
        // 图片模式保存------------------------------------------
        if (list.file != null && list.file.length) {
          // 转格式
          const data = getFormData({ code: this.$route.query.code })
          data.append('file', list.file[0])
          // 保存图片
          this.$store.dispatch('productConfig/saveFlowPrescription', { axios: this.$api, data }).then(res => {
            if (res.code === 200) {
              // 常规数据保存
              this.$store.commit('productConfig/setSelectData', {
                type: 'prescription',
                data: { value: { ...this.list, img: res.data } }
              })
              this.$store.commit('productConfig/controlStep', 'next')
              this.$store.dispatch('productConfig/getFlowInfo', { axios: this.$api, code: this.$route.query.code })
            }
          })
        } else {
          // 常规数据保存------------------------------------------------
          this.$store.commit('productConfig/setSelectData', {
            type: 'prescription',
            data: { value: this.list }
          })
          this.$store.commit('productConfig/controlStep', 'next')
          this.$store.dispatch('productConfig/getFlowInfo', { axios: this.$api, code: this.$route.query.code })
        }
      }
      // this.$emit('close')
    },
    ...mapActions('productConfig', ['validatePrescriptionTable']),
    checkTips(val) {
      this.validatePrescriptionTable(val).then(res => {
        this.sphState = res.sph
        this.cylState = res.cyl
      })
    },
    savePaln(obj) {
      const data = getFormData(obj)
      if (obj.file != null && obj.file.length) {
        data.append('file', obj.file[0])
      }
      this.$api.userCenter
        .prescriptionSave(data, {
          'Content-Type': 'multipart/form-data'
        })
        .then(res => {
          // this.list = res.data
          this.disabledBtn = false
          if (res.code === 200) {
            this.dialog = false
            this.$tips({ msg: 'Your prescription was saved successfully.' })
            // this.getList()
          }
        })
    }
  }
}
function getFormData(object) {
  const formData = new FormData()
  Object.keys(object).forEach(key => {
    const value = object[key]
    if (Array.isArray(value)) {
      value.forEach((subValue, i) => {
        formData.append(key + `[${i}]`, subValue)
      })
    } else {
      formData.append(key, object[key] === null ? '' : object[key])
    }
  })
  return formData
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  background: #fff;
}
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  width: 45%;
  height: auto;
  margin: 0 auto;
}
::v-deep .v-toolbar {
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

::v-deep .v-sheet.v-toolbar {
  box-shadow: none !important;
}
.content {
  padding: 0 50px;
}
.title-text {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}
.confirmTable {
  margin-bottom: 25px;
  ::v-deep td {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.tips {
  // margin-bottom: 30px;
  p {
    color: #ff0000;
    font-size: 14px;
    margin-bottom: 8px;
  }
}
.footer-option {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  box-shadow: 0px -2px 4px 4px #f6f6f6;
  background: #fff;
}
.option-btn {
  height: 40px !important;
  width: 180px;
  font-weight: 600 !important;
}
.white-btn {
  margin-right: 20px;
  color: #3067f2 !important;
  background: #eff3fe !important;
}
.blue-btn {
  color: #fff !important;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .v-dialog__content {
    align-items: flex-end;
  }
  .v-dialog__content ::v-deep .v-dialog {
    width: 100%;
    // height: 55%;
    max-height: 100%;
  }
  .content {
    padding: 0 20px;
  }
  .title-text {
    font-size: 18px;
  }
  .confirmTable {
    ::v-deep td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  .option-btn {
    width: 45%;
  }
  .white-btn {
    margin-right: 0;
    border-radius: 37px 0px 0px 37px;
  }
  .blue-btn {
    border-radius: 0px 37px 37px 0px;
  }
}
</style>
