<template>
  <div v-if="ready" class="product-config">
    <div class="config-box">
      <div class="product">
        <control />
        <div v-show="loading" class="loading">
          <v-progress-circular indeterminate color="#3067f2" />
        </div>
        <transition name="fade">
          <!-- include="currentStep" -->
          <keep-alive>
            <components :is="currentStep" ref="currentCom" class="components-box" @lensesUse="lensesUse" @lensesType="lensesType" @lensesReaders="lensesReaders" @lensesIndex="lensesIndex" @addOnes="addOnes" />
          </keep-alive>
        </transition>
      </div>
      <productFooter ref="footer" :product-info="productInfo" @savePrescription="savePrescription" />
    </div>
    <keep-alive>
      <components :is="guideDialog" />
    </keep-alive>
    <components :is="dialogConfirm" :show="showConfirmDialog" :list="confirmData" @close="showConfirmDialog = false" />
    <Llogin />
    <div v-if="guideBtn" class="guideBtn" @click="showGuide()">Help</div>
  </div>
</template>

<script>
// 详情页面传送步骤类型
// 依据类型改变步骤方案
import { mapState, mapGetters } from 'vuex'
import control from '@/components/Lproduct/control'
import productFooter from '@/components/Lproduct/footer'
import Llogin from '@/components/Llogin/index.vue'
const guide = () => import('@/components/Lproduct/dialogs/guide')
const confirmDialog = () => import('@/components/Lproduct/dialogs/confirmDialog.vue')

export default {
  name: 'product',
  components: {
    control,
    productFooter,
    Llogin
  },
  middleware({ query, redirect, store }) {
    if (process.client) {
      if (!query.code) {
        return redirect('/')
      }
    }
  },
  data() {
    return {
      ready: false,
      productInfo: {
        title: undefined,
        image: undefined,
        price: undefined
      },
      guideDialog: undefined,
      showConfirmDialog: false, // 确认弹窗
      dialogConfirm: undefined, // 配置确认弹窗
      confirmData: undefined
    }
  },
  computed: {
    ...mapState('productConfig', ['step', 'loading']),
    ...mapState('system', ['userToken']),
    // 步骤类型名称控制
    ...mapGetters('productConfig', ['setComName']),
    // 动态组件名称
    currentStep() {
      return this.setComName
    },
    // 显示参数配置帮助
    guideBtn() {
      // 处于第二步  enter perscription
      if (this.step.name === 'prescription') {
        return true
      }
      return false
    }
  },
  watch: {
    // 切换到登录状态
    userToken(val, old) {
      if (this.step.currentStep === 1 && !old && val) {
        this.setNext()
      }
    }
  },
  created() {
    if (process.client) {
      const code = this.$route.query.code
      // 不处于修改状态(从购物车)
      if (this.$route.query.type !== 'edit') {
        this.$store.commit('productConfig/resetData')
      }
      // 获取商品信息
      this.$api.product.flowStart({ code }).then(res => {
        if (res.code === 200) {
          // 设置foot信息
          this.$store.commit('productConfig/setFootData', res.data)
          // 开始获取流程信息
          this.$store.dispatch('productConfig/getFlowInfo', { axios: this.$api, code })
          this.ready = true
        } else {
          this.toPage()
        }
      })
    }
  },
  mounted() {
    // console.log('pppppppppppppppppppppppppp')
    // 进入流程页  获取商品信息
    // const id = this.$route.query.id
    // this.$store.commit('productConfig/resetData')
    // this.$api.product.flowStart({ product_id: id }).then(res => {
    //   if (res.code === 200) {
    //     // 设置foot信息
    //     this.$store.commit('productConfig/setFootData', res.data)
    //     // 开始获取流程信息
    //     this.$store.dispatch('productConfig/getFlowInfo', { axios: this.$api, id })
    //   } else {
    //     this.toPage()
    //   }
    // })
    // this.$store.dispatch('productConfig/getFlowStart', { axios: this.$api, id }).then(res => {
    //   if (res.code !== 200) {
    //     this.toPage()
    //   } else {
    //     this.$store.commit('productConfig/setComName')
    //   }
    // })
  },
  beforeDestroy() {
    this.$store.commit('productConfig/resetData')
  },
  methods: {
    // 拆分 -----------------------------
    lensesUse(val) {
      // 选择镜片用途
      this.$store.commit('productConfig/setSelectData', val)
      if (this.userToken) {
        this.setNext()
      } else {
        this.$store.commit('system/loginDialogTab', 1)
        this.$store.commit('system/toggleLogin', true)
      }
    },
    // 选择镜片类型
    lensesType(val) {
      this.$store.commit('productConfig/setSelectData', val)
      this.setNext()
    },
    // 选择放大倍数
    lensesReaders(val) {
      this.$store.commit('productConfig/setSelectData', val)
      this.setNext()
    },
    // 选择折射率
    lensesIndex(val) {
      this.$store.commit('productConfig/setSelectData', val)
      this.setNext()
    },
    addOnes(val) {
      this.$store.commit('productConfig/setSelectData', val)
      this.setNext()
    },
    setNext() {
      this.$refs.footer.closeList()
      if (this.step.currentStep !== this.step.size) {
        this.$refs.footer.next()
      }
    },
    // vuex  验证处方数据
    savePrescription() {
      this.$nextTick(() => {
        // 获取当前数据
        const prescription = this.$refs.currentCom
        // 数据  ({ info: this.info, file })
        const data = prescription.savefn()
        // const data = prescription.info
        // if (prescription.planType === 1) {
        //   // 获取文件数据
        // }
        if (data !== false) {
          this.confirmData = { ...data.info, file: data.file }
          this.openConfirmDialog()
        }
      })
    },
    // 配置确认弹窗
    openConfirmDialog() {
      this.dialogConfirm = confirmDialog
      this.showConfirmDialog = true
    },
    // 引导组件控制  眼镜配置页help侧边
    showGuide() {
      if (this.step.name === 'prescription') {
        const that = this
        const modelFn = {
          open() {
            if (that.$vuetify.breakpoint.width >= 1690 && document.querySelector('.product-foot')) {
              document.querySelector('.product-foot').style.width = 'calc(100% - 400px)'
            }
            that.guideDialog = guide
          },
          close() {
            if (document.querySelector('.product-foot')) {
              document.querySelector('.product-foot').style.width = '100%'
            }
            that.guideDialog = undefined
          }
        }
        !that.guideDialog ? modelFn.open() : modelFn.close()
        return false
      }
      this.guideDialog = undefined
    },
    toPage() {
      const code = this.$route.query.code
      this.$router.replace({
        name: 'details-code',
        params: { code }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.product-config,
.config-box {
  // height: 100%;
  overflow: hidden;
}
.product-config {
  display: flex;
}
.config-box {
  flex: 1;
}
.loading {
  height: 100%;
  position: absolute;
  z-index: 1;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  ::v-deep .v-progress-circular {
    margin: 0 auto !important;
  }
}
.product {
  // height: calc(100% - 30px);
  max-width: 1200px;
  margin: 30px auto 0;
  // overflow: hidden;
  position: relative;
}
.guideBtn {
  position: fixed;
  right: 0;
  top: 45%;
  color: #fff;
  text-align: center;
  line-height: 44px;
  width: 89px;
  height: 44px;
  font-size: 16px;
  background: #3067f2;
  border-radius: 40px 0px 0px 40px;
  cursor: pointer;
}
/*设置动画*/
.fade-enter,
.fade-leave-to {
  position: absolute;
  transform: translateX(-100%);
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.3s;
}

.fade-enter-to {
  position: relative;
  // transform: scale(1);
  transform: translateX(0);

  opacity: 1;
}
.components-box {
  margin-bottom: 120px;
  margin-top: 50px;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .product {
    // height: calc(100% - 25px);
    margin: 25px 16px 0;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 600px) {
  // .product {
  //   margin: 25px auto 0;
  // }
  .components-box {
    margin-bottom: 70px;
    margin-top: 20px;
  }
  .guideBtn {
    top: 62%;
    line-height: 38px;
    width: 65px;
    height: 38px;
  }
}
</style>
