<template>
  <div class="list">
    <div v-if="medthod.length" class="header-option">
      <h3>Shipping Method</h3>
      <img class="tip-icon" src="/imgs/question.png" alt="tip" @click="tipShow = true" />
    </div>
    <div v-if="medthod.length" class="content">
      <v-radio-group v-model="current" class="item" name="value" hide-details>
        <div v-for="item in medthod" :key="item.id" class="method-item" @click="current = item.id">
          <v-radio class="radio-item" :class="{ radioNode: item.shipping_method == 'Express shipping' }" :value="item.id" color="#3067f2" :ripple="false" />
          <div class="main">
            <div class="shipping-information">
              <div class="left">
                <div class="icon-name">
                  <img v-if="$vuetify.breakpoint.mdAndUp" class="medthod-icon" :src="item.icon" />
                  <span class="medthod-name">{{ item.shipping_method }}</span>
                </div>
                <p class="tips">{{ item.estimated_delivery }}</p>
              </div>
              <p class="right">
                {{ item.shipping_fee > 0 ? currencyIcon + item.shipping_fee : 'Free' }}
              </p>
            </div>
            <template v-if="item.shipping_method == 'Express shipping'">
              <div class="note">Note: Business Express cannot deliver to 'P.O. Box'. Please fill in a detailed address.</div>
            </template>
          </div>
        </div>
      </v-radio-group>
      <tipDialog v-model="tipShow" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tipDialog from '@/components/Lpayment/method/tipDialog'
export default {
  name: 'methodList',
  components: {
    tipDialog
  },
  props: {
    medthod: {
      type: Array,
      default: () => []
    },
    medthodDefault: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // current: 1
      current: 1,
      tipShow: false
    }
  },
  computed: {
    ...mapGetters('system', ['currencyIcon'])
  },
  watch: {
    medthodDefault: {
      handler(val) {
        if (val && JSON.stringify(val) !== '{}') {
          this.current = val.id
        }
      },
      immediate: true
    },
    current(val) {
      const list = this.medthod
      const index = list.findIndex(res => {
        return res.id === val
      })
      if (index > -1) {
        this.$emit('medthos-type', { id: val, name: list[index].shipping_method })
      }
      // console.log(list, val)
    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.header-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h3 {
    font-size: 20px;
    border-bottom: 4px solid #3067f2;
  }
  .tip-icon {
    height: 20px;
    margin-left: 15px;
    cursor: pointer;
  }
  .edit {
    color: #3067f2;
  }
}
.method-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  transition: 0.3s;
  position: relative;
  &:hover {
    background: #f1f1f1;
  }
  .radioNode {
    margin-top: -22px;
  }
  .main {
    width: 100%;
  }
  .shipping-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .note {
    font-size: 14px;
    color: #8f8f8f;
    margin-left: 15px;
  }
  .left {
    display: flex;
    align-items: center;
    .v-radio {
      margin-bottom: 0 !important;
    }
    p {
      margin-bottom: 0;
    }
    .icon-name {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #000;
      margin: 0 15px;
    }
    .medthod-icon {
      height: 40px;
      margin-right: 15px;
    }
    .tips {
      font-size: 14px;
      color: #2c2a2a;
    }
  }
  .right {
    font-weight: 600;
    margin-bottom: 0;
  }
  ::v-deep .v-radio {
    margin-bottom: 0 !important;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .header-option {
    h3 {
      font-size: 18px;
      border-bottom: none;
    }
  }
  .method-item {
    padding: 12px;
    .radioNode {
      margin-top: -40px;
    }
    .note {
      margin-left: 0;
    }
    .left {
      flex-direction: column;
      align-items: flex-start;
      .icon-name {
        margin: 0;
      }
    }
  }
}
</style>
