<!-- 商品列表 -->
<template>
  <div class="page">
    <Nuxt />
    <Lfooter />
  </div>
</template>

<script>
import Lfooter from '@/components/Lfooter/index.vue'
export default {
  name: 'productSearch',
  components: {
    Lfooter
  },
  scrollToTop: true,
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
