<!-- 眼镜参数 -->
<template>
  <div class="step-body">
    <!-- ------------------------------------ Plan ----------------------------------------- -->
    <div class="option-header">
      <div v-if="planType" class="right" @click="planType = 0"><img class="upload-icon" src="/imgs/upload.png" alt="upload" /> Or Upload Prescription</div>
      <div v-else class="right" @click="planType = 1">Fill in the prescription form</div>
    </div>
    <template v-if="planType">
      <div class="rescription form">
        <img class="th-img" src="/imgs/question_orange.png" alt="description" @click="openDialog('add')" />
        <!-- ------------------------------------ Col Nmae ----------------------------------------- -->
        <div v-if="!$vuetify.breakpoint.smAndDown" class="form-header">
          <div class="space item"></div>
          <div class="item">Sphere(SPH)</div>
          <div class="item">Cylinder(CYL)</div>
          <div class="item" :style="{ color: !Raxios || !Laxios ? '#2B2E33' : '#AAACAD' }">AXIS</div>
          <div class="item" :style="{ color: !addState ? '#2B2E33' : '#AAACAD' }">ADD</div>
        </div>
        <div class="form-body">
          <!-- ------------------------------------ Right Eye ----------------------------------------- -->
          <div class="list">
            <div class="type item">Right Eye (OD):</div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">SPH</div>
              <v-select v-model="info.od_sph" class="selects" :items="SPH" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">CYH</div>
              <v-select v-model="info.od_cyl" class="selects" :items="CYL" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title" :style="{ color: !Raxios ? '#2B2E33' : '#AAACAD' }">AXIS</div>
              <v-select v-model="info.od_axis" class="selects" :disabled="Raxios" :items="Axis" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div v-if="!$vuetify.breakpoint.smAndDown" class="item"><v-select v-model="info.od_add" class="selects" :items="ADD" dense hide-details :disabled="addState" placeholder="None" :error="addStateError" /></div>
          </div>
          <!-- ------------------------------------ Left Eye ----------------------------------------- -->
          <div class="list">
            <div class="type item">Left Eye (OS):</div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">SPH</div>
              <v-select v-model="info.os_sph" class="selects" :items="SPH" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">CYH</div>
              <v-select v-model="info.os_cyl" class="selects" :items="CYL" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title" :style="{ color: !Laxios ? '#2B2E33' : '#AAACAD' }">AXIS</div>
              <v-select v-model="info.os_axis" class="selects" :disabled="Laxios" :items="Axis" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
            <div v-if="!$vuetify.breakpoint.smAndDown" class="item"><v-select v-model="info.os_add" class="selects" :items="ADD" dense hide-details :disabled="addState" placeholder="None" :error="addStateError" /></div>
          </div>
          <!-- ------------------------------------ Mobile - ADD ----------------------------------------- -->
          <div v-if="$vuetify.breakpoint.smAndDown" class="list mobile-list">
            <div class="type item">Addition (near) ADD:</div>
            <div class="item">
              <div class="col-title" :style="{ color: !addState ? '#2B2E33' : '#AAACAD' }">Right Eye (OD)</div>
              <v-select v-model="info.od_add" class="selects" :items="ADD" dense hide-details :disabled="addState" outlined placeholder="None" :error="addStateError" />
            </div>
            <div class="item">
              <div class="col-title" :style="{ color: !addState ? '#2B2E33' : '#AAACAD' }">Left Eye (OS)</div>
              <v-select v-model="info.os_add" class="selects" :items="ADD" dense hide-details :disabled="addState" outlined placeholder="None" :error="addStateError" />
            </div>
          </div>
          <div v-if="addStateError" class="error-message">
            <span>Add corrects near vision for reading. If you see one number listed for ADD, use it for both eyes. If you have two different numbers, the prescription could not be processed.</span>
          </div>
          <!-- ------------------------------------ Pupillary Distance ----------------------------------------- -->
          <div class="list mobile-list end-list">
            <div class="type item">PD:<img class="tips-img" src="/imgs/question_orange.png" alt="tips" @click="openDialog('pd')" /></div>
            <template v-if="twoPd">
              <div class="item">
                <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Right</div>
                <v-select v-model="info.pd_right" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="Right" />
              </div>
              <div class="item">
                <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Left</div>
                <v-select v-model="info.pd_left" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="Left" />
              </div>
            </template>
            <template v-else>
              <div class="item one-pd">
                <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Select PD</div>
                <v-select v-model="info.pd_right" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
              </div>
            </template>
            <div class="item select-box">
              <v-checkbox v-model="twoPd" class="input-item" color="#3067F2" label="Have two PDs" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="uploadImg-box">
        <!-- ------------------------------------- upload img -------------------------------------------------- -->
        <div class="upload-box">
          <uploadImg ref="imgCom" :online="[info.img_path]" />
        </div>
        <!-- ------------------------------------ Pupillary Distance ----------------------------------------- -->
        <div class="list mobile-list end-list">
          <div class="type item">PD:<img class="tips-img" src="/imgs/question_orange.png" alt="tips" @click="openDialog('pd')" /></div>
          <template v-if="twoPd">
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Right</div>
              <v-select v-model="info.pd_right" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="Right" />
            </div>
            <div class="item">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Left</div>
              <v-select v-model="info.pd_left" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="Left" />
            </div>
          </template>
          <template v-else>
            <div class="item one-pd">
              <div v-if="$vuetify.breakpoint.smAndDown" class="col-title">Select PD</div>
              <v-select v-model="info.pd_right" class="selects" :items="PD" :menu-props="{ auto: true, bottom: true, maxHeight: 304 }" dense hide-details :outlined="$vuetify.breakpoint.smAndDown" placeholder="None" />
            </div>
          </template>
          <div class="item select-box">
            <v-checkbox v-model="twoPd" class="input-item" color="#3067F2" label="Have two PDs" />
          </div>
          <!-- <div class="item tips-item">
            <img class="tips-img" src="/imgs/question_orange.png" alt="tips" @click="openDialog('pd')" />
          </div> -->
        </div>
      </div>
    </template>
    <!-- ------------------------------------ Save plan ----------------------------------------- -->
    <div class="option-foot">
      <div class="birth">
        <div class="checkbox">
          <!-- <v-checkbox v-model="birth" color="#3067F2" label="Birth Year" /> -->
          Birth Year
        </div>
        <div class="select-date">
          <v-select v-model="info.birth_year" class="my-date" :items="year" placeholder="Select" dense hide-details />
        </div>
      </div>
      <div class="save">
        <div class="checkbox">
          <!-- <v-checkbox v-model="save" color="#3067F2" label="Save My Prescription" /> -->
          Prescription Name
        </div>
        <div class="prescription-text">
          <input v-model="info.title" type="text" class="my-prescription" placeholder="Prescription Name" />
        </div>
      </div>
      <p class="illustrate" @click="openDialog('illustrate')">How to fill out the prescription?</p>
    </div>
    <div class="btn-box">
      <v-btn class="my-btn btn-option" :disabled="disabledBtn" tile depressed block @click="savefn"> SAVE </v-btn>
    </div>
    <components :is="dialogName" :show="showDialog" />
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import uploadImg from '@/components/toolCom/uploadImg'
const addDialog = () => import('@/components/Lproduct/dialogs/addDialog.vue')
const pdDialog = () => import('@/components/Lproduct/dialogs/pdDialog.vue')
const illustrateDialog = () => import('@/components/Lproduct/dialogs/illustrateDialog.vue')

export default {
  name: 'prescriptionCom',
  components: {
    uploadImg
  },
  props: {
    disabledBtn: {
      // 控制按钮
      type: Boolean,
      default: false
    },
    addType: {
      // 添加类型  修改  / 新增
      type: Boolean,
      default: true
    },
    information: {
      // 数据
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      planType: 1, // 方案填写方式 ( 1 form  0 图片  )
      showDialog: false, // 说明弹窗显示
      dialogName: null, // 弹窗说明  组件切换
      twoPd: false, // true -- add模块禁用 || false -- axis 开启
      addState: true, // 是否禁用add
      // 处方备选数据---------------------------------------------
      SPH: [], // 镜面数据
      CYL: [], // 镜面数据
      PD: [], // 镜面数据
      pdOnlyData: [],
      pdTwoData: [],
      ADD: [], // 镜面数据
      Axis: [], // 镜面数据
      year: [], // 生日
      info: {
        prescription_id: 0,
        title: null,
        birth_year: null,
        od_sph: '0.00',
        od_cyl: '0.00',
        od_axis: null,
        od_add: null,
        os_sph: '0.00',
        os_cyl: '0.00',
        os_axis: null,
        os_add: null,
        pd_right: null,
        pd_left: null,
        is_two_pd: null
      }, // 处方数据
      two_pd: false, // 是否为两个PD项
      Raxios: true, // 禁用右axios
      Laxios: true, // 禁用左axios
      // --------------------------------------------------------
      items: ['paln1', 'plan2', 'plan3'] // 方案列表
    }
  },
  computed: {
    // 确保add一致
    addStateError() {
      const odAdd = this.info.od_add
      const osAdd = this.info.os_add
      if (odAdd !== osAdd) {
        return true
      }
      return false
    }
  },
  watch: {
    information: {
      handler(val) {
        if (!this.addType) {
          // 修改状态时  使用props
          try {
            if (val.img_path) {
              this.planType = 0
            }
          } catch (error) {
            this.planType = 1
          }

          this.info = { ...val }
        } else {
          this.planType = 1
        }
        this.setPdDefaultModel()
        // console.log(this.addType)
        // if (val.is_two_pd === 0) {
        //   this.twoPd = false
        // } else if (val.is_two_pd === 1) {
        //   this.twoPd = true
        // }
      },
      deep: true,
      immediate: true
    },
    save(val) {
      if (!val) {
        this.info.title = ''
      }
    },
    'info.pd_right': {
      handler(val) {
        // 只有一个PD
        if (!this.twoPd) {
          this.info.pd_left = val
        }
      },
      deep: true
    },
    'info.od_cyl': {
      handler(val) {
        // cyl 为 0 时
        if (val && val !== '0.00') {
          this.Raxios = false
        } else {
          this.Raxios = true
          this.info.od_axis = ''
        }
      },
      deep: true,
      immediate: true
    },
    'info.os_cyl': {
      handler(val) {
        // cyl 为 0 时
        if (val && val !== '0.00') {
          this.Laxios = false
        } else {
          this.Laxios = true
          this.info.os_axis = ''
        }
      },
      deep: true,
      immediate: true
    },
    // pd 数量
    twoPd(val) {
      this.info.pd_left = ''
      this.info.pd_right = ''
      // 单个
      if (!val) {
        // 一个PD
        this.info.is_two_pd = 0
        this.PD = this.pdOnlyData
      } else {
        this.info.is_two_pd = 1
        this.PD = this.pdTwoData
      }
      this.setPdDefault()
    }
  },
  created() {
    this.addState = false
    this.SPH = this.renderData('None/SPH', 0.25, -20, 10)
    this.CYL = this.renderData('None/CYL', 0.25, -6, 6)
    this.ADD = this.renderData('-Select ADD-', 0.25, 0.75, 3.5)
    // this.PD = this.renderData('-Select PD-', 1, 54, 78)
    this.pdOnlyData = this.renderData('-Select PD-', 1, 54, 78, true)
    this.pdTwoData = this.renderData('-Select PD-', 1, 25, 41, true)
    if (this.twoPd) {
      this.PD = this.pdTwoData
    } else {
      this.PD = this.pdOnlyData
    }
    this.Axis = this.renderData('-Choose Axis-', 1, 1, 180, true)
    this.year = this.renderYear()
  },
  mounted() {},
  destroyed() {
    // Object.assign(this.$data, { info: this.$options.data().info })
    this.info = this.$options.data().info
  },
  methods: {
    setPdDefaultModel() {
      if (!this.addType) {
        if (this.info.is_two_pd === 1) {
          this.twoPd = false
        } else if (this.info.is_two_pd === 0) {
          this.twoPd = true
        }
      } else {
        this.twoPd = false
      }
      this.setPdDefault()
    },
    // 设置默认pd值
    setPdDefault() {
      setTimeout(() => {
        if (this.twoPd) {
          if (!this.info.pd_left) {
            this.info.pd_left = '32'
          }
          if (!this.info.pd_right) {
            this.info.pd_right = '32'
          }
        } else {
          if (!this.info.pd_left) {
            this.info.pd_left = '64'
          }
          if (!this.info.pd_right) {
            this.info.pd_right = '64'
          }
        }
      }, 0)
    },
    // 切换处方方式(图片) 重置数据
    changeInitData() {
      this.info.od_sph = '0.00'
      this.info.od_cyl = '0.00'
      this.info.od_axis = null
      this.info.od_add = null
      this.info.os_sph = '0.00'
      this.info.os_cyl = '0.00'
      this.info.os_axis = null
      this.info.os_add = null
    },
    savefn() {
      if (!this.info.title) {
        this.$tips({ msg: 'Please enter your prescription name.' })
        return false
      }
      if (!this.info.pd_right || !this.info.pd_left) {
        this.$tips({ msg: 'Please enter your PD value.' })
        return false
      }
      // if (!this.info.title || this.info.birth_year) {
      //   this.$tips({ msg: 'Please write the prescription name.' })
      //   return false
      // }
      if (this.addStateError) {
        this.$tips({ msg: 'Please check if the add option is correct.' })
        return false
      }
      this.filterSaveData()
      let file = null
      if (this.planType === 0) {
        // console.log('photo--', this.$refs.imgCom.formData)
        file = this.$refs.imgCom.formData
      }
      this.$emit('save', { info: this.info, file })
    },
    // 保存前处理数据
    filterSaveData() {
      if (this.planType === 1) {
        this.info.is_img_path = 1
        this.info.img_path = null
      } else if (this.planType === 0) {
        this.changeInitData()
        this.info.is_img_path = 0
      }
      if (this.twoPd) {
        this.info.is_two_pd = 0
      } else {
        this.info.is_two_pd = 1
      }
      // add数据
      if (this.addState) {
        this.info.od_add = ''
        this.info.os_add = ''
      }
    },
    // 说明性弹窗
    openDialog(type) {
      switch (type) {
        case 'add':
          this.dialogName = addDialog
          break
        case 'pd':
          this.dialogName = pdDialog
          break
        case 'illustrate':
          this.dialogName = illustrateDialog
          break
        default:
          this.dialogName = illustrateDialog
          break
      }
      this.showDialog = true
    },
    addNew() {
      Object.assign(this.$data, { right: this.$options.data().right, left: this.$options.data().left, mypd: this.$options.data().mypd })
    },
    // tips:  try-on 模块pd数据
    // 生成镜片数据
    renderData(defaultVla, interval = 0.25, negativeMin = -20, positiveMax = 12, simple) {
      // 数字间隔
      // const interval = 0.25
      // let negativeMin = -18
      // const positiveMax = 10
      const list = []
      // if (negativeMin > 0) {
      //   list.push(defaultVla)
      // }
      for (negativeMin; negativeMin <= positiveMax; negativeMin = negativeMin + interval) {
        // if (negativeMin === 0 && defaultVla) {
        //   list.push(defaultVla)
        // } else {
        //   list.push(negativeMin.toFixed(2))
        // }
        if (!simple) {
          let resNum = negativeMin.toFixed(2)
          if (resNum > 0) {
            resNum = '+' + resNum
          }
          list.push(resNum)
        } else {
          list.push(negativeMin + '')
        }

        // list.push(negativeMin.toFixed(2))
      }
      return list
    },
    // 生成年份数据
    renderYear() {
      const year = new Date().getFullYear()
      const end = year - 5 // 当前年份 - 5
      let start = end - 70
      const arr = []
      for (start; start <= end; start++) {
        // const element = array[index];
        arr.push(start)
      }
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-box {
  justify-content: center;
}
.step-body {
  height: 100%;
  .option-header {
    min-height: 44px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    .upload-icon {
      height: 13px;
      margin-right: 10px;
    }
  }
  .left {
    display: flex;
    .select-list {
      max-width: 224px;
      height: 100%;
      margin-right: 20px;
      ::v-deep .v-input__slot {
        min-height: 44px !important;
        .v-label {
          color: #2b2e33 !important;
          top: 12px;
        }
      }
      ::v-deep .v-input__append-inner {
        margin-top: 10px !important;
      }
    }
    .add {
      text-align: center;
      width: 142px;
      height: 100%;
      font-size: 14px;
      line-height: 40px;
      color: #2b2e33;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      border: 1px solid #9e9e9e;
      cursor: pointer;
      span {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
  .right {
    font-size: 14px;
    color: #3067f2;
    line-height: 44px;
    cursor: pointer;
  }
  .option-foot {
    margin-top: 42px;
    .birth,
    .save {
      height: 30px;
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;
    }
    .checkbox {
      width: 200px;
      margin-right: 60px;
    }
    .prescription-text {
      border-bottom: 1px solid #949494;
      input {
        outline: none;
        width: 100%;
      }
    }
    .select-date,
    .prescription-text {
      width: 160px;
      position: relative;
      top: -5px;
    }
  }

  .rescription {
    position: relative;
    max-width: 1200px;
    height: auto;
    min-height: 305px;
    border: 1px solid #525861;
    // box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.06);
    padding: 0 3%;

    .th-img {
      position: absolute;
      right: 15px;
      top: 15px;
      height: 18px;
      cursor: pointer;
    }
  }
  .form-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 40px;
    .item {
      font-size: 14px;
      color: #2b2e33;
      font-weight: 600;
      // flex-shrink: 0;
      width: 15%;
      position: relative;
    }
    .th-img {
      position: absolute;
      right: 0;
      height: 18px;
      cursor: pointer;
    }
  }
  .form-body {
    margin-bottom: 40px;
  }
  .type.item,
  .col-title {
    font-weight: 600;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 40px;
    margin-top: 28px;
    height: 35px;
    .type {
      font-size: 14px;
      color: #2b2e33;
      display: flex;
      align-items: flex-end;
    }
    .item {
      width: 15%;
      height: auto;
      position: relative;
    }
    .one-pd {
      width: calc(36% + 2px);
      height: auto;
    }
    .select-box {
      height: 55%;
      width: 36%;
      position: relative;
    }
  }
  .uploadImg-box {
    .list {
      justify-content: normal;
      .type {
        font-size: 16px;
        font-weight: 400;
        color: #212121;
      }
      .item {
        width: 200px;
        margin-right: 60px;
      }
      .select-box {
        position: relative;
      }
      .tips-img {
        position: absolute;
        top: 2px;
      }
    }
  }
  .error-message {
    display: flex;
    flex-direction: row-reverse;
    span {
      display: inline-block;
      width: 79%;
      margin-top: 20px;
      color: red;
    }
  }
  .illustrate {
    display: inline-block;
    margin-bottom: 0;
    text-decoration: underline;
    cursor: pointer;
  }
}
.btn-box {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 300px;
  height: 46px;
  .btn-option {
    color: #fff !important;
    height: 100% !important;
    border: 0;
    background: #3067f2 !important;
  }
}
.tips-img {
  position: absolute;
  top: 0;
  left: 30px;
  height: 18px;
  cursor: pointer;
}
::v-deep .v-input__slot {
  height: 30px;
  min-height: 30px !important;
}
::v-deep .v-input__append-inner {
  margin-top: 4px !important;
}
::v-deep .v-input,
::v-deep .v-label {
  font-size: 15px !important;
  color: #2b2e33;
}
</style>
<!-- <style scoped lang="scss">
@media screen and (max-width: 1250px) {
  .step-body {
  }
}
</style> -->
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .step-body {
    .form-body {
      margin-bottom: 0;
    }
    .option-header {
      margin-bottom: 0;
      flex-direction: row-reverse;
    }
    .rescription {
      // max-width: 1200px;
      // height: 305px;
      padding: 0;
      border-top: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
      border-left: none;
      border-right: none;
    }

    .type.item,
    .col-title {
      font-size: 13px;
    }
    .col-title {
      margin-bottom: 5px;
    }
    .type.item {
      margin-top: 5px;
    }
    .list {
      height: auto;
      margin-top: 8px;
      .item {
        width: 31%;
        ::v-deep .v-input__slot {
          height: 44px;
          // min-height: 30px !important;
        }
        ::v-deep .v-select__slot .v-input {
          font-size: 13px !important;
        }
        ::v-deep .v-input__append-inner {
          margin-top: 10px !important;
        }
        ::v-deep .v-input,
        ::v-deep .v-label {
          font-size: 13px !important;
        }
        // .v-label {
        //   font-size: 13px;
        // }
      }
      .type {
        width: 100%;
      }
      .one-pd {
        width: 65.5%;
        // height: auto;
      }
      .select-box {
        // height: 45px;
        height: 24px;
        width: 50%;
        margin-top: 10px;
      }
      .tips-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
      }
      .th-img {
        position: relative;
        top: -4px;
        margin-left: 72px;
        height: 16px;
      }
      .tips-img {
        position: absolute;
        top: 0;
        left: 30px;
        height: 18px;
        cursor: pointer;
      }
    }
    .mobile-list {
      display: flex;
      justify-content: flex-start;
      .item {
        margin-right: 3.5%;
      }
    }
    .end-list {
      margin-bottom: 10px;
    }
    .option-foot {
      margin-top: 12px;
      margin-bottom: 12px;
      .checkbox {
        width: 115px;
        margin-right: 30px;
      }
    }
    .uploadImg-box {
      .list {
        justify-content: normal;
        .type {
          font-size: 16px;
          font-weight: 400;
          color: #212121;
        }
        .item {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
  .btn-box {
    margin: 38px auto;
  }
  .error-message {
    span {
      width: 100%;
      font-size: 14px;
      margin-top: 0;
    }
  }
}
</style>
