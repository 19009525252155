var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"pc-process d-flex"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4)]):_c('div',{staticClass:"mobile-process"},[_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node"},[_c('div',{staticClass:"img-item start"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Order")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"connect"},[_c('div',{staticClass:"text-item"},[_vm._v("Processing Time")]),_vm._v(" "),_c('div',{staticClass:"line"}),_vm._v(" "),_c('div',{staticClass:"text-item"},[_vm._v("5~7 Business Days")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node"},[_c('div',{staticClass:"img-item car"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Shipped")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"connect"},[_c('div',{staticClass:"text-item"},[_vm._v("Shipping Time")]),_vm._v(" "),_c('div',{staticClass:"line"}),_vm._v(" "),_c('div',{staticClass:"text-item"},[_vm._v("5~7 Business Days")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node"},[_c('div',{staticClass:"img-item end"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Delivered")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-center"},[_c('div',{staticClass:"row-top"},[_c('div',{staticClass:"node"},[_c('div',{staticClass:"img-item start"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Order")])]),_vm._v(" "),_c('div',{staticClass:"connect"},[_c('div',{staticClass:"text-item"},[_vm._v("Processing Time")]),_vm._v(" "),_c('div',{staticClass:"line"}),_vm._v(" "),_c('div',{staticClass:"text-item"},[_vm._v("5~7 Business Days")])]),_vm._v(" "),_c('div',{staticClass:"node"},[_c('div',{staticClass:"img-item car"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Shipped")])])]),_vm._v(" "),_c('div',{staticClass:"row-bottom"},[_c('div',{staticClass:"node"},[_c('div',{staticClass:"img-item end"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Delivered")])]),_vm._v(" "),_c('div',{staticClass:"connect line-box"},[_c('div',{staticClass:"text-item"},[_vm._v("Shipping Time")]),_vm._v(" "),_c('div',{staticClass:"line"}),_vm._v(" "),_c('div',{staticClass:"text-item"},[_vm._v("5~7 Business Days")]),_vm._v(" "),_c('div',{staticClass:"vertical"})])])])
}]

export { render, staticRenderFns }