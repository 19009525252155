<template>
  <v-dialog v-model="dialog" fullscreen width="100% !important" transition="scale-transition" persistent>
    <div class="content" @click="closeDialog">
      <!-- hide-delimiters -->
      <div class="imgNum">{{ currentIndex + 1 }} / {{ list.length }}</div>
      <div class="img-list">
        <v-carousel v-model="currentIndex" height="100%" :show-arrows="!$vuetify.breakpoint.mobile && list.length > 1" hide-delimiters>
          <v-carousel-item v-for="(item, i) in list" :key="i">
            <v-img lazy-src="/imgs/imgLazy.jpg" class="carousel-img" contain :src="item" alt="detail-item" height="auto" eager />
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'imgDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    current: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialog: false,
      currentIndex: 0
    }
  },
  computed: {},
  watch: {
    show(newValue) {
      this.dialog = newValue
      this.currentIndex = this.current
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
      this.$parent.imgDiologs = false
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 100%;
  background: rgb(0, 0, 0, 0.9);
  // padding-top: 100px;
  position: relative;
}
.imgNum {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  color: #fff;
}
.img-list {
  width: 100%;
}
.img-list ::v-deep {
  .v-responsive {
    justify-content: center;
    max-height: 100vh;
    max-width: 100vw;
  }
  .v-responsive__content {
    flex: 0 0 0px;
  }
}
// .img-list ::v-deep .v-carousel {
//   height: 100% !important;
//   .v-window-item {
//     display: flex;
//     align-items: center;
//   }
//   .v-carousel__item {
//     width: 100%;
//   }
// }
// @media screen and (max-width: 760px) {
//   .content {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }
</style>
