<template>
  <div class="center-body">
    <div class="content-main">
      <div class="content-body">
        <div class="content">
          <div class="list">
            <div v-for="item in list" :key="item.id" class="checkbox-item">
              <v-checkbox v-model="selected" :value="item.value" :label="item.name" hide-details color="#3067F2" />
            </div>
          </div>
          <div class="btn-box">
            <v-btn class="my-btn btn-option" tile depressed block> UPDATE </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'subScription',
  data() {
    return {
      list: [],
      selected: [] // 已订阅
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getSubscription()
  },
  methods: {
    // 获取订阅
    getSubscription() {
      this.$api.userCenter.getSubscription({}).then(res => {
        if (res.code === 200) {
          this.list = res.data
          this.setSelected(res.data)
        }
      })
    },
    setSelected(data) {
      if (!data.length) {
        return false
      }
      const list = data.filter(item => {
        return item.is_subscription === 1
      })
      this.selected = list
    }
    // save() {
    //   this.$api.userCenter.saveSubscription({}).then(res => {
    //     if (res.code === 200) {
    //     }
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.content-body {
  margin-top: 20px;
}
.content {
  height: 348px;
  background: #f9f9fa;
  padding: 35px 30px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  .checkbox-item {
    display: flex;
    align-items: center;
    width: 50%;
    height: 52px;
  }
}
.btn-box {
  margin-top: 24px;
  width: 300px;
  height: 46px;
  .btn-option {
    color: #fff !important;
    height: 100% !important;
    border: 0;
    background: #222426 !important;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .content-body {
    .content {
      height: auto;
    }
    .list {
      flex-direction: column;
      .checkbox-item {
        width: 100%;
      }
    }
  }
  .btn-box {
    margin: 38px auto;
  }
}
</style>
