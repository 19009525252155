<!-- 顶部选项 -->
<template>
  <div id="control-nav" class="option-box">
    <div class="item" @click="toPage('close')">
      <img class="close" src="/imgs/close-nav.png" alt="close" />
    </div>
    <div class="item" @click="toPage('cart')">
      <img src="/imgs/cart-nav.png" alt="shopping_cart" />
    </div>
    <div class="item" @click="toPage('collection')">
      <img class="collection" src="/imgs/collection-nav.png" alt="collection" />
    </div>
    <div class="item" @click="toPage('personal')">
      <img src="/imgs/user-nav.png" alt="center" />
    </div>
    <div class="item" @click="toPage('language')">
      <!-- <img src="/imgs/EN.png')" alt="language" /> -->
      <span class="currency">{{ $store.state.system.currencyList[$store.state.system.currencyIndx].value }}</span>
    </div>
    <div class="item" @click="toPage('search')">
      <img src="/imgs/search-nav.png" alt="search" />
    </div>
    <!-- <v-divider vertical />
    <v-divider vertical />
    <v-divider vertical />
    <v-divider vertical />
    <v-divider vertical /> -->
    <Llogin :show="dialog" mode="mobile" @closeDialog="dialog = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Llogin from '@/components/Llogin/index.vue'
export default {
  name: 'LmobileNav',
  components: {
    Llogin
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapState({
      userToken: state => state.system.userToken
    })
  },
  methods: {
    toPage(name) {
      if (name === 'search') {
        this.$router.push({ name: 'control-search' })
      } else if (name === 'close') {
        const name = this.$store.state.system.from
        const query = this.$store.state.system.queryCurrent
        const params = this.$store.state.system.paramsCurrent
        this.$router.push({
          name,
          query,
          params
        })
      } else if (name === 'language') {
        this.$router.push({ name: 'control-language' })
      } else if (name === 'personal') {
        // this.dialog = true
        // 登录判断
        this.checkAndtoPage(() => {
          this.$router.push({
            name: 'centerMain-account'
          })
        })
        // if (this.userToken) {

        // } else {
        //   this.$store.commit('system/toggleLogin', true)
        // }

        // window.open(page.href, '_blank')
      } else if (name === 'cart') {
        // this.dialog = true
        this.$router.push({ name: 'shoppingCart' })
        // 登录判断
        // this.checkAndtoPage(() => {
        //   this.$router.push({ name: 'shoppingCart' })
        // })
      } else if (name === 'collection') {
        // this.dialog = true
        // 登录判断
        this.checkAndtoPage(() => {
          this.$router.push({ name: 'centerMain-personalization-wishlist' })
        })
      }
    },
    checkAndtoPage(fn) {
      // fn()
      if (this.userToken) {
        fn()
      } else {
        this.$store.commit('system/toggleLogin', true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// #control-nav {
//   position: relative;
// }
.option-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 58px;
  background: #fff;
  border-bottom: 1px solid #cccccc;
  .item {
    height: 100%;
    width: 100%;
    font-size: 0;
    border-right: 1px solid #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    height: 18px;
    // width: 14px;
  }
  .close {
    display: inline-block;
    height: 16px;
    // width: 10px;
  }
}
.currency {
  font-size: 18px;
  color: #000000;
}
</style>
