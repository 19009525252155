<template>
  <div class="help">
    <template v-if="list.data.length">
      <p v-for="item in list.data" :key="item.id" class="item" @click="tagFaq(item.id)">{{ item.title }}</p>
    </template>
    <template v-else>
      <p class="no-question">Unable to find the relevant issue you searched for.</p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'myHelp',
  props: {
    list: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    tagFaq(id) {
      this.$emit('tagFaq', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  cursor: pointer;
}
.no-question {
  text-align: center;
}
</style>
