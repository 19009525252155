<template>
  <v-dialog v-model="dialog" class="dialogs-com" persistent :fullscreen="$vuetify.breakpoint.mobile">
    <div class="dialog-body">
      <div class="dialog-header d-flex">
        <v-spacer />
        <v-btn icon dark @click="closeDialog">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="content-detail">
        <div class="left">
          <!-- <div class="left-bg" :style="{ backgroundImage: `url(${info.image})` }"></div> -->
          <v-img class="img-user" lazy-src="/imgs/imgLazy.jpg" :src="info.image" position="top center" alt="user-photo" />
        </div>
        <div class="right">
          <div class="top">
            <v-img class="product-photo" lazy-src="/imgs/imgLazy.jpg" :src="info.photo" alt="product-photo" contain />
            <p class="title-product">{{ info.code }}</p>
          </div>
          <div class="bottom">
            <!-- 评分 -->
            <div class="rating-box">
              <span>Rating:</span>
              <v-rating :value="info.score" :length="5" readonly half-increments background-color="orange lighten-3" color="orange" />
            </div>
            <!-- 评论 -->
            <p class="user-ask">{{ info.content }}</p>
            <!-- 时间 -->
            <div class="option">
              <p class="time">{{ info.time }}</p>
              <nuxt-link :to="`/details/${info.code}`">
                <div class="btn-box">
                  <v-btn block depressed class="my-btn" tile color="#3067f2">SHOP THIS LOOK</v-btn>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'photoInfo',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    // 查询代码
    currentId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      dialog: false,
      info: {}
    }
  },
  watch: {
    show(newValue) {
      this.dialog = newValue
      if (newValue) {
        this.getInfo()
      } else {
        this.info = {}
      }
    }
  },
  methods: {
    closeDialog() {
      this.$parent.dialog = false
    },
    // 获取详情
    getInfo(code) {
      this.$api.home.getPhotoInfo({ wall_id: this.currentId }).then(res => {
        if (res.code === 200) {
          this.info = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog {
// }
.dialogs-com,
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  background: #fff;
  margin: 0 auto;
}
.v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  width: 800px;
}
.dialog-header {
  background: #f5f5f5;
}
.content-detail {
  min-height: 490px;
  background: #fff;
  display: flex;
}
.left {
  width: 290px;
  display: flex;
  align-items: center;
  position: relative;
  .left-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    filter: blur(2px);
    background-size: cover;
    background-position: center top;
  }
  .img-user {
    height: 100%;
    flex-shrink: 0;
  }
}
.right {
  flex: 1;
  margin: 0 25px;
  overflow: hidden;
  position: relative;

  .top {
    height: 200px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #dcdcdc;
    .product-photo {
      margin: 0 auto;
      height: 80px;
      flex: none;
    }
    .title-product {
      color: #000000;
      margin-top: 30px;
      margin-bottom: 0;
    }
  }

  .bottom {
    padding: 16px 16px 75px;
  }
  .rating-box {
    display: flex;
    align-items: center;
    ::v-deep .v-rating .v-icon {
      padding: 2px !important;
      font-size: 26px;
    }
  }
  .user-ask {
    color: #333333;
    margin: 15px 0;
    font-size: 16px;
  }
  .option {
    position: absolute;
    bottom: 20px;
  }
  .btn-box {
    width: 220px;
  }
  a {
    text-decoration: none;
  }
  .my-btn {
    color: #fff !important;
    height: 42px !important;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .dialog-header {
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    background: none;
  }
  .dialog-body {
    margin-top: 36px;
  }
  .content-detail {
    flex-direction: column;
  }
  .left {
    width: 100%;
    padding: 0 16px;
    .left-bg {
      background-image: none;
    }
    .img-user {
      // height: auto;
      height: 480px;
    }
  }
  .right {
    margin: 0 16px;
    .top {
      height: 158px;
      .product-photo {
        height: 65px;
      }
    }
    .bottom {
      padding: 16px;
    }
    .option {
      position: unset;
    }
    .btn-box {
      width: 100%;
    }
  }
}
</style>
