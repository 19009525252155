<template>
  <div class="content">
    <template v-if="$route.params.articles == 'How-to-Order-Glasses-Online'">
      <div class="content-title">{{ content.title }}</div>
      <div class="flow-box">
        <div class="left">
          <p v-for="index in flowNum" :key="index" class="item" :class="{ active: index == currentFlow }" @click="changeFlow(index)">
            <span>{{ index }}</span>
            <span>Step</span>
          </p>
        </div>
        <div class="right">
          <transition name="fade">
            <img v-if="image_flag" :src="currentImg" alt="" @click="lookImg" />
          </transition>
        </div>
      </div>
      <components :is="renderCom" v-if="$vuetify.breakpoint.mobile" :show="imgDiologs" :list="[currentImg]" />
    </template>
    <template v-else>
      <div class="content-title">{{ content.title }}</div>
      <div class="content-text" v-html="content.content"></div>
      <template v-if="$route.params.articles == 'How-to-Measure-Your-PD'">
        <div class="button-box">
          <a class="my-link" download target="_blank" :href="`${GLOBAL.URL}/materials/download/Stylool-PD-Ruler.pdf`">DOWNLOAD STYLOOL'S PD RULER</a>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
const imgDialog = () => import('@/components/Ldetails/components/imgDialog.vue')
export default {
  name: 'contentFaq',
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      flowNum: 7,
      imgDiologs: false,
      currentFlow: 1,
      image_flag: true,
      flowImg: ['step-1.png', 'step-2.png', 'step-3.png', 'step-4.png', 'step-5.png', 'step-6.png', 'step-7.png']
    }
  },
  computed: {
    currentImg() {
      return '/imgs/flow/' + this.flowImg[this.currentFlow - 1]
    },
    renderCom() {
      if (this.$route.params.articles === 'How-to-Order-Glasses-Online') {
        return imgDialog
      }
      return ''
    }
  },
  watch: {
    currentFlow(val, old) {
      if (val !== old) {
        this.image_flag = false
        setTimeout(() => {
          this.image_flag = true
        }, 100)
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 切换流程
    changeFlow(index) {
      this.currentFlow = index
    },
    // 查看大图
    lookImg() {
      if (this.$vuetify.breakpoint.mobile) {
        this.imgDiologs = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .button-box{
// // text-align: center;

// }
/*设置动画*/
.fade-enter,
.fade-leave-to,
.fade-leave {
  transform: scale(0);
}
// .fade-leave {
//   transition: 0.8s;
//   display: none;
// }
.fade-enter-active {
  transition: 0.3s;
}
.fade-leave-active {
  transition: 0.3s;
}
.fade-enter-to {
  // transform: rotate3d(180, 0, 0, 0deg);
  transform: scale(1);
}
.flow-box {
  background: #5d7ed2;
  padding: 15px 10px;
  border-radius: 8px;
  display: flex;
  .item {
    text-align: center;
    padding: 20px 25px;
    margin-bottom: 0;
    color: #fff;
    font-weight: 600;
    background: #5d7ed2;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s;
  }
  .item.active {
    background: #fff;
    color: #000;
  }
  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.my-link {
  display: inline-block;
  color: #fff !important;
  text-decoration: none;
  text-align: center;
  width: 50%;
  padding: 10px;
  margin-top: 20px;
  background: #3067f2;
}
.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  font-size: 13px;
  background: #f2f2f2;
  color: #2b2e33;
  padding: 0 15px;
  img {
    width: 14px;
    height: 14px;
  }
}
.content {
  padding: 0 15px;
  flex: 1;
  .content-title {
    font-size: 26px;
    font-weight: bold;
    color: #2b2e33;
    text-align: center;
    margin: 25px 0 19px;
  }
  .content-text {
    font-size: 15px;
    color: #525861;
    line-height: 27px;
    ::v-deep p {
      margin-bottom: 30px;
    }
    ::v-deep img {
      width: 100%;
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .flow-box {
    padding: 8px 6px;
    margin: 20px auto;
    .item {
      font-size: 13px;
      padding: 10px 10px;
    }
    .right {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .button-box {
    text-align: center;
  }
  .my-link {
    width: auto;
    margin-bottom: 20px;
  }
}
</style>
