<template>
  <div class="specifications">
    <template v-if="showModel">
      <h3 class="introduce-title title1">Product Description</h3>
      <div class="btn-model">
        <button type="button" class="mm-btn" :class="{ active: currentUnit == 0 }" @click="changeUnit('mm')">MM</button>
        <button type="button" class="in-btn" :class="{ active: currentUnit == 1 }" @click="changeUnit('in')">IN</button>
      </div>
      <div v-if="$vuetify.breakpoint.smAndDown" class="introduce" v-html="info.description"></div>
      <v-row no-gutters class="info-box">
        <v-col cols="12" md="6">
          <div class="frame-img">
            <div class="positive">
              <img src="/imgs/frame.png" alt="positive-frame" />
              <span class="item-info frame-width">Frame width<br />{{ sizeNum(info.frame_width) }}</span>
              <span class="item-info bridge">Bridge<br />{{ sizeNum(info.bridge) }}</span>
              <span class="item-info lens-width">Lens width<br />{{ sizeNum(info.lens_width) }}</span>
              <span class="item-info lens-height">Lens height<br />{{ sizeNum(info.lens_height) }}</span>
            </div>
            <div class="side">
              <img src="/imgs/frame2.png" alt="side-frame" />
              <span class="item-info temple">Temple<br />{{ sizeNum(info.temple) }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="detailed-parameters">
            <div v-if="$vuetify.breakpoint.mdAndUp" class="introduce" v-html="info.description"></div>
            <table class="introduce-table">
              <tbody>
                <tr>
                  <td class="type-name">SKU</td>
                  <td class="type-info">{{ info.code }}</td>
                </tr>
                <tr>
                  <td class="type-name">Frame Weight</td>
                  <td class="type-info">{{ info.weight }}g</td>
                </tr>
                <tr>
                  <td class="type-name">RX Range</td>
                  <td class="type-info">{{ info.rx_min + ' ~ +' + info.rx_max }}</td>
                </tr>
                <tr>
                  <td class="type-name">PD Range</td>
                  <td class="type-info">{{ info.pd_min + ' ~ ' + info.pd_max }}</td>
                </tr>
                <tr>
                  <td class="type-name">Rim</td>
                  <td class="type-info">{{ info.rim }}</td>
                </tr>
                <tr>
                  <td class="type-name">Gender</td>
                  <td class="type-info">{{ info.gender }}</td>
                </tr>
                <tr>
                  <td class="type-name">Materials</td>
                  <td class="type-info">{{ info.mateial }}</td>
                </tr>
                <tr>
                  <td class="type-name">Progressive</td>
                  <td class="type-info">{{ info.is_progressive === 1 ? 'YES' : 'NO' }}</td>
                </tr>
                <tr>
                  <td class="type-name">Shape</td>
                  <td class="type-info">{{ info.shape }}</td>
                </tr>
                <tr>
                  <td class="type-name">Spring Hinge</td>
                  <td class="type-info">{{ info.is_spring_hingee === 1 ? 'YES' : 'NO' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <div class="introduce-box">
        <h3 class="introduce-title title2">Product Description</h3>
        <div class="introduce" v-html="info.description"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { multiply } from '@/assets/utils/decimal'
export default {
  name: 'speciFications',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentUnit: 0
    }
  },
  computed: {
    // 是否显示某些模块 (眼镜 / 配件)
    showModel() {
      const type = this.info.category
      if (type === 'accessories') {
        return false
      } else {
        return true
      }
    },
    sizeNum() {
      return function (val) {
        // console.log(val)
        // return val
        // console.log(multiply(1))
        if (this.currentUnit === 1) {
          const difference = 0.0393701
          return multiply(val, difference).toFixed(2) + 'in'
        } else {
          return val + 'mm'
        }
        // return val
      }
    }
  },
  watch: {},
  methods: {
    changeUnit(type) {
      if (type === 'in') {
        this.currentUnit = 1
      } else {
        this.currentUnit = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.introduce-title {
  font-size: 30px;
  font-weight: bold;
  color: #2b2e33;
  line-height: 35px;
  text-align: center;
  margin-bottom: 25px;
}
.title1 {
  margin-top: 40px;
}
.btn-model {
  margin-bottom: 40px;
  font-size: 0;
  button {
    height: 26px;
    width: 45px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #2b2e33;
    background: #f0f4ff;
    color: #2b2e33;
  }
  .in-btn {
    border-radius: 0px 50px 50px 0px;
  }
  .mm-btn {
    border-radius: 50px 0px 0px 50px;
  }
  .active {
    background: #2b2e33;
    color: #fff;
  }
}
.frame-img {
  width: 554px;
  .positive {
    // border: 1px dashed #9d9898;
    height: 274px;
    position: relative;
    .frame-width {
      top: 65px;
      left: 230px;
    }
    .bridge {
      top: 194px;
      left: 247px;
    }
    .lens-width {
      top: 155px;
      left: 105px;
    }
    .lens-height {
      top: 163px;
      left: 367px;
    }
  }
  .side {
    margin-top: 110px;
    // border: 1px dashed #9d9898;
    height: 177px;
    position: relative;
    .temple {
      top: 124px;
      left: 280px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  .item-info {
    position: absolute;
    display: inline-block;
    font-size: 14px;
    color: #2b2e33;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    background: #fff;
    padding: 5px 10px;
  }
}

.detailed-parameters {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
  .introduce {
    font-size: 15px;
    font-weight: 400;
    color: #525861;
    line-height: 24px;
    // word-break: break-all;
    word-wrap: break-word;
    width: 520px;
  }
  table.introduce-table {
    width: 520px;
    font-size: 16px;
    color: #333333;
    border: 1px solid #dcdfe5;
    border-collapse: collapse;
  }
  table.introduce-table td {
    padding: 10px;
    border: 1px solid #dcdfe5;
  }
  .type-name {
    width: 160px;
    background: #f7f9fc;
  }
  .type-info {
    background: #fff;
  }
}
.introduce-box {
  padding: 25px;
  background: #f7f8fa;
  h3 {
    margin-bottom: 6px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .info-box {
    flex-direction: column;

    & > :first-child {
      margin: 0 auto 20px;
    }
    & > :last-child {
      margin: 0 auto;
    }
  }
  .frame-img,
  .introduce-table {
    margin: 0 auto;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .introduce-title {
    font-size: 15px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 0;
  }
  .introduce-box {
    padding: 0;
    h3 {
      font-size: 15px;
      text-align: left;
    }
    .introduce ::v-deep p {
      font-size: 14px;
    }
  }
  .v-tab {
    font-size: 13px !important;
  }
  .btn-model {
    margin-bottom: 20px;
  }
  .detailed-parameters {
    flex-direction: row;
  }
  .specifications > .introduce {
    margin: 0 auto;
    font-size: 14px;
  }
  .specifications {
    margin: 10px auto 0;
    background: #ffffff;
    padding: 10px;
    border-radius: 12px;
    .frame-img {
      width: 327px;
      margin: 0 auto;
      .positive {
        height: 162px !important;
        .frame-width {
          top: 34px;
          left: 123px;
        }
        .bridge {
          top: 127px;
          left: 143px;
        }
        .lens-width {
          top: 90px;
          left: 51px;
        }
        .lens-height {
          top: 94px;
          left: 210px;
        }
      }
      .side {
        height: 104px !important;
        margin-top: 52px !important;
        margin-bottom: 38px;
        .temple {
          top: 69px;
          left: 146px;
        }
      }
      .item-info {
        font-size: 13px;
        padding: 0 !important;
      }
    }
    .detailed-parameters {
      table.introduce-table {
        width: 100%;
        font-size: 14px;
        margin: 0 auto;
      }
      table.introduce-table td {
        padding: 10px;
      }
      .type-name {
        width: 154px !important;
        background: #f7f9fc;
      }
    }
  }
}
</style>
