<template>
  <div v-show="readonly" class="recommendProduct">
    <h2 class="recommend-title">You May Also Like</h2>
    <div class="swiper-box">
      <div class="swiper-wrapper">
        <!-- 内容 -->
        <div v-for="(item, index) in list" :key="index" class="swiper-slide item-swiper">
          <div class="goods-item">
            <div class="item">
              <div class="banner" :class="{ img2h: item.category === 'eye' ? false : true }" @click="getCurrentInfo(item.code)">
                <v-img contain transition="scale-transition" lazy-src="/imgs/imgLazy.jpg" :src="item.img" />
              </div>
              <div class="content" title="">{{ item.content }}</div>
              <div class="price">
                <template v-if="item.price != item.original">
                  <span class="current-price">{{ currencyIcon }}{{ item.price }}</span>
                  <span class="old-price">{{ currencyIcon }}{{ item.original }}</span>
                </template>
                <template v-else>
                  <span class="only-price">{{ currencyIcon }}{{ item.price }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 控制 -->
      <template v-if="!$vuetify.breakpoint.mobile">
        <div class="swiper-button-next">
          <img class="next-icon" src="/imgs/to.png" alt="next" />
        </div>
        <div class="swiper-button-prev">
          <img class="prev-icon" src="/imgs/to.png" alt="prev" />
        </div>
      </template>
      <productDialog v-model="dialog" :code="currentCode" @refresh="refresh" />
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import { mapGetters } from 'vuex'
import productDialog from '@/components/LshoppingCart/productDialog'
export default {
  name: 'recommendProduct',
  components: {
    productDialog
  },
  data() {
    return {
      // 弹窗-----------------
      dialog: false,
      currentCode: undefined,
      // -----------------
      swiperOption: {
        // slidesPerView: 'auto',
        slidesPerView: 5,
        slidesPerGroup: 5, // 切换数量
        spaceBetween: 10,
        clickable: true,
        // freeMode: true,
        slideToClickedSlide: true, // 点击slide会过渡到这个slide
        preventClicksPropagation: true, // 拖动时阻止事件触发
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        // pagination: {
        //   // el: '.swiper-pagination',
        //   clickable: false,
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          hideOnClick: true,
          disabledClass: 'swiper-btn-disable-none'
        }
        // on: {
        //   slideChangeTransitionStart: function () {
        //     // that.currentImgIndex = this.activeIndex
        //   }
        // }
      },
      list: [], // 推荐列表
      readonly: false
    }
  },
  computed: {
    ...mapGetters('system', ['currencyIcon'])
  },
  watch: {},
  created() {},
  mounted() {
    this.getProduct()
  },
  methods: {
    initSwiper() {
      if (this.$vuetify.breakpoint.mobile) {
        this.swiperOption.slidesPerView = 2
        this.swiperOption.slidesPerGroup = 2
      }
      // eslint-disable-next-line no-new
      new Swiper('.swiper-box', this.swiperOption)
      this.readonly = true
    },
    getProduct() {
      const config = {
        page: 1,
        limit: 20,
        type: 'accessories'
      }
      this.$api.product.getGoodsList(config).then(res => {
        if (res.code === 200) {
          this.list = res.data
          if (res.data.length) {
            this.initSwiper()
          }
        }
      })
    },
    getCurrentInfo(code) {
      this.currentCode = code
      this.dialog = true
    },
    refresh() {
      this.$parent.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.recommendProduct {
  overflow: hidden;
  margin-top: 30px;
  .swiper-button-prev {
    left: 0;
    transform: rotate(180deg);
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background: rgb(0, 0, 0, 0.5);
    height: 40px !important;
    z-index: 1 !important;
    width: 40px !important;
    border-radius: 50%;
    outline: none;
    &::after {
      content: none;
    }
    img {
      height: 15px;
    }
  }
}
.recommend-title {
  margin-bottom: 30px;
  text-align: center;
}
.swiper-box {
  position: relative;
}
// .swiper-slide {
//   //   width: 210px;
// }
.goods-item {
  background: #fff;
  width: 100%;
  .item {
    width: calc(100% - 6px);
    height: 100%;
    margin: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
    transition: box-shadow 1s;

    @media screen and (min-width: 1200px) {
      .right:hover {
        opacity: 0.7;
      }
    }

    .banner {
      width: 86%;
      height: 100px;
      margin: 20px auto 10px;
      cursor: pointer;
      img,
      .v-image {
        height: 100%;
        object-fit: cover;
      }
    }
    .img2h {
      height: 100px;
    }
    .content {
      width: 100%;
      font-size: 16px;
      text-align: center;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .price {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      .current-price {
        color: red;
        font-size: 18px;
        font-weight: 600;
      }
      .old-price {
        color: #6b6b6b;
        font-size: 16px;
        margin-left: 5px;
        position: relative;
        font-weight: 600;
        line-height: 1;
        text-decoration: line-through;
      }
      .only-price {
        color: #252323;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .recommendProduct {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .recommend-title {
    font-size: 16px;
  }
}
</style>
