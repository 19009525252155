<template>
  <div class="reviews" :class="{ fullwidth: reviews.length == 0 }">
    <!-- 添加评论   购买过 -->
    <!-- <div class="add-reviews"><img src="/imgs/edit.png" alt="Add a Review" /> <span @click="reviewDialog = true">Add a Review</span></div> -->
    <div v-if="reviews.length" class="reviews-box">
      <div class="reviews-content">
        <!-- 条目 -->
        <div v-for="item in reviews" :key="item.id" class="reviews-item">
          <!-- 左侧头像 -->
          <!-- <v-img src="/imgs/user.png" /> -->
          <!-- <div class="user-img">
            <v-img lazy-src="/imgs/imgLazy.jpg" :src="item.photo ? item.photo : '/imgs/user.png'" />
          </div> -->
          <!-- 右侧内容 -->
          <div class="reviews-text">
            <!-- 时间 评分 -->
            <div class="top">
              <span class="reviews-info">
                <span class="user-name">{{ item.name }} </span>
                <span>{{ item.create_time }}</span>
              </span>
              <v-rating v-model="item.score" :length="5" readonly half-increments background-color="orange lighten-3" color="orange" />
            </div>
            <p class="user-review">{{ item.content }}</p>
            <div v-if="item.images.length" class="share-pic">
              <div v-for="(img, index) in item.images" :key="index" class="pic-item" @click="getFullImg(item.images, index)">
                <v-img lazy-src="/imgs/imgLazy.jpg" :src="img" contain />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="setPageLength > 1" class="pagination-box">
        <v-pagination v-model="page" color="#222426" class="mt-4" :total-visible="5" :length="setPageLength" @input="change" />
      </div>
      <imgDialog :show="imgDiologs" :list="currentImgList" :current="imgCurrent" />
    </div>
    <template v-else>
      <div class="comment-none"></div>
      <p class="no-content">There is no comment on the current product.</p>
    </template>
    <!-- <reviewDialog :show="reviewDialog" @close="reviewDialog = false" @reviewData="reviewData" /> -->
  </div>
</template>

<script>
// import userDefault from '@/static/imgs/user.png'
import imgDialog from '@/components/Ldetails/components/imgDialog.vue'
// import reviewDialog from '@/components/Ldetails/components/reviewDialog.vue'
export default {
  name: 'myReviews',
  components: {
    imgDialog
    // reviewDialog
  },
  props: {
    // 分页数据
    option: {
      type: Object,
      default: () => {
        return {
          page: 1,
          limit: 9,
          count: 9
        }
      }
    },
    reviews: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      page: 1,
      reviewDialog: false,
      imgDiologs: false,
      currentImgList: [],
      imgCurrent: 0
    }
  },
  computed: {
    // 设置分页数量
    setPageLength() {
      return Math.ceil(this.option.count / this.option.limit)
    }
  },
  watch: {},
  methods: {
    getFullImg(list, index) {
      this.currentImgList = list
      this.imgCurrent = index
      this.imgDiologs = true
    },
    reviewData() {},
    change(val) {
      this.$emit('page', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.reviews {
  width: calc(100% - 140px);
}
.fullwidth {
  width: 100%;
}
.comment-none {
  width: 170px;
  height: 130px;
  background: url('/imgs/comment-none.png') no-repeat;
  background-size: contain;
  margin: 45px auto 30px;
}
.no-content {
  text-align: center;
}
.add-reviews {
  display: flex;
  justify-content: flex-end;
  color: #3067f2;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
  img {
    width: 16px;
    height: 16px;
    margin-right: 17px;
  }
  span {
    cursor: pointer;
  }
}
// .reviews-box {
//   height: 629px;
//   overflow-y: auto;
//   overscroll-behavior: contain;
//   -ms-scroll-chaining: contain;
// }
.mobile-btn-more {
  margin: 20px 0;
}
.reviews-item {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 20px;
  .user-img {
    width: 55px;
    height: 55px;
    // border: 1px dotted #9d9898;
    margin-top: 15px;
    .v-image {
      width: 100%;
      height: 100%;
    }
  }
  .share-pic {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .pic-item {
    width: 70px;
    height: 80px;
    // border: 1px dotted #9d9898;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    .v-image {
      width: 100%;
      height: 100%;
    }
  }
  .reviews-text {
    background: #f7f7f7;
    min-height: 99px;
    flex: 1;
    margin-left: 28px;
    padding: 0 22px;
    ::v-deep .v-rating .v-icon {
      padding: 5px !important;
      font-size: 16px;
    }
    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0 10px;
      .reviews-info {
        font-size: 14px;
        color: #525861;
        .user-name {
          color: #000;
        }
      }
    }

    // margin-bottom: 20px;
  }
}
// .reviews-text::before {
//   content: '';
//   width: 0;
//   height: 0;
//   border: 15px solid;
//   position: absolute;
//   top: 32px;
//   left: 53px;
//   border-color: transparent #f7f7f7 transparent transparent;
// }
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .reviews {
    width: 100%;
  }
  .add-reviews {
    justify-content: flex-start;
    margin-left: 16px;
    img {
      margin-right: 10px;
    }
  }
  .reviews-box {
    height: auto;
    overflow: unset;
  }
  .reviews-item {
    flex-direction: column;
    margin: 0 auto;
    position: relative;
    .reviews-text {
      margin: 0 0 10px 0;
      padding: 10px;
      min-height: unset;
      background: #ffffff;
      .top {
        // position: absolute;
        // top: 0;
        // left: 68px;
        margin-top: 0;
        align-items: center;
        ::v-deep .v-rating .v-icon {
          padding: 0 2px 0 0 !important;
          font-size: 13px;
        }
        .reviews-info {
          font-size: 12px;

          line-height: 13px;
        }
      }
      .user-review {
        font-size: 14px;
        // word-break: break-all;
        // word-wrap: break-word;
        margin-bottom: 0;
      }
      .pic-item {
        width: 65px;
        height: 77px;
      }
    }
  }
  // .reviews-text::before {
  //   content: '';
  //   width: 0;
  //   height: 0;
  //   border: 15px solid;
  //   position: absolute;
  //   top: 56px;
  //   left: 14px; // f7f7f7
  //   border-color: transparent transparent #f7f7f7 transparent;
  // }
  .pagination-box {
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    .size-all {
      height: 40px;
      line-height: 40px;
    }
  }
  .pagination-box ::v-deep .v-pagination__item {
    width: 40px;
    height: 40px;
    font-size: 14px;
    margin: 0 4px;
  }
  .pagination-box ::v-deep .v-pagination__navigation {
    // display: none !important;
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
}
</style>
