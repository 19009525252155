<template>
  <!-- 手机 pc 搜索 -->
  <div class="search">
    <Lsearch :type="tab" @search="search" @comSearch="comSearch" />
    <div class="tab-box">
      <v-tabs v-model="tab" centered background-color="#fff" color="#2B2E33" left>
        <v-tab v-for="(item, index) in tabItem" :key="index" class="my-btn"> {{ item }} </v-tab>
        <v-spacer />
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <products :text="text" :loading="loading" :list="list" :history="history" :hot-tag="hotTag" @tagSearch="tagSearch" @clearHistory="clearHistory" @getMore="getMore" />
          </v-tab-item>
          <v-tab-item>
            <help :list="listFaq" @tagFaq="tagFaq" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { setLocalStorage, getLocalStorage, removeLocalStorage } from '@/assets/utils/storage'
// 搜索框单件
import Lsearch from '@/components/Lsearch/index.vue'
// import all from '@/components/Lsearch/all.vue'
import products from '@/components/Lsearch/products.vue'
import help from '@/components/Lsearch/help.vue'
// import searchAbout from '@/components/Lsearch/searchAbout.vue'
export default {
  name: 'search',
  components: {
    Lsearch,
    // all,
    products,
    help
    // searchAbout
  },
  //  honeloogia使用 作为组件   使用mountred获取数据
  data() {
    return {
      text: '', // 搜索关键词  更多
      tab: 0,
      tabItem: ['Products', 'Help'],
      // product----------------------------------
      history: getLocalStorage('history') || [], // 搜索历史
      hotTag: [], // 热门搜索
      recommendList: [], // 推荐商品
      searchList: [], // 搜索商品
      searchLength: undefined, // 搜索结果数量
      // faq----------------------------------
      faqList: [], // 问题列表
      searchFaqList: [], // 搜索问题
      searchFaqLength: undefined, // 搜索结果数量
      loading: false
    }
  },
  computed: {
    list() {
      if (this.text) {
        return {
          type: 'search',
          data: this.searchList,
          length: this.searchLength
        }
      } else {
        return {
          type: 'recommend',
          data: this.recommendList
        }
      }
    },
    listFaq() {
      if (this.text) {
        return {
          type: 'search',
          data: this.searchFaqList,
          length: this.searchFaqLength
        }
      } else {
        return {
          type: 'recommend',
          data: this.faqList
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      try {
        document.getElementById('search-input').focus()
      } catch (error) {}
    })
    this.getHotSearch()
    this.getGoods('recommend')
    this.getFaq('recommend')
  },
  methods: {
    // 跳转搜索
    search(val) {
      if (this.tab === 0) {
        this.productPage(val)
      } else {
        this.faqPage(val)
      }
      this.$store.commit('system/toggleNavLog', false)
    },
    // 点击更多
    getMore() {
      this.search(this.text)
    },
    // 弹窗搜索
    comSearch(val) {
      this.text = val
      if (this.tab === 0) {
        this.productListFn(val)
      } else {
        this.faqListFn(val)
      }
    },
    // 商品弹窗搜索
    productListFn(val) {
      if (val) {
        // 有内容时
        this.getGoods('search', val)
      } else {
        this.searchList = []
      }
    },
    // 商品跳转
    productPage(val) {
      this.saveHistory(val)
      this.$router.push({
        name: 'search-type',
        params: { type: 'search' },
        query: {
          search: val
        }
      })
    },
    // 获取搜索结果
    getGoods(type, val) {
      this.loading = true
      const obj = {
        limit: 6
      }
      if (type === 'recommend') {
        obj.type = 'best-sellers'
      } else {
        obj.type = type
        obj.search = val
      }
      this.$api.product.getGoodsList(obj).then(res => {
        if (res.code === 200) {
          if (type === 'recommend') {
            // 加载推荐商品
            this.recommendList = res.data
          } else if (type === 'search') {
            // 加载搜索商品
            this.searchList = res.data
            this.searchLength = res.count
          }
        }
        this.loading = false
      })
    },
    faqPage(val) {
      this.$router.push({
        name: 'index-faq-articles',
        params: { articles: 'list' },
        query: {
          search: val
        }
      })
    },
    faqListFn(val) {
      if (val) {
        // 有内容时
        this.getFaq('search', val)
      } else {
        this.searchFaqList = []
      }
    },
    // 问题列表
    getFaq(type, val) {
      const obj = {
        limit: 6
      }
      if (type === 'recommend') {
        obj.search = ''
      } else {
        obj.search = val
      }
      this.$api.home.getFaqList(obj).then(res => {
        if (res.code === 200) {
          if (type === 'recommend') {
            this.faqList = res.data
          } else {
            this.searchFaqList = res.data
            this.searchFaqLength = res.count
          }
        }
      })
    },
    // 去问题页
    tagFaq(id) {
      this.$store.commit('system/toggleNavLog', false)
      this.$router.push({
        name: 'index-faq-articles',
        params: { articles: id }
      })
    },
    // 搜索 热词/历史  并跳转
    tagSearch(val) {
      this.search(val)
    },
    // 获取热搜词
    getHotSearch() {
      this.$api.home.getHotTag().then(res => {
        if (res.code === 200) {
          this.hotTag = res.data
        }
      })
    },
    // 保存搜索历史
    saveHistory(val) {
      if (!val) {
        return false
      }
      // 获取现有记录
      let data = getLocalStorage('history')
      if (!data) {
        data = []
        data.push(val)
        setLocalStorage('history', data)
      } else {
        if (data.length > 10) {
          data.pop()
        }
        data.unshift(val)
        const list = [...new Set(data)]
        setLocalStorage('history', list)
      }
    },
    // 清空历史
    clearHistory() {
      removeLocalStorage('history')
      this.history = []
    }
  }
}
//  隐藏搜索弹窗
// function hideDidlog() {
//   const dialog = document.querySelector('.LhomeDialog')
//   console.log(dialog)
//   if (dialog) {
//     const navPc = document.querySelector('.nav-box-pc')
//     dialog.style.display = 'none'
//     dialog.style.top = navPc.offsetHeight + 'px'
//   }
// }
</script>

<style lang="scss" scoped>
.search {
  font-size: 16px;
  background: #fff;
  // padding: 0 300px;
  max-width: 1200px;
  margin: 0 auto;
  .tab-box {
    ::v-deep .v-window__container {
      padding: 10px 16px;
    }
    .my-btn {
      font-size: 14px;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .search {
    font-size: 16px;
    background: #fff;
    padding: 10px 0;
    .tab-box {
      .my-btn {
        font-size: 13px !important;
      }
      .all,
      .products,
      .help {
        // padding: 10px;
        min-height: 100px;
      }
    }
  }
}
</style>
