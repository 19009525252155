<!-- 付款成功 -->
<template>
  <div class="page">
    <div class="confirm">
      <div v-if="show" class="confirm-body">
        <div class="top"></div>
        <div class="bottom">
          <p class="tips">Payment Success</p>
          <button @click="goPage">Check the order ({{ countdownTime }}s)</button>
        </div>
      </div>
    </div>
    <Lfooter />
  </div>
</template>

<script>
import Lfooter from '@/components/Lfooter/index.vue'
export default {
  name: 'paySuccess',
  components: {
    Lfooter
  },
  scrollToTop: true,
  data() {
    return {
      // 倒计时时间（单位：秒）
      countdownTime: 10,
      timer: null,
      count: 0, // 请求次数
      show: false
    }
  },
  head() {
    return {
      title: 'Payment Success'
    }
  },
  created() {
    this.$nextTick(() => {
      this.$loading().open()
      this.checkCode()
    })
  },
  destroyed() {
    this.cleartimeout()
    this.timer = null
    this.count = 0
  },
  methods: {
    countdown() {
      // 开始倒计时
      const startCountdown = () => {
        this.countdownTime--
        // 检查倒计时是否结束
        if (this.countdownTime >= 2) {
          this.timer = setTimeout(startCountdown, 1000) // 延迟1秒后再次调用startCountdown函数
        } else {
          this.cleartimeout()

          this.goPage()
        }
      }
      startCountdown() // 启动倒计时
    },
    cleartimeout() {
      clearTimeout(this.timer)
      this.timer = null
    },
    checkCode() {
      const code = this.$route.query.code
      this.$api.userCenter.getOrderInfo({ code }).then(res => {
        if (res.code === 200) {
          this.info = res.data
          // 未下单 无倒计时
          if (![1, 2, 3, 4].includes(res.data.status)) {
            //  开始计数  五次后跳转 五次前重试
            this.count++
            if (this.count > 5) {
              this.$loading().close()
              this.$router.replace({
                name: 'centerMain-order-order'
              })
            } else {
              setTimeout(() => {
                this.checkCode()
              }, 3000)
            }
          } else {
            this.$loading().close()
            this.show = true
            this.countdown()
          }
        } else {
          this.$loading().close()
          // 没有订单
          this.$router.replace({
            name: 'centerMain-order-order'
          })
        }
      })
    },
    goPage() {
      const code = this.$route.query.code
      this.$router.replace({
        name: 'centerMain-order-order-detail',
        query: { code }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
}
.confirm {
  height: 70vh;
  display: flex;
  align-items: center;
}
.confirm-body {
  width: 468px;
  height: 312px;
  padding: 25px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .top {
    width: 170px;
    height: 128px;
    background: url('/imgs/pay-succss.png') no-repeat;
    background-size: contain;
    margin-bottom: 30px;
  }
  .tips {
    text-align: center;
  }
  button {
    background: #3067f2;
    color: #fff;
    width: 238px;
    padding: 10px;
    margin-top: 20px;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .confirm {
    height: 60vh;
  }
  .confirm-body {
    width: 350px;
    height: 300px;
  }
}
</style>
