import { render, staticRenderFns } from "./reviews.vue?vue&type=template&id=23dd0f56&scoped=true"
import script from "./reviews.vue?vue&type=script&lang=js"
export * from "./reviews.vue?vue&type=script&lang=js"
import style0 from "./reviews.vue?vue&type=style&index=0&id=23dd0f56&prod&lang=scss&scoped=true"
import style1 from "./reviews.vue?vue&type=style&index=1&id=23dd0f56&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23dd0f56",
  null
  
)

export default component.exports