<template>
  <!-- nav dialog 分类 -->
  <div class="shopType">
    <div v-if="list.rim" class="type-box">
      <div class="type-header">SHOP BY TYPE</div>
      <p v-for="item in list.rim" :key="item.id" class="type-list" @click="toList('rim', item.id)">{{ item.name }}</p>
    </div>
    <div v-if="list.material" class="type-box">
      <div class="type-header">SHOP BY MATERIAL</div>
      <p v-for="item in list.material" :key="item.id" class="type-list" @click="toList('material', item.id)">{{ item.name }}</p>
    </div>
    <div v-if="list.color" class="type-box">
      <div class="type-header">SHOP BY COLOR</div>
      <div class="list">
        <v-tooltip v-for="item in list.color" :key="item.id" top color="#222426" z-index="9999">
          <template #activator="{ on, attrs }">
            <!-- <a href=""><img :src="item.src" :alt="item.name" /></a> -->
            <p class="color-list" :style="{ background: `url(${item.icon})` }" v-bind="attrs" v-on="on" @click="toList('color', item.id)"></p>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </div>
    </div>
    <div v-if="list.shape" class="type-box">
      <div class="type-header">SHOP BY SHAPE</div>
      <p v-for="item in list.shape" :key="item.id" class="type-list" @click="toList('shape', item.id)">{{ item.name }}</p>
    </div>
    <div v-if="list.genders" class="img-type">
      <div v-for="item in list.genders" :key="item.id" class="img-list" @click="toList('genders', item.id)">
        <img :src="`/imgs/${renderImg(item.name)}`" :alt="item.name" />
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shopType',
  props: {
    type: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: () => {
        return {
          Rim: [],
          Material: [],
          Color: [],
          Shape: [],
          Genders: []
        }
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    renderImg() {
      return function (type) {
        if (type === 'Men') {
          return 'icon-men.png'
        } else if (type === 'Women') {
          return 'icon-women.png'
        } else if (type === 'Kids') {
          return 'icon-kids.png'
        }
      }
    }
  },
  watch: {},
  methods: {
    toList(type, id) {
      let typeTop = ''
      if (this.type === 'Eyeglasses') {
        typeTop = 'eyeglasses'
      } else if (this.type === 'Sunglasses') {
        typeTop = 'sunglasses'
      } else {
        typeTop = this.type
      }
      const query = {}
      const filter = encodeURI(JSON.stringify({ [type]: [id] }))
      query.filter = filter
      this.$router.push({
        name: 'search-type',
        params: {
          type: typeTop
        },
        query
      })
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.shopType {
  max-width: 1200px;
  font-size: 16px;
  margin: 35px auto 35px;
  display: flex;
  justify-content: space-between;
  .type-box {
    // margin-right: 122px;
    .type-header {
      font-size: 16px;
      font-weight: 600;
      color: #2b2e33;
      line-height: 19px;
      margin-bottom: 20px;
    }
    & > p {
      margin-bottom: 15px;
      font-size: 16px;
      font-family: Ubuntu-Regular, Ubuntu;
      font-weight: 400;
      color: #525861;
      line-height: 19px;
      cursor: pointer;
      &:hover {
        color: #3067f2;
      }
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  width: 190px;
  .color-list {
    width: 25px;
    height: 25px;
    margin-right: 20px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
}
.img-type {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 280px;
  .img-list {
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #3067f2;
    }
    img {
      width: 120px;
      height: 120px;
      margin-bottom: 10px;
    }
    p {
      color: #ffffff;
      margin-bottom: 20px;
    }
    &:nth-child(1) p {
      text-shadow: 0px 0px 1px #3067f2, 2px 2px 2px #3067f2, -2px 2px 4px #3067f2, 3px -1px 4px #3067f2, 3px 3px 2px #3067f2, -1px -2px 2px #3067f2, 3px 3px 2px #3067f2;
    }
    &:nth-child(2) p {
      text-shadow: 0px 0px 1px #ffc600, 2px 2px 2px #ffc600, -2px 2px 4px #ffc600, 3px -1px 4px #ffc600, 3px 3px 2px #ffc600, -1px -2px 2px #ffc600, 3px 3px 2px #ffc600;
    }
    &:nth-child(3) p {
      text-shadow: 0px 0px 1px #28ad00, 2px 2px 2px #28ad00, -2px 2px 4px #28ad00, 3px -1px 4px #28ad00, 3px 3px 2px #28ad00, -1px -2px 2px #28ad00, 3px 3px 2px #28ad00;
    }
  }
}
</style>
