<template>
  <div class="page">
    <div class="top-banner">
      <div class="banner">
        <img src="/imgs/banner-us.png" alt="Contact" />
      </div>
      <div class="us-text">
        <p>
          Customer service is not just answering questions. Our team of Eyewear Consultants, Dispensing Opticians, and Customer Service Representatives will accompany you throughout your shopping journey until you meet
          your most suitable Stylool glasses. See better, shop together!
        </p>
      </div>
    </div>
    <div class="content">
      <div class="contact-list">
        <a @click="openChat">
          <div class="item">
            <img src="/imgs/about-l.png" alt="Live Chat" />
            <div class="item-content">
              <p>Live Chat:</p>
              <p>Start a live chat (24/7)</p>
            </div>
          </div>
        </a>
        <a href="mailto:support@stylool.com">
          <div class="item">
            <img src="/imgs/about-e.png" alt="Email" />
            <div class="item-content">
              <p>Email:</p>
              <p>support@stylool.com</p>
            </div>
          </div>
        </a>
      </div>
      <div class="contact-option">
        <div class="left">
          <img src="/imgs/live-con.png" alt="" />
        </div>
        <div class="right">
          <div class="title-text">
            <h3>Live Chat: Start a live chat (24/7)</h3>
          </div>
          <p>We are always ready to respond to your "Hi" in 10 seconds from live chat. 24/7 standby for you.</p>
        </div>
      </div>
      <div class="contact-option">
        <div class="right">
          <div class="title-text">
            <h3>Email: support@stylool.com</h3>
            <h4>We will reply within 24 hours on</h4>
            <h4>weekdays and 48 hours on weekends</h4>
          </div>
          <div class="tips">
            <p>Don't know how to read your prescription? Send us a copy of your original prescription.</p>
            <p>Wanna return or exchange your eyeglasses? Just send us your return or exchange request in your account center.</p>
          </div>
          <div class="tips">
            <p>Please note:</p>
            <p>Always contact us first before returning your package;</p>
            <p>Use your registered email address;</p>
            <p>Be sure to include your order number;</p>
            <p>Don't forget to move our email to your Inbox.</p>
          </div>
        </div>
        <div class="left">
          <img src="/imgs/emial-con.png" alt="" />
        </div>
      </div>
    </div>
    <Lfooter />
  </div>
</template>

<script>
import Lfooter from '@/components/Lfooter/index.vue'
export default {
  name: 'contactUs',
  components: {
    Lfooter
  },
  //   validate({ query, redirect, error }) {
  //     return redirect('/index')
  //   },
  data() {
    return {}
  },
  head() {
    return {
      title: 'Contact Us'
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    getEmail() {
      try {
        window.location.href = 'mailto:support@stylool.com'
      } catch (error) {}
    },
    openChat() {
      // eslint-disable-next-line no-undef
      if (FB) {
        // eslint-disable-next-line no-undef
        FB.CustomerChat.showDialog()
      }
    }
    // getTel() {
    //   try {
    //     window.location.href = 'tel:1-949-508-0322'
    //   } catch (error) {}
    // }
  }
}
</script>

<style lang="scss" scoped>
.content {
  max-width: 1200px;
  margin: -45px auto 45px;
}
.banner {
  height: 335px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    object-fit: cover;
  }
  .banner-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    text-align: center;
    font-size: 52px;
    color: #fff;
    font-weight: 600;
  }
}
.us-text {
  background: #3067f2;
  color: #ffffff;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  transform: translate(0, -50%);
  p {
    font-size: 17px;
    margin-bottom: 0;
  }
}
.contact-list {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  margin: 20px 0;
  padding: 25px 0;
  border-bottom: 1px solid #e5e5e5;
  a {
    text-decoration: none;
  }
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    img {
      width: 58px;
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 0;
      font-weight: 600;
    }
    p:last-child {
      font-size: 15px;
    }
  }
  .item-content {
    text-align: center;
  }
}
.contact-option {
  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
  .left {
    width: 50%;
    flex-shrink: 0;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }
  p {
    color: #727272;
    margin-bottom: 0px;
  }
}
.reverse {
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }
  .right {
    padding: 0;
  }
}
.title-text {
  margin-bottom: 8px;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .content {
    margin: -62px auto 62px;
  }
  .banner .banner-text {
    font-size: 38px;
  }
  .us-text {
    padding: 16px;
    p {
      font-size: 15px;
    }
  }
  .contact-list {
    flex-direction: column;
    padding: 0 16px;
    border: none;
    a {
      margin-bottom: 16px;
    }
    .item {
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 16px;
      img {
        width: 38px;
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
    .item:last-child {
      margin-bottom: 0;
    }
    .item-content {
      text-align: left;
    }
  }
  .contact-option {
    flex-direction: column;
    .left {
      width: 100%;
    }
    p {
      font-size: 15px;
      text-align: center;
    }
  }
  .reverse {
    .left {
      order: 1;
    }
  }
  .title-text {
    text-align: center;
    h3 {
      font-size: 17px;
    }
    h4 {
      font-size: 16px;
    }
  }
}
</style>
