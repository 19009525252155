<template>
  <div class="product-foot">
    <div v-if="$vuetify.breakpoint.mdAndUp" class="current-price d-flex">
      <div class="left">
        <div class="img">
          <v-img contain transition="scale-transition" lazy-src="/imgs/imgLazy.jpg" :src="productInfo.image" />
        </div>
        <div class="info-price">
          <p class="name">{{ productInfo.title }}</p>
          <p>
            <span class="">Frame price:</span><span class="num-price">{{ currencyIcon }}{{ productInfo.price }}</span>
          </p>
        </div>
      </div>
      <div class="right">
        <div class="total">
          <p @mouseenter="showPrice = true" @mouseleave="showPriceFn">
            <span class="">Total:</span><span class="num-price">{{ currencyIcon }}{{ getInventory.total }}</span>
            <!-- 显示图标 -->
            <img class="more rotate-icon-up" :class="{ 'rotate-icon-up': showPrice, 'rotate-icon-down': !showPrice }" src="/imgs/more.png" alt="more-icon" />
          </p>
        </div>
        <div class="option">
          <v-btn class="my-btn" :disabled="step.nextControl" block tile color="#3067F2" @click="next">{{ btnName }}</v-btn>
        </div>
      </div>
      <!-- <v-spacer /> -->
    </div>
    <div v-else class="option-mobile d-flex">
      <div class="mobile-info-price" @mouseenter="showPrice = true" @mouseleave="showPriceFn">
        <div class="space">
          <div class="price-box">
            <span class="num-price">{{ currencyIcon }}{{ getInventory.total }}</span>
            <p>{{ productInfo.title }}</p>
          </div>
          <div class="status-dialog">
            <!-- 显示图标 -->
            <!-- <img v-show="showPrice" class="more rotate-icon-up" src="/imgs/more.png" alt="more-icon" />
            <img v-show="!showPrice" class="more rotate-icon-down" src="/imgs/more.png" alt="more-icon" /> -->
            <img class="more rotate-icon-up" :class="{ 'rotate-icon-up': showPrice, 'rotate-icon-down': !showPrice }" src="/imgs/more.png" alt="more-icon" />
          </div>
        </div>
      </div>
      <!-- <v-btn color="#3067F2">Next</v-btn> -->
      <div class="btn-box">
        <v-btn class="my-btn" :disabled="step.nextControl" tile block color="#3067F2" @click="next">{{ btnName }}</v-btn>
      </div>
    </div>
    <priceList v-if="showPrice" :show="showPrice" class="price-detail" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { debounce } from '@/assets/utils/toolFn'
import priceList from '@/components/Lproduct/dialogs/priceList.vue'
export default {
  name: 'productFoot',
  components: {
    priceList
  },
  data() {
    return {
      showPrice: false
    }
  },
  computed: {
    ...mapState({
      userToken: state => state.system.userToken,
      step: state => state.productConfig.step,
      productInfo: state => state.productConfig.footData
    }),
    ...mapGetters('productConfig', ['btnName', 'getInventory']),
    ...mapGetters('system', ['currencyIcon'])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 关闭清单
    closeList() {
      this.showPrice = false
    },
    showPriceFn() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.showPrice = false
      }
    },
    next: debounce(function () {
      // 切换到第二步时 强制登录
      // 如果未登录
      if (this.step.currentStep === 1 && !this.userToken) {
        this.$store.commit('system/loginDialogTab', 1)
        this.$store.commit('system/toggleLogin', true)
        return false
      }
      // 配方验证
      if (this.step.flowList[this.step.flowList.length - 1].name === 'prescription') {
        this.$emit('savePrescription')
        return false
      }
      if (this.step.currentStep < this.step.size && !this.step.flowList[this.step.flowList.length - 1].end) {
        this.$store
          .dispatch('productConfig/getFlowInfo', {
            axios: this.$api,
            code: this.$route.query.code
          })
          .then(res => {
            if (res) {
              this.$store.commit('productConfig/controlStep', 'next')
            }
          })
      } else {
        this.$store
          .dispatch('productConfig/FlowSubmit', {
            axios: this.$api,
            code: this.$route.query.code
          })
          .then(code => {
            if (code === 200) {
              this.$router.replace({
                name: 'shoppingCart'
              })
            }
          })
      }
    }, 300)
    // next() {
    //   // 配方验证
    //   if (this.step.flowList[this.step.flowList.length - 1].name === 'prescription') {
    //     this.$emit('savePrescription')
    //     return false
    //   }
    //   if (this.step.currentStep < this.step.size && !this.step.flowList[this.step.flowList.length - 1].end) {
    //     this.$store
    //       .dispatch('productConfig/getFlowInfo', {
    //         axios: this.$api,
    //         code: this.$route.query.code
    //       })
    //       .then(res => {
    //         if (res) {
    //           this.$store.commit('productConfig/controlStep', 'next')
    //         }
    //       })
    //   } else {
    //     this.$store
    //       .dispatch('productConfig/FlowSubmit', {
    //         axios: this.$api,
    //         code: this.$route.query.code
    //       })
    //       .then(code => {
    //         if (code === 200) {
    //           this.$router.replace({
    //             name: 'shoppingCart'
    //           })
    //         }
    //       })
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.product-foot {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 80px;
  border-top: 1px solid #e0e0e0;
  background: #fff;
  .current-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    height: 60px;
  }
  .left,
  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }
  .info-price {
    font-size: 16px;
    line-height: 19px;
    margin-left: 20px;
    .name {
      color: #525861;
    }
    .type {
      color: #2d3240;
      span:nth-of-type(1) {
        margin-right: 10px;
      }
      b {
        color: #2b2e33;
      }
    }
  }
  .total {
    margin-right: 50px;
    font-size: 18px;
    color: #2d3240;
    user-select: none;
    cursor: pointer;
    .num-price {
      margin-left: 10px;
      font-size: 24px;
      font-weight: 800;
      color: #222426;
    }
    .more {
      width: 16px;
      cursor: pointer;
    }
  }
  .option {
    height: 48px;
    width: 250px;
    .my-btn {
      height: 100%;
      color: #fff;
    }
  }
  .img {
    width: 140px;
    height: 100%;
  }
  .price-detail {
    // top: 72%;
    // left: 55%;
    // transform: translate(-50%, -50%);
    left: 36%;
    bottom: 100px;
  }
  .rotate-icon-up {
    transform: rotate(0);
  }
  .rotate-icon-down {
    transform: rotate(180deg);
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .product-foot .price-detail {
    left: 4%;
    bottom: 70px;
  }
  .product-foot {
    padding: 0 20px;
    height: 67px;

    .option-mobile {
      width: 100%;
      justify-content: space-between;
      .mobile-info-price {
        .space {
          display: flex;
          align-items: flex-end;
        }
        // width: 40%;
        width: auto;
        p {
          font-size: 13px;
          color: #525861;
          margin-bottom: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .num-price {
          font-size: 14px;
          font-weight: 600;
          color: #2b2e33;
        }
        .status-dialog {
          margin-left: 10px;
          img {
            width: 16px;
          }
        }
      }
      .btn-box {
        // flex-shrink: 0;
        width: 192px;
        height: 42px;
        .my-btn {
          height: 100%;
          color: #fff;
        }
      }
    }
  }
}
</style>
