<template>
  <v-dialog v-model="dialog" class="coupons" persistent>
    <div class="option">
      <v-spacer />
      <v-btn icon dark @click="closeDialog">
        <v-icon color="#000">mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="content-coupons">
      <h3>
        <!-- Check the coupon <br />
        to use in your shopping cart -->
        <template v-if="userToken">The coupons have been collected!</template>
        <template v-else>Subscribe to receive the coupons below</template>
      </h3>
      <div class="list">
        <div v-for="(item, index) in list" :key="index" class="list-item">
          <div class="tag-box">COUPON</div>
          <div class="info-text">
            <p v-if="item.first" class="flag">Only for first order</p>
            <div class="price">
              <span class="price-top">
                <template v-if="item.discounts_type === 1"> ${{ item.price }} </template>
                <template v-else> {{ item.price }}% </template>
              </span>
              <span>OFF</span>
            </div>
            <p class="title-text">{{ item.subtitle }}</p>
          </div>
        </div>
      </div>
      <div v-if="!userToken" class="unLogin">
        <!-- 未登录 领取 -->
        <div v-if="emailNoReg" class="get-box">
          <input v-model="email" class="email-input" type="text" placeholder="Enter email address" />
          <v-btn class="my-btn email-btn" color="#3067f2" tile depressed :disabled="loginload" :loading="loginload" @click="getAndreg">GET COUPONS</v-btn>
        </div>
        <!-- 领取失败  邮箱已注册 -->
        <v-form v-else ref="login" class="form-box">
          <v-text-field
            v-model="login.email"
            label="Email"
            hide-details="auto"
            required
            class="form-input-item email-item"
            background-color="#F2F3F5"
            color="#3067F2"
            outlined
            :rules="emailCheck"
            @click="hasEmail = false"
          />
          <v-text-field
            v-model="login.password"
            label="Password"
            hide-details="auto"
            required
            class="form-input-item"
            background-color="#F2F3F5"
            color="#3067F2"
            outlined
            :rules="[rules.passWord]"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            @keyup.enter.native="sign"
          />
          <v-btn block class="my-btn btn-sign" color="#3067F2" :disabled="loginload" :loading="loginload" @click="sign"> SIGN IN </v-btn>
        </v-form>
        <div class="policy">By registering you agree to our <span class="agreeContent" @click="agreePage">privacy policy</span>.</div>
      </div>
      <div v-else class="logged">
        <v-btn class="my-btn get-btn" color="#3067f2" tile depressed @click="goShopping">GO SHOPPING</v-btn>
        <p class="check"><span @click="goCheck">Check Coupons</span></p>
      </div>
      <div class="bg-box"></div>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { isEmail, isPassWord2 } from '@/assets/utils/validate'
export default {
  name: 'LCoupons',
  data() {
    return {
      dialog: false,
      show1: false,
      showPass: false,
      loginload: false,
      emailNoReg: true,
      email: '',
      list: [
        {
          discounts_type: 1,
          price: '7',
          subtitle: 'Sitewide Sale',
          first: 1
        },
        {
          discounts_type: 1,
          price: '5',
          subtitle: 'Over US$60'
        },
        {
          discounts_type: 1,
          price: '10',
          subtitle: 'Over US$109'
        },
        {
          discounts_type: 1,
          price: '15',
          subtitle: 'Over US$169'
        }
      ],
      login: {
        email: '',
        password: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          return isEmail(value) || 'Invalid e-mail.'
        },
        passWord: value => {
          return isPassWord2(value) || 'Please enter a password of at least 8 characters.'
        }
      },
      hasEmail: false
    }
  },
  computed: {
    ...mapState({
      couponsDialog: state => state.system.couponsDialog,
      userToken: state => state.system.userToken
    }),
    emailCheck() {
      // 已存在账号 合格
      // if (this.hasEmail && this.login.email && isEmail(this.login.email)) {
      //   return ['The email is already register, you can sign in now.']
      // } else
      if (this.login.email && !isEmail(this.login.email)) {
        // return 'Invalid e-mail.'
        return ['Invalid e-mail.']
      } else {
        return []
      }
    }
  },
  watch: {
    couponsDialog(newValue) {
      this.dialog = newValue
      this.emailNoReg = true
      this.email = ''
    }
  },
  created() {},
  mounted() {},
  methods: {
    closeDialog() {
      // this.dialog = false
      this.$store.commit('system/toogglecoupons', false)
    },
    // 查看协议页
    agreePage() {
      const page = this.$router.resolve({
        name: 'type',
        params: { type: 'privacy-policy' }
      })
      window.open(page.href, '_blank')
    },
    loginSuccess(res) {
      this.$tips({ msg: 'Login Success' })
      this.closeDialog()
      this.$store.commit('system/saveToken', res.token)
      this.$store.dispatch('system/getUserInfo', { axios: this.$api })
      if (this.$route.name !== 'product') {
        this.$router.go(0)
      }
    },
    // 一键注册 (填写邮箱提交 200后 调用登录  使用同名密码登录 5重复后 切换自主登录)
    getAndreg() {
      if (!isEmail(this.email)) {
        this.$tips({ msg: 'Invalid e-mail.' })
        return false
      }
      this.loginload = true
      this.$api.system.regGetCoupon({ email: this.email }).then(res => {
        this.loginload = false
        if (res.code === 200) {
          // this.loginSuccess(res)
          this.login.email = this.email
          this.login.password = this.email
          setTimeout(() => {
            this.loginload = true
            this.userLogin()
          }, 0)
        } else if (res.code === 5) {
          // 已经注册
          this.hasEmail = true
          this.login.email = this.email
          this.emailNoReg = false
        }
      })
    },
    sign() {
      if (this.$refs.login.validate()) {
        this.loginload = true
        this.userLogin()
      }
    },
    // 用户登录
    userLogin() {
      this.$api.system.toLogin(this.login).then(res => {
        this.loginload = false
        if (res.code === 200) {
          this.loginSuccess(res)
        }
      })
    },
    goShopping() {
      this.closeDialog()

      this.$router.push('/')
    },
    goCheck() {
      this.closeDialog()

      this.$router.push({
        name: 'centerMain-wallet-coupons'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  background: #fff;
  font-size: 16px;
  width: 520px;
}
.form-box {
  width: 86%;
  margin: 0 auto;
  .input-label {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 4px;
  }
  .form-input-item {
    margin-bottom: 15px;
  }
  .btn-sign {
    height: 45px !important;
    color: #ffffff !important;
  }
}
.option {
  display: flex;
}
.content-coupons {
  position: relative;
  min-height: 360px;
  h3 {
    font-size: 20px;
    color: #2b2e33;
    line-height: 30px;
    margin-bottom: 15px;
    text-align: center;
  }
}
.bg-box {
  position: absolute;
  z-index: -1;
  background: rgba(48, 103, 242, 0.25);
  height: 68%;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-left-radius: 15%;
  border-top-right-radius: 15%;
}
.unLogin {
  margin-top: 10px;
  text-align: center;
  .get-box {
    display: flex;
    justify-content: center;
    // margin-bottom: 10px;
  }
  .email-input {
    border: 2px solid #3067f2;
    width: 280px;
    height: 45px;
    outline: none;
    padding: 6px;
    font-size: 14px;
    color: #696d72;
    letter-spacing: 1px;
  }
  .email-btn {
    height: 45px !important;
    color: #fff !important;
  }
  .agreeContent {
    color: #3067f2;
    cursor: pointer;
    text-decoration: underline;
  }
}
.logged {
  margin-top: 15px;
  text-align: center;
  .get-btn {
    width: 230px;
    height: 45px !important;
    color: #fff !important;
  }
  .check {
    margin: 0;
    padding: 15px 0;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.list-item {
  display: flex;
  width: 40%;
  margin-bottom: 15px;
  height: 100px;
  &.available {
    cursor: pointer;
  }
  .tag-box {
    width: 25%;
    user-select: none;
    color: #fff;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5c723;
    writing-mode: vertical-rl; /* 垂直方向上自右向左 */
    // text-orientation: upright; /* 文本方向正常 */
    // .price-top {
    //   margin-bottom: 5px;
    // }
  }
  .flag {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 0 5px;
    background: #f5c723;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .info-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    color: #ffffff;
    background: #3067f2;
    position: relative;
    cursor: default;
    p {
      margin-bottom: 0;
    }
    .title-text {
      font-weight: 600;
    }
    .price {
      font-size: 24px;
      font-weight: 600;
    }
    .tips {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: #3067f2;
      border-radius: 5px;
      padding: 0 10px;
    }
  }
  &.unavailable {
    .price {
      background: #a8a8a8;
    }
    .info-text {
      background: #d7d7d7;
    }
  }
}
.policy {
  padding: 15px 0;
}
// .content-box {
//   //   background: #fff;
//   height: auto;
//   min-height: 100%;
// }
// .v-dialog__content ::v-deep .v-dialog {
//   width: 630px;
//   height: 873px;
//   margin: 0 auto;
// }
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .list-item {
    width: 46%;
    margin-bottom: 10px;
    height: 80px;
    .tag-box {
      width: 21%;
      font-size: 13px;
    }
    .info-text {
      padding-left: 8px;
    }
    .info-text .price {
      font-size: 20px;
    }
    .info-text .title-text {
      font-size: 14px;
    }
  }
  .unLogin {
    .policy {
      font-size: 13px;
    }
    .get-box {
      flex-direction: column;
      align-items: center;
    }
    .email-input {
      width: 80%;
    }
    .email-btn {
      width: 80%;
      margin-top: 10px;
    }
  }
}
</style>
