<template>
  <table class="table-page" cellspacing="0" cellpadding="0">
    <thead>
      <tr>
        <td class="title-name name-style"></td>
        <td class="title-name name-style">SPH</td>
        <td class="title-name name-style">CYL</td>
        <td class="title-name name-style">AXIS</td>
        <td class="title-name name-style">ADD</td>
      </tr>
    </thead>
    <tbody>
      <!-- right -->
      <tr>
        <td class="title-name name-style">OD</td>
        <template v-if="imgs">
          <td class="item" rowspan="2" colspan="4">
            <img class="imgs" :src="imgs" alt="prescription" @click="imgDiologs = true" />
          </td>
        </template>
        <template v-else>
          <td class="sph" :class="{ errorText: objState.sphState }">{{ list.od_sph }}</td>
          <td class="cyl" :class="{ errorText: objState.cylState }">{{ list.od_cyl }}</td>
          <td class="axis">{{ list.od_axis }}</td>
          <td class="add">{{ list.od_add }}</td>
        </template>
      </tr>
      <!-- left -->
      <tr>
        <td class="title-name name-style">OS</td>
        <template v-if="!imgs">
          <td class="sph" :class="{ errorText: objState.sphState }">{{ list.os_sph }}</td>
          <td class="cyl" :class="{ errorText: objState.cylState }">{{ list.os_cyl }}</td>
          <td class="axis">{{ list.os_axis }}</td>
          <td class="add">{{ list.os_add }}</td>
        </template>
        <!-- <template v-if="!list.is_two_pd">
          <td class="item">{{ list.pd_left }}</td>
        </template> -->
      </tr>
      <!-- pd -->
      <tr>
        <td class="title-name name-style">PD</td>
        <template v-if="!pdState">
          <td colspan="2" class="pd">{{ list.pd_right }}</td>
          <td colspan="2" class="pd">{{ list.pd_left }}</td>
        </template>
        <template v-else>
          <td colspan="4" class="pd">{{ list.pd_right }}</td>
        </template>
      </tr>
    </tbody>
    <imgDialog :show="imgDiologs" :list="[imgs]" :current="0" />
  </table>
</template>

<script>
import imgDialog from '@/components/Ldetails/components/imgDialog'
export default {
  name: 'tableEdit',
  components: {
    imgDialog
  },
  props: {
    list: {
      type: Object,
      default: () => {}
    },
    // 数值状态
    objState: {
      type: Object,
      default: () => {
        return {
          sphState: false,
          cylState: false
        }
      }
    }
  },
  data() {
    return {
      imgs: '',
      imgDiologs: false
    }
  },
  computed: {
    pdState() {
      if (this.list.is_two_pd) {
        return true
      }
      return false
    },
    renderImg() {
      const img = this.list.img_path
      const file = this.list.file
      if (img) {
        return img
      }
      if (Object.prototype.toString.call(file) === '[object Array]') {
        this.createImage(file)
      }
      return false
    }
  },
  watch: {
    'list.file': {
      handler(val) {
        // 有文件
        if (Object.prototype.toString.call(val) === '[object Array]' && val.length) {
          this.createImage(val)
          return false
        }
        if (this.list.img_path) {
          this.imgs = this.list.img_path
        } else {
          this.imgs = ''
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    createImage(file) {
      const that = this
      // for (let index = 0; index < file.length; index++) {
      const reader = new FileReader()
      reader.readAsDataURL(file[0])
      reader.onload = function () {
        that.imgs = reader.result
      }
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-page {
  font-size: 14px;
  width: 100%;
  border: 1px solid #525861;
}
.title-name {
  width: 20%;
}
.name-style {
  color: #2b2e33;
  background: #f5f6fa;
}
td {
  height: 30px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  border: 1px solid #e5e7ec;
  background: #f5f6fa;
}
.errorText {
  color: #ff0000;
}
.imgs {
  // height: 75px;
  height: 75px;
  max-width: 100%;
  cursor: pointer;
}
</style>
