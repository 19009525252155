<template>
  <div class="list">
    <div v-for="item in list" :key="item.id" class="item info-item">
      <div class="text">
        <div class="left">Name:</div>
        <div class="right">{{ item.firstname }} {{ item.lastname }}</div>
      </div>
      <div class="text address">
        <div class="left">Address:</div>
        <div class="right">
          <div>
            {{ item.address }}
            {{ item.city }}
            {{ item.zip_code }}
          </div>
          <div>{{ item.country }}</div>
        </div>
      </div>
      <div class="text">
        <div class="left">Telephone:</div>
        <div class="right">{{ item.phone }}</div>
      </div>
      <div class="option-box">
        <span class="option" @click="edit(item.id)">Edit</span>
        <span class="option" @click="remove(item.id)">Delete</span>
      </div>
    </div>
    <div class="item add" @click="addDialog"><span>+</span></div>
  </div>
</template>

<script>
export default {
  name: 'itemAddress',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    edit(id) {
      this.$emit('edit', id)
    },
    remove(id) {
      this.$emit('remove', id)
    },
    addDialog() {
      this.$emit('add')
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}
.list > .item {
  flex-shrink: 0;
  width: 48%;
  height: 230px;
  position: relative;
  margin-bottom: 30px;
  &.info-item {
    padding: 20px;
    border: 2px solid #eeeeef;
  }

  &.add {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    background: #f9f9fa;
    cursor: pointer;
    span {
      font-size: 50px;
    }
  }
  .text {
    display: flex;
    font-size: 14px;
    margin-bottom: 15px;
    .left {
      flex-shrink: 0;
      width: 90px;
      text-align: right;
      margin-right: 15px;
    }
    .right {
      font-weight: 600;
      word-break: break-word;
    }

    .address > .right {
      display: flex;
      flex-direction: column;
    }
  }
  .option-box {
    position: absolute;

    bottom: 20px;
    right: 20px;
    .option {
      color: #3067f2;
      margin-left: 15px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 1130px) {
  .list {
    flex-direction: column;
    align-items: center;
    .item.info-item {
      padding: 16px;
    }
    .item {
      width: 100%;
      margin-bottom: 15px;
      height: auto;
    }
    .item > .text {
      margin-bottom: 8px;
      flex-direction: column;
      .left {
        text-align: left;
        margin-bottom: 5px;
      }
    }
    .item .option {
      top: 16px;
      right: 16px;
    }
    .item.add {
      height: 50px;
    }
  }
}
</style>
