<template>
  <div v-if="JSON.stringify(info) !== '{}'" class="center-body">
    <div class="summarize" :class="{ summarizeBlock: [3, 4].includes(hederInfo.status) }">
      <div class="product-info">
        <div class="comHeader top-header">
          <!-- Order #{{ hederInfo.code }} - <span :class="{ error: hederInfo.status === 1 }">{{ renderStatus(hederInfo.status) }}</span> -->
          <p>
            Order #{{ hederInfo.code }} - <span>{{ renderStatus(hederInfo.status) }}</span>
          </p>
          <p v-if="[3, 4].includes(hederInfo.status)">
            <a class="my-link" download target="_blank" :href="`${GLOBAL.URL}/order/invoice?order_code=${hederInfo.key}`">Invoice</a>
          </p>
        </div>
        <div class="list">
          <div class="item">
            <div class="left">Order Date:</div>
            <div class="right">{{ hederInfo.orderTime }}</div>
          </div>
          <div v-if="hederInfo.payTime" class="item">
            <div class="left">Payment Date:</div>
            <div class="right">{{ hederInfo.payTime }}</div>
          </div>
          <div class="item">
            <div class="left">Shipping Method:</div>
            <div class="right">{{ hederInfo.expressWay }}</div>
          </div>
          <div class="item">
            <div class="left">Payment Method:</div>
            <div class="right">{{ hederInfo.payWay }}</div>
          </div>
          <div v-if="hederInfo.orderRemark" class="item">
            <div class="left">Remark:</div>
            <div class="right">{{ hederInfo.orderRemark }}</div>
          </div>
        </div>
      </div>
      <div v-if="hederInfo.status === 0 && $vuetify.breakpoint.mdAndUp" class="align-pay">
        <!-- hederInfo.status === 0 && timer -->
        <div class="list-pay">
          <p v-if="second !== 0" class="countdown-box">
            Remaining payment time:
            <span v-if="day - 0">{{ day }} :</span>
            <span>{{ hour }} :</span>
            <span>{{ minute }}:</span>
            <span>{{ second }}</span>
          </p>
          <!-- <div id="paypal-button-container"></div> -->
          <div id="pay-button-container" @click="toPay">Pay</div>
          <!-- <div class="pay-item stripe-box">
          <div class="stripe"></div>
        </div>
        <div class="pay-item paypal-box">
          <div class="paypal"></div>
        </div> -->
        </div>
      </div>
    </div>
    <div class="address">
      <v-tabs color="#3067F2" background-color="transparent">
        <v-tab class="tab-title">Shipping Address</v-tab>
        <v-tab v-if="billing && JSON.stringify(billing) != '{}'" class="tab-title">Billing Address</v-tab>
        <v-tab-item v-if="shipping && JSON.stringify(shipping) != '{}'">
          <div class="info-address">
            <p class="item">
              <span class="left">Name:</span>
              <span class="right">{{ shipping.firstname }} {{ shipping.lastname }}</span>
            </p>
            <p class="item">
              <span class="left">Address:</span>
              <span class="right">
                <span>{{ shipping.address }}, </span>
                <template v-if="shipping.apt_suite">
                  <span>{{ shipping.apt_suite }}, </span> </template
                >{{ shipping.city }}, {{ shipping.state }}, {{ shipping.zip_code }}, {{ shipping.country }}</span
              >
            </p>
            <p class="item">
              <span class="left">Tel:</span>
              <span class="right">{{ shipping.phone }}</span>
            </p>
          </div>
        </v-tab-item>
        <v-tab-item v-if="billing && JSON.stringify(billing) != '{}'">
          <div class="info-address">
            <p class="item">
              <span class="left">Name:</span>
              <span class="right">{{ billing.firstname }} {{ billing.lastname }}</span>
            </p>
            <p class="item">
              <span class="left">Address:</span>
              <span class="right">
                <span>{{ billing.address }}, </span>
                <template v-if="billing.apt_suite">
                  <span>{{ billing.apt_suite }}, </span> </template
                >{{ billing.city }}, {{ billing.state }}, {{ billing.zip_code }}, {{ billing.country }}</span
              >
            </p>
            <p class="item">
              <span class="left">Tel:</span>
              <span class="right">{{ billing.phone }}</span>
            </p>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
    <div v-if="product" class="product">
      <p class="comHeader">Products Information</p>
      <div class="product-list">
        <div v-for="(item, index) in product" :key="item.code" class="product-item">
          <div class="img-box">
            <img :src="item.image" alt="product" />
          </div>
          <div class="details">
            <!-- 所选项 -->
            <div class="list">
              <!-- 常规信息 -->
              <div class="top-info">
                <div class="item">
                  <p class="left">
                    <span class="keyWord">Frame:</span>
                    <span class="value">{{ item.code }}</span>
                  </p>
                  <p class="right">{{ currencyIcon }}{{ item.price }}</p>
                </div>
                <div class="item">
                  <p class="left keyWord">
                    Color:<span style="font-weight: 400">{{ item.color }}</span>
                  </p>
                  <!-- <p class="right"></p> -->
                </div>
              </div>
              <!-- 处方配置 -->
              <div v-if="isHasAnyAttr(item.process, 'prescription')" class="config">
                <tableEdit :list="item.process.prescription.value" />
              </div>
              <!-- 有流程配置 -->
              <div v-if="isHasProcess(item.process)" class="process">
                <div v-if="isHasAnyAttr(item.process, 'lens_use')" class="item">
                  <p class="left">
                    <span class="keyWord">{{ renderItem(item.process, 'lens_use').name }}:</span>
                    <span class="value">{{ renderItem(item.process, 'lens_use').value }}</span>
                  </p>
                  <p class="right right-text">{{ currencyIcon }}{{ renderItem(item.process, 'lens_use').price }}</p>
                </div>
                <div v-if="isHasAnyAttr(item.process, 'lens_type')" class="item">
                  <p class="left">
                    <span class="keyWord">{{ renderItem(item.process, 'lens_type').name }}:</span>
                    <span class="value">{{ renderItem(item.process, 'lens_type').value }}</span>
                  </p>
                  <p class="right right-text">{{ currencyIcon }}{{ renderItem(item.process, 'lens_type').price }}</p>
                </div>
                <div v-if="isHasAnyAttr(item.process, 'lens_index')" class="item">
                  <p class="left">
                    <span class="keyWord">{{ renderItem(item.process, 'lens_index').name }}:</span>
                    <span class="value">{{ renderItem(item.process, 'lens_index').value }}</span>
                  </p>
                  <p class="right right-text">{{ currencyIcon }}{{ renderItem(item.process, 'lens_index').price }}</p>
                </div>
                <div v-if="isHasAnyAttr(item.process, 'magnification')" class="item">
                  <p class="left">
                    <span class="keyWord">{{ renderItem(item.process, 'magnification').name }}:</span>
                    <span class="value">{{ renderItem(item.process, 'magnification').value }}</span>
                  </p>
                  <p class="right right-text">{{ currencyIcon }}{{ renderItem(item.process, 'magnification').price }}</p>
                </div>
                <div v-if="isHasAnyAttr(item.process, 'add_ones')" class="item">
                  <p class="left">
                    <span class="keyWord">{{ renderItem(item.process, 'add_ones').name }}:</span>
                    <span class="value">{{ renderItem(item.process, 'add_ones').value }}</span>
                  </p>
                  <p class="right right-text">{{ currencyIcon }}{{ renderItem(item.process, 'add_ones').price }}</p>
                </div>
              </div>
            </div>
            <!-- 合计 -->
            <div class="other">
              <p class="text-w">QTY:{{ item.num }}</p>
              <p class="text-w">Total:{{ currencyIcon }}{{ item.sub_price }}</p>
            </div>
            <template v-if="[3, 4, 5].includes(hederInfo.status)">
              <template v-if="item.is_review === 0">
                <div class="option-to-product">
                  <button @click="comment(item, index)">Comment</button>
                </div>
              </template>
              <template v-else-if="item.is_review === 1">
                <div class="option-to-product">
                  <button @click="viewComment(item)">View Comments</button>
                </div>
              </template>
            </template>
          </div>
        </div>
        <reviewDialog :show="showReview" :product-info="currentProductInfo" @close="closeReview" />
      </div>
    </div>
    <div class="price">
      <div class="price-list">
        <p class="item">
          <span class="left">Subtotal</span>
          <span class="right bold-text">{{ currencyIcon }}{{ hederInfo.productPrice }}</span>
        </p>
        <p class="item">
          <span class="left">Advanced Shipping</span>
          <span class="right bold-text">{{ currencyIcon }}{{ hederInfo.expressMoney }}</span>
        </p>
        <p v-if="hederInfo.couponprice - 0 > 0" class="item">
          <span class="left">Favorable Price</span>
          <span class="right bold-text">- {{ currencyIcon }}{{ hederInfo.couponprice }}</span>
        </p>
        <p class="item">
          <span class="left bold-text">Geand Total</span>
          <span class="right bold-text total">{{ currencyIcon }}{{ hederInfo.actualPrice }}</span>
        </p>
      </div>
    </div>
    <div v-if="hederInfo.status === 0 && $vuetify.breakpoint.smAndDown" class="align-pay">
      <!-- hederInfo.status === 0 && timer -->
      <div class="list-pay">
        <p v-if="second !== 0" class="countdown-box">
          Remaining payment time:
          <span v-if="day - 0">{{ day }} :</span>
          <span>{{ hour }} :</span>
          <span>{{ minute }}:</span>
          <span>{{ second }}</span>
        </p>
        <div id="paypal-button-container"></div>
        <!-- <div class="pay-item stripe-box">
          <div class="stripe"></div>
        </div>
        <div class="pay-item paypal-box">
          <div class="paypal"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { setHoursTimeNumS } from '@/assets/utils/toolFn'
import { getItem, renderStatus, replaceSunglasses } from '@/assets/utils/renderData'
import tableEdit from '@/components/LshoppingCart/item/tableEdit'
import reviewDialog from '@/components/LcenterMain/order/reviewDialog.vue'
export default {
  name: 'orderDetail',
  components: {
    tableEdit,
    reviewDialog
  },
  validate({ query, redirect, error }) {
    if (!query.code) {
      error({ statusCode: 404 })
    } else {
      return true
    }
  },
  data() {
    return {
      info: {},
      // 未付款--------------
      day: 0, // 天
      hour: 0, // 时
      minute: 0, // 分
      second: 0, // 秒
      timer: null,
      // 未付款--------------
      // 评论
      showReview: false,
      currentProductInfo: {},
      // 创建成功后的订单编号
      currentOrder: undefined
    }
  },
  computed: {
    ...mapGetters('system', ['currencyIcon']),
    renderStatus() {
      return function (val) {
        return renderStatus(val)
      }
    },
    replaceType() {
      return function (val) {
        return replaceSunglasses(val)
      }
    },
    // 订单头
    hederInfo() {
      const {
        code,
        status,
        order_time: orderTime,
        pay_time: payTime,
        express_way: expressWay,
        pay_way: payWay,
        product_price: productPrice,
        actual_price: actualPrice,
        coupon_price: couponprice,
        express_money: expressMoney,
        order_remark: orderRemark,
        key
      } = this.info
      return { code, status, orderTime, payTime, expressWay, payWay, actualPrice, productPrice, expressMoney, couponprice, orderRemark, key }
    },
    // 地址
    shipping() {
      return this.info.shipping
    },
    billing() {
      return this.info.billing
    },
    // 商品信息
    product() {
      return this.info.product
    },
    // 判断是否有流程配置
    isHasProcess() {
      return function (data) {
        if (Object.prototype.toString.call(data) === '[object Array]') {
          return false
        }
        if (Object.prototype.toString.call(data) === '[object Object]' && JSON.stringify(data) !== '{}') {
          return true
        }
      }
    },
    // 判断是否有某个属性
    isHasAnyAttr() {
      return function (data, key) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          return true
        }
        return false
      }
    },
    // 渲染已有流程项目  => 渲染所属项
    renderItem() {
      return function (data, key) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (getItem(key) === 'Prescription Type') {
            return {
              name: getItem(key),
              price: data[key].money,
              value: replaceSunglasses(data[key].value)
            }
          }
          return {
            name: getItem(key),
            price: data[key].money,
            value: data[key].value
          }
        } else {
          return false
        }
      }
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getOrderInfo()
  },
  destroyed() {
    this.clearTimer()
  },
  methods: {
    clearTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    getOrderInfo() {
      const code = this.$route.query.code
      this.$api.userCenter.getOrderInfo({ code }).then(res => {
        if (res.code === 200) {
          this.info = res.data
          // 未下单 无倒计时
          // if (res.data.status === 0) {
          //   this.createPaypalSdk()
          // }
          // 未下单 有倒计时
          if (res.data.status === 0 && res.data.order_time) {
            const startTime = res.data.order_time_stamp
            const endTime = startTime + setHoursTimeNumS(0.5)
            // const etimer = new Date(endTime).getTime() // 获取过期日期的时间戳
            this.countDown(endTime)
            this.createPaypalSdk()
          }
        }
      })
    },
    snapshootStatus(status) {
      this.$api.shoppingCart.snapshootStatus({
        order_id: this.$route.params.payId,
        status,
        order_code: this.currentOrder,
        page: 2
      })
    },
    goDetail(status) {
      this.$router.replace({ name: 'centerMain-order-order-detail', query: { code: this.currentOrder } })
    },
    goSuccess(status) {
      this.$router.replace({ name: 'payment-success', query: { code: this.currentOrder } })
    },
    goCart(status) {
      this.$router.replace({ name: 'shoppingCart' })
    },
    // 创建二次支付
    createPaypalSdk() {
      this.$loading().open()
      // 加载paypal
      const createSrc = () => {
        const script = document.createElement('script')
        script.src = this.GLOBAL.SRTRIPE_LINK
        script.async = true
        script.onload = () => {
          this.$loading().close()
        }
        script.onerror = () => {
          console.log('loading js error')
          createSrc()
        }
        document.head.appendChild(script)
      }
      createSrc()
    },
    countDown(time) {
      const that = this
      if (!time) {
        return
      }
      const secondes = time
      that.timer = setInterval(() => {
        const now = Math.floor(Date.now() / 1000)
        if (now < secondes) {
          const diff = parseInt(secondes - now) // 相差多少秒
          let s = diff % 60 // 秒
          let m = parseInt(diff / 60) // 分钟
          let h = 0 // 小时
          let d = 0 // 天
          if (m > 60) {
            h = parseInt(m / 60)
            m = m % 60
          }
          if (h > 24) {
            d = parseInt(h / 24)
            h = h % 24
          }
          if (d >= 0) {
            d = d < 10 ? '0' + d : d
          }
          if (h >= 0) {
            h = h < 10 ? '0' + h : h
          }
          if (h >= 0 || m >= 0) {
            m = m < 10 ? '0' + m : m
          }
          s = s < 10 ? '0' + s : s
          // 时分秒赋值
          that.day = d
          that.hour = h
          that.minute = m
          that.second = s
        } else {
          that.clearTimer()
          this.info.status = 6
        }
      }, 1000)
    },
    // 评论
    comment(item, index) {
      this.currentProductInfo = { code: item.code, color: item.color, image: item.image, title: item.title, id: item.id, index, state: 0 }
      this.showReview = true
    },
    // 关闭评论窗口
    closeReview(data) {
      this.showReview = false
      this.setProductReview(data)
    },
    // 设置已评论
    setProductReview(data) {
      if (data.state) {
        const currentProduct = this.info.product[data.index]
        currentProduct.is_review = 1
      }
    },
    // 查看评论
    viewComment(item) {
      this.currentProductInfo = { code: item.code, color: item.color, image: item.image, title: item.title, id: item.id, state: 1 }
      this.showReview = true
    },
    toPay() {
      const that = this
      this.$loading().open()
      // 点击付款---------------------
      console.log('startCreateOrder')
      that.snapshootStatus(1)
      that.$api.shoppingCart.createOrderStripe({ reorder_code: that.hederInfo.code }).then(res => {
        console.log('createOrderEnd')
        if (res.code !== 200) {
          this.$loading().close()
          // 未成功创建订单
          that.$tips({ msg: res.msg, time: 6000 })
          return false
        } else {
          that.currentOrder = res.data.order_code
        }

        let key
        if (process.env.NODE_ENV === 'production') {
          key = that.GLOBAL.SRTRIPE_public_key_pro
        } else {
          key = that.GLOBAL.SRTRIPE_public_key_dev
        }
        // eslint-disable-next-line no-undef
        const stripe = Stripe(key)
        stripe
          .redirectToCheckout({
            sessionId: res.data.id
          })
          .then(result => {
            this.$loading().close()
            this.goDetail()
            console.log(result, '------------------------------------------')
          })
          .catch(() => {
            this.$loading().close()
            this.goDetail()
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 评论 ------------
.option-to-product {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  button {
    background: #3067f2;
    color: #fff;
    padding: 5px 10px;
  }
}
// 评论 ------------

.align-pay {
  display: flex;
  flex-direction: row-reverse;
  .countdown-box {
    text-align: right;
    font-size: 18px;
    color: #ff0000;
  }
}
.error {
  color: #ff0000;
}
.list-pay {
  width: 300px;
  div {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .stripe-box,
  .paypal-box {
    height: 45px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .stripe-box {
    background: #fff;
    margin-bottom: 10px;
  }
  .paypal-box {
    background: #ffc045;
  }
  .stripe {
    height: 32px;
    width: 100%;
    background-image: url('/imgs/stripe.png');
  }
  .paypal {
    height: 32px;
    width: 100%;
    background-image: url('/imgs/paypal.png');
  }
}
.summarize {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summarize.summarizeBlock {
  display: block;
}
.center-body {
  & > div {
    background: #eaecf1;
    margin-bottom: 20px;
    padding: 16px;
  }
  .align-pay {
    padding: 16px 16px 0 16px;
  }
  & > .product {
    background: #fff;
  }
  .comHeader {
    font-weight: 600;
    margin-bottom: 20px;
  }
  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-bottom: 0;
    }
    .my-link {
      color: #3067f2;
    }
  }
  .list > .item {
    display: flex;
    margin-bottom: 8px;
    .left {
      width: 140px;
      margin-right: 20px;
    }
  }
  p {
    margin-bottom: 8px;
  }
}
.address .v-tab {
  padding: 0;
  margin-right: 15px;
  font-size: 15px;
}
.address ::v-deep .v-tabs-items {
  margin-top: 12px;
  background: transparent;
}

.product-list {
  p {
    margin-bottom: 8px;
  }
  .product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #b4b4b4;
  }
  .product-item:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  .img-box {
    width: 240px;
    // margin-top: 62px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.details {
  flex: 1;
  margin-left: 25px;
  // .process {

  // }
  .item {
    display: flex;
    justify-content: space-between;
  }
}
.config {
  margin-bottom: 16px;
}
.other {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .text-w:last-child {
    margin-bottom: 0;
  }
}
.text-w,
.keyWord {
  font-weight: 600;
}
.price {
  display: flex;
  flex-direction: row-reverse;
}
.price-list {
  width: 38%;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bold-text {
    font-weight: 600;
  }
}
#pay-button-container {
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  background: #000;
  padding: 3%;
  border-radius: 5px;
  cursor: pointer;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .list-pay {
    width: 100%;
  }
  .center-body > div {
    margin-bottom: 10px;
    padding: 10px;
  }
  .summarize {
    margin-top: 10px;
  }
  .center-body > .align-pay {
    padding: 10px 10px 0 10px;
  }
  .align-pay {
    .countdown-box {
      text-align: center;
    }
  }
  .product-list {
    .img-box {
      width: 70%;
      margin-top: 0;
      margin: 0 auto 0;
    }
    .product-item {
      align-items: normal;
      flex-direction: column;
    }
  }
  .details {
    margin-left: 0;
  }
  .price-list {
    width: 100%;
  }
}
</style>
