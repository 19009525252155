<!-- 首页 -->
<template>
  <div class="page">
    <!-- <LNavbar /> -->

    <Nuxt />
    <!-- <Lfooter :class="footer" /> -->
    <Lfooter />
  </div>
</template>

<script>
// 导航栏
// import LNavbar from '@/components/Lnav/Lnavbar/index.vue'
// 页面底部
import Lfooter from '@/components/Lfooter/index.vue'
export default {
  name: 'IndexPage',
  components: {
    // LNavbar,
    Lfooter
  },
  scrollToTop: true,
  data() {
    return {}
  },
  head() {
    return {
      title: 'Stylool',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Stylool - Affordable Prescription Eyeglasses & Fashion Eyewear Online - Stylool Optical'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Stylool - Affordable Prescription Eyeglasses & Fashion Eyewear Online - Stylool Optical2'
        }
      ]
    }
  },
  computed: {
    // view: function () {
    //   // 设置底部padding
    //   const none = {
    //     'view-pc': true
    //   }
    //   //
    //   const pcShow = {
    //     'view-mobile': true
    //   }
    //   if (this.$route.name === 'index') {
    //     return none
    //   }
    //   return pcShow
    // },
    // footer: function () {
    //   // 显示页面底部
    //   const none = {
    //     '': true
    //   }
    //   // pc状态页面底部
    //   const pcShow = {
    //     'd-none d-sm-block d-md-block d-lg-block d-lg-block': true
    //   }
    //   const list = ['index', 'index-about'] // 手机端需要显示底部的页面
    //   if (list.includes(this.$route.name)) {
    //     return none
    //   }
    //   return pcShow
    // }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
}
.view-pc {
  // margin-bottom: 462px;
}
.none {
  display: none;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .view-mobile {
    // margin-bottom: 1020px;
  }
}
</style>
