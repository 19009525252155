<template>
  <div class="headerBreadcrumbs">
    <div class="content">
      <v-breadcrumbs :items="fullPath" />
    </div>
    <!-- {{ fullPath }}
    <br />
    {{ $route.params.type }} <br />{{ $route.params.goodsDetails }} -->
  </div>
</template>

<script>
export default {
  name: 'headerBreadcrumbs',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    fullPath() {
      // return `Hmoe/${this.$route.params.type}/${this.$route.params.goodsDetails}`
      if (this.$route.query.class) {
        return [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: this.$route.query.class
            // href: 'details/',
            // exact: true
          },
          {
            text: this.$route.query.name,
            disabled: true
          }
        ]
      } else {
        return [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: this.name,
            disabled: true
          }
        ]
      }
    }
  },
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.headerBreadcrumbs {
  padding: 0;
  background: #f7f7f7;
}

.content {
  max-width: 1200px;
  min-width: 735px;
  margin: 0 auto;
  ::v-deep .v-breadcrumbs {
    padding: 12px 0 !important;
  }
  ::v-deep .v-breadcrumbs li:nth-child(even) {
    padding: 0 6px;
  }
}
@media screen and (max-width: 1280px) {
  .content {
    padding: 0 16px;
  }
}
// .headerBreadcrumbs ::v-deep .v-application a {
//   color: red !important;
// }
</style>
