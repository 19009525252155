<template>
  <div class="center-body">
    <div class="content-main">
      <div class="content-body">
        <div class="tips">
          <p>Please note that "Edit/Delete" the prescription won't affect your existing order. Please contact support@stylool.com if you want to change the prescription in your order.</p>
        </div>
        <div class="list">
          <prescriptionTable v-for="item in list" :key="item.prescription_id" :info="item" class="table-com" @edit="edit" @remove="remove" />
          <div class="add" @click="addDialog"><span>+</span></div>
        </div>
      </div>
    </div>
    <prescriptionDialog :add-type="dialogPrescriptionType" :information="information" :disabled-btn="disabledBtn" :show="dialog" @save="save" />
  </div>
</template>

<script>
import prescriptionTable from '@/components/LcenterMain/setting/prescriptionTable'
import prescriptionDialog from '@/components/LcenterMain/setting/prescriptionDialog'
// import prescriptionDialog from '@/components/LcenterMain/setting/prescriptionDialog'

export default {
  name: 'prescription',
  components: {
    prescriptionTable,
    prescriptionDialog
  },
  data() {
    return {
      dialog: false,
      disabledBtn: false,
      dialogPrescriptionType: true, // 窗口状态是否为新增
      list: [], // 处方列表数据
      information: {} // 单条数据
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    addDialog() {
      this.dialogPrescriptionType = true
      this.dialog = true
    },
    getList() {
      this.$api.userCenter.prescriptionList().then(res => {
        this.list = res.data
      })
    },
    getDetiles(id) {
      this.$api.userCenter.prescriptionDetiles({ prescription_id: id }).then(res => {
        // res.prescription_id = res.data.id
        this.information = res.data
        this.information.prescription_id = res.data.id
        this.dialogPrescriptionType = false
        // 打开弹窗
        this.dialog = true
      })
    },
    save(obj) {
      this.disabledBtn = true
      // const data = new FormData()
      // data.append('file', obj.file[0])
      // data.append('key', '1')
      // console.log(obj.file)
      const data = getFormData(obj.info)
      if (obj.file != null && obj.file.length) {
        data.append('file', obj.file[0])
      }
      this.$api.userCenter
        .prescriptionSave(data, {
          'Content-Type': 'multipart/form-data'
        })
        .then(res => {
          // this.list = res.data
          this.disabledBtn = false
          if (res.code === 200) {
            this.dialog = false
            this.$tips({ msg: 'Your prescription was saved successfully.' })
            this.getList()
          }
        })
    },
    deleteItem(id) {
      this.$api.userCenter.prescriptionDelete({ prescription_id: id }).then(res => {
        if (res.code === 200) {
          // this.$tips({ msg: 'Your prescription was deleted successfully.' })
          this.getList()
        }
      })
    },
    edit(id) {
      this.getDetiles(id)
    },
    remove(id) {
      this.$confirm({ msg: 'Are you sure to delete this item?' }).then(res => {
        if (res === 'success') {
          this.deleteItem(id)
        }
      })
    }
  }
}
function getFormData(object) {
  const formData = new FormData()
  Object.keys(object).forEach(key => {
    const value = object[key]
    if (Array.isArray(value)) {
      value.forEach((subValue, i) => {
        formData.append(key + `[${i}]`, subValue)
      })
    } else {
      formData.append(key, object[key] === null ? '' : object[key])
    }
  })
  return formData
}
</script>

<style lang="scss" scoped>
.content-body {
  margin-top: 20px;
}
.tips {
  background: #faf7ec;
  padding: 20px;
  p {
    font-size: 14px;
    font-weight: 400;
    color: #845f2e;
    line-height: 24px;
    margin-bottom: 0;
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}
.add {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 48%;
  margin-bottom: 15px;
  background: #f9f9fa;
  cursor: pointer;
  span {
    font-size: 50px;
  }
}
.table-com {
  width: 48%;
  margin-bottom: 15px;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 1130px) {
  .table-com,
  .add {
    width: 100%;
  }
}
// @media screen and (max-width: 960px) {
//   .list {
//     flex-direction: column;
//     align-items: center;
//     .table-com {
//       width: 100%;
//     }
//   }
//   .add {
//     height: 50px;
//     width: 100%;
//   }
// }
</style>
