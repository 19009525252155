<template>
  <div v-if="btnFlag" class="scrollTop-wrap">
    <!-- <div class="go-top" @click="backTop()"><span>^ </span>BACK TO THE TOP</div> -->
    <div class="go-top" @click="backTop()">TOP</div>
  </div>
</template>
<script>
export default {
  name: 'scrollTop',
  data: function () {
    return {
      btnFlag: false,
      scrollTop: 0 // 当前滑动距离
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop, true)
  },
  methods: {
    // 点击图标回到顶部方法，加计时器是为了缓慢回到顶部
    backTop() {
      const This = this
      const timer = setInterval(() => {
        const ispeed = Math.floor(-This.scrollTop / 5)
        document.getElementsByTagName('html')[0].scrollTop = This.scrollTop + ispeed
        if (This.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 计算距离顶部的高度，当高度大于200显示回顶部图标，小于200则隐藏
    scrollToTop() {
      const This = this
      const dom = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0 // 获取滑动模块的信息
      This.scrollTop = dom
      //   console.log(dom, dom.scrollTop)
      if (This.scrollTop > 600) {
        This.btnFlag = true
      } else {
        This.btnFlag = false
      }
    }
  }
}
</script>
<style lang="scss">
.scrollTop-wrap {
  position: fixed;
  bottom: 20%;
  right: 2%;
  z-index: 100;
  background: #222426;
  text-align: center;
  height: 50px;
  border-radius: 50%;
  width: 50px;
  .go-top {
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
    span {
      position: relative;
      top: 6px;
      left: -3px;
      font-size: 20px;
    }
  }
}
</style>
