<template>
  <v-dialog v-model="dialog" persistent background="#fff" hide-overlay :fullscreen="$vuetify.breakpoint.mobile">
    <div class="dialog-body">
      <v-toolbar class="com-header" color="#fff">
        <p class="dialog-title">Prescription Values</p>
        <v-spacer />
        <v-btn icon dark @click="close">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="content">
        <v-toolbar class="content-header">
          <v-tabs v-model="tab">
            <v-tabs-slider color="#3067f2" />
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, index) in contentList" :key="index">
            <p>{{ item }}</p>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'addDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      tab: null,
      items: ['SPH', 'CYL', 'AXIS', 'ADD'],
      contentList: [
        'Sphere, or Spherical, refers to the main correction in the prescription. Minus (-) values are for nearsightedness, and Plus (+) values are for farsightedness. If “PL” or “Plano” is written for this value on your prescription, please select “0.00” or “None” for this field.',
        'Cylinder refers to a correction for astigmatism. It can be either positive or negative. A CYL value will always have an Axis value. If “DS” or “SPH” is noted in the CYL column on your prescription, you have no astigmatism in that eye. And you could enter “0.00” or “None” for the CYL and Axis in this case.',
        'Axis refers to the angle of the correction for astigmatism (CYL) in the eye from 1 to 180 degrees. If there is no CYL value then there is no Axis value.',
        'This number indicates the additional magnification that is added to the distance prescription to get the reading portion of the lens in a multi-focal prescription.'
      ]
    }
  },
  computed: {},
  watch: {
    show: {
      handler(newValue) {
        this.dialog = newValue
      },
      immediate: true
    }
  },
  methods: {
    close() {
      // this.$emit('close')
      this.$parent.showDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  background: #fff;
}
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  width: 30%;
  height: auto;
  margin: 0 auto;
}
::v-deep .com-header.v-toolbar {
  height: 50px !important;
}
::v-deep .v-rating {
  margin-left: -8px;
}
::v-deep .v-sheet.v-toolbar {
  box-shadow: none !important;
}
::v-deep .v-rating .v-icon {
  padding: 5px !important;
}
.dialog-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.content {
  padding: 0 16px;
  ::v-deep .v-toolbar {
    height: 58px !important;
    .v-toolbar__content {
      height: 100% !important;
    }
  }
  ::v-deep .v-tabs-items {
    min-height: 145px;
  }
}
.content-header {
  .v-tab {
    font-size: 16px;
    letter-spacing: 0;
    min-width: 75px;
  }
  ::v-deep .v-tabs {
    padding-left: 0;
  }
  ::v-deep .v-tabs-slider-wrapper {
    height: 3px !important;
    top: 0 !important;
  }
  ::v-deep .v-tabs-bar__content {
    border-bottom: 1px solid #dcdfe5;
  }
  // ::v-deep .v-tab {
  //   border-bottom: 1px solid #dcdfe5;
  // }
  ::v-deep .v-tab--active {
    border-left: 1px solid #dcdfe5;
    border-right: 1px solid #dcdfe5;
  }
  ::v-deep .v-item-group {
    margin-top: 10px;
  }
  ::v-deep .v-tabs-bar {
    height: 42px !important;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .v-dialog__content ::v-deep .v-dialog {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}
</style>
