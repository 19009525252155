<template>
  <div v-show="$vuetify.breakpoint.mdAndUp" class="currentFilter">
    <div class="tag-box">
      <v-chip-group column>
        <template v-for="(val, key) in check">
          <v-chip v-for="(item, index) in val" :key="item.id" class="mr-2" close color="#222426" outlined @click:close="closeTag(key, index)"> {{ item.name }} </v-chip>
        </template>
      </v-chip-group>
    </div>
  </div>
</template>
<script>
export default {
  name: 'currentFilter',
  props: {
    check: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    closeTag(key, index) {
      //   this.currentAll.splice(index, 1)
      this.$emit('closeTag', { key, index })
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-box {
  width: 100%;
}
</style>
