<template>
  <div class="checkboxFilter">
    <v-checkbox v-for="val in list[type]" :key="val.id" v-model="current" class="checkbox-item" :label="val.name" color="#222426" :value="val" hide-details @change="change" />
  </div>
</template>

<script>
export default {
  name: 'checkboxFilter',
  props: {
    // 筛选类型
    type: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: () => {}
    },
    check: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      current: []
    }
  },
  computed: {},
  watch: {
    check(newValue) {
      if (!newValue[this.type] || !newValue[this.type].length) {
        this.current = []
      }
    },
    deep: true
  },
  methods: {
    change() {
      this.$emit('dataFn', { selected: this.current, type: this.type })
    }
  }
}
</script>

<style lang="scss" scoped>
.checkboxFilter {
  display: flex;
  flex-wrap: wrap;
  .checkbox-item {
    margin-right: 56px !important;
    font-size: 16px;
    color: #2b2e33;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .item-name {
    margin-bottom: 10px;
  }
  .checkbox-item {
    width: 100% !important;
  }
}
</style>
