<template>
  <v-app light>
    <!-- 通知栏推送 -->
    <!-- <Lnotice app /> -->
    <template v-if="showNav">
      <Lnotice />
      <LNavbar />
    </template>
    <!-- <div v-show="showNav" class="header-control">
      <Lnotice />
      <LNavbar />
    </div> -->
    <v-main id="mainEl">
      <v-container class="px-0 py-0" fluid>
        <!-- 加载动画 -->
        <Loading :hidden="ready" :style="{ display: ready ? 'none' : 'flex' }" />
        <div :hidden="!ready" style="height: 100%">
          <!-- 内容 -->
          <!-- keep-alive :keep-alive-props="{ include: ['productSearch'] }" -->
          <Nuxt />
          <!-- 折扣券弹窗 -->
          <div v-if="couponTag && showNav" class="open-coupons">
            <img class="close-icon" src="/imgs/close.png" alt="close-coupons" @click="closeCoupons" />
            <img class="open-icon" src="/imgs/gift.png" alt="open-coupons" @click="openCoupons" />
          </div>
          <Lcoupons />
          <!-- 全局loading -->

          <!-- <requestLoading /> -->
          <!-- 全局阴影 -->
          <div v-show="$store.state.system.overlay" class="overlay"></div>
        </div>
      </v-container>
      <!-- 聊天fackbook插件 -->
      <div id="fb-root"></div>
      <div id="fb-customer-chat" class="fb-customerchat"></div>
      <!-- <div id="fb-root"></div>
      <div class="fb-customerchat" attribution_version="biz_inbox" page_id="126442290367809"></div> -->
      <!-- -------------- -->
      <components :is="showTry" />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Lnotice from '@/components/Lnav/Lnotice/index.vue'
import Lcoupons from '@/components/Lcoupons/index.vue'
import LNavbar from '@/components/Lnav/Lnavbar/index.vue'
import Loading from '@/components/Loading/index.vue'
const tryOn = () => import('~/components/toolCom/tryOn/tryOn')
// 全局   请求loading
// import requestLoading from '@/components/global/requestLoading.vue'
export default {
  name: 'DefaultLayout',
  components: {
    Lnotice,
    Loading,
    LNavbar,
    Lcoupons
  },
  // async asyncData({ $api, store }) {
  //   const data = await $api.home.getCountry()
  //   console.log(data)
  //   store.state.system.currencyList = data
  // },
  // middleware({ store, ...context }) {
  // store.system.cks = 1596
  // store.commit('system/setcks', 1596)
  // console.log(context.$api)
  // store.dispatch('system/getCountry', { axios: context.$api }).then(res => {
  //   store.commit('system/setCountry', res)
  // })
  // if (process.client) {
  //   if (!params.glassesType) {
  //     return redirect('/')
  //   } else {
  //     // 进入第一页面 设置数量 初始组件
  //     store.commit('productConfig/controlStepData', params.glassesType)
  //   }
  // }
  // },
  data() {
    return {
      fixed: false,
      ready: false,
      couponTag: true,
      scrollTop: 0 // 当前滑动距离
    }
  },
  computed: {
    ...mapState({
      userToken: state => state.system.userToken
    }),
    showNav() {
      // 显示页面底部
      // const none = {
      //   'd-none': true
      // }
      // // pc状态页面底部
      // const show = {
      //   'd-block': true
      // }
      // 不需要显示nav的页面  +   礼物图标
      const list = ['product', 'payment']
      if (list.includes(this.$route.name)) {
        return false
      }
      return true
    },
    showTry() {
      if (this.$store.state.system.tryOnShow) {
        return tryOn
      }
      return null
      // tryOn
    }
  },
  watch: {
    // $route(to, from) {
    //   if (process.client) {
    //     console.log(to)
    //     window.gtag('config', 'YOUR_GA_TRACKING_ID', {
    //       page_path: to.fullPath
    //     })
    //   }
    // },
    '$route.path': {
      immediate: true,
      handler(newValue, oldValue) {
        if (process.client && newValue !== oldValue && window.gtag) {
          window.gtag('config', 'G-WTMPK5VPWG', {
            page_path: newValue
          })
          window.gtag('config', 'AW-11236287464', {
            page_path: newValue
          })
          // window.gtag('config', 'YOUR_GA_TRACKING_ID', {
          //    page_path: route.fullPath,
          // });
        }
      }
    }
    // '$route.path': {
    //   immediate: true, // 第一次的数据，也要当做是一种变化
    //   handler(newValue, oldValue) {
    //     // 执行代码部分
    //     console.log(newValue, oldValue)
    //     // return false
    //     // eslint-disable-next-line no-unreachable
    //     if (oldValue && oldValue.includes('/control')) {
    //       const headerActivity = document.querySelector('.header-activity')
    //       if (headerActivity.style.display === 'none') {
    //         // document.getElementById('mainEl').style.top = '60px'
    //         console.log(document.querySelector('#mainEl').style.paddingTop)
    //         setTimeout(() => {
    //           document.querySelector('#mainEl').style.paddingTop = '0 !important'
    //         }, 200)
    //       }
    //     }
    //   }
    // }
  },
  mounted() {
    localStorage.setItem('SETACTIVITY', 0)
    // load和DOMContentLoaded
    window.addEventListener('DOMContentLoaded', this.initPage())
    // window.addEventListener('scroll', this.scrollToTop, true)
    // window.gtag('event', 'page_view', {
    //   page_location: this.$router.currentRoute.fullPath,
    //   page_path: this.$router.currentRoute.path,
    //   page_title: this.$route.meta.title,
    //   init: (function (event) {
    //     console.log(event)
    //     alert(1)
    //   })()
    // })
  },
  destroyed() {
    // window.removeEventListener('scroll', this.scrollToTop, true)
  },
  created() {
    // const that = this
    if (process.client) {
      // 获取货币信息
      // this.$store.dispatch('system/getCountry', { axios: this.$api }).then(res => {
      //   this.$store.commit('system/setCountry', res.data)
      // })
      this.$store.dispatch('system/getCountry', { axios: this.$api })
      this.$store.dispatch('system/getUserInfo', { axios: this.$api })
      this.$store.dispatch('system/getCollectionsAndCartsNum', { axios: this.$api })
      // // eslint-disable-next-line nuxt/no-globals-in-created
      // document.onreadystatechange = function () {
      //   // eslint-disable-next-line nuxt/no-globals-in-created
      //   if (document.readyState === 'complete') {
      //     console.log('加载完成......', 'zhungtai')
      //     that.ready = false
      //   }
      // }
      // eslint-disable-next-line nuxt/no-globals-in-created
      // if (document.readyState === 'interactive') {
      //   that.ready = true
      //   console.info(that.ready)
      // }
      // // eslint-disable-next-line nuxt/no-globals-in-created
      // console.log(document.readyState, 'zhungtai')
      // contentLoaded(window, function () {
      //   that.ready = true
      //   console.log('Loading completed!')
      //   renderFackBookPlugin()
      //   // console.log(that.$loading())
      // })
    }
  },
  methods: {
    initPage() {
      this.ready = true
      if (!this.userToken && this.showNav) {
        setTimeout(() => {
          // 获取是否打开优惠券
          try {
            const tag = sessionStorage.getItem('COUPON_TAG')
            if (!tag) {
              this.couponTag = true
              this.$store.commit('system/toogglecoupons', true)
            } else {
              this.closeCoupons()
            }
          } catch (error) {}
        }, 1000)
      }

      console.log('Loading completed!')
      // renderFackBookPlugin()
      renderGooglePlugin()
    },
    // 打开优惠券
    openCoupons() {
      this.$store.commit('system/toogglecoupons', true)
    },
    // 关闭优惠券
    closeCoupons() {
      this.couponTag = false
      sessionStorage.setItem('COUPON_TAG', 1)
    }
  }
}

// function renderFackBookPlugin() {
//   const chatbox = document.getElementById('fb-customer-chat')
//   chatbox.setAttribute('page_id', '126442290367809')
//   chatbox.setAttribute('attribution', 'biz_inbox')
//   window.fbAsyncInit = function () {
//     // eslint-disable-next-line no-undef
//     FB.init({
//       xfbml: true,
//       version: 'v16.0'
//     })
//   }
//   ;(function (d, s, id) {
//     let js = null
//     if (d.getElementById(id)) {
//       return false
//     }
//     js = d.createElement(s)
//     js.id = id
//     js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js?vs=16'
//     js.async = true
//     d.body.appendChild(js)
//   })(document, 'script', 'facebook-jssdk')
// }
function renderGooglePlugin() {
  // 将 Google Analytics 代码内容插入到 <script> 标签中
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-WTMPK5VPWG'
  document.head.appendChild(script)

  const scriptFn = document.createElement('script')
  // scriptFn.async = true
  // 定义 Google Analytics 代码内容
  const analyticsCode = `
                         window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-WTMPK5VPWG');
                        gtag('config', 'AW-11236287464');`
  scriptFn.appendChild(document.createTextNode(analyticsCode))
  document.head.appendChild(scriptFn)
}
// function recordPage() {
//   const KEEPTIMES = 'KEEPTIMES'
//   const KEEPTIMEE = 'KEEPTIMEE'

//   // 单页面
//   // 1 删除本地已经储存时间 并初始化时间
//   document.localStorage.setItem(KEEPTIMES, new Date().getTime())
//   document.addEventListener('visibilitychange', function () {
//     // 记录间隔计时器
//     let timerRecord = null
//     // 短轮询间隔计时器
//     let timerSendRecord = null
//     // 用户离开了当前页面
//     if (document.visibilityState === 'hidden') {
//       console.log('hidden')
//       clearInterval(timerRecord)
//       clearInterval(timerSendRecord)
//     }

//     // 用户打开或回到页面
//     if (document.visibilityState === 'visible') {
//       console.log('show')
//       // 2 设置更新间隔
//       timerRecord = setInterval(() => {
//         document.localStorage.setItem(KEEPTIMEE, new Date().getTime())
//       }, 5000)
//       timerSendRecord = setInterval(() => {
//         document.localStorage.setItem(KEEPTIMEE, new Date().getTime())
//       }, 5000)
//     }
//   })
// }
</script>
<style lang="scss" scoped>
.open-coupons {
  position: fixed;
  right: 15px;
  top: 55%;
  z-index: 2;
  cursor: pointer;

  .open-icon {
    width: 42px;
    // animation: shake 5s infinite; /* 设置动画时长为2秒，执行3次 */
  }
  .close-icon {
    width: 12px;
    position: absolute;
    right: -6px;
    top: -10px;
  }
}
@keyframes shake {
  0% {
    transform: translateX(0) rotate(0);
  }
  // 25% {
  //   transform: translateX(5px) rotate(5deg);
  // }
  70% {
    transform: translateX(-5px) rotate(-5deg);
  }
  85% {
    transform: translateX(5px) rotate(5deg);
  }
  100% {
    transform: translateX(0) rotate(0);
  }
}
.v-main ::v-deep {
  position: relative;
  // padding: 110px 0 0 0 !important;
}
// @media screen and (max-width: 600px) {
//   .v-main ::v-deep {
//     // padding: 106px 0 0 0 !important;
//   }
// }
.header-control {
  position: sticky;
  top: 0;
  z-index: 3;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(88, 88, 88, 46%);
  z-index: 1;
  pointer-events: none;
  will-change: opacity;
}
</style>
