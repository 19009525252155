<template>
  <div class="loader-box">
    <div class="loading"></div>
  </div>
</template>

<script>
export default {
  name: 'loadIng',
  data() {
    return {}
  },
  mounted() {
    this.$nextTick(() => {
      this.$nuxt.$loading.start()
      this.$nuxt.$loading.finish()
    })
  },
  methods: {
    start() {},
    finish() {},
    // element组件
    open() {
      this.$message.error('Page Error')
    },
    fail() {
      this.open()
    },
    increase(num) {}
  }
}
</script>

<style lang="scss" scoped>
.loader-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  // z-index: 10000;
  //  loading-logo.gif
}
.loading {
  width: 385px;
  height: 90px;
  background: url(/imgs/loading-logo.gif);
  background-size: 100% 100%;
  background-position: center;
  margin-top: 12%;
}
@media screen and (max-width: 1000px) {
  .loading {
    width: 300px;
    height: 70px;
    margin-top: 35%;
  }
}
</style>
