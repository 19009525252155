import { render, staticRenderFns } from "./goodsinfo.vue?vue&type=template&id=596edbdd&scoped=true"
import script from "./goodsinfo.vue?vue&type=script&lang=js"
export * from "./goodsinfo.vue?vue&type=script&lang=js"
import style0 from "./goodsinfo.vue?vue&type=style&index=0&id=596edbdd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596edbdd",
  null
  
)

export default component.exports