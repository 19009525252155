<template>
  <v-dialog v-model="dialog" class="dialogs-com" persistent>
    <div class="dialog-body">
      <div class="dialog-header d-flex">
        <v-spacer />
        <v-btn icon dark @click="closeDialog">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="content-detail">
        <p class="title-coupon">
          Check the coupon
          <br />
          to use in your shopping cart
        </p>
        <div class="list">
          <div v-for="(item, index) in list" :key="index" class="list-item">
            <div class="tag-box">COUPON</div>
            <div class="info-text">
              <p v-if="item.first" class="flag">Only for first order</p>
              <div class="price">
                <span class="price-top">
                  <template v-if="item.discounts_type === 1"> ${{ item.price }} </template>
                  <template v-else> {{ item.price }}% </template>
                </span>
                <span>OFF</span>
              </div>
              <p class="title-text">{{ item.subtitle }}</p>
            </div>
          </div>
        </div>
        <div class="logged">
          <v-btn class="my-btn get-btn" color="#3067f2" tile depressed @click="closeDialog">GO SHOPPING</v-btn>
          <p class="check"><span @click="goCheck">Check Coupons</span></p>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'coupinsBox',
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      list: [
        {
          discounts_type: 1,
          price: '7',
          subtitle: 'Sitewide Sale',
          first: 1
        }
      ]
    }
  },
  watch: {
    show(newValue) {
      this.dialog = newValue
    }
  },
  methods: {
    closeDialog() {
      this.$parent.couponsDialogs = false
    },
    goCheck() {
      this.closeDialog()
      this.$router.push({
        name: 'centerMain-wallet-coupons'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog {
// }
.dialogs-com,
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  background: #fff;
  margin: 0 auto;
  font-size: 16px;
  width: 460px;
}
::v-deep .v-toolbar {
  height: 50px;
}
.content-detail {
  padding: 0 16px;
  min-height: 340px;
  background: #fff;
  h2 {
    text-align: center;
    margin-bottom: 10px;
  }
}
.title-coupon {
  font-size: 24px;
  text-align: center;
}
.logged {
  margin-top: 15px;
  text-align: center;
  .get-btn {
    width: 230px;
    height: 45px !important;
    color: #fff !important;
  }
  .check {
    margin: 0;
    padding: 15px 0;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
  }
}
.list {
  display: flex;
  justify-content: center;
}
.list-item {
  display: flex;
  width: 90%;
  margin-bottom: 15px;
  height: 100px;
  &.available {
    cursor: pointer;
  }
  .tag-box {
    width: 16%;
    user-select: none;
    color: #fff;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5c723;
    writing-mode: vertical-rl; /* 垂直方向上自右向左 */
    // text-orientation: upright; /* 文本方向正常 */
    // .price-top {
    //   margin-bottom: 5px;
    // }
  }
  .flag {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 0 5px;
    background: #f5c723;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .info-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    color: #ffffff;
    background: #3067f2;
    position: relative;
    cursor: default;
    p {
      margin-bottom: 0;
    }
    .title-text {
      font-weight: 600;
    }
    .price {
      font-size: 24px;
      font-weight: 600;
    }
    .tips {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: #3067f2;
      border-radius: 5px;
      padding: 0 10px;
    }
  }
  &.unavailable {
    .price {
      background: #a8a8a8;
    }
    .info-text {
      background: #d7d7d7;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .v-dialog__content ::v-deep .v-dialog {
    width: 90%;
  }
  .title-coupon {
    font-size: 20px;
  }
}
</style>
