<template>
  <v-app dark>
    <div class="error-body">
      <!-- {{ error.statusCode }}
      <h1 v-if="error.statusCode === 404">
        {{ pageNotFound }}
      </h1>
      <h1 v-else>
        {{ otherError }}
      </h1>
       -->
      <!-- {{ error }} -->
      <div class="left"></div>
      <div class="right">
        <!-- {{ error }} -->
        <!-- {{error.message  }} -->
        <h1 v-if="error.statusCode === 404">
          {{ pageNotFound }}
        </h1>
        <h1 v-else>
          {{ otherError }}
        </h1>
        <NuxtLink to="/"><button>Home page</button></NuxtLink>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'EmptyLayout',
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    }
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 20px;
}
.error-body {
  margin: 10% auto 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.left {
  width: 286px;
  height: 267px;
  background: url('/imgs/error_page.png');
  background-size: contain;
  margin-right: 30px;
}
.right button {
  background: #3067f2;
  color: #fff;
  width: 238px;
  padding: 10px;
  margin-top: 20px;
}
@media screen and (max-width: 1280px) {
  .error-body {
    flex-direction: column;
  }
  .left {
    margin-right: 0;
  }
}
</style>
