<template>
  <!-- 商品 -->
  <div class="body-page">
    <!-- <v-progress-circular indeterminate color="primary" size="50" /> -->
    <div class="content-box hot">
      <h2 v-if="title" class="mode-title">{{ title }}</h2>
      <v-container class="good-list" fluid>
        <v-row v-if="list.length && !loading" no-gutters>
          <v-col v-for="item in list" :key="item.variant_id" cols="6" md="4">
            <div class="goods-item">
              <div class="item">
                <div class="top">
                  <div class="left" :class="renderTag(item.tag)">
                    <div v-if="item.discount" class="discount">
                      <!-- <img class="tag_bg" :src="'/imgs/' + renderTagBg(item.tag)" alt="" /> -->
                      <p class="tagNum">{{ item.discount }}% Off</p>
                      <!-- <br /><span class="state">OFF</span> -->
                    </div>
                  </div>
                  <div class="right" @mouseenter="setCollection(item, 'enter')" @mouseleave="setCollection(item, 'leave')" @click="collection(item, '')">
                    <img :src="setCollection(item)" alt="collection" />
                  </div>
                </div>
                <div class="banner" :class="{ img2h: type === 'eye' ? false : true }">
                  <nuxt-link :to="{ name: 'details-code', params: { code: item.code } }">
                    <img :key="item.code" v-lazy="item.img" :alt="item.alt" />
                    <!-- <v-img contain transition="scale-transition" lazy-src="/imgs/imgLazy.jpg" :src="item.img" :alt="item.alt" /> -->
                  </nuxt-link>
                </div>
                <div class="content" title="">{{ item.content }}</div>
                <div class="price">
                  <template v-if="item.price != item.original">
                    <span class="current-price">{{ currencyIcon }}{{ item.price }}</span>
                    <span class="old-price">{{ currencyIcon }}{{ item.original }}</span>
                  </template>
                  <template v-else>
                    <span class="only-price">{{ currencyIcon }}{{ item.price }}</span>
                  </template>
                </div>
                <div v-if="item.category !== 'accessories'" class="btn-by d-flex justify-center">
                  <v-btn class="my-btn small try-btn" small outlined color="indigo" @click="tryOn(item)">TRY ON</v-btn>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div v-else-if="!loading && !list.length" class="empty-tips">
          <img class="empty-img" src="/imgs/empty.png" alt="no-product" />
          <p class="empty-text">There is no product display for the time being.</p>
        </div>
        <template v-else>
          <div class="loading" style="height: 50vh">
            <v-progress-circular indeterminate color="#3067f2" />
          </div>
        </template>
      </v-container>
      <template v-if="list.length && !hidePage">
        <!-- pc 展示分页 / all -->
        <div v-if="$vuetify.breakpoint.mdAndUp" class="pagination-box mt-4 d-flex" :class="{ hideSwitch: !allPage ? false : true }">
          <v-pagination v-model="page" color="#222426" :total-visible="7" :length="setPageLength" @input="change" />
          <v-btn v-if="allPage" class="my-btn btn-shadow size-all" outlined color="#525861" @click="getAll">All Products</v-btn>
        </div>
        <!-- 手机 首页只展示all -->
        <div v-if="$vuetify.breakpoint.smAndDown" class="pagination-box mt-4 d-flex justify-center">
          <v-pagination v-if="!allPage" v-model="page" color="#222426" :total-visible="7" :length="setPageLength" @input="change" />
          <v-btn v-if="allPage" class="mobile-btn-more" rounded @click="getAll">All Products</v-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// import glasses from '@/static/imgs/glasses.png'
import { mapState, mapGetters } from 'vuex'
import { subtract, divide, multiply } from '@/assets/utils/decimal'
import collection from '@/static/imgs/collection.png'
import collected from '@/static/imgs/collected.png'
export default {
  name: 'LGoods',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'eye' // accessories 配件
    },
    // 列表标题
    title: {
      type: String,
      default: ''
    },
    // 隐藏分页器
    hidePage: {
      type: Boolean,
      default: false
    },
    // 展示全部按钮
    allPage: {
      type: Boolean,
      default: false
    },
    // 分页配置数据
    option: {
      type: Object,
      default: () => {
        return {
          page: 1,
          limit: 9,
          count: 9
        }
      }
    },
    // 数据
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 当前页码
      page: 1
    }
  },
  computed: {
    /**
     *
     * @param {原价} price1
     * @param {现价} price2
     * @return 折扣比例
     */
    getDiscount() {
      return function (price1, price2) {
        const difference = subtract(price1, price2)
        const discount = divide(difference, price1).toFixed(2)
        return multiply(discount, 100)
      }
    },
    renderTag() {
      return function (val) {
        let tag
        switch (val) {
          case 0:
            tag = 'hot'
            break
          case 1:
            tag = 'hot'
            break
          case 2:
            tag = 'flash'
            break
          default:
            tag = 'hot'
            break
        }
        return {
          [tag]: true
        }
      }
    },
    // renderTagBg() {
    //   return function (val) {
    //     let tag
    //     // switch (val) {
    //     //   case 0:
    //     //     tag = 'hot_tag.png'
    //     //     break
    //     //   case 1:
    //     //     tag = 'hot_tag.png'
    //     //     break
    //     //   case 2:
    //     //     tag = 'flash_tag.png'
    //     //     break
    //     //   default:
    //     //     tag = 'hot_tag.png'
    //     //     break
    //     // }
    //     return tag
    //   }
    // },
    // 设置是否收藏
    setCollection() {
      return function (obj) {
        if (obj.collection) {
          return collected
        } else if (!obj.collection) {
          return collection
        }
      }
    },
    // 设置分页数量
    setPageLength() {
      if (this.allPage) {
        if (Math.ceil(this.option.count / this.option.limit) > 3) {
          return 3
        } else {
          return Math.ceil(this.option.count / this.option.limit)
        }
      }
      if (this.option) {
        return Math.ceil(this.option.count / this.option.limit)
      }
      return 1
    },
    ...mapState({
      userToken: state => state.system.userToken
    }),
    ...mapGetters('system', ['currencyIcon'])
  },
  watch: {
    '$route.query': {
      handler(val) {
        if (val.page && val.page !== undefined) {
          this.page = val.page - 0
        }
      },
      immediate: true,
      deep: true
    }
    // 'option.page': {
    //   handler(val, oldPage) {
    //     if (val) {
    //       this.page = val - 0
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  methods: {
    change(val) {
      this.$emit('page', val)
    },
    getAll() {
      this.$emit('getAll')
    },
    toPage(code, name) {
      // const className = this.$route.query.type
      // this.$router.push({
      //   name: 'index-details',
      //   query: { id, class: className, name }
      // })
      const page = this.$router.resolve({
        name: 'details',
        query: { code }
      })
      window.open(page.href, '_blank')
    },
    collection(item) {
      if (this.userToken) {
        this.$api.product.setWishlist({ code: item.code }).then(res => {
          if (res.code === 200) {
            item.collection ? (item.collection = false) : (item.collection = true)
            this.$store.dispatch('system/getCollectionsAndCartsNum', { axios: this.$api })
          }
        })
      } else {
        this.$store.commit('system/toggleLogin', true)
      }

      // 收藏操作
    },
    // 穿戴
    tryOn(obj) {
      this.$store.commit('system/toggleTryOnShow', { state: true, img: obj.image_frame, code: obj.code })
    }
  }
}
</script>

<style lang="scss" scoped>
/*设置动画*/
.fade-enter,
.fade-leave-to {
  transform: scale(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}
.fade-enter-to {
  transform: scale(1);
}
.pagination-box.hideSwitch ::v-deep .v-pagination__navigation {
  display: none !important;
}
.pagination-box ::v-deep .v-pagination__navigation {
  width: 40px;
  height: 40px;
  font-size: 14px;
}
.body-page {
  padding-top: 70px;
  padding-bottom: 70px;
}
.mode-title {
  margin-bottom: 70px;
}
.row > div {
  margin-bottom: 10px;
}
.goods-item {
  background: #fff;
  width: 100%;
  // height: 450px;

  // margin: 0 4px 20px;
  .item {
    width: calc(100% - 6px);
    height: 100%;

    margin: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-content: center;
    // justify-content: space-around;
    position: relative;
    transition: box-shadow 1s;
    .top {
      display: flex;
      justify-content: space-between;
      height: 42px;
      padding: 0 25px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      .tagNum {
        background: #3067f2;
        padding: 0 10px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        border-radius: 0 11px 11px 0;
        font-size: 14px;
        margin-bottom: 0;
        color: #fff;
      }
      // .left.hot {
      //   width: 70px;
      //   height: 70px;
      //   color: #fff;
      //   .tagNum {
      //     margin-top: 20px;
      //     transform: rotate(353deg);
      //     font-size: 13px;
      //     font-weight: 600;
      //   }
      // }
      // .left.flash {
      //   width: 70px;
      //   height: 60px;
      //   color: #ff621d;
      //   .tagNum {
      //     margin-top: 16px;
      //     font-size: 14px;
      //     font-weight: 600;
      //   }
      // }
      .left {
        position: relative;
        .discount {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        // img {
        //   position: absolute;
        //   height: 100%;
        //   width: 100%;
        // }
        // .tagNum {
        //   margin-bottom: 0;
        //   margin-left: auto;
        //   margin-right: auto;
        //   line-height: 1;
        //   text-align: center;
        //   word-wrap: break-word;
        //   word-break: break-all;
        //   position: absolute;
        //   // left: 2px;
        // }
        .state {
          font-size: 13px;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
      .right {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          height: 18px;
          width: 21px;
          position: relative;
        }
      }
    }
    @media screen and (min-width: 1200px) {
      .right:hover {
        opacity: 0.7;
      }
    }

    .banner {
      display: flex;
      justify-content: center;
      width: 86%;
      // height: 152px;
      // min-height: 152px;
      // height: auto;
      height: 152px;
      margin: 88px auto 24px;
      cursor: pointer;
      a {
        display: flex;
        justify-content: center;
      }
      img,
      .v-image {
        // height: 100%;
        // width: 100%;
        // object-fit: cover;
        // width: 100%;
        object-fit: contain;
        height: 100%;
        max-width: 90%;
      }
    }
    .img2h {
      height: 215px;
    }
    .content {
      width: 100%;
      padding: 0 20px;
      font-size: 16px;
      text-align: center;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .price {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      .current-price {
        color: red;
        font-size: 18px;
        font-weight: 600;
      }
      .old-price {
        color: #6b6b6b;
        font-size: 16px;
        margin-left: 5px;
        position: relative;
        font-weight: 600;
        line-height: 1;
        // top: 3px;
        text-decoration: line-through;
      }
      .only-price {
        color: #252323;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .btn-by {
      margin-bottom: 10px;
    }
  }
}

.my-btn.small {
  height: 25px;
}

.pagination-box {
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  .size-all {
    margin-left: 4px;
    height: 40px !important;
    line-height: 40px;
  }
}
.pagination-box ::v-deep .v-pagination__item {
  width: 40px;
  height: 40px;
  font-size: 14px;
  margin: 0 4px;
}
@media screen and (min-width: 1280px) {
  .item:hover {
    box-shadow: 2px 2px 10px 2px #c7ccd5cc;
    // transform: scale(1.001);
  }
}
.empty-tips {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .empty-img {
    height: 186px;
    margin-bottom: 16px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .try-btn {
    font-size: 13px !important;
  }
  .body-page {
    margin-top: 0;
    padding-top: 30px;
    padding-bottom: 48px;
  }
  .body-page,
  .content-box,
  .good-list,
  .goods-item {
    background: #f9fafc;
  }
  .content-box {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  .mode-title {
    margin-bottom: 38px;
  }
  .row > div {
    margin-bottom: 10px;
  }

  .row > div:nth-of-type(odd) {
    .item {
      margin: 0 5px 0 0;
    }
  }
  .row > div:nth-of-type(even) {
    .item {
      margin: 0 0 0 5px;
    }
  }
  .goods-item {
    // margin: 0 4px 20px !important;
    // height: 266px !important;
    .item {
      width: calc(100% - 4px);
      // margin-left: ;
      .top {
        padding: 0 10px;
        .left.hot {
          .tagNum {
            font-size: 12px;
          }
        }
        .left.flash {
          .tagNum {
            font-size: 12px;
          }
        }
      }
      .banner {
        width: 100%;
        margin: 54px 0 10px !important;
        height: 64px !important;
        // border: 1px dashed red;
      }
      .img2h {
        height: 125px !important;
      }
      .content {
        margin-bottom: 6px;
        font-size: 13px;
        padding: 0 14px;
      }
      .price {
        // font-size: 13px;
        margin-bottom: 6px;
        .current-price {
          font-size: 14px;
        }
        .old-price {
          font-size: 13px;
        }
        .only-price {
          font-size: 14px;
        }
      }
      .btn-by {
        margin-bottom: 12px;
      }
    }
  }
  .pagination-box {
    margin-top: 18px;
    // margin-bottom: 48px;
  }
}
</style>
