<template>
  <section class="header-activity">
    <!-- <img src="left" alt=""> -->
    <!-- :style="{ display: show ? 'block' : 'none' }" -->
    <div class="count-down" :style="{ display: show ? 'block' : 'none' }">
      <div class="count-content">
        <div class="left">
          <p>Fresh Look in Winter, Buy One Get One Free(Frames + Lenses)</p>
          <p v-if="$vuetify.breakpoint.width > 960" class="code">Code: GSBOGO</p>
        </div>
        <div class="center">
          <p>Ends in</p>
          <span class="item">{{ day }}</span
          ><span class="dot">:</span><span class="item">{{ hour }}</span
          ><span class="dot">:</span><span class="item">{{ minute }}</span
          ><span class="dot">:</span><span class="item">{{ second }}</span>
        </div>
        <div class="right">
          <p v-if="$vuetify.breakpoint.width <= 960" class="code">Code: GSBOGO</p>
          <span>SHOP NOW</span>
          <img src="/imgs/close-white.png" alt="close" @click="close" />
        </div>
      </div>
    </div>
    <div class="notice">
      <div class="left-option d-md-none d-lg-none d-xl-none" @click="move('left')"><img src="/imgs/left.png" alt="left-move" /></div>
      <div class="content" :style="{ left: left }">
        <div class="left">
          <!-- <img src="/imgs/coupons.png" alt="coupons" />
        <p class="line">|</p> -->
          <ul>
            <li>
              <span class="item">Free Shipping over US$69<img class="question-icon" alt="tips" src="/imgs/question-white.png" @click="getTips('Free_Shipping')" /></span>
            </li>
            <li>
              <span class="item">Buy 1, Get Other Frames 50% OFF<img class="question-icon" alt="tips" src="/imgs/question-white.png" @click="getTips('Frames_OFF')" /></span>
            </li>
          </ul>
          <tipDialog v-model="tipFlag" :type="tipType" />
        </div>
        <div ref="rightBtn" class="right">
          <!-- <nuxt-link :to="{ name: 'contact-us' }"> -->
          <nuxt-link :to="{ name: 'contact-us' }">
            <button type="button" class="botton-notice">Contact</button>
          </nuxt-link>
          <!-- </nuxt-link> -->
          <nuxt-link :to="{ name: 'index-faq-articles', params: { articles: 'list' } }">
            <button type="button" class="botton-notice">Help</button>
          </nuxt-link>
        </div>
      </div>
      <div class="right-option d-md-none d-lg-none d-xl-none" @click="move('right')"><img src="/imgs/left.png" alt="right-move" /></div>
    </div>
  </section>
</template>

<script>
// import { setNav } from '@/assets/utils/header.js'
import tipDialog from '@/components/Lnav/Lnotice/tipDialog.vue'
export default {
  name: 'LNotice',
  components: {
    tipDialog
  },
  data() {
    return {
      left: '0',
      day: 0, // 天
      hour: 0, // 时
      minute: 0, // 分
      second: 0, // 秒
      timer: null,
      expireat: '2023-4-30 10:25', // 过期时间
      show: null,
      tipFlag: false, // 优惠说明弹窗
      tipType: undefined // 弹窗文字内容
    }
  },
  computed: {},
  watch: {
    '$route.path': {
      handler(val) {
        if (process.client) {
          if (val === '/440') {
            document.querySelector('.header-activity').style.display = 'none'
          }
        }
      },
      immediate: true
    }
  },
  created() {
    if (process.client) {
      // 用户是否关闭折扣窗
      this.show = localStorage.getItem('SETACTIVITY') - 0 || ''
    }
  },
  mounted() {
    // 显示条幅
    if (this.show === 1) {
      const etimer = new Date(this.expireat).getTime() // 获取过期日期的时间戳
      this.countDown(etimer)
    }

    // this.left = (document.documentElement.clientWidth || document.body.clientWidth) + 'px'
  },
  destroyed() {
    this.clearTimer()
  },
  methods: {
    countDown(time) {
      const that = this
      if (!time) {
        return
      }
      const secondes = time
      that.timer = setInterval(() => {
        const now = new Date().getTime()
        if (now < secondes) {
          const diff = parseInt((secondes - now) / 1000) // 相差多少秒
          let s = diff % 60 // 秒
          let m = parseInt(diff / 60) // 分钟
          let h = 0 // 小时
          let d = 0 // 天
          if (m > 60) {
            h = parseInt(m / 60)
            m = m % 60
          }
          if (h > 24) {
            d = parseInt(h / 24)
            h = h % 24
          }
          if (d >= 0) {
            d = d < 10 ? '0' + d : d
          }
          if (h >= 0) {
            h = h < 10 ? '0' + h : h
          }
          if (h >= 0 || m >= 0) {
            m = m < 10 ? '0' + m : m
          }
          s = s < 10 ? '0' + s : s
          // 时分秒赋值
          that.day = d
          that.hour = h
          that.minute = m
          that.second = s
        } else {
          that.clearTimer()
        }
      }, 1000)
    },
    clearTimer() {
      clearInterval(this.timer)
      this.timer = null
      this.show = 0
    },
    move(type) {
      // 当前偏移量
      const current = this.left.split('px')[0] - 0
      let width = document.documentElement.clientWidth || document.body.clientWidth
      width = width * 0.75
      if (type === 'right') {
        const dom = this.$refs.rightBtn
        // 检测 通知栏 按钮位置
        if (this.getOffset(dom).x && this.getOffset(dom).x < 100) {
          return false
        }
        // 向左移动
        this.left = current - width + 'px'
      } else {
        // 向右移动
        if (current >= 0) {
          return false
        }
        this.left = current + width + 'px'
      }
    },
    getOffset(dom) {
      let x = dom.offsetLeft
      let y = dom.offsetTop
      let parent = dom.offsetParent
      // while判断是否有设有定位的祖先元素
      // parent.offsetParent = null表示找到的是body,则不需要进入while
      // parent.offsetParent != null表示找到的是设有定位的祖先元素
      while (parent.offsetParent != null) {
        x += parent.offsetLeft
        y += parent.offsetTop
        parent = parent.offsetParent
      }
      return { x, y } // {x:x,y:y}
    },
    close() {
      localStorage.setItem('SETACTIVITY', 0)
      this.show = 0
      // this.$nextTick(() => {
      //   setNav()
      // })
    },
    getTips(type) {
      this.tipFlag = true
      this.tipType = type
    }
  }
}
</script>

<style lang="scss" scoped>
// pc 40    mobile 46
.header-activity {
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  // background: #3067f2;
  width: 100%;
  position: relative;
  z-index: 2;
}
.count-down {
  height: 50px;
  background: linear-gradient(360deg, #f14c27 0%, #fe732f 100%);
  color: #fff;
  .count-content {
    min-width: 800px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .left,
    .center,
    .right {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
      p {
        margin-bottom: 0;
      }
    }
    .left {
      p {
        display: inline-block;
      }
      .code {
        margin-left: 25px;
      }
    }
    .center {
      margin-left: 50px;
      margin-right: 50px;
      p {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 5px;
      }
      .item {
        display: inline-block;
        color: #f85f2b;
        font-size: 16px;
        font-weight: 600;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
      }
      .dot {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    .right > span {
      cursor: pointer;
      border-bottom: 3px solid #fff;
    }
    .right > img {
      height: 12px;
      margin-left: 50px;
      cursor: pointer;
    }
  }
}

.notice {
  height: 40px;
  color: #fff;
  background: #2b2e33;

  .content {
    display: flex;
    justify-content: space-between;
    max-width: 1720px;
    // min-width: 735px;
    height: 100%;
    font-size: 14px;
    margin: 0 auto;
    width: 90%;
  }
  .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > * {
      margin-right: 30px;
    }
    img {
      height: 18px;
      margin-left: 10px;
      cursor: pointer;
    }
    p {
      margin-bottom: 0;
    }
    ul {
      height: 100%;
      display: flex;
      padding-left: 0;
      li {
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        .item {
          position: relative;
          display: flex;
          align-items: center;
          &:first-child {
            margin-right: 55px;
          }
          &::before {
            content: '';
            position: absolute;
            left: -10px;
            top: 8px;
            border-radius: 50%;
            width: 5px;
            height: 5px;
            background: #fff;
          }
        }
      }
    }
  }
  .right {
    height: 100%;
    display: flex;
    a {
      text-decoration: none;
      color: #fff;
    }
    .botton-notice {
      background: #31353b;
      height: 100%;
      padding: 0 20px;
      cursor: pointer;
      &:first-child {
        margin-right: 2px;
      }
      @media screen and (min-width: 1080px) {
        &:hover {
          background: #000;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1280px) {
  .count-down {
    .count-content {
      justify-content: space-between;
      .left,
      .center,
      .right {
        font-size: 14px;
      }
      .center {
        margin: 0;
      }
      .right {
        display: flex;
        align-items: center;
        img {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .count-down {
    height: auto;
    padding: 10px;
    .count-content {
      min-width: 100%;
      flex-direction: column;

      .left {
        text-align: center;
      }
      .center {
        margin: 5px 0;
        .item {
          width: 26px;
          height: 26px;
          line-height: 26px;
        }
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        .code {
          margin-right: 18px;
        }
        img {
          margin-right: 15px;
        }
      }
    }
  }
  .notice {
    display: flex;
    height: 46px;
    overflow: hidden;
    .left-option,
    .right-option {
      width: 12%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
      background: #2b2e33;
      img {
        width: 9px;
        height: 17px;
      }
    }
    .right-option > img {
      transform: rotate(180deg);
    }
    .content {
      width: 75%;
      margin: 0 auto;
      position: relative;
      transition: left 0.5s;
    }
    .left {
      & > * {
        margin-right: 15px;
      }
      .line {
        margin-right: 20px;
      }
    }
    .img {
      width: 79px;
      height: 27px;
    }
    ul {
      li {
        width: 75vw;
        justify-content: center;
        position: relative;
        .item {
          &:first-child {
            margin-right: 0 !important;
          }
        }
        // width: 175px;
      }
    }
  }
}
</style>
