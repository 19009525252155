<template>
  <div class="guide">
    <img class="close-icon" src="/imgs/close.png" alt="close" @click="close" />
    <div class="item">
      <h4 class="item-title">How to fill out the prescription?</h4>
      <p class="item-text">A (+) means you have trouble seeing near, and a (-) means you have trouble seeing things faraway.</p>
    </div>
    <div class="line"></div>
    <div class="item">
      <h4 class="item-title">How to measure PD?</h4>
      <p class="item-text">PD (PUPILLARY DISTANCE) is the distance between your two pupils in millimeters. If there is no PD on your prescription, checking with your eye doctor is recommended.</p>
    </div>
    <div class="btn-box pd-btn-box">
      <v-btn class="my-btn measure" tile depressed block>
        <a class="my-link" download target="_blank" :href="`${GLOBAL.URL}/materials/download/Stylool-PD-Ruler.pdf`">
          <img class="icon" src="/imgs/measure.png" alt="icon" />
          Download PD Ruler
        </a>
      </v-btn>
    </div>
    <p class="text-p">Unable to make sure your Prescription?</p>
    <div class="btn-box">
      <v-btn class="my-btn union" tile depressed block @click="showTicket = true">
        <img class="icon" src="/imgs/union.png" alt="icon" />
        Leave a message
      </v-btn>
    </div>
    <ticketDialog :show="showTicket" />
  </div>
</template>

<script>
import ticketDialog from '@/components/toolCom/ticketDialog.vue'
export default {
  name: 'guideDialog',
  components: {
    ticketDialog
  },
  data() {
    return {
      showTicket: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$parent.showGuide()
    }
  }
}
</script>

<style lang="scss" scoped>
.guide {
  width: 400px;
  height: calc(100vh - 60px);
  background: #f5f6fa;
  position: relative;
  z-index: 5;
  padding: 40px;
}
.close-icon {
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.line {
  margin: 40px 0;
  border: 1px solid #dddddd;
}
.item-title {
  font-size: 17px;
  color: #2b2e33;
  margin-bottom: 20px;
  line-height: 24px;
}
.item-text {
  font-size: 16px;
  color: #525861;
  line-height: 24px;
}
.measure,
.union {
  height: 48px !important;
  line-height: 48px;
}
.measure {
  color: #fff !important;
  background: #3067f2 !important;
}
.union {
  color: #3067f2 !important;
  border: 1px solid #3067f2;
}
.btn-box .icon {
  height: 18px;
  margin-right: 6px;
}
.text-p {
  color: #2b2e33;
  font-size: 16px;
  margin-bottom: 18px;
}
.pd-btn-box {
  margin: 40px 0 70px;
}
.my-link {
  text-decoration: none;
  color: #fff !important;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1690px) {
  .guide {
    position: absolute;
    right: 0;
  }
}
@media screen and (max-width: 960px) {
  .guide {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
  }
}
</style>
