<template>
  <v-dialog v-model="dialog" class="dialogs-com" persistent :fullscreen="$vuetify.breakpoint.mobile" hide-overlay>
    <div class="dialog-body">
      <div class="dialog-header d-flex">
        <v-spacer />
        <v-btn icon dark @click="closeDialog">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="content-detail">
        <div class="content">
          <div v-for="item in list" :key="item.id" class="item" @click="setCurrent(item)">
            <p class="top">{{ item.firstname }} {{ item.lastname }} {{ item.phone }}</p>
            <div class="bottom">
              <p class="detail">
                <span>{{ item.address }}, </span>
                <template v-if="item.apt_suite">
                  <span>{{ item.apt_suite }}, </span> </template
                >{{ item.city }}, {{ item.state }}, {{ item.zip_code }}, {{ item.country }}
              </p>
            </div>
          </div>
        </div>
        <div class="option">
          <v-btn class="my-btn option-btn blue-btn" color="#3067f2" tile depressed block @click="openDialogAdd">Add an address</v-btn>
        </div>
      </div>
      <!-- <addressForm :show="addressForm" :info="info" @close="addressForm = false" @save="saveForm" /> -->
    </div>
  </v-dialog>
</template>

<script>
// import addressForm from '@/components/LcenterMain/address/addressDialog'
export default {
  name: 'addressList',
  components: {
    // addressForm
  },
  props: {
    show: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'shipping',
      require: true
    }
    // list: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
    return {
      dialog: false,
      list: []
      // // 单条弹窗
      // addressForm: false,
      // info: {
      //   gender: undefined,
      //   firstname: undefined,
      //   lastname: undefined,
      //   country: undefined,
      //   address: undefined,
      //   apt_suite: undefined,
      //   city: undefined,
      //   zip_code: undefined,
      //   state: undefined,
      //   phone: undefined,
      //   backup_phone: undefined,
      //   is_primary: undefined,
      //   address_id: 0,
      //   id: undefined
      // }
    }
  },
  watch: {
    show(newValue) {
      this.dialog = newValue
      if (newValue) {
        this.getList()
      }
    }
  },
  methods: {
    // 获取地址列表
    getList() {
      this.$api.userCenter.getAddressList().then(res => {
        if (res.code === 200) {
          this.list = res.data
        }
      })
    },
    setCurrent(item) {
      this.$emit('setAddress', { data: item, type: this.type })
    },
    openDialogAdd() {
      this.$parent.openDialogAdd()
    },
    closeDialog() {
      // this.$parent.sizeDialogs = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog {
// }
.dialogs-com,
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
  position: relative;
}
.v-dialog__content ::v-deep .v-dialog {
  background: #fff;
  margin: 0 auto;
}
.v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  width: 735px;
}
::v-deep .v-toolbar {
  height: 50px;
}
.content-detail {
  padding: 0 16px;
  min-height: 375px;
  height: 100%;
  background: #fff;
}
.content {
  padding-bottom: 66px;
}
.item {
  padding: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  background: #f8f6f6;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 4px 4px 0px #e1e1e1;
  }
  .top {
    margin-bottom: 6px;
    font-size: 18px;
    color: #000;
  }
  .detail {
    margin-bottom: 0;
  }
}
.option {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  width: 50%;
  margin: 0 auto;
  .my-btn {
    color: #fff !important;
    height: 44px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
}
</style>
