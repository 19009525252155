<template>
  <v-dialog v-model="dialog" class="dialog-box" persistent background="#fff" hide-overlay>
    <div class="dialog-body">
      <v-toolbar color="#fff">
        <v-spacer />
        <v-btn icon dark @click="close">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="content">
        <v-form v-if="dialog" ref="address" class="list">
          <div class="item-row name">
            <div class="gender-item">
              <v-select v-model="dataInfo.gender" :rules="[rules.required]" class="input-item" :items="genderList" color="#3067f2" outlined hide-details="auto" />
            </div>
            <div class="item">
              <v-text-field v-model="dataInfo.firstname" :rules="[rules.required]" class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" placeholder="First Name" />
            </div>
            <div class="item">
              <v-text-field v-model="dataInfo.lastname" :rules="[rules.required]" class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" placeholder="Last Name" />
            </div>
          </div>
          <div class="item-row">
            <div class="item">
              <div class="label-item">
                <span class="label-left">Country</span>
                <!-- <v-text-field required class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" /> -->
                <v-select v-model="dataInfo.country" :rules="[rules.required]" class="input-item" :items="countryList" color="#3067f2" outlined hide-details="auto" />
              </div>
            </div>
          </div>
          <div class="item-row">
            <div class="item">
              <v-text-field
                v-model="dataInfo.address"
                :label="showLabel('Address Line 1')"
                :rules="[rules.required]"
                class="input-item"
                background-color="#fff"
                color="#3067F2"
                outlined
                hide-details="auto"
                placeholder="Street Address"
              />
            </div>
          </div>
          <div class="item-row">
            <div class="item">
              <v-text-field v-model="dataInfo.apt_suite" :label="showLabel('Address Line 2')" class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" placeholder="Apt/Suite Number" />
            </div>
          </div>
          <div class="item-row city">
            <div class="item">
              <v-text-field v-model="dataInfo.city" :label="showLabel('City')" :rules="[rules.required]" class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" placeholder="City" />
            </div>
            <div class="item">
              <v-text-field
                v-model="dataInfo.zip_code"
                :label="showLabel('Post/Zip Code')"
                :rules="[rules.required]"
                class="input-item"
                background-color="#fff"
                color="#3067F2"
                outlined
                hide-details="auto"
                placeholder="Post/Zip Code"
              />
            </div>
          </div>
          <div class="item-row">
            <div class="item">
              <!-- <v-text-field
                v-model="dataInfo.state"
                :label="showLabel('State/Province')"
                :rules="[rules.required]"
                class="input-item"
                background-color="#fff"
                color="#3067F2"
                outlined
                hide-details="auto"
                placeholder="State/Province"
              /> -->

              <template v-if="provinceList.length">
                <v-select v-model="dataInfo.state" :rules="[rules.required]" class="input-item" :items="provinceList" color="#3067f2" outlined hide-details="auto" />
              </template>
              <template v-else>
                <v-text-field
                  v-model="dataInfo.state"
                  :label="showLabel('State/Province')"
                  :rules="[rules.required]"
                  class="input-item"
                  background-color="#fff"
                  color="#3067F2"
                  outlined
                  hide-details="auto"
                  placeholder="State/Province"
                />
              </template>
            </div>
          </div>
          <!-- 号码 -->
          <div class="item-row">
            <div class="item">
              <div class="label-item">
                <!-- <span class="label-num">+1</span> -->
                <div class="text-center country-box phone1">
                  <v-menu offset-y top attach=".phone1">
                    <template #activator="{ on, attrs }">
                      <!-- <v-btn color="primary" > Dropdown </v-btn> -->
                      <div class="currency-current" v-bind="attrs" v-on="on">
                        <!-- <img v-if="countryTel[selectedItem1].img" class="currency-img" :src="countryTel[selectedItem1].img" :alt="countryTel[selectedItem1].name" /> -->
                        <span>{{ countryTel[selectedItem1].code === '' ? countryTel[selectedItem1].name : countryTel[selectedItem1].code }}</span>
                      </div>
                    </template>
                    <v-list class="currency">
                      <v-list-item-group v-model="selectedItem1" color="#3067f2">
                        <v-list-item v-for="(item, index) in countryTel" :key="index">
                          <v-list-item-title>
                            <img v-if="item.img" class="currency-img" :src="item.img" :alt="item.name" />
                            <span>{{ item.name }}</span>
                            <span class="currency-left">{{ item.code }}</span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
                <v-text-field
                  ref="tel"
                  v-model="phone1.tel"
                  :rules="[rules.required]"
                  class="input-item"
                  background-color="#fff"
                  color="#3067F2"
                  outlined
                  hide-details="auto"
                  placeholder="Phone Number"
                  @input="handleInput"
                />
              </div>
            </div>
          </div>
        </v-form>
        <p class="tips">Make sure your address is correct. If the address contains typos or other errors, your package may be undeliverable.</p>
        <div class="select">
          <template v-if="type === 'shipping'">
            <v-checkbox v-model="dataInfo.is_primary" label="Set as primary shipping address" color="#3067F2" />
          </template>
          <template v-else>
            <v-checkbox v-model="dataInfo.is_primary" label="Set as primary billing address" color="#3067F2" />
          </template>
        </div>
        <div class="btn-box">
          <v-btn class="my-btn btn-option" tile depressed block :disabled="btnState" @click="addressSave"> CONFIRM </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
// import uploadImg from '@/components/toolCom/uploadImg'
export default {
  name: 'addressDialog',
  components: {
    // uploadImg
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'shipping'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'Required.'
      },
      dataInfo: {
        gender: undefined,
        firstname: undefined,
        lastname: undefined,
        country: undefined,
        address: undefined,
        apt_suite: undefined,
        city: undefined,
        zip_code: undefined,
        state: undefined,
        phone: undefined,
        backup_phone: undefined,
        is_primary: undefined,
        address_id: 0
      },
      dialog: false,
      btnState: false, // 保存按钮
      genderList: ['Mr.', 'Ms.', 'Mx.'],
      // country: 'United States',
      countryList: [],
      provinceList: [],
      countryTel: [{ code: '+1' }],
      phone1: {
        code: undefined,
        tel: undefined
      },
      phone2: {
        code: undefined,
        tel: undefined
      }
    }
  },
  computed: {
    // 显示输入框提示文本
    showLabel() {
      const id = this.info.address_id
      return function (val) {
        if (id !== 0) {
          return val
        }
        return ''
      }
    },
    selectedItem1: {
      get() {
        if (!this.phone1.code) {
          return 0
        }
        const index = this.countryTel.findIndex(item => {
          return item.code === this.phone1.code
        })
        if (index === -1) {
          return this.countryTel.length - 1
        }
        return index
      },
      set(value) {
        if (this.countryTel[value].code !== '') {
          this.phone1.code = this.countryTel[value].code
        } else {
          this.phone1.code = this.countryTel[value].name
        }
      }
    }
  },
  watch: {
    show(newValue) {
      this.dialog = newValue
      if (newValue) {
        this.getCountryList()
        this.getCountryCode()
        this.dataInfo = { ...this.info }
        // this.$refs.address.reset()
      } else {
        this.init()
      }
    },
    info: {
      handler() {
        this.dataInfo = { ...this.info }
        // 号码分割------------------------------
        if (this.info.phone) {
          const phoneArr = this.info.phone.split(' ')
          if (phoneArr.length > 1) {
            this.phone1.code = phoneArr[0]
            this.phone1.tel = phoneArr[1]
          }
        }
      },
      deep: true,
      immediate: true
    },
    // 切换省
    'dataInfo.country': {
      handler(val, old) {
        if (val && val !== old) {
          this.getStateList(val)
        }
      }
    }
  },
  mounted() {
    // this.getCountryList()
    // this.getCountryCode()
  },
  methods: {
    handleInput(event) {
      const filteredValue = this.phone1.tel.replace(/[^0-9-]/g, '')
      this.$nextTick(() => {
        this.phone1.tel = filteredValue
      })
    },
    // 获取国家
    getCountryList() {
      this.$api.userCenter.getCountryList().then(res => {
        if (res.code === 200) {
          this.countryList = res.data
        }
      })
    },
    // 获取国家号段
    getCountryCode() {
      this.$api.userCenter.getCountryCode().then(res => {
        if (res.code === 200) {
          this.countryTel = res.data
        }
      })
    },
    // 获取省列表
    getStateList(val) {
      this.$api.userCenter.getProvinceList({ country: val }).then(res => {
        if (res.code === 200) {
          this.provinceList = res.data
        }
      })
    },
    close() {
      this.$emit('close')
    },
    addressSave() {
      if (!this.$refs.address.validate()) {
        this.$tips({ msg: 'Please fill in required items!' })
        return false
      }
      if (!this.phone1.code) {
        this.phone1.code = '+1'
      }
      this.$set(this.dataInfo, 'address_id', this.dataInfo.id)
      this.dataInfo.phone = `${this.phone1.code} ${this.phone1.tel}`
      this.$emit('save', this.dataInfo)
      this.close()
    },
    init() {
      // this.dataInfo = {
      //   gender: undefined,
      //   firstname: undefined,
      //   lastname: undefined,
      //   country: undefined,
      //   address: undefined,
      //   apt_suite: undefined,
      //   city: undefined,
      //   zip_code: undefined,
      //   state: undefined,
      //   phone: undefined,
      //   backup_phone: undefined,
      //   is_primary: undefined,
      //   address_id: 0
      // }
      // this.phone1= {
      //   code: undefined,
      //   tel: undefined
      // }
      // this.phone2= {
      //   code: undefined,
      //   tel: undefined
      // }
      Object.assign(this.$data, this.$options.data())
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-box ::v-deep .v-dialog__content {
  z-index: 10001 !important;
}
::v-deep .v-dialog {
  width: 650px;
  // height: 90%;
  margin: 0 auto;
  overflow-y: hidden;
  background: #fff;
}
.dialog-body {
  font-size: 16px;
  // height: 100%;
  height: 78vh;
}
::v-deep .v-toolbar {
  height: 50px;
}
::v-deep .v-rating {
  margin-left: -8px;
}
::v-deep .v-sheet.v-toolbar {
  box-shadow: none !important;
}
::v-deep .v-rating .v-icon {
  padding: 5px !important;
}
.input-item {
  ::v-deep .v-input__control {
    flex-wrap: nowrap;
  }
}
.content {
  height: 88%;
  overflow-y: auto;
  padding: 0 50px;
}
.item-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  ::v-deep label.error--text:not(.v-label--active) {
    top: 6px;
  }
  .item {
    height: 44px;
    line-height: 44px;
    flex: 1;
  }
  .gender-item {
    height: 44px;
    line-height: 44px;
    width: 120px;
    flex-shrink: 0;
  }
  &.name {
    .item:nth-child(2) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  &.city {
    .item:nth-child(1) {
      margin-right: 20px;
    }
  }
}
.label-item {
  display: flex;
  height: 100%;
  line-height: inherit;
  .label-left,
  .country-box {
    display: inline-block;
    flex-shrink: 0;
    width: 113px;
    height: 100%;
    line-height: inherit;
    font-size: 14px;
    color: #a3a7ad;
    text-align: center;
    border-top: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    background: #fff;
  }
  .label-left {
    background: #f0f0f0;
  }
  .currency-left {
    margin-right: 5px;
  }
  .currency-img {
    height: 15px;
  }
}
.tips {
  font-size: 14px;
  color: #909399;
  line-height: 21px;
}
.btn-box {
  margin-top: 10px;
  margin-bottom: 55px;
  width: 200px;
  height: 40px;
  .btn-option {
    color: #fff !important;
    height: 100% !important;
    border: 0;
    background: #3067f2 !important;
  }
}
.country-box {
  ::v-deep .v-menu__content {
    max-height: 280px;
    background: #fff;
  }
}
.currency-current {
  display: flex;
  align-items: center;
  justify-content: center;
  .currency-img {
    margin-right: 5px;
  }
}
.item .input-item ::v-deep .v-input__slot fieldset,
.gender-item .input-item ::v-deep .v-input__slot fieldset {
  border: 1px solid #cccccc !important;
}
::v-deep .v-text-field--outlined .v-label.v-label--active {
  top: 18px;
}
::v-deep .v-text-field--outlined .v-label {
  top: 12px;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1280px) {
  ::v-deep .v-dialog {
    height: 90%;
  }
  .dialog-body {
    font-size: 16px;
    // height: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 960px) {
  .v-dialog__content ::v-deep .v-dialog {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
  .content {
    padding: 0 16px;
  }
  .item-row {
    ::v-deep label.error--text:not(.v-label--active) {
      top: 6px;
    }
    &.name .item {
      &:nth-child(2) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    &.city .item {
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
  .btn-box {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 390px) {
  .item-row ::v-deep .v-text-field input {
    font-size: 14px;
  }
}
</style>
