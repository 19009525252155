<!-- 手机端nav菜单 -->
<template>
  <div class="drawer-box">
    <LmobileNav />
    <Nuxt />
  </div>
</template>

<script>
// import { hideNav, showNav } from '@/assets/utils/header'
// import { hideNav, showNav } from '@/assets/utils/header'

import LmobileNav from '@/components/Lnav/LmobileNav/index.vue'
export default {
  name: 'control',
  components: {
    LmobileNav
  },
  scrollToTop: true
  // created() {
  //   if (process.client) {
  //     hideNav()
  //   }
  // },
  // // mounted() {
  // //   hideNav()
  // // },
  // beforeDestroy() {
  //   showNav()
  // }
}
</script>
<style>
.v-main ::v-deep {
  position: relative;
}
</style>
<style lang="scss" scoped>
.drawer-box {
  position: fixed;
  z-index: 3;
  width: 100%;
  top: 0;
  background: #fff;
  & > div:last-child {
    margin-top: 58px;
  }
  .logo_mobile {
    // background: #f0f2f4;
    font-size: 0;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 58px;
    img {
      width: 121px;
      // height: 21px;
    }
  }
  // .expansion-content {
  //   font-size: 14px;
  // }
}

// .v-expansion-panel-header {
//   padding: 0 16px;
//   font-size: 16px;
// }
// .v-expansion-panels .v-expansion-panel {
//   background: #fff;
//   color: #000;
// }
// ::v-deep .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
//   color: #000;
// }
// ::v-deep .v-expansion-panel-content__wrap {
//   padding: 0 16px;
// }
</style>
