<template>
  <div class="listMenu">
    <p v-for="item in list" :key="item.id" class="item" :class="{ active: item.id == current }" @click="getContent(item.id)">{{ item.title }}</p>
  </div>
</template>

<script>
export default {
  name: 'listMenu',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: 0
    }
  },
  computed: {},
  watch: {
    '$route.params.articles': {
      handler(val) {
        this.setActive(val)
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    getContent(id) {
      this.current = id
      this.$emit('change', id)
      // let move = 0
      // if (this.$vuetify.breakpoint.name !== 'xs') {
      //   move = 500
      // } else {
      //   move = 0
      // }
      // console.log(move)
      // window.scrollTo({
      //   top: move,
      //   behavior: 'smooth'
      // })
      // document.getElementsByClassName('banner')[0].scrollIntoView(true)
    },
    setActive(val) {
      const list = this.list
      const index = list.findIndex(item => {
        return item.id === val
      })
      if (index > -1) {
        this.current = list[index].id
      } else {
        this.current = list[0].id
      }
    }
    // setActive() {
    //   const faq = this.$route.params.articles.split('-').join('')
    //   const list = document.querySelectorAll('.listMenu')
    //   for (let i = 0; i < list.length; i++) {
    //     list[i].classList.remove('active')
    //     if (list[i].innerText.split('/').join('').split('?').join('').split(' ').join('').split(',').join('') === faq) {
    //       debugger
    //       console.log('--------------')
    //       list[i].classList.add('active')
    //       return false
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.listMenu {
  margin-right: 15px;
  flex-shrink: 0;
  width: 332px;
  height: min-content;
  position: sticky;
  top: 100px;
  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    margin-bottom: 0;
    color: #5c626a;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .item.active {
    background: #3067f2;
    color: #fff;
  }
  .item:hover {
    background: #3067f2;
    color: #fff;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .listMenu {
    width: 100%;
    margin-right: 0;
    position: relative;
    top: 0;
    height: auto;
    .item:hover {
      background: #fff;
      color: #5c626a;
    }
  }
}
</style>
