<template>
  <div class="navType">
    <div class="nav-munu">
      <div class="back" @click="$router.back()"><img src="/imgs/back.png" alt="back" /></div>
      <div class="nav-name">{{ $route.params.type }}</div>
    </div>
    <div class="list-box">
      <p class="all-tag" @click="toList()">All {{ $route.params.type.toUpperCase() }}</p>
      <v-expansion-panels v-model="panelOpen" accordion>
        <v-expansion-panel v-if="list.rim">
          <v-expansion-panel-header>SHOP BY TYPE</v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="expansion-content">
              <p v-for="item in list.rim" :key="item.id" class="item-line" @click="toList('rim', item.id)">{{ item.name }}</p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="list.material">
          <v-expansion-panel-header>SHOP BY MATERIAL</v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="expansion-content">
              <p v-for="item in list.material" :key="item.id" class="item-line" @click="toList('material', item.id)">{{ item.name }}</p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="list.color">
          <v-expansion-panel-header>SHOP BY COLOR</v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="expansion-content">
              <div class="color-box">
                <div v-for="item in list.color" :key="item.id" class="color-list" @click="toList('color', item.id)">
                  <p class="color-item">
                    <span class="color-text" :style="{ background: `url(${item.icon})` }"></span>
                  </p>
                  <p class="right">{{ item.name }}</p>
                </div>
              </div>

              <!-- <p v-for="item in list.color" :key="item.id" class="item-line">{{ item.name }}</p> -->
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="list.shape">
          <v-expansion-panel-header>SHOP BY SHAPE</v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="expansion-content">
              <p v-for="item in list.shape" :key="item.id" class="item-line" @click="toList('shape', item.id)">{{ item.name }}</p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-if="list.genders" class="img-type">
        <div v-for="item in list.genders" :key="item.id" class="img-list" @click="toList('genders', item.id)">
          <img :src="`/imgs/${renderImg(item.name)}`" :alt="item.name" />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navType',
  data() {
    return {
      panelOpen: undefined,
      list: {}
    }
  },
  computed: {
    renderImg() {
      return function (type) {
        if (type === 'Men') {
          return 'icon-men.png'
        } else if (type === 'Women') {
          return 'icon-women.png'
        } else if (type === 'Kids') {
          return 'icon-kids.png'
        }
      }
    }
  },
  mounted() {
    this.getType()
  },
  methods: {
    toList(type, id) {
      let typeTop = ''
      if (this.$route.params.type === 'Eyeglasses') {
        typeTop = 'eyeglasses'
      } else if (this.$route.params.type === 'Sunglasses') {
        typeTop = 'sunglasses'
      } else {
        typeTop = this.$route.params.type
      }
      if (type) {
        const query = {}
        const filter = encodeURI(JSON.stringify({ [type]: [id] }))
        query.filter = filter
        this.$router.push({
          name: 'search-type',
          params: {
            type: typeTop
          },
          query
        })
        // this.$router.push({
        //   name: 'search-type',
        //   params: {
        //     type: typeTop
        //   },
        //   query: {
        //     cut: type,
        //     id
        //   }
        // })
      } else {
        this.$router.push({
          name: 'search-type',
          params: {
            type: typeTop
          }
        })
      }
    },
    async getType() {
      const { data: typeList } = await this.$api.system.getGoodsType({
        type: 'All'
      })
      this.list = typeList
    }
  }
}
</script>

<style lang="scss" scoped>
.navType {
  font-size: 18px;
  margin-top: 57px;
  .nav-munu {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 58px;
    font-weight: 500;
    color: #2b2e33;
    padding: 0 10px;
    position: relative;
    background: #f0f2f4;
    .back {
      position: absolute;
      left: 18px;
      width: 40px;
      img {
        width: 10px;
        height: 18px;
      }
    }
    .nav-name {
      text-align: center;
      font-size: 17px;
      font-weight: 600;
      color: #2b2e33;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
  .list-box {
    .item-line {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #dde0e3;
      font-size: 14px;
      font-weight: 400;
      color: #525861;
      margin-bottom: 0;
    }
  }
  .all-tag {
    font-size: 16px;
    margin-bottom: 0;
    padding: 11px 10px;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
  }
  .v-expansion-panel-header {
    padding: 0 10px;
    font-size: 16px;
  }
  .v-expansion-panel-header--active {
    color: #3067f2;
  }
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 16px;
  }
  .color-box {
    display: flex;
    flex-wrap: wrap;
  }
  .color-list {
    display: flex;
    width: 50%;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    .right {
      margin-bottom: 0;
      color: #525861;
    }
  }
  .color-item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    padding: 3px;
    margin-right: 5px;
    margin-bottom: 0;
    border-radius: 50%;
    cursor: pointer;
  }
  .color-text {
    display: inline-block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  .color-item {
    border: 3px solid #e7e7e7;
  }
  .img-type {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 0 16px;
    .img-list {
      text-align: center;
      img {
        width: 110px;
        height: 110px;
        margin-bottom: 10px;
      }
      p {
        color: #fff;
        margin-bottom: 20px;
      }
      &:nth-child(1) p {
        text-shadow: 0px 0px 1px #3067f2, 2px 2px 2px #3067f2, -2px 2px 4px #3067f2, 3px -1px 4px #3067f2, 3px 3px 2px #3067f2, -1px -2px 2px #3067f2, 3px 3px 2px #3067f2;
      }
      &:nth-child(2) p {
        text-shadow: 0px 0px 1px #ffc600, 2px 2px 2px #ffc600, -2px 2px 4px #ffc600, 3px -1px 4px #ffc600, 3px 3px 2px #ffc600, -1px -2px 2px #ffc600, 3px 3px 2px #ffc600;
      }
      &:nth-child(3) p {
        text-shadow: 0px 0px 1px #28ad00, 2px 2px 2px #28ad00, -2px 2px 4px #28ad00, 3px -1px 4px #28ad00, 3px 3px 2px #28ad00, -1px -2px 2px #28ad00, 3px 3px 2px #28ad00;
      }
    }
  }
}
</style>
