<template>
  <div class="cropper-box">
    <div class="cropper-content">
      <div class="edit-box cropper">
        <input v-if="status" id="uploadFileInput" type="file" accept="image/png,image/jpeg" @change="upload" />
        <vueCropper
          v-else
          ref="cropper"
          :img="option.img"
          :output-size="option.size"
          :output-type="option.outputType"
          :info="true"
          :can-scale="option.canScale"
          :full="option.full"
          :can-move="option.canMove"
          :can-move-box="option.canMoveBox"
          :original="option.original"
          :auto-crop="option.autoCrop"
          :fixed="option.fixed"
          :fixed-number="option.fixedNumber"
          :center-box="option.centerBox"
          :info-true="option.infoTrue"
          :fixed-box="option.fixedBox"
          :mode="option.mode"
        />
        <div v-if="coverDom" class="cover">
          <v-progress-circular class="loaidng-com" indeterminate color="#3067f2" />
        </div>
      </div>
      <div class="option-box">
        <p class="tips">Please select a picture width your face forward, centered.level, and no glasses.</p>
        <div class="btnGroup">
          <v-btn block tile depressed @click="close">Cancel</v-btn>
          <v-btn block tile depressed color="#3067f2" :disabled="loading" :loading="loading" class="blue-btn" @click="finish">Submit</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 动态设置缩放比
function getBoxInfo(w1, h1, w2, h2) {
  // const box = document.querySelector('.cropper-view-box>img')
  // const boxWidth = box.clientWidth
  // const boxHeight = box.clientHeight
  // console.table({
  //   w1,
  //   h1,
  //   w2,
  //   h2
  // })
  // 缩放比----------------------------------
  const scaleX = w1 / w2
  const scaleY = h1 / h2
  return {
    x: scaleX.toFixed(6),
    y: scaleY.toFixed(6)
  }
}
// https://github.xyxiao.cn/vue-cropper/
export default {
  name: 'cropperImg',
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      status: true,
      coverDom: false,
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [this.config.width, this.config.height], // 截图框的宽高比例
        autoCropWidth: '90%',
        autoCropHeight: '90%',
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: 'contain' // cover contain  图片铺满容器
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.uploadFile()
    // this.$refs.cropper.coe = 1
  },
  methods: {
    close() {
      this.$emit('close')
      // this.$parent.uploadShow = false
    },
    // 触发 input：file
    uploadFile() {
      try {
        document.querySelector('#uploadFileInput').click()
      } catch (e) {}
    },
    // 点击上传按钮后的事件
    upload(e) {
      // e.target.files.length === 0
      const file = e.target.files[0]
      if (!file.type.includes('image')) {
        this.$tips({ msg: 'Please select a file of picture type' })
        return false
      }
      const self = this
      this.createReader(file, function (w, h) {
        // if (w > self.config.width || h > self.config.height) {
        //   //   self.$message.error('请上传宽度大于 1200px，高度大于 240px 的封面图片。')
        //   const UFI = document.getElementById('uploadFileInput')
        //   UFI.value = ''
        // } else {
        //   self.file2base64(file)
        // }
        self.coverDom = true
        self.file2base64(file)
      })
    },
    // 获取图片宽高
    createReader(file, whenReady) {
      const reader = new FileReader()
      reader.onload = function (evt) {
        const image = new Image()
        image.onload = function () {
          const width = this.width
          const height = this.height
          if (whenReady) {
            whenReady(width, height)
          }
        }
        image.src = evt.target.result
      }
      reader.readAsDataURL(file)
    },
    // file类型文件 转base64文件类型
    file2base64(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      const self = this
      reader.onload = function (e) {
        // 图片base64化
        const newUrl = this.result // 图片路径
        self.$nextTick(() => {
          self.option.img = newUrl
          self.status = false
          setTimeout(() => {
            const img = new Image()
            img.src = newUrl
            img.onload = function () {
              self.$nextTick(() => {
                self.$refs.cropper.scale = getBoxInfo(self.config.width * 0.9, self.config.height * 0.9, img.naturalWidth, img.naturalHeight).x
                self.coverDom = false
                // console.log(self.$refs.cropper.scale)
                // setTimeout(() => {
                //   self.$refs.cropper.scale = getBoxInfo(self.config.width * 0.9, self.config.height * 0.9, img.naturalWidth, img.naturalHeight).x
                //   self.coverDom = false
                // }, 1000)
              })
            }
          }, 1200)
        })
      }
    },
    // 点击确定，这一步是可以拿到处理后的地址, 然后上传到服务器
    finish() {
      if (!this.$refs.cropper) {
        this.uploadFile()
        return false
      }
      this.$refs.cropper.getCropBlob(data => {
        // 将接收到blod文件对象转为file
        const file = new File([data], 'proFileCover.jpg', { type: data.type, lastModified: Date.now() })
        const params = new FormData()
        params.append('file', file)
        // console.log(params.get('image'))
        this.$emit('file', params)
        // const self = this
        // self.$axios.post('upload_image', params).then(res => {
        //   if (res.data.code === 0) {
        //     //  显示图片
        //     self.pageImage = res.data.data
        //     // 关闭隐藏div,显示图片
        //     self.status = false
        //   }
        // })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cropper-box {
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  top: 0;
  bottom: 0;
  z-index: 10;
}
.cover {
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
.loaidng-com.v-progress-circular {
  margin: 40% auto 0;
}
.cropper-content {
  width: 100%;
  height: 100%;
}
.cropper {
  position: relative;
  width: 100%;
  height: 380px;
}
.vue-cropper {
  background-repeat: repeat;
}
.option-box {
  padding: 0 16px;
  .tips {
    margin: 6px 0;
  }
}
.btnGroup {
  margin: 0 auto;
  .blue-btn {
    color: #fff !important;
    margin-top: 10px;
  }
}
#uploadFileInput {
  position: absolute;
  z-index: -100;
  opacity: 0;
}
</style>
