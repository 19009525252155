<template>
  <!-- 首页 展示墙 -->
  <div class="body-page">
    <div class="content-box photo-wall">
      <h2 class="mode-title">{{ title }}</h2>
      <p class="title-introduction">Highlight Your Personal Style with Stylool Glasses</p>
      <v-container v-if="list.length >= 9" fluid class="photo-box">
        <v-container fluid>
          <v-row>
            <v-col class="item" cols="6" sm="4">
              <div class="box" @mouseover="overDom = 0" @mouseleave="overDom = null" @click="getShow(list[0].id)">
                <v-img class="main-img" :src="list[0].image" lazy-src="/imgs/imgLazy.jpg" aspect-ratio="1" position="top center" />
                <p class="show-goods" :style="{ bottom: overDom == 0 ? '0' : '-32px' }">
                  <!-- <nuxt-link :to="`/details/${list[0].code}`">Read This Review</nuxt-link> -->
                  Read This Review
                </p>
              </div>
            </v-col>
            <v-col class="item" cols="6" sm="4">
              <v-row class="secondary-box">
                <v-col v-for="item in list.slice(1, 5)" :key="item.code" class="item" cols="6">
                  <div class="box" @mouseover="overDom = item.code" @mouseleave="overDom = null" @click="getShow(item.id)">
                    <v-img class="secondary-img" :src="item.image" lazy-src="/imgs/imgLazy.jpg" aspect-ratio="1" position="top center" />
                    <p class="show-goods" :style="{ bottom: overDom == item.code ? '0' : '-32px' }">
                      <!-- <nuxt-link :to="`/details/${item.code}`">Read This Review</nuxt-link> -->
                      Read This Review
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="item" cols="12" sm="4">
              <v-row class="secondary-box2">
                <v-col v-for="(item, index) in list.slice(5, 9)" :key="item.code" class="item" :class="`end-${index + 1}`" cols="3" sm="6">
                  <div class="box" @mouseover="overDom = item.code" @mouseleave="overDom = null" @click="getShow(item.id)">
                    <v-img class="secondary-img" :src="item.image" lazy-src="/imgs/imgLazy.jpg" aspect-ratio="1" />
                    <p class="show-goods" :style="{ bottom: overDom == item.code ? '0' : '-32px' }">
                      <!-- <nuxt-link :to="`/details/${item.code}`">Read This Review</nuxt-link> -->
                      Read This Review
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
      <preview :show="dialog" :current-id="currentId" />
    </div>
  </div>
</template>

<script>
import preview from '@/components/Lhome/Lphotowall/preview'
export default {
  name: 'LphotoWall',
  components: {
    preview
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      overDom: null, // 当前悬浮元素下标
      dialog: false,
      currentId: undefined
    }
  },
  methods: {
    getShow(id) {
      this.currentId = id
      this.dialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.body-page {
  padding-top: 70px;
  padding-bottom: 70px;
  overflow: hidden;
  background: #f7f8fa;
}
.mode-title {
  // margin: 70px 0 30px;
  margin-bottom: 25px;
}
.title-introduction {
  text-align: center;
  margin-bottom: 30px;
  color: #000;
}
.v-tab {
  text-transform: none;
  font-size: 18px;
}
.photo-box ::v-deep .v-tabs-items {
  margin-top: 40px;
  background: transparent;
}
.photo-box .item {
  padding: 0 !important;
}
.main-img {
  // height: 20.2vw;
  // padding-bottom: 18px;
  ::v-deep .v-responsive__sizer {
    padding-bottom: calc(100% - 18px) !important;
  }
}
.secondary-box > .item > div {
  margin: 0 0 18px 18px;
}
// .secondary-img {
//   // height: 9.63vw;
// }
// .secondary-box2 > .item > div {
//   padding: 0 0 18px 18px;
// }
.end-1 > div,
.end-2 > div,
.end-3 > div,
.end-4 > div {
  margin: 0 0 18px 18px;
}
.box {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.show-goods {
  width: 100%;
  height: 32px;
  color: #fff !important;
  line-height: 32px;
  position: absolute;
  bottom: -32px;
  text-align: center;
  margin-bottom: 0;
  background: rgb(48, 103, 242, 0.35);
  cursor: pointer;
  transition: bottom 0.3s;
  a {
    color: #fff !important;
    text-decoration: none;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 600px) {
  .v-tab {
    font-size: 17px;
  }
  .content-box {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
  }
  .mode-title {
    margin-bottom: 20px;
  }
  .photo-box ::v-deep .v-tabs-items {
    margin-bottom: 30px;
  }
  .body-page {
    padding-top: 48px;
    padding-bottom: 48px;
    background: #fff !important;

    .main-img {
      height: 165px !important;
    }
    .secondary-box > .item > div {
      margin: 0 0 9px 9px !important;
    }
    .end-1 > div {
      margin: 0 4.5px 0 0 !important;
    }
    .end-2 > div {
      margin: 0 0 0 4.5px !important;
    }
    .end-3 > div {
      margin: 0 0 0 9px !important;
    }
    .end-4 > div {
      margin: 0 0 0 9px !important;
    }
    .secondary-img {
      height: 78px !important;
    }
  }
}
</style>
