<template>
  <div class="body-page">
    <div class="step-box" :style="{ justifyContent: step.currentStep === 1 ? 'center' : 'space-between' }">
      <img v-show="step.currentStep !== 1" class="pre" src="/imgs/back.png" alt="pre" @click="control('pre')" />
      <div class="control">
        <h3 class="step-title">
          {{ title }}
          <template v-if="title == 'Add ones'">
            <div class="tips-box" @mouseenter="tipsState = true" @mouseleave="tipsState = false">
              <img class="tips-icon" src="/imgs/question_orange.png" alt="title-tips" />
              <titleTips v-show="tipsState" />
            </div>
          </template>
        </h3>
        <v-stepper v-model="step.currentStep" class="step-control" tile flat outlined>
          <v-stepper-header>
            <!-- <v-stepper-step color="#3067F2" :complete="step > 1" step="1" />
            <v-divider />
            <v-stepper-step color="#3067F2" :complete="step > 2" step="2" />
            <v-divider />
            <v-stepper-step color="#3067F2" step="3" /> -->
            <template v-for="n in step.size">
              <v-stepper-step :key="`${n}-step`" color="#3067F2" :complete="step.currentStep > n" :step="n" />
              <v-divider v-if="n !== step.size" :key="n" />
            </template>
          </v-stepper-header>
        </v-stepper>
      </div>
      <img v-show="step.currentStep !== 1" class="next" src="/imgs/back.png" alt="next" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleTips from '@/components/Lproduct/dialogs/titleTips'
export default {
  name: 'controlCom',
  components: {
    titleTips
  },
  data() {
    return {
      tipsState: false
    }
  },
  computed: {
    ...mapState({
      step: state => state.productConfig.step,
      title: state => {
        const titleList = state.productConfig.step.flowList
        if (titleList.length) {
          return titleList[titleList.length - 1].title
        } else {
          return state.productConfig.step.title
        }
      }
    })
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // pre naxt  控制进退
    control(type) {
      if (this.$route.query.type === 'edit') {
        // 储存流程信息(过程)
        this.$store.commit('productConfig/insetFirstFlowList', {
          name: 'lens_use',
          title: 'Prescription Type',
          end: false
        })
      }
      this.$store.commit('productConfig/controlStep', type)
    }

    // pre() {
    //   if (this.step > 1) {
    //     this.$emit('preStep')
    //   }
    // },
    // next() {
    //   if (this.step < this.size) {
    //     this.$emit('nextStep')
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.step-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 776px;
  height: 88px;
  margin: 0 auto;
}
.pre {
  cursor: pointer;
}
.pre,
.next {
  width: 14px;
  height: 22px;
}
.next {
  transform: rotate(180deg);
}
.control {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  .step-title {
    font-size: 30px;
    color: #2b2e33;
    position: relative;
  }
  .tips-box {
    position: absolute;
    top: 0;
    right: -48px;
  }
  .tips-icon {
    height: 18px;
    cursor: pointer;
  }

  .step-control {
    width: 377px;
    height: 26px;
    margin: 0 auto;
    background: #fff;
    ::v-deep .v-stepper__header {
      height: 100%;
      box-shadow: none;
    }
    ::v-deep .v-stepper__step {
      padding: 0;
    }
    &.v-sheet--outlined {
      border: none;
    }
    .v-stepper--flat {
      box-shadow: none;
    }
    ::v-deep .v-stepper__step__step {
      margin-right: 0;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 600px) {
  .step-box {
    width: 100%;
    padding: 0 16px;
  }
  .control {
    .step-title {
      color: #2b2e33;
      font-size: 17px;
    }
    .step-control {
      width: 100%;
    }
    .tips-box {
      top: 4px;
    }
  }
}
</style>
