<template>
  <div class="nav-menu">
    <div class="logo_mobile">
      <img src="/imgs/logo.png" alt="logo" @click="$router.replace('/')" />
    </div>
    <LmobileMenu />
  </div>
</template>

<script>
import LmobileMenu from '@/components/Lnav/LmobileMenu/index.vue'
export default {
  name: 'control',
  components: {
    LmobileMenu
  }
}
</script>

<style lang="scss" scoped>
.nav-menu {
  .logo_mobile {
    background: #f0f2f4;
    font-size: 0;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 58px;
    img {
      width: 121px;
      // height: 21px;
    }
  }
}
</style>
