<template>
  <div class="item">
    <div class="item-header"><span>③</span>Dedit Statement</div>
    <div class="item-content">
      <div class="search-body">
        <div class="body-header">Check the credit statement:</div>
        <div class="body-option-box">
          <div class="body-option">
            <v-select v-model="creditCurrentMonth" class="input-item" :items="MonthList" color="#3067f2" outlined hide-details="auto">
              <template #prepend-inner>Month:</template>
            </v-select>
          </div>
          <div v-if="yearList.length" class="body-option">
            <v-select v-model="creditCurrentyear" class="input-item" :items="yearList" color="#3067f2" outlined hide-details="auto">
              <template #prepend-inner>Year:</template>
            </v-select>
          </div>
          <div class="btn-box">
            <v-btn class="my-btn btn-option" tile depressed block> Submit</v-btn>
          </div>
        </div>
      </div>
      <div class="res-body">
        <div class="body-header">Details of the last 3 months:</div>
        <table class="table-list">
          <thead>
            <tr>
              <td>Consumption Time</td>
              <td>Consumption Amount</td>
              <td>Consumption Channel</td>
              <td>Commission Source</td>
            </tr>
          </thead>
          <tbody>
            <template v-if="!list.length">
              <tr>
                <td class="item" colspan="4">No more data</td>
              </tr>
              <tr>
                <td class="item" colspan="4">In the query</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'statementTab',
  data() {
    return {
      tab: 0,
      list: [],
      creditCurrentMonth: '',
      MonthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      creditCurrentyear: '',
      yearList: []
    }
  },
  computed: {},
  watch: {},
  created() {
    this.yearList = this.renderYear()
  },
  mounted() {},
  methods: {
    renderYear() {
      const currentYear = new Date().getFullYear()
      const year = []
      for (let index = 0; index < 10; index++) {
        year.push(currentYear - index)
      }
      return year
    }
  }
}
</script>

<style lang="scss" scoped>
.item-header {
  font-size: 14px;
  color: #2b2e33;
  padding: 0 12px;
  height: 40px;
  line-height: 40px;
  background: #f5f5f5;
  span {
    position: relative;
    top: -1px;
  }
}
.item-content {
  padding: 10px 15px;
  border: 1px solid #f5f5f5;
  background: #fff;
  .body-header {
    font-size: 14px;
    color: #2b2e33;
    line-height: 21px;
  }
  .search-body {
    .body-option-box {
      display: flex;
      align-items: center;
      height: 38px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .body-option {
      width: 180px;
      height: 100%;
      margin-right: 10px;
    }
    .input-item ::v-deep .v-input__prepend-inner {
      margin-top: 11px;
    }
    .input-item ::v-deep .v-input__slot .v-input__append-inner {
      margin-top: 8px !important;
    }
    .input-item ::v-deep .v-input__slot fieldset {
      border: 1px solid #d8d8d9 !important;
    }
    .btn-box {
      width: 100px;
      height: 100%;
      .btn-option {
        color: #fff !important;
        height: 100% !important;
        border: 0;
        background: #222426 !important;
      }
    }
  }
}
.table-list {
  width: 100%;
  font-size: 14px;
  border: 1px solid #dcdfe5;
  border-collapse: collapse;
  margin-top: 15px;
}
.table-list > thead {
  tr {
    color: #525861;
    font-size: 14px;
    font-weight: 400;
    background: #eeeeef;
    border-bottom: 1px solid #dcdfe5;
  }
  td {
    padding: 5px 12px;
    border-right: 1px solid #dcdfe5;
  }
}
.table-list > tbody {
  tr {
    background: #eeeeef;
    border-bottom: 1px solid #dcdfe5;
  }
  td {
    font-size: 14px;
    color: #525861;
    padding: 5px 12px;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .item-content {
    .search-body {
      .body-option-box {
        flex-direction: column;
        height: auto;
      }
      .body-option {
        width: 100%;
        height: 38px;
        margin-right: 0;
        margin-bottom: 8px;
      }
      .btn-box {
        width: 100%;
        height: 38px;
      }
    }
  }
}
@media screen and (max-width: 495px) {
  .table-list {
    thead td {
      padding: 0;
      font-size: 12px;
      text-align: center;
    }
  }
}
</style>
