<template>
  <div class="content">
    <div class="item">
      <h6 class="title-tips">Anti-reflective Coating</h6>
      <p>
        Super hydrophobic coating. An AR coating is a layered metal oxide coating applied to both sides of the lens that greatly reduces both the internal and external the reflections on the lens surface while allowing
        more light through the lens.
      </p>
    </div>
    <div class="item">
      <h6 class="title-tips">Super Hydrophobic Coating</h6>
      <p>Super Hydrophobic coatings have all the properties of a regular AR coating, plus the added benefit of a water resistant coating that repels dirt and water, and is easier to clean.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'titleTips',
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  z-index: 5;
  width: 468px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e5e7ec;
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.05);
}
.item {
  &:nth-of-type(1) {
    margin-bottom: 24px;
  }
  h6 {
    font-size: 16px;
    color: #2b2e33;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    color: #525861;
    line-height: 21px;
    margin-bottom: 0;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 600px) {
  .content {
    left: -205px;
    width: 280px;
  }
}
</style>
