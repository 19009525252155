<template>
  <v-dialog :value="value" class="dialogs-com" persistent>
    <div class="dialog-body">
      <div class="dialog-header d-flex">
        <v-spacer />
        <v-btn icon dark @click="closeDialog">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="content-detail">
        <template v-if="type === 'Free_Shipping'">
          <p class="tip-title">Free Standard Shipping over US$69</p>
          <p class="item">1. Order total over US$69 (US/CA/AU/JP/GB/DE/FR).</p>
          <p class="item">2. Limited to standard shipping.</p>
          <p class="item">3. Automatically apply without coupon code.</p>
        </template>
        <template v-if="type === 'Frames_OFF'">
          <p class="tip-title">Buy 1 Get Other Frames 50% OFF</p>
          <p class="item">1. Please select at least two frames, 50% off is automatically applied to the rest of frames that have a lower price.</p>
          <p class="item">2. Not eligible for frames under USD $8.00.</p>
          <p class="item">3. Two is Better Than One.</p>
        </template>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'tipsDialog',
  props: {
    value: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  watch: {
    show(newValue) {
      this.dialog = newValue
    }
  },
  methods: {
    closeDialog() {
      // this.$parent.sizeDialogs = false
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog {
// }
.dialogs-com,
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  background: #fff;
  margin: 0 auto;
}
.v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  width: 416px;
}
::v-deep .v-toolbar {
  height: 50px;
}
.content-detail {
  padding: 0 16px 16px 16px;
  // min-height: 375px;
  background: #fff;
  .tip-title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
  }
  .item {
    word-break: break-word;
    margin-bottom: 4px;
    font-size: 14px;
    color: #373535;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    width: 95%;
  }
}
</style>
