<template>
  <transition name="confirm">
    <div v-if="show" class="confirm">
      <div class="confirm-body">
        <div class="confirm-title">{{ config.title }}</div>
        <div class="confirm-content">{{ config.msg }}</div>
        <div class="confirm-option">
          <button class="cancel-btn" type="button" @click="cancel">Cancel</button>
          <button class="confirm-btn" type="button" @click="confirm">Confirm</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'conFirm',
  data() {
    return {
      config: { msg: 'Are you sure you want to continue?', title: 'Please Confirm' },
      show: false
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    cancel() {},
    confirm() {}
  }
}
</script>
<style lang="scss" scoped>
.confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000000000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.confirm-body {
  width: 468px;
  height: 190px;
  padding: 25px;
  position: fixed;
  top: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 2px #b7b2b2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.confirm-title {
  font-size: 22px;
  color: #272626;
  font-weight: 600;
}
.confirm-content {
  font-size: 18px;
  color: #585757;
}
.confirm-option {
  display: flex;
  justify-content: flex-end;
}
.confirm-option > button {
  outline: none;
  margin-left: 20px;
  width: 130px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
}
.cancel-btn {
  background: #fff;
}
.confirm-btn {
  color: #fff;
  background: #3067f2;
}
.confirm-enter,
.confirm-leave-to {
  position: fixed;
  transform: scale(0);
  opacity: 0;
}
.confirm-enter-active,
.confirm-leave-active {
  transition: 0.2s;
}

.confirm-enter-to {
  position: fixed;
  transform: scale(1);

  opacity: 1;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .confirm-body {
    width: 350px;
    height: 190px;
  }
  .confirm-title {
    font-size: 20px;
  }
  .confirm-content {
    font-size: 16px;
  }
  .confirm-option > button {
    width: 120px;
  }
}
</style>
