<template>
  <v-dialog :value="value" class="dialogs-com" persistent>
    <div class="dialog-body">
      <div class="dialog-header d-flex">
        <v-spacer />
        <v-btn icon dark @click="closeDialog">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="content-detail">
        <p class="tip-title">COVID-19 Notice:</p>
        <p class="item">Your order may experience delivery delay and the tracking information may not be updated timely.</p>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'tipDialog',
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  watch: {
    show(newValue) {
      this.dialog = newValue
    }
  },
  methods: {
    closeDialog() {
      // this.$parent.sizeDialogs = false
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog {
// }
.dialogs-com,
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  background: #fff;
  margin: 0 auto;
}
.v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  width: 406px;
}
::v-deep .v-toolbar {
  height: 50px;
}
.content-detail {
  padding: 0 16px;
  min-height: 120px;
  background: #fff;
  font-size: 14px;
}
.tip-title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}
.item {
  word-break: break-word;
  margin-bottom: 4px;
  font-size: 14px;
  color: #373535;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    width: 95%;
  }
}
</style>
