<template>
  <div class="center-body">
    <template v-if="list.length === 2">
      <div class="list">
        <div v-for="(item, indexTop) in list" :key="item.id" class="item">
          <div class="left">
            <div class="top">{{ item.description | textSplit }}</div>
            <div v-if="item.files.length" class="bottom">
              <img v-for="(img, index) in item.files" :key="index" class="img-item" :src="img" alt="question-photo" @click="look(indexTop, index)" />
            </div>
          </div>
          <div class="right">
            <div class="top">{{ item.create_time }}</div>
            <!-- <div class="bottom"></div> -->
          </div>
        </div>
      </div>
      <ticketDialog :show="showTicket" />
      <imgDialog :show="imgDiologs" :list="getGoodsimgList" :current="currentImgIndex" />
    </template>
    <template v-else>
      <div class="empty" style="height: 60vh"></div>
    </template>
  </div>
</template>

<script>
import ticketDialog from '@/components/toolCom/ticketDialog.vue'
import imgDialog from '@/components/Ldetails/components/imgDialog.vue'

export default {
  name: 'ticketsHistory',
  components: {
    ticketDialog,
    imgDialog
  },
  filters: {
    textSplit: function (value) {
      const splitLength = 60
      if (value.length > splitLength) {
        return value.substring(0, splitLength) + '...'
      }
      return value
    }
  },
  // 注入到提交问题组件
  // pages/centerMain/tickets/my-tickets
  provide() {
    return {
      info: () => this.currentData
    }
  },
  data() {
    return {
      showTicket: false,
      currentData: {},
      list: [],
      modelDailog: true,
      imgDiologs: false,
      currentImgIndex: 0,
      rowIndex: 0
    }
  },
  computed: {
    // 过滤出纯图片数组
    getGoodsimgList() {
      return this.list[this.rowIndex].files
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    look(indexTop, index) {
      this.rowIndex = indexTop
      this.currentImgIndex = index
      this.imgDiologs = true
    },
    getList() {
      this.$api.userCenter.contactlist().then(res => {
        if (res.code === 200) {
          this.list = res.data
        }
      })
    }
    // view(data) {
    //   this.currentData = data
    //   this.show = true
    // }
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #cecece;
  margin-bottom: 10px;
}
.item:hover {
  background: #f8f8f8;
}
.item:last-child {
  margin-bottom: 0;
}
.left {
  .top {
    font-size: 18px;
    font-weight: 600;
    word-break: break-word;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  .bottom {
    margin-top: 15px;
  }
}
.img-item {
  height: 50px;
  margin-right: 10px;
}
.right {
  .top {
    text-align: right;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .list {
    margin: 25px 0;
  }
  .item {
    padding: 10px;
  }
}
</style>
