<!-- 此处获取购物车 商品数据 -->
<template>
  <div class="list-body">
    <div class="header-count">
      <div class="cart-num">Shopping Cart({{ selectGoodsLength > 0 ? selectGoodsLength : 0 }})</div>
      <div v-show="list.length" class="select">
        <v-checkbox v-model="checkbox" class="input-item" :ripple="false" label="Select All" color="#3067F2" @change="setAllSelect" />
      </div>
    </div>
    <!-- <div class="header-count">Shopping Cart</div> -->

    <itemCom v-for="(item, index) in list" :key="item.id" :list="item" @changeSelect="changeSelect($event, index)" @numChange="numChange($event, index)" @remove="remove(index)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import itemCom from '@/components/LshoppingCart/item'
import { add } from '@/assets/utils/decimal'
export default {
  name: 'listCom',
  components: {
    itemCom
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkbox: false, // 全选,
      selectGoodsLength: 0
      // flagShow: 0 // 回显记录 0执行  1已完成
    }
  },

  computed: {
    // https://wanghi.cn/202003/12539.html
    // cashify  汇率问题
    ...mapGetters('system', ['currency'])
    // https://blog.csdn.net/qq_34122822/article/details/126663795
    // 精度丢失 decimal.js
  },
  watch: {
    // 选择监听
    list: {
      handler() {
        const that = this
        // 全选判定
        const selectAll = that.list.every(item => {
          return item.select === true
        })
        that.checkbox = selectAll
        this.countCurrent()
      },
      immediate: true,
      deep: true
    }
  },
  created() {},
  methods: {
    // 计算总价
    countCurrent() {
      const that = this
      let sum = 0 // 价格
      let length = 0 // 勾选数量
      let allLength = 0 // 购物车当前总数量
      const selectList = [] // 勾选id列表
      const listLength = that.list.length
      const list = that.list
      // 筛选已选商品 每项总价汇总计算
      for (let index = 0; index < listLength; index++) {
        const item = list[index]
        allLength += item.num
        if (item.select) {
          length += item.num
          sum = add(sum, item.price_total)
          selectList.push(item.id)
        }
      }
      const data = {
        allLength,
        length,
        subtotal: sum,
        selectList
      }
      this.selectGoodsLength = allLength
      this.$emit('allTotal', data)
    },
    // 支付返回 回选商品
    // setSelectItem() {
    //   const that = this
    //   const backList = that.route.params.list
    //   const allList = that.list
    //   if (!allList.legnth) {
    //     return false
    //   }
    //   if (that.route.params.list && backList.length && !this.flagShow) {
    //     for (let index = 0; index < that.list.length; index++) {
    //       const item = that.list[index]
    //       const index2 = allList.findIndex(res => {
    //         return res.id === item.id
    //       })
    //       if (index2 !== -1) {
    //         allList[index2].select = true
    //       }
    //     }
    //     this.flagShow = 1
    //   }
    // },
    // 设置全选
    setAllSelect(e) {
      const that = this
      for (let index = 0; index < that.list.length; index++) {
        if (that.list[index].stock > 0) {
          that.$set(that.list[index], 'select', e)
        }
      }
    },
    // 选择变化
    changeSelect(val, index) {
      this.$set(this.list[index], 'select', val)
      // this.list[index].select = val
      // this.list[index].price = val.total
    },
    // 总价变化
    // total(val, index) {
    //   this.$set(this.list[index], 'price', val)

    //   // this.list[index].price = val
    // },
    // 数量变化
    numChange(val, index) {
      const current = this.list[index].num
      let num = current
      if (val === 'add') {
        ++num
      } else {
        --num
      }
      this.$api.shoppingCart
        .editNum({
          cart_id: this.list[index].id,
          num
        })
        .then(res => {
          if (res.code === 200) {
            this.$set(this.list[index], 'num', num)
          }
        })
    },
    remove(index) {
      this.$api.shoppingCart
        .delProduct({
          cart_id: this.list[index].id
        })
        .then(res => {
          if (res.code === 200) {
            // this.$tips({ msg: 'You have successfully deleted this product!' })
            this.$parent.getList()
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-body {
  flex: 1;
  margin-right: 42px;
}
.header-count {
  height: 72px;
  font-size: 16px;
  color: #2b2e33;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select {
  height: 40px;
  ::v-deep .v-input__slot {
    margin-bottom: 0;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .header-count {
    padding: 0 15px;
  }
  .list-body {
    margin-right: 0;
    .select {
      height: 24px;
    }
  }
}
</style>
