<template>
  <div class="item">
    <div class="item-header"><span>①</span>Account Operation</div>
    <div class="item-content">
      <v-tabs v-model="tab" background-color="#fff" color="#3067F2">
        <v-tab>Withdraw</v-tab>
        <v-tab>Redeem Coupons</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="tab-content">
            <div class="tab-header">
              After I submit my withdrawal application, STYLOOL'll process it within 1 week. The minimum withdrawal amount is $10 each time. Click <a style="color: #3067f2" href="#">here</a> for more details about the
              withdrawal as well as handling fee.
            </div>
            <div class="tab-option">
              <div class="item-option">
                <v-text-field required class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" title="Amount" placeholder="Amount" />
              </div>
              <div class="item-option">
                <v-text-field required class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" title="Paypal account" placeholder="Paypal Account" />
              </div>
              <div class="btn-box">
                <v-btn class="my-btn btn-option" tile depressed block> Submit</v-btn>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="tab-content">
            <div class="tab-header">This operation is irrevocable. The generated coupon is valid for 1 months.</div>
            <div class="tab-option">
              <div class="item-option">
                <v-text-field required type="number" min="1" class="input-item" background-color="#fff" color="#3067F2" outlined hide-details="auto" title="Amount" placeholder="Amount" />
              </div>
              <div class="btn-box">
                <v-btn class="my-btn btn-option" tile depressed block> Submit</v-btn>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accountTab',
  data() {
    return {
      tab: 0
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.item-header {
  font-size: 14px;
  color: #2b2e33;
  padding: 0 12px;
  background: #f5f5f5;
  height: 40px;
  line-height: 40px;
  span {
    position: relative;
    top: -1px;
  }
}
.item-content {
  border: 1px solid #f5f5f5;
  background: #fff;
  .v-tab {
    font-size: 16px;
    letter-spacing: 0;
    // font-weight: 600;
  }
  @media screen and (min-width: 1280px) {
    .v-tab {
      padding: 0 20px;
    }
  }
  ::v-deep .v-tabs {
    padding-left: 15px;
  }
  ::v-deep .v-tabs-slider-wrapper {
    height: 3px !important;
    top: 0 !important;
  }
  ::v-deep .v-tabs-bar__content {
    border-bottom: 1px solid #dcdfe5;
  }
  // ::v-deep .v-tab {
  //   border-bottom: 1px solid #dcdfe5;
  // }
  ::v-deep .v-tab--active {
    border-left: 1px solid #dcdfe5;
    border-right: 1px solid #dcdfe5;
  }
  ::v-deep .v-item-group {
    margin-top: 10px;
  }
  ::v-deep .v-tabs-bar {
    height: 36px !important;
  }

  .tab-content {
    padding: 0 15px;
    .tab-header {
      font-size: 14px;
      color: #2b2e33;
      line-height: 21px;
    }
    .tab-option {
      display: flex;
      align-items: center;
      height: 38px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .item-option {
      width: 180px;
      height: 100%;
      margin-right: 10px;
    }
    .input-item ::v-deep .v-input__slot fieldset {
      border: 1px solid #d8d8d9 !important;
    }
    .btn-box {
      width: 100px;
      height: 100%;
      .btn-option {
        color: #fff !important;
        height: 100% !important;
        border: 0;
        background: #222426 !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .item-content {
    .tab-content {
      .tab-option {
        flex-direction: column;
        height: auto;
      }
      .item-option {
        width: 100%;
        height: 38px;
        margin-right: 0;
        margin-bottom: 8px;
      }
      .btn-box {
        width: 100%;
        height: 38px;
      }
    }
  }
}
</style>
