<template>
  <div class="center-body">
    <div class="content-main">
      <div class="content-header">
        <h3>My Wallet</h3>
      </div>
      <div class="content-body">
        <div class="list">.....</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'center-index',
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.content-header {
  position: relative;
  padding-left: 12px;
  padding-bottom: 12px;
  font-size: 18px;
  color: #363a40;
  margin-top: 12px;
  border-bottom: 1px solid #e6e9ed;
}
.content-header::before {
  display: block;
  content: '';
  width: 4px;
  height: 20px;
  background: #3067f2;
  position: absolute;
  top: 6px;
  left: 0;
}
.content-body {
  margin-top: 20px;
}
</style>
