<template>
  <div class="coupon">
    <!-- <p class="code-title">Discount Code:</p> -->
    <div class="code-box">
      <template v-if="couponState === 'wait'">
        <input v-model="code" class="code-input" type="text" placeholder="Enter or select a code/coupon" @focus="coupon(true)" @blur="coupon(false)" />
        <v-btn class="my-btn code-btn" color="#2B2E33" tile :disabled="codeState" depressed @click="apply">Apply</v-btn>
      </template>
      <template v-else-if="couponState === 'loading'">
        <v-btn class="my-btn loading-btn" block color="#2B2E33" loading tile depressed />
      </template>
      <template v-else>
        <p class="result-text"><v-icon color="#3067f2">mdi-check</v-icon>Coupon Applied.</p>
        <v-btn class="my-btn code-btn" color="#2B2E33" tile depressed @click="cancel">Cancel</v-btn>
      </template>
      <transition name="fade">
        <div v-if="showCoupon" class="coupon-box">
          <template v-if="couponList.length">
            <div v-for="(item, index) in couponList" :key="index" class="coupon-item" :class="{ unavailable: !item.coupon_use }" @click="selectCode(item.coupon_code, item.coupon_use)">
              <div class="left">
                <p class="state-price">
                  <template v-if="item.discounts_type === 1"> ${{ item.price }} </template>
                  <template v-else> {{ item.price }}% </template>
                  OFF
                </p>
                <!-- <p class="state-text">OFF</p> -->
              </div>
              <div class="center"></div>
              <div class="right">
                <p class="subtitle">{{ item.subtitle }}</p>
                <!-- <p class="time">{{ item.use_end_time }}</p> -->
              </div>
              <p v-if="item.is_first_use" class="tips">Only for first order</p>
            </div>
          </template>
          <!-- <template v-else>
            <p>There are currently no available coupons available.</p>
          </template> -->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { coupon } from '@/components/Lpayment/count/couponCom/mixin/coupon'
export default {
  name: 'couponPc',
  mixins: [coupon],
  data() {
    return {
      // ----coupon---
      showCoupon: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    coupon(state) {
      if (state && this.couponList.length) {
        this.showCoupon = true
      } else {
        setTimeout(() => {
          this.showCoupon = false
        }, 200)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/*设置动画*/
.fade-enter,
.fade-leave-to,
.fade-leave {
  transform: rotate3d(180, 0, 0, 90deg);
}
// .fade-leave {
//   transition: 0.8s;
//   display: none;
// }
.fade-enter-active {
  transition: 0.3s;
}
.fade-leave-active {
  transition: 0.3s;
}
.fade-enter-to {
  transform: rotate3d(180, 0, 0, 0deg);
}

.coupon-box {
  position: absolute;
  background: #ffffff;
  padding: 10px;
  width: 100%;
  top: 50px;
  max-height: 40vh;
  overflow-y: auto;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 1px 6px 0px #aaaaaa;
  transform-origin: top;

  .coupon-item {
    position: relative;
    display: flex;
    align-items: center;
    background: #000000;
    padding: 10px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.5s;
    &:last-child {
      margin-bottom: 0;
    }
    &:not(.unavailable):hover {
      background: #3067f2;
    }
    .tips {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: #f5c723;
      font-size: 12px;
      border-radius: 5px;
      padding: 0 5px;
    }
  }
  .unavailable {
    cursor: no-drop;
    background: #a8a8a8;
  }
  .left {
    font-size: 14px;
    width: 60px;
    p {
      text-align: center;
      margin-bottom: 0;
    }
  }
  .center {
    margin: 0 20px 0 20px;
    height: 25px;
    border: 1px dashed #ffffff;
  }
  .right {
    font-size: 12px;
    p {
      margin-bottom: 0;
    }
    .subtitle {
      font-size: 14px;
      // margin-bottom: 4px;
    }
  }
}
.coupon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 25px;
  height: 85px;
  background: #f7f9fc;
}
.code-box {
  height: 36px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.code-title {
  color: #2b2e33;
  font-size: 16px;
  margin-bottom: 15px;
}
.code-input {
  height: 100%;
  width: 280px;
  outline: none;
  border: 1px solid #ebedf0;
  padding: 6px;
  font-size: 14px;
  color: #adafb2;
  letter-spacing: 1px;
}
.result-text {
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 0;
  margin-right: 5px;
  padding-left: 5px;
  font-size: 16px;
  color: #3067f2;
  border: 1px solid #ebedf0;
}
.code-btn,
.loading-btn {
  height: 100% !important;
  color: #fff !important;
}
.code-box .my-btn {
  font-size: 14px !important;
}
.loading-btn {
  ::v-deep .v-progress-circular {
    margin: 0 auto !important;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .coupon {
    padding: 0 15px;
  }
  .count-box {
    padding: 15px;
  }
  .code-input {
    flex: 1;
    margin-right: 10px;
  }
  .coupon-box {
    .coupon-item {
      font-size: 14px;
      padding: 10px;
    }
    .center {
      margin: 0 15px 0 15px;
    }
  }
}
</style>
