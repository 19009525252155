<template>
  <div class="back-box">
    <template v-if="pageName">
      <template v-if="$vuetify.breakpoint.smAndDown">
        <div v-show="setHide" class="mobile-nenu">
          <span>{{ pageName }}</span>
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <div class="option" v-bind="attrs" v-on="on"><img class="img" src="/imgs/menu2.png" alt="menu-center" /></div>
            </template>
            <div class="center-menu">
              <p @click="menu">Menu</p>
              <p @click="goHone">Home</p>
            </div>
          </v-menu>
        </div>
      </template>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <div v-show="setHide" class="content-header">
          <h3>{{ pageName }}</h3>
        </div>
      </template>
    </template>
    <template v-else>
      <div v-show="setHide && $vuetify.breakpoint.smAndDown" class="mobile-nenu" @click="$router.back()">
        <div class="option stage2"><img class="img" src="/imgs/back.png" alt="back-to-list" />Back</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'mobilePageHeaderBack',
  data() {
    return {
      showDialog: false
    }
  },
  computed: {
    pageName() {
      const pathName = this.$route.name
      let pageName = ''
      switch (pathName) {
        case 'centerMain-account':
          pageName = 'My Account'
          break
        case 'centerMain-order-order':
          pageName = 'Order List'
          break
        case 'centerMain-settings-Info':
          pageName = 'Personal Info'
          break
        case 'centerMain-settings-address':
          pageName = 'Address Management'
          break
        case 'centerMain-settings-password':
          pageName = 'Change Password'
          break
        case 'centerMain-settings-subscription':
          pageName = 'E-Mail Subscription'
          break
        case 'centerMain-settings-prescription':
          pageName = 'My Prescription'
          break
        case 'centerMain-wallet-wallet':
          pageName = 'My Wallet'
          break
        case 'centerMain-wallet-coupons':
          pageName = 'My Coupons'
          break
        case 'centerMain-personalization-wishlist':
          pageName = 'My Wishlist'
          break
        case 'centerMain-tickets-tickets-history':
          pageName = 'Tickets History'
          break
        default:
          pageName = ''
          break
      }
      return pageName
    },
    setHide() {
      if (this.pageName === 'My Account') {
        return false
      }
      return true
    }
  },
  watch: {},
  methods: {
    goHone() {
      this.$router.replace({
        name: 'index'
      })
    },
    menu() {
      this.$router.push({
        name: 'centerMain-account'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-header {
  position: relative;
  padding-left: 12px;
  padding-bottom: 12px;
  font-size: 18px;
  color: #363a40;
  margin-top: 12px;
  border-bottom: 1px solid #e6e9ed;
}
.content-header::before {
  display: block;
  content: '';
  width: 4px;
  height: 20px;
  background: #3067f2;
  position: absolute;
  top: 6px;
  left: 0;
}
.stage2 {
  font-size: 16px;
  img {
    margin-right: 8px;
    height: 18px;
  }
}
@media screen and (max-width: 960px) {
  .mobile-nenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 60px;
    background: #f7f7f7;
    font-size: 17px;
    color: #2b2e33;
    .close {
      height: 14px;
    }
  }
  .option {
    display: flex;
    align-items: center;
  }
  .img {
    height: 22px;
  }
  .center-menu {
    text-align: center;
    width: 150px;
    background: #fff;
    p {
      color: #2b2e33;
      font-size: 16px;
      margin-bottom: 0;
      padding: 10px 0;
    }
    p:first-child {
      border-bottom: 1px solid #dbdbdb;
    }
  }
}
</style>
