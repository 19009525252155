<template>
  <div class="cart-body">
    <div v-if="list.length" class="cart-content">
      <listCom ref="list" class="left" :list="list" @allTotal="allTotal" />
      <recommend v-if="$vuetify.breakpoint.mobile" />
      <countCom class="right" :total="selectGoods" :loading="loadingBtn" @pay="goPay" />
    </div>
    <div v-else class="blank-box">
      <template v-if="empty">
        <div class="top"></div>
        <div class="bottom">
          <p class="tips">SHOPPING CART IS EMPTY</p>
          <NuxtLink to="/"><button>Continue shopping</button></NuxtLink>
        </div>
      </template>
      <template v-else>
        <div class="loading" style="height: 50vh">
          <v-progress-circular indeterminate color="#3067f2" />
        </div>
      </template>
    </div>
    <recommend v-if="!$vuetify.breakpoint.mobile" />
  </div>
</template>

<script>
// import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/assets/utils/storage'
import { mapState } from 'vuex'
import listCom from '@/components/LshoppingCart/list'
import countCom from '@/components/LshoppingCart/count'
import recommend from '@/components/LshoppingCart/recommend'
export default {
  name: 'cartBody',
  components: {
    listCom,
    countCom,
    recommend
  },
  data() {
    return {
      // 加载中显示
      empty: false,
      list: [],
      selectGoods: {
        allLength: 0, // 总数量
        subtotal: undefined, // 总价
        length: undefined, // 数量
        selectList: null // 列表id集合
      },
      loadingBtn: false
    }
  },
  computed: {
    ...mapState({
      userToken: state => state.system.userToken
    })
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    // 设置数据
    // async setCartList() {
    //   this.list = await this.getList()
    //   console.log(this.list)
    // },
    // 获取线上数据
    async getList() {
      // this.$loading().open()
      // let list = []
      const res = await this.$api.shoppingCart.cartList()
      // this.$loading().close()
      if (res.code === 200) {
        // this.setData(res.data)
        this.list = res.data
        this.$store.dispatch('system/getCollectionsAndCartsNum', { axios: this.$api })
        // 设置全选
        if (this.list.length) {
          setTimeout(() => {
            this.$refs.list.setAllSelect(true)
          }, 0)
        }
      }
      this.empty = true
    },
    allTotal(val) {
      this.selectGoods = val
    },
    goPay() {
      if (!this.userToken) {
        this.$store.commit('system/toggleLogin', true)
        return false
      }
      this.loadingBtn = true
      this.$api.shoppingCart.cartSnapshot({ cart_id: this.selectGoods.selectList }).then(res => {
        if (res.code === 200) {
          this.$router.push({
            name: 'payment',
            params: {
              list: this.selectGoods.selectList,
              payId: res.data // 快照id
            }
          })
        }
        this.loadingBtn = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.blank-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;
  padding-bottom: 30px;
  .top {
    width: 300px;
    height: 160px;
    background: url('/imgs/shopping-none.png') no-repeat;
    background-size: contain;
    margin-bottom: 30px;
  }
  .tips {
    text-align: center;
  }
  button {
    background: #3067f2;
    color: #fff;
    width: 238px;
    padding: 10px;
    margin-top: 20px;
  }
}
.cart-body {
  max-width: 1440px;
  margin: 0 auto 50px;
  padding: 0 25px;
}
.cart-content {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.right {
  margin-top: 72px;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .cart-body {
    margin: 0 auto;
    padding: 0;
  }
  .cart-content {
    flex-direction: column;
  }
}
</style>
