import { render, staticRenderFns } from "./centerMain.vue?vue&type=template&id=4233f630&scoped=true"
import script from "./centerMain.vue?vue&type=script&lang=js"
export * from "./centerMain.vue?vue&type=script&lang=js"
import style0 from "./centerMain.vue?vue&type=style&index=0&id=4233f630&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4233f630",
  null
  
)

export default component.exports