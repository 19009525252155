import { render, staticRenderFns } from "./aboutStory.vue?vue&type=template&id=12cf1692&scoped=true"
import script from "./aboutStory.vue?vue&type=script&lang=js"
export * from "./aboutStory.vue?vue&type=script&lang=js"
import style0 from "./aboutStory.vue?vue&type=style&index=0&id=12cf1692&prod&lang=scss&scoped=true"
import style1 from "./aboutStory.vue?vue&type=style&index=1&id=12cf1692&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12cf1692",
  null
  
)

export default component.exports