<template>
  <div class="inputNumber">
    <button class="reduce" :disabled="subReadOnly" :style="{ color: subReadOnly ? '#949699' : '#2b2e33' }" @click="change('sub')"><span>-</span></button>
    <span>{{ current }}</span>
    <button class="increase" :disabled="addReadOnly" :style="{ color: addReadOnly ? '#949699' : '#2b2e33' }" @click="change('add')"><span>+</span></button>
  </div>
</template>

<script>
export default {
  name: 'inputNumber',
  props: {
    max: {
      type: Number,
      default: 10000
    },
    min: {
      type: Number,
      default: 1
    },
    value: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      defalut: false
    }
  },
  data() {
    return {
      current: this.value
    }
  },
  computed: {
    addReadOnly() {
      if (this.current < this.max && !this.disabled) {
        return false
      }
      return true
    },
    subReadOnly() {
      if (this.current > this.min && !this.disabled) {
        return false
      }
      return true
    }
  },
  watch: {
    value(val) {
      this.current = val
    }
  },
  created() {},
  mounted() {},
  methods: {
    change(type) {
      this.$emit('change', type)
    }
    // add() {
    //   if (this.max > this.current) {
    //     this.current++
    //   }
    // },
    // sub() {
    //   if (this.max > this.current) {
    //     this.current--
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.inputNumber {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 108px;
  height: 32px;
  border: 1px solid #e0e0e0;
}
button {
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  overflow: hidden;
}
.reduce {
  font-size: 28px;
  span {
    margin-top: -3px;
  }
}
.increase {
  font-size: 24px;
  span {
    margin-top: -1px;
  }
}
</style>
