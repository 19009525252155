<template>
  <div class="prescriptionTable">
    <table class="table-list">
      <caption>
        {{
          info.title
        }}
        ({{
          info.create_time
        }})
        <template v-if="$vuetify.breakpoint.smAndDown"> - {{ info.birth_year }} </template>
      </caption>
      <thead>
        <tr>
          <td></td>
          <td>Sphere</td>
          <td>Cylinder</td>
          <td>Axis</td>
          <td>ADD</td>
          <td>PD</td>
          <td v-if="!$vuetify.breakpoint.smAndDown">Birth Year</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="item">OD</td>
          <template v-if="info.img_path">
            <td class="item" rowspan="2" colspan="4">
              <img class="imgs" :src="info.img_path" alt="prescription" />
            </td>
          </template>
          <template v-else>
            <td class="item">{{ info.od_sph | defaultNone }}</td>
            <td class="item">{{ info.od_cyl | defaultNone }}</td>
            <td class="item">{{ info.od_axis | defaultNone }}</td>
            <td class="item">{{ info.od_add | defaultNone }}</td>
          </template>
          <template v-if="info.is_two_pd">
            <td class="item" rowspan="2">{{ info.pd_right | defaultNone }}</td>
          </template>
          <template v-else>
            <td class="item">{{ info.pd_right | defaultNone }}</td>
          </template>
          <td v-if="!$vuetify.breakpoint.smAndDown" class="item" rowspan="2">{{ info.birth_year }}</td>
        </tr>
        <tr>
          <td class="item">OS</td>
          <template v-if="!info.img_path">
            <td class="item">{{ info.os_sph | defaultNone }}</td>
            <td class="item">{{ info.os_cyl | defaultNone }}</td>
            <td class="item">{{ info.os_axis | defaultNone }}</td>
            <td class="item">{{ info.os_add | defaultNone }}</td>
          </template>
          <template v-if="!info.is_two_pd">
            <td class="item">{{ info.pd_left | defaultNone }}</td>
          </template>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="7">
            <div class="content">
              <span class="btn-item" @click="remove(info.id)">Delete</span>
              <span class="btn-item edit" @click="edit(info.id)">Edit</span>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: 'prescriptionTable',
  filters: {
    defaultNone: function (value) {
      if (!value) {
        return ''
      }
      const filterStr = ['None', 'Choose', 'Select']
      // if(filterStr.includes(value)){

      // }
      const res = filterStr.filter(item => {
        return value.includes(item)
      })
      if (res.length) {
        return '--'
      }
      return value
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    edit(id) {
      this.$emit('edit', id)
    },
    remove(id) {
      this.$emit('remove', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-list {
  width: 100%;
  font-size: 14px;
  border: 1px solid #dcdfe5;
  border-collapse: collapse;
}
.table-list > caption {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #2b2e33;
  text-align: left;
  padding: 0 15px;
  border-top: 1px solid #dcdfe5;
  border-left: 1px solid #dcdfe5;
  border-right: 1px solid #dcdfe5;
  background: #f5f5f5;
}
.table-list > tfoot {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding: 0 15px;
  border-top: 1px solid #dcdfe5;
}
.table-list > thead {
  tr {
    color: #525861;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid #dcdfe5;
  }
  td {
    padding: 8px;
    text-align: center;
  }
}
.table-list > tbody {
  tr {
    background: #fff;
  }
  td {
    font-size: 14px;
    color: #525861;
    text-align: center;
    padding: 8px;
  }
  .imgs {
    width: 50px;
  }
}
.content {
  height: 40px;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 15px;
  .btn-item {
    color: #3067f2;
    cursor: pointer;
    &:hover {
      color: #2760f1;
    }
  }
  .edit {
    margin-right: 15px;
  }
}
// .table-list tr {
//   border-bottom: 1px solid #dcdfe5;
// }
</style>
<style scoped lang="scss">
@media screen and (max-width: 440px) {
  .table-list > tbody td {
  }
}
</style>
