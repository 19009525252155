<template>
  <!-- 关于 为什么选择我们 -->
  <div class="aboutAnswer">
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <div class="item item-left">
            <!-- contain -->
            <v-img class="img" lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_quality.jpg`" />
            <div class="activity-title">Quality Control</div>
            <div class="describe">Our products are all tested and have the CE certification mark indicating</div>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="item item-center">
            <v-img class="img" lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_price.jpg`" />
            <div class="activity-title">Affordable Price</div>
            <div class="describe">It’s our vision to meet every need and occasion as affordable price</div>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="item item-right">
            <v-img class="img" lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_service.jpg`" />
            <div class="activity-title">Expert Customer Service Team</div>
            <div class="describe">We are ready to solve every problem you have at any time</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'aboutAnswer'
}
</script>

<style lang="scss" scoped>
.aboutAnswer {
  margin-bottom: 40px;
}
.item {
  width: 95%;
  margin: 0 auto 20px;
  text-align: left;
  .img {
    margin-bottom: 20px;
  }
  .activity-title {
    font-size: 18px;
    color: #2b2e33;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 5px;
  }
  .describe {
    font-size: 16px;
    font-weight: 400;
    color: #6a6d70;
    line-height: 22px;
  }
}
.item-center {
  margin: 0 auto;
}
.item-right {
  margin-left: 10px;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .aboutAnswer {
    margin-bottom: 0;

    .item {
      width: 100%;
      margin: 0 0 38px;
      text-align: center;
    }
  }
}
</style>
