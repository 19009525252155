<template>
  <div class="product-list">
    <div class="glassesList" :style="{ marginBottom: top ? '0' : '70px' }">
      <!-- {{ $route.params.glassesList }} -->
      <div class="recommend">
        <img :src="cover" alt="banner" />
      </div>
      <Lgoods id="list" ref="list" :option="listConfig" :list="goodsList" :type="listConfig.type" @page="changePage" />
      <!-- <div class="shopList"></div> -->
      <LgoTop v-if="$vuetify.breakpoint.smAndDown" />
    </div>
  </div>
</template>

<script>
import { typeName } from '@/assets/utils/renderData'
import head from '@/assets/utils/TDK'
import Lgoods from '@/components/Lgoods/index.vue'
import LgoTop from '@/components/LgoTop/index.vue'
export default {
  name: 'productList',
  components: {
    Lgoods,
    LgoTop
  },
  async asyncData({ route, $api, error }) {
    const query = route.query
    const config = {
      page: 1,
      limit: 18,
      type: 'accessories',
      search: query.search ? query.search : ''
    }
    const { data: goodsList, count, cover } = await $api.product.getGoodsList(config)
    return {
      goodsList,
      cover,
      listConfig: {
        ...config,
        count
      },
      loading: false
    }
  },
  data() {
    return {
      cover: undefined,
      // 商品列表
      goodsList: [],
      // 商品参数
      listConfig: {
        count: null,
        limit: 18,
        type: ''
      }
    }
  },
  head() {
    return {
      title: head('accessories').title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: head('accessories').description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: head('accessories').keywords
        }
      ]
    }
  },
  computed: {
    top: function () {
      if (this.$route.name === 'search') {
        return ''
      }
      return 'none'
    },
    typeName() {
      return typeName('accessories') || this.$route.query.search
    }
  },
  watchQuery: true,
  created() {},
  mounted() {},
  methods: {
    getViewScroll() {
      let height = 0
      const recommend = document.querySelector('.recommend')
      if (recommend) {
        if (document.documentElement.scrollTop < recommend.clientHeight) {
          return false
        } else {
          height = height + recommend.clientHeight
        }
      }
      document.documentElement.scrollTop = height
    },
    async getList(page = 1) {
      this.getViewScroll()
      this.loading = true
      this.listConfig.page = page
      const { data: goodsList, count, limit, cover } = await this.$api.product.getGoodsList(this.listConfig)
      this.goodsList = goodsList
      this.listConfig.count = count
      this.listConfig.limit = limit
      this.cover = cover
      this.loading = true
    },
    // 翻页
    changePage(val) {
      this.getList(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend {
  img {
    width: 100%;
  }
  cursor: pointer;
}
#list {
  &::v-deep.body-page {
    scroll-margin: 145px;
    padding-top: 20px;
  }
}
</style>
