<template>
  <div class="center-body">
    <div class="content-body">
      <table class="table-list">
        <thead>
          <tr>
            <td class="thumbnail-title">Thumbnail</td>
            <td>Product Model</td>
            <td>Color</td>
            <td>Size</td>
            <td>Price</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          <template v-if="list.length && !loading">
            <tr v-for="(item, index) in list" :key="index">
              <td class="item">
                <div class="content">
                  <img class="thumbnail" :src="item.img" alt="product" />
                </div>
              </td>
              <td class="item">
                <div class="model-box">
                  <span>{{ item.content }}</span>
                  <span>{{ item.code }}</span>
                </div>
              </td>
              <td class="item">
                <div class="content color-box">
                  <!-- <span class="img-color" :style="{ background: `url(${item.color.icon})` }"></span> -->
                  <span class="name">{{ item.color }}</span>
                </div>
              </td>
              <td class="item">{{ item.size }}({{ item.frame_width }})</td>
              <td class="item">${{ item.price }}</td>
              <td class="item">
                <div class="content">
                  <span class="btn-item buy" @click="buy(item.code)">Buy Now</span>
                  <span class="btn-item remove" @click="remove(item.code)">Remove</span>
                </div>
              </td>
            </tr>
          </template>
          <template v-else-if="loading">
            <div class="loading" style="height: 50vh">
              <v-progress-circular indeterminate color="#3067f2" />
            </div>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <div class="empty-box">
                  <img class="empty-icon" src="/imgs/wishlistEmpty.png" alt="empty" />
                  <p>You have no items in your wishlist.</p>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pcWishlist',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      defalut: true
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    buy(val) {
      this.$router.push({
        name: 'details-code',
        params: {
          code: val
        }
      })
    },
    remove(val) {
      this.$emit('remove', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-box {
  .empty-icon {
    width: 245px;
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
  }
}

.content-body {
  margin-top: 20px;
}
table.table-list {
  width: 100%;
  font-size: 14px;
  border: 1px solid #dcdfe5;
  border-collapse: collapse;
}
table.table-list > thead {
  tr {
    color: #525861;
    background: #f5f5f5;
    font-size: 14px;
    font-weight: 400;
  }
  td {
    text-align: center;
    padding: 10px;
  }
}
table.table-list > tbody {
  position: relative;
  tr {
    background: #fff;
    &:hover {
      background: #faf8f8;
    }
  }
  td {
    font-size: 14px;
    color: #525861;
    text-align: center;
    padding: 20px 10px;
  }
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-bottom: 0;
      font-size: 14px;
      display: inline-block;
    }
    .thumbnail {
      // width: 150px;
      height: 60px;
    }
    .img-color {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
    }
    .state {
      position: relative;
      .state-text {
        margin-right: 10px;
      }
      .icon {
        height: 18px;
        position: absolute;
        cursor: pointer;
      }
    }

    .view {
      cursor: pointer;
      text-decoration: underline;
    }
    .btn-item {
      display: inline-block;
      width: 90px;
      padding: 6px 0;
      text-align: center;
      cursor: pointer;
    }
    .buy {
      color: #fff;
      background: #3067f2;
    }
    .remove {
      color: #3067f2;
    }
  }
  .content.color-box {
    flex-direction: column;
    justify-content: center;
  }
}
table.table-list tr {
  border-bottom: 1px solid #dcdfe5;
}
.thumbnail-title {
  width: 20%;
}
.model-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
