<template>
  <div class="content-body">
    <client-only>
      <template v-if="list.length && Array.isArray(list)">
        <div v-for="item in list" :key="item.id" class="item">
          <!-- 订单头 -->
          <div class="herder-info">
            <div class="left">
              <span>Order:</span>
              <span>{{ item.code }}</span>
            </div>
            <div class="right" :style="{ color: item.status == 0 ? '#ff0000' : '' }">{{ renderStatus(item.status) }}</div>
          </div>
          <div class="body">
            <!-- 订单商品 -->
            <div class="product-list">
              <div v-for="(item1, index) in item.product" :key="index" class="product-item">
                <div class="product-info">
                  <div class="left-img" @click="goPage(item1.code)">
                    <img class="product-img" :src="item1.image" alt="product" />
                  </div>
                  <div class="left-info">
                    <p class="product-name">{{ item1.title }}</p>
                    <p class="product-other-info">Sku:{{ item1.code }}</p>
                    <p class="product-other-info">Lens:{{ replaceType(item1.lens) }}</p>
                    <p class="product-other-info">Color:{{ item1.color }}</p>
                  </div>
                </div>
                <div class="item-price">
                  <p class="product-num">x{{ item1.num }}</p>
                  <p class="product-item-price">{{ currencyIcon }}{{ item1.price }}</p>
                </div>
              </div>
            </div>
            <div class="total">
              <p>Total:</p>
              <p>{{ currencyIcon }}{{ item.actual_price }}</p>
            </div>
            <div class="option">
              <!-- 手机模式 -->
              <template v-if="$vuetify.breakpoint.smAndDown">
                <p class="btn-item" @click="viewOrder(item.code)">View</p>
                <countDown v-if="item.status === 0" :start-time="item.order_time_stamp" @endTime="state => endTime(state, item.code)" />
                <!-- <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <div class="option-menu" v-bind="attrs" v-on="on"><img class="img" src="/imgs/menu2.png" alt="menu-center" /></div>
                </template>
                <div class="center-menu">
                  <template v-if="[3, 4].includes(item.status)">
                    <p class="btn-item" @click="refund(item.code)">Refund</p>
                    <p class="btn-item" @click="openDialog('Comment', item)">Comment</p>
                    <p class="btn-item" @click="del(item.code)">Delete</p>
                  </template>
                  <template v-if="[1, 2].includes(item.status)">
                    <p class="btn-item" @click="refund(item.code)">Refund</p>
                  </template>
                  <template v-else>
                    <p class="btn-item" @click="del(item.code)">Delete</p>
                  </template>
                </div>
              </v-menu> -->
              </template>
              <template v-else>
                <!-- 00:00:00 -->
                <countDown v-if="item.status === 0" :start-time="item.order_time_stamp" @endTime="state => endTime(state, item.code)" />
                <span class="btn-item blue-btn" @click="viewOrder(item.code)">View</span>
                <!-- <span class="btn-item yellow-btn" @click="refund(item.code)">Refund</span>
              <span class="btn-item success-btn" @click="openDialog('Comment', item)">Comment</span>
              <span class="btn-item error-btn" @click="del(item.code)">Delete</span> -->
              </template>
            </div>
          </div>
        </div>
      </template>
    </client-only>
  </div>
</template>

<script>
import { mixin } from '@/components/LcenterMain/order/mixin/mixin'
import countDown from '@/components/LcenterMain/order/countDown.vue'
export default {
  name: 'listOrder',
  components: {
    countDown
  },
  mixins: [mixin],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    goPage(code) {
      this.$router.push({
        name: 'details-code',
        params: { code }
      })
    },
    endTime(state, code) {
      const index = this.list.findIndex(item => {
        return item.code === code
      })
      if (index !== -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.list[index].status = 6
      }
    }
  }
}
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.content-body {
  margin-top: 20px;
}
.item {
  border-radius: 8px;
  border: 1px solid #f0f2f4;
  margin-bottom: 8px;
}
.product-img {
  width: 130px;
}
.herder-info {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: #eaecf1;
  padding: 8px;
  font-weight: 600;
  border-bottom: 1px solid #f0f2f4;
}
.product-item,
.product-info,
.body,
.option {
  display: flex;
  align-items: center;
}
.body {
  align-items: stretch;
}
.product-list {
  width: 70%;
  border-right: 1px solid #f0f2f4;
  p {
    margin-bottom: 0;
  }
}
.product-item {
  padding: 8px;
  justify-content: space-between;
  .left-img {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }
}
.product-name,
.product-item-price {
  font-size: 17px;
  font-weight: 600;
}
.product-other-info {
  font-size: 15px;
}
.item-price {
  text-align: right;
}
// .    border-bottom: 1px solid red;
.total,
.option {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.total {
  padding: 0 40px;
  text-align: center;
  border-right: 1px solid #f0f2f4;
  p {
    margin-bottom: 0;
    font-weight: 600;
  }
}
.option {
  flex: 1;
  flex-direction: column;
  padding: 0 15px;
  span {
    display: inline-block;
    cursor: pointer;
    width: 128px;
    text-align: center;
    font-weight: 600;
    padding: 5px;
    border: 1px solid #f0f2f4;
    margin-bottom: 10px;
  }
  span:last-child {
    margin-bottom: 0;
  }
  .success-btn {
    color: #008822;
    border: 1px solid #008822;
  }
  .blue-btn {
    color: #006eff;
    border: 1px solid #006eff;
  }
  .yellow-btn {
    color: #f5bc00;
    border: 1px solid #f5bc00;
  }
  .error-btn {
    color: #ff0000;
    border: 1px solid #ff0000;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .body {
    flex-direction: column;
    padding: 0 16px;
  }
  .item {
    margin-bottom: 20px;
  }
  .product-list {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #f0f2f4;
  }
  .herder-info {
    font-size: 14px;
  }
  // .product-info {
  //   align-items: stretch;
  // }
  .product-img {
    width: 82px;
  }
  .product-item {
    padding: 8px 0;
    .left-img {
      margin-right: 8px;
    }
  }
  .product-name,
  .product-item-price,
  .product-num {
    font-size: 14px;
  }
  .product-other-info {
    font-size: 13px;
  }
  .total {
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 0 0 0;
    font-size: 14px;
  }
  .option {
    align-items: flex-end;
    padding: 10px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    .img {
      width: 25px;
      transform: rotate(90deg);
    }
  }
  .option-menu {
    display: flex;
  }
  .center-menu {
    text-align: center;
    min-width: 100px;
    background: #fff;
    p {
      color: #2b2e33;
      font-size: 16px;
      margin-bottom: 0;
      padding: 10px;
      border-bottom: 1px solid #dbdbdb;
    }
    p:last-child {
      border-bottom: none;
    }
  }
  .btn-item {
    margin-bottom: 0;
    width: 120px;
    text-align: center;
    font-weight: 600;
    padding: 2px;
    border: 1px solid #f0f2f4;
    color: #006eff;
    border: 1px solid #006eff;
  }
}
</style>
