<template>
  <transition name="confirm">
    <div v-if="show" class="confirm">
      <div class="confirm-body">
        <div class="top"></div>
        <div class="bottom">
          <p class="tips">Payment success</p>
          <button @click="goPage">View the order</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'conFirm',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.$loading().open()
        } else {
          this.$loading().close()
        }
      },
      immediate: true
    }
  },
  methods: {
    goPage() {
      this.$parent.showTips = false
      this.$router.replace({
        name: 'centerMain-order-order'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000000000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.confirm-body {
  width: 468px;
  height: 312px;
  padding: 25px;
  position: fixed;
  top: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 2px #b7b2b2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .top {
    width: 170px;
    height: 128px;
    background: url('/imgs/pay-succss.png') no-repeat;
    background-size: contain;
    margin-bottom: 30px;
  }
  .tips {
    text-align: center;
  }
  button {
    background: #3067f2;
    color: #fff;
    width: 238px;
    padding: 10px;
    margin-top: 20px;
  }
}

.confirm-enter,
.confirm-leave-to {
  position: fixed;
  transform: scale(0);
  opacity: 0;
}
.confirm-enter-active,
.confirm-leave-active {
  transition: 0.2s;
}

.confirm-enter-to {
  position: fixed;
  transform: scale(1);

  opacity: 1;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .confirm-body {
    width: 350px;
    height: 300px;
  }
}
</style>
