<template>
  <div class="about-page">
    <div class="banner" :style="{ backgroundImage: cover ? `url(${cover})` : '' }"></div>
    <div class="content">
      <div class="about-info">
        <article class="story">
          <section class="section1">
            Stepping into the same old optical shop as you always do, you start to try on those unremarkable frames on the counter at random. One after another, to be honest, you are still not quite satisfied with the
            fifth pair, but, it would be more or less awkward to ask the SA to continue. Finally it comes to the eighth one, you both get tired and have to compromise.
          </section>
          <section class="section2">
            It is literally what happened to me -- even more than once. As I’m not rich enough to spend another $500 soon, I have to wear the mediocre glasses at the most conspicuous place on my face. Every time I look
            in the mirror, it brings back memories of this unpleasant experience, and I just can’t help wondering:
          </section>
          <ul class="question">
            <li>Why would these prescription glasses cost so much?</li>
            <li>Can’t I own and wear different styles of glasses according to different occasions or moods?</li>
          </ul>
          <section class="section3">That’s where our story begins.</section>
        </article>

        <div class="us">
          <section>
            “Stylool wants affordable spectacles to meet every need and occasion, so we partner with the best opticians and designers around the world to offer you reliable lenses and fashionable frames with the latest
            trend - down to the actual cost of each pair of prescription glasses we make.”
          </section>
          <strong><em> Camille, CEO of Stylool </em></strong>
        </div>
      </div>
      <aboutStory />
    </div>

    <div class="answer">
      <h3>Why Choose Stylool</h3>
      <div class="answer-com">
        <aboutAnswer />
      </div>
    </div>
  </div>
</template>

<script>
import head from '@/assets/utils/TDK'
import aboutStory from '@/components/Lhome/Labout/aboutStory.vue'
import aboutAnswer from '@/components/Lhome/Labout/aboutAnswer.vue'

export default {
  name: 'aboutPage',
  components: {
    aboutStory,
    aboutAnswer
  },
  async asyncData({ $api, error }) {
    const { cover } = await $api.home.getCopywritingInfo({
      type: 'about-stylool'
    })
    return {
      cover
    }
  },
  data() {
    return {}
  },
  head() {
    return {
      title: head('about-us').title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: head('about-us').description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: head('about-us').keywords
        }
      ]
    }
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.about-page {
  font-size: 16px;
}
.banner {
  height: 550px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.content {
  width: auto;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  .story {
    width: 1000px;
    margin: 40px auto 60px;
    font-size: 18px;
    font-family: Arial, Helvetica, Tahoma, Futura, sans-serif;
    font-weight: 300;
    color: #2b2e33;
    line-height: 28px;
    // word-break: break-all;
    .section1 {
      margin-bottom: 30px;
    }
    .question {
      position: relative;
      left: 6px;
      margin-bottom: 30px;
    }
  }
  .us {
    width: 820px;
    padding: 20px 10px;
    margin: 0 auto 55px;
    border-top: 1px solid #dadee3;
    border-bottom: 1px solid #dadee3;
    text-align: center;
    section {
      font-size: 24px;
      font-family: Arial, Helvetica, Tahoma, Futura, sans-serif;
      font-weight: 300;
      color: #2b2e33;
      line-height: 36px;
      margin-bottom: 25px;
    }
    strong {
      font-size: 18px;
      font-family: Ubuntu-Medium Italic, Ubuntu;
      font-weight: normal;
      color: #2b2e33;
      line-height: 33px;
    }
  }
}
.answer {
  background: #f7f8fa;
  overflow: hidden;
  .answer-com {
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1200px;
  }

  h3 {
    margin: 70px 0 50px;
    text-align: center;
    font-size: 30px;
    // font-family: Ubuntu-Bold, Ubuntu;
    font-weight: bold;
    color: #2b2e33;
    line-height: 35px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .story {
    width: 100% !important;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .banner {
    height: 192px;
  }
  .content {
    padding: 0 16px;
    .story {
      width: 100%;
      margin: 34px auto;
      font-size: 15px;
      line-height: 27px;
    }
    .us {
      width: 100%;
      padding: 22px 10px;
      margin: 0 auto 55px;
      section {
        font-size: 20px;
        line-height: 36px;
        margin-bottom: 25px;
      }
      strong {
        font-size: 18px;
        line-height: 33px;
      }
    }
  }
  .answer {
    padding: 0 16px;
    .answer-com {
      padding: 0;
    }
    h3 {
      margin: 30px 0 38px;
      font-size: 30px;
      line-height: 35px;
    }
  }
}
</style>
