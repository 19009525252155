<template>
  <div class="faq-page">
    <template v-if="$vuetify.breakpoint.name !== 'xs'">
      <div class="banner">
        <img :src="cover" alt="banner" />
      </div>
      <div class="container-box">
        <listMenu :list="list" @change="change" />
        <contentFaq v-if="content" :key="$route.params.articles" :content="content" />
      </div>
    </template>
    <!-- 手机端 -->
    <template v-else>
      <!-- <div class="nav-header">
        <img src="/imgs/close.png" alt="close" @click="$router.back()" />
      </div> -->
      <div class="nav-header">
        <v-menu offset-y class="menu-box">
          <template #activator="{ on, attrs }">
            <div class="option" v-bind="attrs" v-on="on"><img class="img" src="/imgs/menu2.png" alt="menu-center" /></div>
          </template>
          <div class="center-menu">
            <p @click="menu">Menu</p>
            <p @click="goHone">Home</p>
          </div>
        </v-menu>
      </div>
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <listMenu :list="list" @change="change" />
      </v-navigation-drawer>
      <contentFaq v-if="content" :key="$route.params.articles" :content="content" />
      <LgoTop dom=".indexMobile" />
    </template>
  </div>
</template>

<script>
import listMenu from '@/components/Lfaq/listMenu.vue'
import contentFaq from '@/components/Lfaq/content.vue'
import LgoTop from '@/components/LgoTop/index.vue'

export default {
  name: 'faqArticles',
  components: {
    listMenu,
    contentFaq,
    // eslint-disable-next-line vue/no-unused-components
    LgoTop
  },
  validate({ params }) {
    // return的结果为布尔值，如果为false则404
    return params.articles !== ''
  },
  async asyncData({ params, query, $api, error }) {
    let paramsInfo = params.articles
    const { data: list, cover } = await $api.home.getFaqList({ search: query.search || '' })
    // 查询到列表
    if (list.length) {
      if (paramsInfo === 'list') {
        paramsInfo = list[0].id
      }
      const { data: content } = await $api.home.getFaqInfo({ question_id: paramsInfo })
      return {
        cover,
        list,
        content
      }
    } else {
      return {
        cover,
        list
      }
    }
  },
  data() {
    return {
      drawer: false
    }
  },
  head() {
    return {
      title: this.content.title
    }
  },
  computed: {},
  watch: {
    '$route.params.articles': {
      handler(val) {
        if (this.$vuetify.breakpoint.name === 'xs') {
          this.drawer = val === 'list'
        }
      },
      deep: true,
      immediate: true
    }
  },
  watchQuery: true,
  created() {},
  mounted() {},
  methods: {
    change(id) {
      // const info = this.list[index]
      this.$router.push({
        name: 'index-faq-articles',
        params: {
          articles: id
        }
      })
      if (this.$vuetify.breakpoint.name === 'xs') {
        this.drawer = false
      }
    },
    goHone() {
      this.$router.replace({
        name: 'index'
      })
    },
    menu() {
      this.drawer = true
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-pagee {
  font-size: 16px;
}
.banner {
  // height: 550px;
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  img {
    width: 100%;
  }
}
.container-box {
  display: flex;
  max-width: 1200px;
  margin: 60px auto 100px;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  // .banner {
  //   height: 220px;
  // }
  .menu {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    background: red;
  }
  .nav-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 54px;
    padding: 0 16px;
    background: #f2f2f2;
  }
  ::v-deep .v-navigation-drawer {
    width: 100% !important;
  }
  .option {
    display: flex;
    align-items: center;
  }
  .img {
    height: 22px;
  }
  .center-menu {
    text-align: center;
    width: 150px;
    background: #fff;
    p {
      color: #2b2e33;
      font-size: 16px;
      margin-bottom: 0;
      padding: 10px 0;
    }
    p:first-child {
      border-bottom: 1px solid #dbdbdb;
    }
  }
}
</style>
