<template>
  <div class="filter">
    <!--  -->
    <div v-if="$vuetify.breakpoint.mdAndUp" class="pc-filter" @mouseleave="currentIndex = undefined">
      <!-- 上区大类 -->
      <div class="bg-type">
        <div id="type-box" class="type-box">
          <div class="type-name">
            <div v-for="(item, index) in items" :key="index" :class="index == currentIndex ? 'active' : ''" class="item">
              <span @mouseenter="selectType(index)"> {{ item.name }}</span>
              <span v-show="index == currentIndex" class="rotate-icon-down icon-box">
                <img class="filter-icon" :src="more2" alt="open" />
              </span>
              <span v-show="index !== currentIndex" class="rotate-icon-up icon-box">
                <img class="filter-icon" :src="more" alt="open" />
              </span>
            </div>
            <v-spacer />
          </div>
          <!-- <div class="shopNum">{{ length }} Products</div> -->
          <!-- 排序方式 -->
          <div class="sort-box">
            <div class="select">
              <v-select v-model="selected" class="input-item" :items="selectList" color="#3067f2" dense outlined hide-details />
            </div>
          </div>
        </div>
      </div>
      <!-- 筛选条件 -->
      <transition name="fade">
        <div v-show="currentIndex !== undefined && showFilter && readyFilter" class="bg-list">
          <div class="type-list">
            <!-- <transition-group name="fade" tag="div"> -->
            <colorFilter v-show="currentIndex === 0" :check="currentAll" :list="list" type="color" @dataFn="checkData" />
            <sizeFilter v-show="currentIndex === 1" ref="sizeFilter" :check="currentAll" :list="list" type="size" @dataFn="checkData" />
            <checkboxFilter v-show="currentIndex === 2" :check="currentAll" :list="list" type="genders" @dataFn="checkData" />
            <checkboxFilter v-show="currentIndex === 3" :check="currentAll" :list="list" type="material" @dataFn="checkData" />
            <checkboxFilter v-show="currentIndex === 4" :check="currentAll" :list="list" type="shape" @dataFn="checkData" />
            <checkboxFilter v-show="currentIndex === 5" :check="currentAll" :list="list" type="rim" @dataFn="checkData" />
            <checkboxFilter v-show="currentIndex === 6" :check="currentAll" :list="list" type="feature" @dataFn="checkData" />
            <!-- </transition-group> -->
            <!-- 筛选操作 -->
            <div class="option">
              <span class="option-item" @click="clearAll">Delete selection</span>
            </div>
          </div>
        </div>
      </transition>
      <!-- 已选条件 -->
      <div v-if="JSON.stringify(currentAll) !== '{}'" class="fliter-tag">
        <!-- 分类tag -->
        <currentFilter :check="currentAll" @closeTag="closeTag" />
      </div>
    </div>
    <div v-else class="mobile-filter">
      <div class="filter-header">
        <div class="left" @click="sheet = true">
          <span class="icon" :style="{ color: JSON.stringify(currentAll) !== '{}' ? '#3067f2' : '#5f5f5f' }"><img src="/imgs/filter.png" alt="filter" />Filter</span>
        </div>
        <div class="shop-num">{{ length }} Products</div>
      </div>
      <v-bottom-sheet v-if="readyFilter" v-model="sheet">
        <v-sheet class="sheet-box" height="528px">
          <div class="filter-content">
            <v-expansion-panels v-model="panels" accordion>
              <v-expansion-panel v-for="(item, i) in items" :key="i">
                <v-expansion-panel-header> {{ item.name }} </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template v-if="item.name === 'Color'">
                    <colorFilter :check="currentAll" :list="list" type="color" @dataFn="checkData" />
                  </template>
                  <template v-if="item.name === 'Size'">
                    <sizeFilter ref="sizeFilter" :check="currentAll" :list="list" type="size" @dataFn="checkData" />
                  </template>
                  <template v-if="item.name === 'Gender'">
                    <checkboxFilter :check="currentAll" :list="list" type="genders" @dataFn="checkData" />
                  </template>
                  <template v-if="item.name === 'Material'">
                    <checkboxFilter :check="currentAll" :list="list" type="material" @dataFn="checkData" />
                  </template>
                  <template v-if="item.name === 'Shape'">
                    <checkboxFilter :check="currentAll" :list="list" type="shape" @dataFn="checkData" />
                  </template>
                  <template v-if="item.name === 'Rim'">
                    <checkboxFilter :check="currentAll" :list="list" type="rim" @dataFn="checkData" />
                  </template>
                  <template v-if="item.name === 'Feature'">
                    <checkboxFilter :check="currentAll" :list="list" type="feature" @dataFn="checkData" />
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div class="filter-footer">
            <v-btn class="my-btn option-btn" :disabled="JSON.stringify(currentAll) == '{}'" color="#3067F2" @click="clearAll"> Clear All </v-btn>
            <v-btn class="my-btn option-btn" color="#3067F2" @click="sheet = false"> Confirm </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
      <currentFilter :check="currentAll" @closeTag="closeTag" />
      <!-- <div v-if="JSON.stringify(currentAll) !== {}" class="option" style="text-align: right">
        <span class="option-item" @click="clearAll">Delete selection</span>
      </div> -->
      <div class="sort-box">
        <div class="select mobile-select">
          <v-select v-model="selected" class="input-item" :items="selectList" outlined hide-details />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 下拉多选
import checkboxFilter from '@/components/Lfilter/checkboxFilter'
import colorFilter from '@/components/Lfilter/colorFilter'
import sizeFilter from '@/components/Lfilter/sizeFilter'
// 当前条件
import currentFilter from '@/components/Lfilter/currentFilter'
import more from '@/static/imgs/more.png'
import more2 from '@/static/imgs/more2.png'
export default {
  name: 'LFilter',
  components: {
    checkboxFilter,
    currentFilter,
    colorFilter,
    sizeFilter
  },
  props: {
    // 商品数量
    length: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      more, // 图片
      more2,
      readyFilter: false,
      showFilter: false,
      // 筛选泪目
      items: [{ name: 'Color' }, { name: 'Size' }, { name: 'Gender' }, { name: 'Material' }, { name: 'Shape' }, { name: 'Rim' }, { name: 'Feature' }],
      currentIndex: undefined,
      list: {
        // 材料
        material: undefined,
        // 形状
        shape: undefined,
        // 轮廓
        rim: undefined,
        // 特点
        feature: undefined,
        // 大小
        size: undefined,
        // 颜色
        color: undefined,
        // 性别
        gender: undefined
      },
      filterFlag: false, // 筛选值联动标记
      // 当前选中
      currentAll: {},
      // 宽度范围
      widthVal: {},
      // 是否打开筛选
      sheet: false,
      // 手机展开项
      panels: undefined,
      // 排序方式
      selectList: ['Default', 'New Arrivals', 'Best Sellers', 'Price: High to Low', 'Price: Low to High'],
      // 当前排序
      // selected: 'Default'
      selected: this.$route.params.sort ? this.$route.params.sort : 'Default'
    }
  },
  computed: {
    watchFilterData() {
      // const currentAll = this.currentAll
      // const widthVal = this.widthVal
      // const selected = this.selected
      const { currentAll, widthVal, selected, filterFlag } = this
      const obj = {
        selected: currentAll,
        widthVal,
        sort: selected,
        filterFlag
      }
      return JSON.parse(JSON.stringify(obj))
    }
  },
  watch: {
    // '$route.params': {
    //   handler(val) {
    //     console.log('11')
    //     this.selected = val.sort ? val.sort : 'Default'
    //   },
    //   deep: true,
    //   immediate: true
    // },
    watchFilterData: {
      handler(newVal, oldVal) {
        delete newVal.filterFlag
        delete oldVal.filterFlag
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$emit('filterData', newVal)
        }
      },
      deep: true
    },
    currentAll: {
      handler(val) {
        this.filterFlag = !this.filterFlag
        // this.$emit('filterData', { selected: val, widthVal: this.widthVal, sort: this.selected })
      },
      deep: true
    },
    widthVal: {
      handler(val) {
        this.filterFlag = !this.filterFlag
        // this.$emit('filterData', { selected: this.currentAll, widthVal: val, sort: this.selected })
      },
      deep: true
    }
    // selected(val) {
    //   this.$emit('filterData', { selected: this.currentAll, widthVal: this.widthVal, sort: val })
    // }
  },
  mounted() {
    this.getType()
    const recommend = document.querySelector('.recommend')
    const flexdDom = document.querySelector('.filter')
    const typeBox = document.querySelector('#type-box')

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            flexdDom.style.position = 'relative'
            flexdDom.style.top = 'auto'
            if (typeBox) {
              typeBox.style.padding = '15px 20px'
            }
          } else {
            setFixed()
          }
        })
      },
      { threshold: 0.3 }
    )
    observer.observe(recommend)
    const setFixed = () => {
      // pc端固定
      const top = document.querySelector('.system-header').offsetHeight + 'px'
      // if (this.$vuetify.breakpoint.width > 1280) {

      // } else {
      //   top = document.querySelector('#mainEl').style.paddingTop
      // }
      if (top) {
        flexdDom.style.position = 'fixed'
        flexdDom.style.top = top
        if (typeBox) {
          typeBox.style.padding = '5px 20px'
        }
      }
    }
  },
  methods: {
    async getType() {
      const { data: typeList } = await this.$api.system.getGoodsType({
        type: 'All'
      })
      this.list = typeList
      this.readyFilter = true
    },
    selectType(index) {
      if (this.currentIndex === index) {
        this.currentIndex = undefined
      } else {
        this.showFilter = false
        setTimeout(() => {
          this.showFilter = true
        })
        this.currentIndex = index
      }
    },
    clearAll() {
      // 通用无特殊
      this.currentAll = {}
      // 尺寸范围
      this.widthVal = {}
      this.currentAllCheckClear()
    },
    closeTag(val) {
      if (val.key === 'size') {
        this.$refs.sizeFilter.current = []
      }
      this.currentAll[val.key].splice(val.index, 1)
      this.currentAllCheckClear()
    },
    checkData(val) {
      if (val.type === 'width') {
        this.widthVal = val.selected
        return false
      }
      this.$set(this.currentAll, [val.type], val.selected)
    },
    currentAllCheckClear() {
      const val = this.currentAll
      if (JSON.stringify(val) === '{}') {
        return false
      }
      for (const i in val) {
        if (!val[i].length) {
          delete val[i]
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-content {
  font-size: 16px;
  padding: 10px;
  background: #fff;
}
.sheet-box {
  font-size: 16px;
  position: relative;
  overflow-y: scroll;
  .filter-content {
    padding: 0 0 48px;
    .v-expansion-panel-header {
      padding: 0 10px;
      font-size: 16px;
    }
  }
  .filter-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    .option-btn {
      width: 50%;
      height: 48px;
      line-height: 48px;
      font-size: 16px;
      color: #fff;
      border-radius: 0 !important;
    }
  }
}

// .mobile-select {
//   margin-top: 15px;
// }
::v-deep .v-input__slot {
  height: 44px;
  min-height: 44px !important;
}
::v-deep .v-input__append-inner {
  margin-top: 12px !important;
}
/*设置动画*/
.fade-enter,
.fade-leave-to,
.fade-leave {
  transform-origin: top;
  transform: rotate3d(180, 0, 0, 90deg);
}
.fade-leave {
  display: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.5s;
}
.fade-enter-to {
  transform-origin: top;
  transform: rotate3d(180, 0, 0, 0deg);
}
</style>
<style lang="scss" scoped>
.type-box,
.fliter-tag {
  max-width: 1170px;
  min-width: 735px;
  margin: 0 auto;
  // padding: 0px 20px;
}
.type-list {
  max-width: 1080px;
  min-width: 735px;
  margin: 0 auto;
}
.filter {
  font-size: 16px;
  background: #fff;
  position: relative;
  z-index: 1;
  width: 100%;
  .fliter-tag {
    padding: 8px 20px 0;
    display: flex;
    justify-content: space-between;
    :v-deep .v-text-field__details {
      display: none;
    }
  }
  .sort-box {
    display: flex;
  }
  .try-icon {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #3067f2;
    line-height: 28px;
    margin-left: 35px;
    cursor: pointer;
    img {
      height: 34px;
      margin-right: 10px;
    }
  }
  .bg-type {
    position: relative;
    z-index: 2;
    background: #fff;
    border-bottom: 1px solid #cccccc;
  }
  .type-box {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    transition: padding 0.2s;
    .type-name {
      display: flex;
      // width: 55%;
      flex: 1;
      justify-content: space-between;
      cursor: pointer;
      color: #5f5f5f;
      .item {
        display: flex;
        font-size: 16px;
        margin-right: 40px;
        align-items: center;
      }
      .active {
        color: #3067f2;
      }
      .rotate-icon-down {
        transform: rotate(180deg);
        display: inline-block;
      }
      .icon-box {
        display: flex;
        align-items: center;
        margin-left: 8px;
      }
      .filter-icon {
        width: 10px;
        height: 6px;
      }
    }
  }
  .bg-list {
    position: absolute;
    width: 100%;
    z-index: 1;
    background: #f7f7f7;
  }
  .type-list {
    padding: 15px 20px;
    .item {
      display: flex;
      margin-top: 10px;
      .item-name {
        width: 200px;
        font-size: 18px;
        font-weight: 500;
        color: #000;
      }
      .item-type {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;
        .checkbox-item {
          width: 20%;
          margin-bottom: 10px;
        }
      }
    }
    .option {
      display: flex;
      flex-direction: row-reverse;
      color: #919499;
      .option-item {
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.select {
  width: 225px;
  height: 36px;
  .input-item ::v-deep .v-input__slot .v-input__append-inner {
    margin-top: 8px !important;
  }
  .input-item ::v-deep .v-input__slot fieldset {
    border: 1px solid #cccccc !important;
  }
}
</style>
<!-- <style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .filter .type-box .type-name .item {
    margin-right: 30px;
  }
}
</style> -->
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .select {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .filter {
    z-index: 2;
    padding: 16px !important;
    .mobile-filter {
      .filter-header {
        margin-bottom: 8px;
        .left:active {
          color: #3067f2;
        }
        img {
          width: 15px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
