<template>
  <v-dialog v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.mobile">
    <div class="login-dialogs">
      <v-toolbar color="#fff">
        <v-spacer />
        <v-btn icon dark @click="closeDialog">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-tabs v-model="tabIndex" class="layoutDialog" color="#3067F2" centered background-color="#fff" left>
        <v-tab class="my-btn">Sign In</v-tab>
        <v-tab class="my-btn">Create an Account</v-tab>
        <v-spacer />

        <v-tab-item>
          <v-form ref="login" class="form-box">
            <p class="input-label" for="">E-mail <span style="color: #ff0000">*</span></p>

            <v-text-field v-model="login.email" hide-details="auto" required class="form-input-item" background-color="#F2F3F5" color="#3067F2" outlined clearable :rules="[rules.email]" />
            <p class="input-label" for="">Password <span style="color: #ff0000">*</span></p>
            <v-text-field
              v-model="login.password"
              class="form-input-item"
              hide-details="auto"
              outlined
              required
              autocomplete
              background-color="#F2F3F5"
              color="#3067F2"
              clearable
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              @click:append="showPass = !showPass"
              @keyup.enter.native="sign"
            />
            <div class="option">
              <v-row no-gutters>
                <v-col cols="6">
                  <div class="remember">
                    <!-- <v-checkbox v-model="checkbox">
                      <template #label>
                        <div>Remember me</div>
                      </template>
                    </v-checkbox> -->
                    <v-checkbox v-model="Remember" label="Remember me" color="info" hide-details />
                  </div>
                </v-col>
                <v-col cols="6"><div class="forget" @click="forget">Forget password?</div></v-col>
              </v-row>
            </div>
            <v-btn block class="my-btn btn-login" color="#3067F2" :disabled="loginload" :loading="loginload" @click="sign"> Sign In </v-btn>
            <!-- <p class="tips">By creating your account, you agree to our <span class="agreeContent" @click="agreePage">Privacy Policy</span>.</p> -->
            <!-- <v-divider /> -->
            <!-- <div class="or-line">
              <div class="line"></div>
              <div class="text">or</div>
              <div class="line"></div>
            </div> -->
            <!-- <v-btn block class="my-btn btn-login btn-other mb-2" color="#fff"> <img class="btn-icons facebook" src="/imgs/facebook2.png" alt="facebook" /> Facebook </v-btn>
            <v-btn block class="my-btn btn-login btn-other" color="#fff"> <img class="btn-icons google" src="/imgs/google.png" alt="google" /> Google </v-btn> -->
          </v-form>
        </v-tab-item>
        <!-- ------------------------------------------------------------ -->
        <v-tab-item>
          <v-form ref="register" class="form-box">
            <v-row>
              <!-- <v-col class="item" cols="12" md="6">
                <p class="input-label" for="">First name <span style="color: #ff0000">*</span></p>
                <v-text-field v-model="create.firstname" hide-details="auto" :rules="[rules.name]" outlined required background-color="#F2F3F5" color="#3067F2" />
              </v-col>
              <v-col class="item" cols="12" md="5" offset-md="1">
                <p class="input-label" for="">Last name <span style="color: #ff0000">*</span></p>
                <v-text-field v-model="create.lastname" hide-details="auto" :rules="[rules.name]" outlined required background-color="#F2F3F5" color="#3067F2" />
              </v-col> -->
              <v-col class="item" cols="12">
                <p class="input-label" for="">E-mail <span style="color: #ff0000">*</span></p>
                <v-text-field v-model="create.email" hide-details="auto" :rules="[rules.email]" outlined required background-color="#F2F3F5" color="#3067F2" autocomplete="off" clearable />
              </v-col>
              <v-col class="item" cols="12">
                <p class="input-label" for="">Password <span style="color: #ff0000">*</span></p>
                <v-text-field
                  v-model="create.password"
                  class="form-input-item"
                  hide-details="auto"
                  :rules="[rules.passWord]"
                  outlined
                  required
                  autocomplete="new-password"
                  background-color="#F2F3F5"
                  color="#3067F2"
                  clearable
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass"
                  @keyup.enter.native="register"
                />
              </v-col>
              <v-col class="item mt-2" cols="12">
                <v-btn block class="my-btn btn-login" color="#3067F2" :loading="registerLoad" :disabled="registerLoad" @click="register">
                  Register
                  <template #loader>
                    <span>Register...</span>
                  </template>
                </v-btn>
              </v-col>
              <v-col class="item" cols="12">
                <div class="remember create">
                  <div>By registering you agree to our <span class="agreeContent" @click="agreePage">privacy policy</span>.</div>
                  <!-- <v-checkbox v-model="Agreement">
                    <template #label>
                      By registering you agree to our privacy policy.
                      <div>Agree to receive emails by checking <span class="agreeContent" @click="agreePage">Privacy Policy</span></div>
                    </template>
                  </v-checkbox> -->
                </div>
              </v-col>
            </v-row>
            <!-- <v-btn block class="my-btn btn-login btn-other mb-2" color="#fff"> <img class="btn-icons facebook" src="/imgs/facebook2.png" alt="facebook" /> Facebook </v-btn>
            <v-btn block class="my-btn btn-login btn-other" color="#fff"> <img class="btn-icons google" src="/imgs/google.png" alt="google" /> Google </v-btn> -->
          </v-form>
        </v-tab-item>
      </v-tabs>
    </div>
    <!-- <agreement :show="agreeDialog" @close="agreeDialog = false" /> -->
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { setPassword, getPassword } from '@/assets/utils/encryption'
import { isEmail, isPassWord2, isName } from '@/assets/utils/validate'
// import agreement from '@/page/toolCom/agreement.vue'
export default {
  name: 'LLogin',
  // components: {
  //   agreement
  // },
  data() {
    return {
      agreeDialog: false,
      dialog: false,
      tabIndex: 0,
      login: {
        email: '',
        password: ''
      },
      create: {
        // firstname: '',
        // lastname: '',
        email: '',
        password: ''
      },
      showPass: false,
      Agreement: true,
      show1: false,
      Remember: false,
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          return isEmail(value) || 'Invalid e-mail.'
        },
        name: value => {
          return isName(value) || 'Invalid name, please use english.'
        },
        passWord: value => {
          return isPassWord2(value) || 'Please enter a password of at least 8 characters.'
        }
      },
      loginload: false,
      registerLoad: false
    }
  },
  computed: {
    ...mapState({
      loginDialogTab: state => state.system.loginDialogTab,
      loginDialog: state => state.system.loginDialog
    })
  },
  watch: {
    loginDialog(newValue) {
      this.dialog = newValue
      if (!newValue) {
        try {
          this.$refs.login.resetValidation()
          this.$refs.register.resetValidation()
        } catch (error) {}
      }
      if (this.tabIndex === 0) {
        this.getRemember()
      }
    },
    loginDialogTab(newValue) {
      this.tabIndex = newValue
    }
  },
  methods: {
    setRemember() {
      window.localStorage.setItem('haveE', setPassword(this.login.email))
      window.localStorage.setItem('haveP', setPassword(this.login.password))
    },
    getRemember() {
      const name = window.localStorage.getItem('haveE') || ''
      const password = window.localStorage.getItem('haveP') || ''
      this.login = {
        email: getPassword(name),
        password: getPassword(password)
      }
    },
    // 用户登录
    userLogin() {
      this.$api.system.toLogin(this.login).then(res => {
        this.loginload = false
        if (res.code === 200) {
          this.$store.commit('system/saveToken', res.token)
          this.$store.dispatch('system/getUserInfo', { axios: this.$api })
          if (this.Remember) {
            this.setRemember()
          }
          this.closeDialog()
          if (this.$route.name !== 'product') {
            this.$router.go(0)
          }
        }
      })
    },
    // 用户注册
    userRegister() {
      this.$api.system.toRegister(this.create).then(res => {
        this.registerLoad = false
        if (res.code === 200) {
          // 直接登录------------------------
          this.registerToLogin()
          // 注册成功 去登录--------------------------
          // this.tabIndex = 0
          // //  预填充
          // this.login.email = this.create.email
          // this.$refs.register.reset()
          // --------------------------
        }
        // this.$store.commit('system/saveToken', res.data.token)
      })
    },
    registerToLogin() {
      this.login.email = this.create.email
      this.login.password = this.create.password
      this.userLogin()
    },
    closeDialog() {
      Object.assign(this.$data, this.$options.data())
      // this.$emit('closeDialog', false)
      this.$store.commit('system/toggleLogin', false)
      this.$store.commit('system/loginDialogTab', 0)
    },
    sign() {
      if (this.$refs.login.validate()) {
        this.loginload = true
        this.userLogin()
      }
    },
    register() {
      if (!this.Agreement) {
        return false
      }
      if (this.$refs.register.validate()) {
        this.registerLoad = true
        this.userRegister()
      }
    },
    // 查看协议页
    agreePage() {
      const page = this.$router.resolve({
        name: 'type',
        params: { type: 'privacy-policy' }
      })
      window.open(page.href, '_blank')
    },
    forget() {
      this.closeDialog()
      this.$store.commit('system/toggleReset', true)
    }
  }
}
</script>

<style lang="scss" scoped>
// input
.agreeContent {
  color: #3067f2;
  cursor: pointer;
  text-decoration: underline;
}
::v-deep .v-text-field {
  margin-bottom: 8px;
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #f2f3f5 inset !important;
  }
}
::v-deep .v-text-field--outlined fieldset {
  border: none !important;
}
::v-deep .v-slide-group__wrapper {
  padding-left: 50px;
}
::v-deep .v-input__slot {
  min-height: 48px !important;
}
::v-deep .v-input__append-inner {
  margin-top: 12px !important;
}
.v-dialog__content {
  z-index: 999999 !important;
}
.login-dialogs,
.v-dialog__content ::v-deep .v-dialog {
  width: 525px !important;
  margin: 0 auto;
}
.link {
  color: #3067f2 !important;
  text-decoration: underline;
}
.layoutDialog {
  width: 100%;
  .remember.create {
    margin-top: 15px;
    font-size: 14px;
    text-align: center;
    color: #777474;
  }
  .form-box {
    width: 435px;
    margin: 30px auto 50px;
    .input-label {
      font-size: 14px;
      line-height: 30px;
      margin-bottom: 4px;
    }
    .input-item {
      height: 48px;
      margin-bottom: 20px;
    }
    .remember ::v-deep .v-label {
      color: #3067f2;
      font-size: 14px;
    }
    .forget {
      font-size: 14px;
      color: #3067f2;
      display: flex;
      width: 100%;
      height: 100%;
      align-content: center;
      align-items: center;
      flex-direction: row-reverse;
      cursor: pointer;
    }
    .btn-login {
      height: 48px;
      color: #fff;
    }
    .btn-other {
      height: 48px;
      color: #525861;
      background: #e0e0e0;
      .btn-icons {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .facebook {
        width: 14px;
      }
    }
    .tips {
      font-size: 13px;
      color: #8a9099;
      margin: 20px auto;
      text-align: center;
    }
    .item {
      padding: 0;
    }
  }
  .or-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 18px;
    .line {
      width: 45%;
      height: 1px;
      background: #e0e0e0;
    }
    .text {
      font-size: 14px;
      color: #8a9099;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  // .v-dialog {
  //   width: 580px !important;
  // }
  ::v-deep .v-slide-group__wrapper {
    padding-left: 20px;
  }
  .login-dialogs,
  .v-dialog__content ::v-deep .v-dialog {
    width: 100% !important;
    margin: 0 auto;
    height: 100%;
    background: #fff;
  }
  .layoutDialog {
    width: 100%;

    .form-box {
      width: 90%;
      .remember ::v-deep .v-label {
        font-size: 12px;
      }
      .forget {
        font-size: 12px;
      }
    }
  }
}
</style>
