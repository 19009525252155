<template>
  <v-dialog v-model="dialog" class="dialogs-com" persistent :fullscreen="$vuetify.breakpoint.mobile">
    <div class="dialog-body">
      <div class="dialog-header d-flex">
        <v-spacer />
        <v-btn icon dark @click="closeDialog">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="content-detail">
        <div class="table-com">
          <h2>Frame Size Guide</h2>
          <table class="size-table">
            <tbody>
              <tr class="table-header">
                <td>Size:</td>
                <td>Frame Width(mm)</td>
              </tr>
              <tr>
                <td>Narrow</td>
                <td>≤128mm</td>
              </tr>
              <tr>
                <td>Medium</td>
                <td>129mm - 138mm</td>
              </tr>
              <tr>
                <td>Wide</td>
                <td>≥139mm</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="frame-com">
          <h2 class="product-size">
            <span class="product-code">{{ info.title }} ({{ info.code }})</span>
            <span class="size-type" @click="toggle">
              <template v-if="currentUnit === 1">Show in mm</template>
              <template v-else>Show in inches</template>
            </span>
          </h2>
          <div class="parameter">
            <div class="item">
              <div class="left">
                <span class="size-num">{{ sizeNum(info.frame_width) }}</span>
                <img class="imgs" src="/imgs/size/ic_fw.png" alt="guide" />
              </div>
              <div class="right">
                <h2>Frame Width</h2>
                <p>Frame Width is the measurement horizontally across the back of the frame.</p>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <span class="size-num">{{ sizeNum(info.lens_width) }}</span>
                <img class="imgs" src="/imgs/size/ic_lw.png" alt="guide" />
              </div>
              <div class="right">
                <h2>Lens Width</h2>
                <p>Lens Width is the width of each lens at its widest point.</p>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <span class="size-num">{{ sizeNum(info.bridge) }}</span>
                <img class="imgs" src="/imgs/size/ic_bw.png" alt="guide" />
              </div>
              <div class="right">
                <h2>Bridge Width</h2>
                <p>Bridge Width is the shortest distance (in millimeters) between your two lenses.</p>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <span class="size-num">{{ sizeNum(info.lens_height) }}</span>
                <img class="imgs" src="/imgs/size/ic_lh.png" alt="guide" />
              </div>
              <div class="right">
                <h2>Lens Height</h2>
                <p>Lens Height is the vertical distance of the lens at its tallest point. If you want to order progressive, the lens height of the frame should be at least 33mm.</p>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <span class="size-num">{{ sizeNum(info.temple) }}</span>
                <img class="imgs" src="/imgs/size/ic_tl.png" alt="guide" />
              </div>
              <div class="right">
                <h2>Temple Length</h2>
                <p>Temple Length is the length of the temple to its temple tip, including the bend that sits on your ear.</p>
              </div>
            </div>
          </div>
          <p>Due to the different measurements methods, the measurements printed on the inside of the temple arm may vary from those showing on our website.</p>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { multiply } from '@/assets/utils/decimal'
export default {
  name: 'sizeDescribe',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
      currentUnit: 0
    }
  },

  computed: {
    sizeNum() {
      return function (val) {
        if (this.currentUnit === 1) {
          const difference = 0.0393701
          return multiply(val, difference).toFixed(2) + 'in'
        } else {
          return val + 'mm'
        }
        // return val
      }
    }
  },
  watch: {
    show(newValue) {
      this.dialog = newValue
    }
  },
  methods: {
    closeDialog() {
      this.$parent.sizeDialogs = false
    },
    toggle() {
      this.currentUnit === 0 ? (this.currentUnit = 1) : (this.currentUnit = 0)
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog {
// }
.dialogs-com,
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  background: #fff;
  margin: 0 auto;
}
.v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  width: 960px;
}
::v-deep .v-toolbar {
  height: 50px;
}
.content-detail {
  padding: 0 16px;
  min-height: 375px;
  background: #fff;
  h2 {
    text-align: center;
    margin-bottom: 10px;
  }
}
.size-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  color: #000;
  td {
    border: 1px solid #d4d4d4;
    padding: 10px;
  }
  .table-header {
    background: #d4d4d4;
    td {
      color: #000;
      font-weight: 600;
    }
  }
}
.product-size {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  .size-type {
    color: #3067f2;
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
  }
}
.parameter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 0 16px;
  border: 1px solid #d4d4d4;
  .item {
    display: flex;
    width: 33%;
    padding-top: 12px;
    padding-bottom: 12px;
    flex-direction: column;
    // border-top: 1px solid #d4d4d4;
    // border-bottom: 1px solid #d4d4d4;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    .size-num {
      color: #3067f2;
      font-weight: 600;
    }
    .imgs {
      height: 90px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2 {
      font-size: 16px;
      margin: 5px auto;
    }
    p {
      font-size: 14px;
      text-align: center;
      margin-bottom: 0;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 726px) {
  .parameter .left .imgs {
    height: 60px;
  }
  .parameter {
    flex-direction: column;
    .item {
      width: 100%;
      flex-direction: row;
    }
  }
}
@media screen and (max-width: 460px) {
  .parameter .left .imgs {
    height: 50px;
  }
}
</style>
