<template>
  <!-- 首页 问题 -->
  <div class="body-page">
    <div class="content-box faq">
      <h2 class="mode-title">{{ title }}</h2>
      <p class="guide">
        If you have any other question, please contace us<strong class="link"> <a class="link-url" @click="openChat"> on live </a> </strong> or email:
        <strong class="link"> <a class="link-url" href="mailto:support@stylool.com">support@stylool.com</a> </strong>
      </p>
      <div class="faq-box">
        <div class="item">
          <div class="box">
            <nuxt-link :to="{ name: 'index-faq-articles', params: { articles: 'How-to-Order-Glasses-Online' } }">
              <v-img class="item-img" contain lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_ogo.jpg`" />
              <p class="question">HOW TO ORDER GLASSES ONLINE?</p>
            </nuxt-link>
          </div>
        </div>
        <div class="item">
          <div class="box">
            <nuxt-link :to="{ name: 'index-faq-articles', params: { articles: 'How-to-use-CodeCoupon' } }">
              <v-img class="item-img" contain lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_htuc.jpg`" />
              <p class="question">HOW TO USE CODE/COUPON?</p>
            </nuxt-link>
          </div>
        </div>
        <div class="item">
          <div class="box">
            <nuxt-link :to="{ name: 'index-faq-articles', params: { articles: 'How-to-Read-Prescription' } }">
              <v-img class="item-img" contain lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_rp.jpg`" />
              <p class="question">HOW TO READ PRESCRIPTION?</p>
            </nuxt-link>
          </div>
        </div>
        <div class="item">
          <div class="box">
            <nuxt-link :to="{ name: 'index-faq-articles', params: { articles: 'How-to-Measure-Your-PD' } }">
              <v-img class="item-img" contain lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_myp.jpg`" />
              <p class="question">HOW TO MEASURE YOUR PD?</p>
            </nuxt-link>
          </div>
        </div>
      </div>
      <!-- <v-container fluid class="faq-box">
        <v-row no-gutters>
          <v-col cols="6" sm="3">
          </v-col>
          <v-col cols="6" sm="3">
          </v-col>
          <v-col cols="6" sm="3">
          </v-col>
          <v-col cols="6" sm="3">
          </v-col>
        </v-row>
      </v-container> -->
      <v-container class="btn-box px-0 py-0 d-flex justify-center">
        <nuxt-link :to="{ name: 'index-faq-articles', params: { articles: 'list' } }">
          <v-btn class="mobile-btn-more" rounded>View All</v-btn>
        </nuxt-link>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LFaq',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    toPage() {
      this.$router.push({ name: 'index-faq' })
    },
    openChat() {
      // eslint-disable-next-line no-undef
      if (FB) {
        // eslint-disable-next-line no-undef
        FB.CustomerChat.showDialog()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.body-page {
  background: #f7f8fa;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;
}
.mode-title {
  margin-bottom: 15px;
}
.btn-box {
  margin-top: 25px;
  a {
    text-decoration: none;
  }
  // margin-bottom: 50px;
}
.guide {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  .link {
    text-decoration: underline;
    margin: 0 2px;
    cursor: pointer;
  }
}
// .faq {
//   margin-top: 40px;
// }
.faq-box {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.item {
  width: 20%;
  margin: 0 14px;
  .box {
    min-height: 200px;
    position: relative;
    margin-bottom: 30px;
  }
  .item-img {
    // height: 330px;
  }
  // &::v-deep .v-image {
  //   width: 100%;
  //   height: 100%;
  // }
  .question {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50px;
    height: 50px;
    color: #000000;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #a8ddf3;
  }
}
</style>
<!-- <style lang="scss" scoped>
@media screen and (max-width: 1420px) {
  .item-img {
    // height: 330px;
  }
}
</style> -->
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .body-page {
    padding-top: 48px;
    padding-bottom: 48px;
    background: #fff;
  }
  .content-box {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
  }
  .faq {
    .box {
      margin-bottom: 30px;
    }
    // .row > div:nth-of-type(odd) {
    //   .item {
    //     padding-right: 8px;
    //     padding-left: 0;
    //   }
    // }
    // .row > div:nth-of-type(even) {
    //   .item {
    //     padding-left: 8px;
    //     padding-right: 0;
    //   }
    // }
    .faq-box {
      flex-wrap: wrap;
      .item:nth-of-type(odd) {
        margin-right: 12px;
        margin-left: 0;
      }
      .item:nth-of-type(even) {
        margin-left: 12px;
        margin-right: 0;
      }
    }
    .item {
      width: 42%;
    }
    .question {
      height: auto;
      position: relative;
      background: none;
    }
    .btn-box {
      margin-top: 8px;
    }
  }
}
</style>
