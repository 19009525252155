<template>
  <div class="center-body">
    <div class="content-main">
      <div class="content-body">
        <div class="balance">
          <p class="balance-text">Balance Available:</p>
          <p class="balance-num">$0.00</p>
        </div>
        <accountTab />
        <credit />
        <statement />
      </div>
    </div>
  </div>
</template>

<script>
import accountTab from '@/components/LcenterMain/wallet/accountTab'
import credit from '@/components/LcenterMain/wallet/credit'
import statement from '@/components/LcenterMain/wallet/statement'

export default {
  name: 'walletPage',
  components: {
    accountTab,
    credit,
    statement
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.content-body {
  margin-top: 20px;
}
.balance {
  background: #fcf4ed;
  padding: 20px;
  margin-bottom: 20px;
  .balance-text {
    letter-spacing: 1px;
    font-size: 16px;
    color: #2b2e33;
    font-weight: 600;
    margin-bottom: 0;
  }
  .balance-num {
    font-size: 30px;
    font-weight: bold;
    color: #ff7f1f;
    margin-bottom: 0;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .content-body {
    .balance {
      padding: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
