<!-- 第三步 膜层 addOnes -->
<template>
  <div class="step-body">
    <div class="list">
      <!-- <div v-for="(item, index) in list" :key="item.id" class="item" :class="{ active: index == current }" @click="select(index)"></div> -->
      <div v-for="(item, index) in list" :key="item.value" class="item" :class="{ active: index == current }" @click="select(index)">
        <div class="left">
          <img :src="item.icon" alt="Preview" />
        </div>
        <div class="right">
          <p class="text-title">{{ item.name }} {{ item.money == 0 ? 'Free' : currencyIcon + '' + item.money }}</p>
          <p class="text-tips">{{ item.describe }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flow from '@/components/Lproduct/mixin/getFlow'
export default {
  name: 'addOnes',
  mixins: [flow],
  data() {
    return {
      current: null // 当前选中类型
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    select(index) {
      this.current = index
      this.$emit('addOnes', {
        data: this.list[index],
        type: 'add_ones'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.step-body {
  .list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 990px;
    margin: 0 auto;
    overflow: auto;
    .item {
      height: 110px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      border: 1px solid #525861;
      border-radius: 5px;
      cursor: pointer;
    }
    .item:hover {
      transition: all 0.5s;
      border: 1px solid #3067f2;
      background: rgb(48, 103, 242, 0.18);
      box-shadow: 0px 0px 4px #3067f2;
    }
    .active {
      border: 1px solid #3067f2;
      background: rgb(48, 103, 242, 0.18);
    }
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    img {
      height: 64px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #525861;
    p {
      margin-bottom: 0;
    }
    .text-title {
      font-size: 18px;
      font-weight: 600;
      color: #2b2e33;
      margin-bottom: 10px;
    }
  }
  .option {
    margin-top: 20px;
    width: 88px;
    border-bottom: 1px solid #2b2e33;
    cursor: pointer;
    img {
      width: 17px;
      height: 10px;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 600px) {
  .step-body {
    .list {
      width: 100%;
      .item {
        padding: 20px 0;
        height: 105px;
      }
    }
    .left {
      width: 68px;
      height: 68px;
      img {
        height: 52px;
      }
    }
    .right {
      font-size: 13px;
      padding-right: 20px;
      .text-title {
        font-size: 13px;
        margin-bottom: 6px;
      }
    }
  }
}
</style>
