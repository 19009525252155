<template>
  <div class="page">
    <!-- <Nuxt /> -->
    <div class="explain">
      <h1>{{ dataText.title }}</h1>
      <div class="content-explain" v-html="dataText.content"></div>
    </div>
    <Lfooter />
  </div>
</template>

<script>
import Lfooter from '@/components/Lfooter/index.vue'
export default {
  name: 'explainPage',
  components: {
    Lfooter
  },
  async asyncData({ route, $api, error }) {
    const type = route.params.type
    const list = ['promise', 'privacy-policy', 'terms-conditions', 'return-exchange', 'about-stylool', 'why-choose', 'our-story']
    if (list.includes(type)) {
      const { data: dataText } = await $api.home.getCopywritingInfo({ type })
      return {
        dataText
      }
    } else {
      error({ statusCode: 404 })
    }
  },
  data() {
    return {}
  },
  head() {
    return {
      title: this.dataText.title
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.explain {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 16px;
  h1 {
    margin: 20px auto;
    text-align: center;
  }
}
.content-explain {
  margin-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .explain {
    h1 {
      font-size: 20px;
    }
  }
  .content-explain {
    margin-bottom: 60px;
  }
}
</style>
