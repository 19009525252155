<template>
  <div class="list-box">
    <template v-if="list.length && !loading">
      <div v-for="item in list" :key="item.id" class="item">
        <div class="item-option">
          <span class="buy" @click="buy(item.code)">Buy Now</span>
          <span class="line-h"></span>
          <span class="remove" @click="remove(item.code)">Remove</span>
        </div>
        <div class="item-banner">
          <img class="product-img" :src="item.img" alt="product" />
        </div>
        <div class="item-info">
          <p class="info-item">
            <span class="info-name">Product Model:</span>
            <span class="info-text">{{ item.content }} - </span>
            <span class="info-text">{{ item.code }}</span>
          </p>
          <p class="info-item">
            <span class="info-name">Color:</span>
            <!-- <span class="img-color" :style="{ background: `url(${item.color.icon})` }"></span> -->
            <span class="info-text">{{ item.color }}</span>
          </p>
          <p class="info-item">
            <span class="info-name">Size:</span>
            <span class="info-text">{{ item.size }}({{ item.frame_width }})</span>
          </p>
          <p class="info-item">
            <span class="info-name">Price:</span>
            <span class="info-text">${{ item.price }}</span>
          </p>
        </div>
      </div>
    </template>
    <template v-else-if="loading">
      <div class="loading" style="height: 50vh">
        <v-progress-circular indeterminate color="#3067f2" />
      </div>
    </template>
    <template v-else>
      <div class="empty-box">
        <img class="empty-icon" src="/imgs/wishlistEmpty.png" alt="empty" />
        <p>You have no items in your wishlist.</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'mobileWishlist',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      defalut: true
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    buy(val) {
      this.$router.push({
        name: 'details-code',
        params: {
          code: val
        }
      })
    },
    remove(val) {
      this.$emit('remove', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-box {
  margin: 25px 0;
  text-align: center;
  padding: 25px;
  .empty-icon {
    width: 50%;
    margin-bottom: 25px;
  }
  p {
    font-size: 14px;
  }
}

.item {
  background: #fff;
  padding: 14px;
  margin-bottom: 7px;
}
.item-option {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 6px;
  height: 35px;
  font-size: 13px;
  .remove {
    color: #525861;
    margin-left: 0;
  }
  .line-h {
    width: 2px;
    height: 14px;
    background: #e0e0e0;
    margin-left: 10px;
    margin-right: 10px;
  }
  .buy {
    height: 100%;
    width: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3067f2;
    border: 1px solid #3067f2;
  }
}
.item-banner {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
  .product-img {
    height: 116px;
  }
}
.item-info {
  .info-item {
    font-size: 13px;
    margin-bottom: 10px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .info-name {
    color: #949699;
    margin-right: 6px;
  }
  .img-color {
    display: inline-block;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-right: 3px;
    position: relative;
    top: 3px;
  }
  .info-text {
    color: #2b2e33;
  }
}
</style>
