<template>
  <div class="payment-body">
    <div class="pay-content">
      <div class="left">
        <addressList class="item-com" @addressChange="addressChange" @billing="billingChange" />
        <methodList class="item-com" :medthod-default="methodType" :medthod="medthod" @medthos-type="setMedthosTpye" />
        <!-- <payList class="item-com" @pays-type="setPayType" /> -->
      </div>
      <countInfo class="right" :delivery-fee="deliveryFee" @userNote="userNote" />
    </div>
    <payTips :show="showTips" />
  </div>
</template>

<script>
import { getRandomString } from '@/assets/utils/toolFn'
import addressList from '@/components/Lpayment/address/list'
import methodList from '@/components/Lpayment/method/list'
// import payList from '@/components/Lpayment/payList/list'
import countInfo from '@/components/Lpayment/count/index'
import payTips from '@/components/Lpayment/confirm.vue'
// const config = {
//   clientId: 'ASMo5HatkXqEbOdSs4A3n-AL3Xw1tTwWU4dQR6Arj_OUdRl8XvQRmhFvZWG_PI99rBQsVromNJ2hU0Ly',
//   secret: 'EBw-dW5MIRsHbaefokcEJ0_BtqxPohYqLjc8R6Qy15HT5Y2GyeH5KlLdRFfaqxkVIrouDHRh0BEz_F2w'
// }
export default {
  name: 'paymentIndex',
  components: {
    addressList,
    methodList,
    // payList,
    countInfo,
    payTips
  },
  validate({ params, redirect, error }) {
    if (!params.list || !params.list.length) {
      // return error({ statusCode: 500 })
      redirect({ name: 'shoppingCart' })
    } else {
      return true
    }
  },
  // middeware({ route, redirect, ...ac }) {
  //   console.log(ac)
  // },
  data() {
    return {
      // 随机码
      getRandomString: getRandomString(64),
      // 地址
      shippingAddress: {},
      billingAddress: {},
      // 派送方式
      methodType: null,
      // 派送方式列表
      medthod: [],
      // 派送费
      deliveryFee: null,
      orderNote: undefined,
      //  selectGoods: {
      //   subtotal: undefined,
      //   length: undefined
      // },
      // 付款方式
      payType: 'paypal',
      // 成功提示
      showTips: false
    }
  },
  // head() {
  //   return {
  //     script: [
  //       {
  //         src: `https://www.paypal.com/sdk/js?client-id=${config.clientId}&commit=true`
  //       }
  //     ]
  //   }
  // },
  computed: {
    payReady() {
      const address = this.shippingAddress
      const deliveryFee = this.deliveryFee
      if (address && JSON.stringify(address) !== '{}' && deliveryFee && JSON.stringify(deliveryFee) !== '{}') {
        return true
      }
      return false
    }
  },
  watch: {
    // 是否填写条件(地址/派送方式)
    payReady(val) {
      if (val) {
        hideCover()
      } else {
        showCover()
      }
    }
  },
  created() {
    // if (process.client) {
    //   const ids = this.$route.params.list
    //   if (!ids || !ids.length) {
    //     this.$tips({ msg: 'The current page is invalid!' })
    //     setTimeout(() => {
    //       this.$router.replace({ name: 'shoppingCart' })
    //     }, 2000)
    //   }
    // }
  },
  mounted() {
    this.$loading().open()
    const getPay = setInterval(() => {
      console.log('listen in-----')
      // eslint-disable-next-line no-undef
      if (paypal) {
        clearInterval(getPay)
        paypalFn(this, {
          goList: () => {
            this.$router.replace({ name: 'centerMain-order-order' })
          }
        })
        this.$loading().close()
      }
    }, 400)
  },
  methods: {
    userNote(text) {
      this.orderNote = text
    },
    addressChange(info) {
      this.shippingAddress = info
      this.$api.shoppingCart
        .payDelivery({
          country: info.country
        })
        .then(res => {
          if (res.code === 200) {
            this.medthod = res.data
            if (res.data.length) {
              this.methodType = { id: res.data[0].id, name: res.data[0].shipping_method }
              this.setMedthosTpye(this.methodType)
            }
          }
        })
    },
    billingChange(info) {
      this.billingAddress = info
    },
    // 设置配送方式  设置运费
    setMedthosTpye(info) {
      const list = this.medthod
      this.methodType = info
      const index = list.findIndex(item => {
        return item.id === info.id
      })
      this.deliveryFee = {
        price: list[index].shipping_fee,
        name: list[index].shipping_method
      }
    },
    setPayType(info) {
      this.payType = info
    },
    getInfo() {
      const shipping = this.shippingAddress
      const billing = this.billingAddress
      // eslint-disable-next-line camelcase
      const express_id = this.methodType.id
      // eslint-disable-next-line camelcase
      const pay_way = this.payType
      // eslint-disable-next-line camelcase
      const submit_code = this.getRandomString
      // eslint-disable-next-line camelcase
      const cart_id = this.$route.params.list
      // eslint-disable-next-line camelcase
      const order_remark = this.orderNote
      // eslint-disable-next-line camelcase
      let is_billing = 0
      if (JSON.stringify(billing) !== '{}') {
        // eslint-disable-next-line camelcase
        is_billing = 1
      }
      const data = {
        shipping,
        billing,
        // eslint-disable-next-line camelcase
        is_billing,
        // eslint-disable-next-line camelcase
        express_id,
        // eslint-disable-next-line camelcase
        cart_id,
        // eslint-disable-next-line camelcase
        pay_way,
        // eslint-disable-next-line camelcase
        submit_code,
        // eslint-disable-next-line camelcase
        order_remark
      }
      return data
    }
  }
}
function paypalFn(that, fnList) {
  // eslint-disable-next-line no-undef
  paypal
    .Buttons({
      style: {
        layout: 'vertical', // horizontal - vertical
        color: 'blue',
        // label: 'Place Order',
        tagline: false
      },
      // 创建订单
      createOrder(data, actions) {
        console.log('startCreateOrder')
        return that.$api.shoppingCart.createOrder(that.getInfo()).then(res => {
          console.log('createOrderEnd')
          if (res.code !== 200) {
            // 未成功创建订单
            that.$tips({ msg: res.msg, time: 6000 })
            return false
          }
          return res.data
          // return false
        })
      },
      // 交易结果后调用
      onApprove(data) {
        console.log('startBusiness')
        return that.$api.shoppingCart
          .capturePayment(data)
          .then(res => {
            console.log(res, 'businessEnd')
            if (res.code === 200) {
              that.showTips = true
            } else {
              that.$tips({ msg: res.msg, time: 6000 })
              that.$loading().open()
              setTimeout(() => {
                fnList.goList()
                that.$loading().close()
              }, 600)
            }
            return res
          })
          .catch(() => {
            that.$tips({ msg: 'Payment timeout...', time: 6000 })
            // 网络未接入，网络中断，服务器无法连接
            fnList.goList()
          })
      },
      // 取消操作
      onCancel(data) {
        console.log('Cancel')
        fnList.goList()
      },
      // 错误操作
      onError(data) {
        console.log('Error')
        // fnList.goList()
      }
    })
    .render('#paypal-button-container')
}
/**
 * 显示支付按钮遮罩层
 */
function showCover() {
  const cover = document.querySelector('#cover-btn')
  cover.classList.add('cover-index')
}
/**
 * 隐藏支付按钮遮罩层
 */
function hideCover() {
  const cover = document.querySelector('#cover-btn')
  cover.classList.remove('cover-index')
}
</script>

<style lang="scss" scoped>
.payment-body {
  max-width: 1440px;
  margin: 0 auto 50px;
  overflow: hidden;
  padding: 0 25px;
}
.pay-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.left {
  flex: 1;
  margin-right: 60px;
  .item-com {
    margin-bottom: 20px;
  }
}
// .right {
// }
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .payment-body {
    padding: 0 16px;
  }
  .pay-content {
    flex-direction: column;
  }
  .left {
    margin-right: 0;
  }
}
</style>
