<template>
  <div class="page">
    <div class="body-text">
      <div class="content">
        <h1 class="page-title">Why Choose Stylool</h1>
        <p class="title-item">Precise Prescription and Quality Guarantee is Our Focus</p>
        <p class="item-text">
          The lens lab of Stylool is consist of a team of opticians and optimists, equipped with the most advanced optical equipment. The product assembly line includes some of the most exquisite lens designed today. If
          you are in need of our customized eyewear, the high-tech lenses provide you the best adaptation and offer you a perfect sight.
        </p>
        <p class="item-text">When you order any of our products on our platform, please carefully check the following indicators:</p>
        <p class="item-text">
          The model number and colors are according to its stock and the information that it provides online. These perform quality control checks on the frame in the lab and the selections you make during the order
          process. All lenses are fitted by experienced optical technicians, with numerous lens accuracy checks throughout the assembly process. Remember that all of our products pass through six steps of quality
          inspection before shipping.
        </p>
        <p class="item-text">
          What’s more, the glasses will be re-checked again as part of the packing and shipping process, only for quality assurance. All the hands-on quality control processes aim that you can receive the highest quality
          glasses every single time! Stylool takes pride in the craftwork that it has owned for all these years and the honor that every pair of eyeglasses meets the extremely high standards of its valued customers.
        </p>
        <p class="title-item">Unbeatable Online Savings on Stylool</p>
        <p class="item-text">
          All Stylool frames and lenses are directly from the manufacturer. We cut down the prices by eliminate exporters, wholesalers, and retailers. It is estimated that the average customer could save over 75% price
          margin by shopping on Stylool. Moreover, the shipping delivery process also saves you money because Stylool ships with country-specific protocols and environmentally friendly materials. And every pair of
          eyeglasses meets the extremely high standards of its valued customers.
        </p>
        <p class="item-text">
          Pioneering shopping websites, like Stylool, are actively trying to build a new era where fashionable eyewear is cheap and durable. Stylool does sell ultra-trendy, professional prescription quality eyeglasses at
          a competitive price because of its efficient processes and lower management costs.
        </p>
      </div>
    </div>
    <div class="content">
      <div class="center-flow">
        <img src="/imgs/flow.png" alt="" />
      </div>
      <div class="bottom-other">
        <div class="contact-option">
          <div class="left">
            <img src="/imgs/tsphc.png" alt="" />
          </div>
          <div class="right">
            <div class="title-text">
              <h3>The Scratch-resistant Coating is Default</h3>
            </div>
            <p>
              A scratch-resistant coating is another premium feature with a price from $30 to $50 that can be bought at any of your local optician. While on Stylool, it's absolutely FREE. The scratch-resistant coating is
              very durable and is applied on both sides of the lens. This coating is excellent in minimizing the tiny scratches that generally occur in our day-to-day usage of the glasses.
            </p>
            <p>
              Stylool also offers a premium anti-reflective coating which is even better in glare reduction and durability at an affordable price of $5 which is relatively cheaper than the market. The premium
              anti-reflective coating is ideal for computer users and night drivers.
            </p>
          </div>
        </div>
        <div class="contact-option">
          <div class="right">
            <div class="title-text">
              <h3>Free Accessories</h3>
            </div>
            <p>
              Along with your new glasses, you will also receive gift accessories: a soft eyeglass pouch and a PD ruler. A soft eyeglass pouch is very effective in keeping your lenses crystal clear. With the help of a PD
              ruler, you will be able to measure your PD at home.
            </p>
            <p>All that Stylool cares about is that every customer finds what they are looking for when clicking through our site, and what they receive is worth every penny they spend.</p>
          </div>
          <div class="left">
            <img src="/imgs/fa.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Lfooter />
  </div>
</template>

<script>
import Lfooter from '@/components/Lfooter/index.vue'
export default {
  name: 'why-choose-stylool',
  components: {
    Lfooter
  },
  data() {
    return {}
  },
  head() {
    return {
      title: 'Why Choose Stylool'
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.page {
  position: relative;
}
.body-text {
  background: linear-gradient(0deg, rgba(224, 238, 255, 0.09), #a2cdfd);
}
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}
.page-title {
  padding: 30px 0;
  text-align: center;
}
.title-item {
  font-size: 22px;
  font-weight: 600;
  color: #191919;
  margin-bottom: 15px;
}
.item-text {
  font-size: 14px;
  color: #191919;
  margin-bottom: 15px;
}
.center-flow {
  margin: 40px 0;
  img {
    width: 80%;
  }
}
.bottom-other {
  margin-bottom: 30px;
}
.contact-option {
  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
  .left {
    width: 50%;
    flex-shrink: 0;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }
  .title-text {
    font-size: 22px;
    margin-bottom: 15px;
  }
  p {
    color: #727272;
    font-size: 14px;
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .page-title {
    font-size: 20px;
    padding: 20px 0;
  }
  .title-item {
    font-size: 16px;
  }
  .item-text {
    font-size: 13px;
  }
  .center-flow {
    img {
      width: 100%;
    }
  }
  .contact-option {
    flex-direction: column;
    .left {
      width: 100%;
    }
    .title-text {
      h3 {
        font-size: 16px;
      }
      margin-bottom: 8px;
      text-align: center;
    }
    p {
      font-size: 13px;
      margin-bottom: 8px;
      text-align: left;
    }
  }
}
</style>
