<template>
  <div class="product-list">
    <div class="glassesList" :style="{ marginBottom: top ? '0' : '70px' }">
      <!-- {{ $route.params.glassesList }} -->
      <div class="recommend" @click="bannerPage(coverCode)">
        <img :src="cover" alt="banner" />
      </div>
      <Lfilter ref="filter" :length="listConfig.count" @filterData="filterData" />
      <Lgoods id="list" ref="list" :loading="loading" :option="listConfig" :list="goodsList" @page="changePage" />
      <!-- <div class="shopList"></div> -->
      <LgoTop v-if="$vuetify.breakpoint.smAndDown" />
    </div>
  </div>
</template>

<script>
import { typeName } from '@/assets/utils/renderData'
import head from '@/assets/utils/TDK'
import { searchPath } from '@/assets/utils/auth'
import Lfilter from '@/components/Lfilter/index.vue'
import Lgoods from '@/components/Lgoods/index.vue'
import LgoTop from '@/components/LgoTop/index.vue'
export default {
  name: 'productList',
  key() {
    return Math.random()
  },
  components: {
    Lfilter,
    Lgoods,
    LgoTop
  },
  validate({ params, query }) {
    // throw new Error('Under Construction!')
    return searchPath(params.type)
  },
  /**
   * asyncData 接收route路径变动
   * 1 页脚导航
   * 2 nav导航
   * 3 page变更
   * 4 筛选条件变更
   * router对筛选条件 filter数据进行解码
   * 将params+query条件整合为 config
   * 将 filter合并至config
   * 形成最后查询条件
   * (sort) 可能出现先存在params  筛选操作下存在于filter
   * 经由Object.assign 实际以后手filter为最终数据
   */
  async asyncData({ route, $api, error, app }) {
    const query = route.query
    const params = route.params
    const config = {
      limit: 18,
      type: params.type,
      sort: params.sort ? params.sort : 'Default',
      page: query.page ? query.page : 1
      // [cut]: [query.id ? query.id : '']
    }
    if (query.search) {
      config.search = query.search
    }
    // const filter = query.filter ? query.filter : 'all'
    // if (filter !== 'all') {
    //   Object.assign(config, filter)
    // }
    // filter转码
    let filter
    if (query.filter && query.filter !== 'all') {
      filter = decodeURI(query.filter)
      try {
        filter = JSON.parse(filter)
        Object.assign(config, filter)
      } catch (error) {
        filter = 'all'
      }
    }
    const { data: goodsList, count, cover, coverCode } = await $api.product.getGoodsList(config)

    return {
      goodsList,
      cover,
      coverCode,
      listConfig: {
        filter,
        page: config.page,
        limit: config.limit,
        search: config.search,
        count
      },
      loading: false
    }
  },
  data() {
    return {
      cover: undefined,
      coverCode: undefined,
      // 商品列表
      goodsList: [],
      // 商品参数
      listConfig: {
        count: null,
        limit: 18
      },
      loading: false
    }
  },
  head() {
    return {
      title: head(this.$route.params.type).title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: head(this.$route.params.type).description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: head(this.$route.params.type).keywords
        }
      ]
    }
  },

  computed: {
    top: function () {
      if (this.$route.name === 'search') {
        return ''
      }
      return 'none'
    },
    typeName() {
      return typeName(this.$route.params.type) || this.$route.query.search
    }
  },

  watch: {
    // 路径变动时清空查询条件
    '$route.path': {
      handler(val, old) {
        if (val !== old) {
          this.listConfig.type = this.$route.params.type
          this.$refs.filter.clearAll()
        }
      }
    }
  },
  watchQuery: true,
  created() {},
  mounted() {
    this.recordPage('list')
  },
  methods: {
    // 记录进入页面
    recordPage(type) {
      this.$api.system.track_log({ type })
    },
    // 点图跳转
    bannerPage(code) {
      if (code) {
        this.$router.push({
          name: 'details-code',
          params: { code }
        })
      }
    },
    // 移动页面
    getViewScroll() {
      let height = 0
      const recommend = document.querySelector('.recommend')
      if (recommend) {
        if (document.documentElement.scrollTop < recommend.clientHeight) {
          return false
        } else {
          height = height + recommend.clientHeight
        }
      }
      document.documentElement.scrollTop = height
    },
    // 切换页码 整合现有筛选条件 携带跳转更改路径 触发asyncData
    getListPage(page = 1) {
      this.getViewScroll()
      this.loading = true
      const query = {
        page,
        search: this.listConfig.search
      }
      if (this.listConfig.filter && this.listConfig.filter !== 'all') {
        const filter = encodeURI(JSON.stringify(this.listConfig.filter))
        query.filter = filter
      }
      this.$router.push({
        name: 'search-type',
        params: this.$route.params,
        query
      })
    },
    // 翻页
    changePage(val) {
      this.getListPage(val)
    },
    // 筛选条件
    filterData(val) {
      const selected = val.selected
      const list = {} // 查询条件
      // 组合
      for (const key in selected) {
        if (selected[key].length) {
          list[key] = []
          for (let index = 0; index < selected[key].length; index++) {
            const item = selected[key][index]
            if (key === 'size') {
              list[key].push(item.name)
            } else {
              list[key].push(item.id)
            }
          }
        }
      }
      // size选项处理 (单选)
      if (val.selected.size && val.selected.size[0].name === 'Custom') {
        if (JSON.stringify(val.widthVal) !== '{}') {
          list.frame_width_min = val.widthVal.FrameValue[0]
          list.frame_width_max = val.widthVal.FrameValue[1]
          list.temple_min = val.widthVal.TempleValue[0]
          list.temple_max = val.widthVal.TempleValue[1]
          list.lens_width_min = val.widthVal.lensW[0]
          list.lens_width_max = val.widthVal.lensW[1]
          list.lens_height_min = val.widthVal.lensH[0]
          list.lens_height_max = val.widthVal.lensH[1]
          list.bridge_min = val.widthVal.bridgeW[0]
          list.bridge_max = val.widthVal.bridgeW[1]
        }
      }
      // 合并筛选项
      const query = this.$route.query
      let filter = query.filter ? query.filter : 'all'
      // if (filter && filter !== 'all') {
      //   filter = decodeURI(filter)
      //   try {
      //     // 上次筛选
      //     filter = JSON.parse(filter)
      //     // Object.assign(filter, list)
      //     // 当前筛选
      //     const result = Object.assign({}, filter, list)
      //   } catch (error) {
      //     filter = list
      //   }
      // } else {
      //   filter = list
      // }
      filter = list
      filter.sort = val.sort
      // 重置页面查条件
      this.listConfig = {
        page: 1,
        limit: 18,
        count: null,
        // sort: val.sort, // 排序方式
        filter
      }
      if (query.search) {
        this.listConfig.search = query.search
      }
      this.getListPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend {
  img {
    width: 100%;
  }
  cursor: pointer;
}
#list {
  &::v-deep.body-page {
    scroll-margin: 145px;
    padding-top: 20px;
    // margin-bottom: 145px;
  }
}
</style>
