<template>
  <div class="content-box goodsinfo">
    <div class="product-module">
      <v-row no-gutters>
        <imgDialog :show="imgDiologs" :list="getGoodsimgList" :current="currentImgIndex" />
        <sizeDialog :show="sizeDialogs" :info="info" />
        <couponsDialog :show="couponsDialogs" />
        <v-col cols="12" :md="6">
          <template v-if="info.image.length">
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <div class="current-photo" :class="{ videoBg: showMode }">
                <!-- 轮播模式----------------------------------------------------- -->
                <div v-if="!showMode" class="main-box" :class="{ unfull: info.image[currentImgIndex].type === 1, full: info.image[currentImgIndex].type === 2 }">
                  <!-- <v-img class="img-main" transition="scale-transition" :src="imgList[currentImgIndex].src" @click.native="preview" /> -->
                  <scale :src="info.image[currentImgIndex].path" fit="scale-down" :alt="info.alt" @click.native="preview" />
                </div>
                <template v-else>
                  <!-- <video-player :video-url="info.image[currentImgIndex].path" /> -->
                  <video-player :video-url="info.image[currentImgIndex].path" />
                </template>
              </div>
              <div class="img-box">
                <div class="swiper mySwiperP">
                  <div class="swiper-wrapper">
                    <div v-if="showModel" class="swiper-slide item-swiper try-box" @click="toTry">
                      <img class="try" src="/imgs/try.png" alt="try on" />
                      TRY ON
                    </div>
                    <div v-for="(item, index) in info.image" :key="index" class="swiper-slide item-swiper" :class="currentImgIndex == index ? 'active-img' : ''" @click="currentImgIndex = index">
                      <!-- <v-img class="img-items" :class="{ coverBox: item.type === 2 }" lazy-src="/imgs/imgLazy.jpg" contain :src="item.path" :art="info.art" /> -->
                      <!-- <img class="img-items" :class="{ coverBox: item.type === 2 }" :src="item.path" :alt="info.alt ? info.alt + ' Glasses' + index : ''" /> -->
                      <template v-if="[1, 2].includes(item.type)">
                        <img :key="index" v-lazy="item.path" class="img-items" :class="{ coverBox: item.type === 2 }" :alt="info.alt ? info.alt + ' Glasses' + index : ''" />
                      </template>
                      <template v-else>
                        <img style="width: 50%" src="/imgs/player.png" art="playerVideo" />
                      </template>
                    </div>
                  </div>
                  <div class="swiper-button-next" @click="changeImgView('next')">
                    <img class="next-icon" src="/imgs/to.png" alt="next" />
                  </div>
                  <div class="swiper-button-prev" @click="changeImgView('prev')">
                    <img class="prev-icon" src="/imgs/to.png" alt="prev" />
                  </div>
                  <!-- <div class="swiper-pagination"></div> -->
                </div>
              </div>
            </div>
            <div v-else class="mobile-img accent-2">
              <!-- <v-carousel v-model="currentImgIndex" class="my-carousel" hide-delimiters hide-delimiter-background :show-arrows="false">
                <v-carousel-item v-for="(item, i) in info.image" :key="i" contain class="carousel-img" :class="{ unfullM: item.type === 1, fullM: item.type === 2 }" :src="item.path" @click.native="preview" />
              </v-carousel>
              <div class="imgNum">{{ currentImgIndex + 1 }}/{{ info.image.length }}</div> -->
              <div class="swiper mySwiperM">
                <div class="swiper-wrapper">
                  <div v-for="(item, index) in info.image" :key="index" class="swiper-slide" :class="{ unfullM: item.type === 1, fullM: item.type === 2, videoBg: item.type == 3 }">
                    <!-- <v-img class="img-items" lazy-src="/imgs/imgLazy.jpg" contain :src="item.path" @click="swiperPreview(index)" /> -->
                    <template v-if="[1, 2].includes(item.type)">
                      <img :key="index" v-lazy="item.path" class="img-items" :alt="info.alt ? info.alt + ' Glasses' + index : ''" @click="swiperPreview(index)" />
                    </template>
                    <template v-else>
                      <!-- <video-player :video-url="info.image[currentImgIndex].path" /> -->
                      <video-player :video-url="item.path" :is-end="item.path !== info.image[currentImgIndex].path" />
                    </template>
                  </div>
                </div>
                <span v-show="!showMode" class="try-text" @click="toTry">TRY ON</span>
                <div v-show="!showMode" class="imgNum">{{ currentImgIndex + 1 }}/{{ info.image.length }}</div>
              </div>
            </div>
          </template>
        </v-col>

        <v-col cols="12" :md="6">
          <div class="goods-option">
            <div class="module">
              <div class="moduleFlex">
                <div class="goods-title">
                  <span class="name">{{ info.title }}</span>
                  <div v-if="$vuetify.breakpoint.mdAndUp" class="collection-box" @click="collection(info)">
                    <img v-if="info.is_wishlist" class="collection" contain src="/imgs/collected.png" />
                    <img v-else class="collection" contain src="/imgs/collection.png" />
                  </div>
                </div>
                <div class="score">
                  <v-rating :value="info.score" :length="5" half-increments readonly background-color="orange lighten-3" color="orange" />
                  <!-- <span>{{ productScore }}</span> -->
                  <span v-if="$vuetify.breakpoint.mdAndUp" style="cursor: pointer" @click="jumpReviews">{{ info.reviews }} Reviews</span>
                </div>
              </div>
              <div class="price-module" :class="{ tagBoxBg: info.price - 0 > 7, tagNoBg: info.price - 0 <= 7 }">
                <div v-if="info.price !== info.original" class="price">
                  <span class="current">{{ currencyIcon }}{{ info.price }}</span
                  ><span class="old-price">{{ currencyIcon }}{{ info.original }}</span>
                </div>
                <div v-else class="price">
                  <span class="price-only">{{ currencyIcon }}{{ info.price }}</span>
                </div>
                <div v-if="info.price - 0 > 7" class="coupons">
                  <div class="left">
                    <span class="coupon-tips">US $7 Off First Order</span>
                  </div>
                  <div class="tag" @click="checkCoupns">Coupons <img src="/imgs/to.png" alt="coupons" /></div>
                </div>
              </div>
            </div>
            <div class="goods-config">
              <!-- 其他商品 -->
              <div class="goods-box">
                <div class="top">
                  <p class="goods-title">
                    Color: <span class="goods-name">{{ info.color }}</span>
                  </p>
                  <div v-if="info.stock > 0" class="goods-state in">in stock</div>
                  <div v-else class="goods-state out">out of stock</div>
                </div>
                <div class="bottom">
                  <div class="goods-list">
                    <p
                      v-for="(list, indexColor) in info.variant"
                      :key="indexColor"
                      class="goods-item"
                      :class="{ active: defaultColorIndex == indexColor ? true : false, disable: list.stock == 0 }"
                      @click="changeTheme(indexColor)"
                    >
                      <nuxt-link :to="`/details/${list.code}`" class="goods-text" :style="{ backgroundImage: `url(${list.image})` }">
                        <!-- <span ></span> -->
                      </nuxt-link>
                      <!-- 标记热卖 -->
                      <template v-if="list.is_hot">
                        <img class="hot-product-icon" src="/imgs/hot.png" alt="hot-product" />
                      </template>
                    </p>
                  </div>
                </div>
              </div>
              <!-- 尺码 -->
              <div v-if="showModel" class="size">
                <span class="size-name">Size: </span>
                <div class="size-list">
                  <p class="size-item">{{ info.size }}</p>
                  <p class="size-tips" @click="sizeDialogs = true">Frame Size Guide</p>
                </div>
              </div>
              <!-- 下一步 -->
              <div v-if="$vuetify.breakpoint.mdAndUp" class="option">
                <template v-if="showModel">
                  <template v-if="info.stock">
                    <v-btn class="my-btn" depressed block color="#3067F2" @click="goSelect">Select Lenses</v-btn>
                    <v-btn class="my-btn" depressed style="color: #000" block color="#F0F4FF" @click="addToCart">Frame Only</v-btn>
                  </template>
                  <template v-else>
                    <v-btn class="my-btn" block color="#F2994A" @click="contact">Remind Us to Replenish</v-btn>
                  </template>
                </template>
                <template v-else>
                  <template v-if="info.stock">
                    <v-btn class="my-btn" block color="#3067F2" @click="addToCart">Add To Cart</v-btn>
                  </template>
                  <template v-else>
                    <v-btn class="my-btn" block color="#F2994A" @click="contact">Remind Us to Replenish</v-btn>
                  </template>
                </template>
              </div>
              <div class="tips">
                <div class="tips-item">
                  <!-- <img src="/imgs/cycle.png" alt="" /> -->
                  <span>30-day Return & Exchange</span>
                </div>
                <div class="tips-item">
                  <!-- <img src="/imgs/repair.png" alt="" /> -->
                  <span>Customized by Craftsmen with Care</span>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown" class="goods-btn-option">
      <div class="option-box">
        <div class="left-option">
          <div class="btn-collection" @click="collection(info)">
            <img v-if="info.is_wishlist" class="collection" contain src="/imgs/collected.png" />
            <img v-else class="collection" contain src="/imgs/collection.png" />
          </div>
        </div>
        <div class="btn-tobuy">
          <template v-if="showModel">
            <template v-if="info.stock > 0">
              <button type="button" class="frame-btn" @click="addToCart">Frame Only</button>
              <button type="button" class="lenses-btn" @click="goSelect">Select Lenses</button>
            </template>
            <template v-else>
              <button class="remind-btn" block color="#F2994A" @click="contact">Remind Us to Replenish</button>
            </template>
          </template>
          <template v-else>
            <template v-if="info.stock > 0">
              <button type="button" class="lenses-btn" @click="addToCart">Add To Cart</button>
            </template>
            <template v-else>
              <v-btn class="my-btn remind-btn" block color="#F2994A" @click="contact">Remind Us to Replenish</v-btn>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import scale from '@/components/toolCom/scale.vue'
import imgDialog from '@/components/Ldetails/components/imgDialog.vue'
import sizeDialog from '@/components/Ldetails/components/sizeDialog.vue'
import couponsDialog from '@/components/Ldetails/components/couponsDialog.vue'
import videoPlayer from '@/components/Ldetails/components/videoPlayer.vue'

//  商品预览模块相关
import { imgPreview } from '@/components/Ldetails//mixin/imgPreview'
export default {
  name: 'goodsInfo',
  components: {
    scale,
    imgDialog,
    sizeDialog,
    couponsDialog,
    videoPlayer
  },
  mixins: [imgPreview],
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      imgDiologs: false, // 图片弹窗
      sizeDialogs: false, // size 弹窗
      showTicket: false, // 联系我们
      couponsDialogs: false // 优惠券弹窗
    }
  },
  computed: {
    ...mapGetters('system', ['currencyIcon']),
    ...mapState({
      userToken: state => state.system.userToken
    }),
    // 是否显示某些模块 (眼镜 / 配件)
    showModel() {
      const type = this.info.category
      if (type === 'accessories') {
        return false
      } else {
        return true
      }
    },
    defaultColorIndex: {
      // 默认首选颜色
      get() {
        const data = this.info
        const id = data.id
        const index = data.variant.findIndex(item => {
          return item.id === id
        })
        return index
      },
      set(val) {
        this.defaultColorIndex = val
      }
    },
    // 过滤出纯图片数组
    getGoodsimgList() {
      const list = this.info.image
      const arr = []
      for (let index = 0; index < list.length; index++) {
        const item = list[index]
        arr.push(item.path)
      }
      return arr
    }
  },
  watch: {
    '$route.query.id': {
      handler() {
        this.currentImgIndex = 0
        if (this.mobileSwiper) {
          this.mobileSwiper.slideTo(0, 0, false)
        }
      },
      deep: true
    },
    currentImgIndex: {
      handler(val) {
        if ([1, 2].includes(this.info.image[val].type)) {
          this.showMode = 0
        } else {
          this.showMode = 1
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.recordPage('info')
  },
  methods: {
    toTry() {
      this.$store.commit('system/toggleTryOnShow', { state: true, img: this.info.image_frame, code: this.info.code })
    },
    // 库存不足联系
    contact() {
      if (this.userToken) {
        this.$tips({ msg: 'Successful, you will receive an email when stock is available.' })
      } else {
        this.$store.commit('system/toggleLogin', true)
      }
    },
    preview() {
      if (this.$vuetify.breakpoint.mobile) {
        this.imgDiologs = true
      }
    },
    addToCart() {
      const params = { code: this.info.code }
      // if (!this.userToken) {
      //   // this.$store.commit('system/toggleLogin', true)
      //   // return false
      // }
      // else {
      // }
      // params.status = !this.userToken
      this.$api.shoppingCart.addToCart(params).then(res => {
        if (res.code === 200) {
          this.$router.push({
            name: 'shoppingCart'
          })
          this.$api.shoppingCart.incompleteFrame({ product_code: this.info.code })
          this.$store.dispatch('system/getCollectionsAndCartsNum', { axios: this.$api })
        }
      })
    },
    // 记录进入页面
    recordPage(type) {
      this.$api.system.track_log({ type })
    },
    goSelect() {
      // if (!this.userToken) {
      //   this.$store.commit('system/toggleLogin', true)
      //   return false
      // }
      this.recordPage('flow')
      this.$router.push({
        name: 'product',
        query: {
          code: this.info.code
        }
      })
    },
    changeTheme(index) {
      if (index === 2) {
        this.$parent.info.goodsState = false
      } else {
        this.$parent.info.goodsState = true
      }
    },

    collection(item) {
      if (this.userToken) {
        this.$api.product.setWishlist({ code: item.code }).then(res => {
          if (res.code === 200) {
            item.is_wishlist ? (item.is_wishlist = false) : (item.is_wishlist = true)
            this.$store.dispatch('system/getCollectionsAndCartsNum', { axios: this.$api })
          }
        })
      } else {
        this.$store.commit('system/toggleLogin', true)
      }
      // data.collection ? (data.collection = false) : (data.is_wishlist = true)
    },
    // 切换到评论
    jumpReviews() {
      this.$emit('jumpReviews')
      // console.log(document.getElementsByClassName('introduction')[0])
    },
    // 检查优惠券
    checkCoupns() {
      if (this.userToken) {
        this.couponsDialogs = true
      } else {
        this.$store.commit('system/toogglecoupons', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/detail/goodsInfo.scss';
</style>
