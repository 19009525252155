<template>
  <div class="count-body">
    <div id="count-content" class="count-content">
      <!-- 折扣代码 -->
      <component :is="renderCode" ref="coupon" @useCoupon="useCoupon" @cancelCoupon="cancelCoupon" />
      <div class="product" :class="{ close: !showItem }">
        <div class="header-item" @click="showItem = !showItem">
          <span class="left">Items:</span>
          <span class="item-num">
            {{ total.sub_num == 0 ? '0' : total.sub_num }}
            <img class="toggle-icon" :class="{ rotate: showItem }" src="/imgs/more.png" alt="toggle" />
          </span>
        </div>
        <transition name="fade">
          <listItem v-if="showItem" class="list-box" :list="itemList" />
        </transition>
      </div>
      <div class="count-box">
        <ul class="list">
          <li>
            <span class="left">Subtotal</span>
            <span class="right">{{ currencyIcon }}{{ total.subtotal }}</span>
          </li>
          <li v-if="deliveryFee != null">
            <span class="left">{{ deliveryFee.name }}</span>
            <span class="right">{{ currencyIcon }}{{ deliveryFee.price }}</span>
          </li>
          <li v-if="discountList.length" class="discount-box">
            <span class="left">Discount</span>
            <!-- <span class="right">-{{ currencyIcon }}{{ total.discountTotal }}</span> -->
            <div class="list-discount">
              <p v-for="(discountItem, index) in discountList" :key="index">
                <span class="discount-left">{{ discountItem.text }}</span>
                <span class="discount-right">-{{ currencyIcon }}{{ discountItem.value }}</span>
              </p>
            </div>
          </li>
        </ul>
        <div class="option">
          <div class="count">
            <span class="left">Grand Total</span>
            <span class="right">{{ currencyIcon }}{{ addShippingTotal }}</span>
          </div>
          <div class="note">
            <textarea v-model="note" class="note-input" placeholder="(Optional) Any special requirements? Kindly leave us a message."></textarea>
          </div>
          <div class="btn-box">
            <div id="cover-btn" class="cover-index" @click="errorTips"></div>
            <!-- <div id="pay-button">Place Order</div> -->
            <div id="pay-button-container" @click="payFn">Pay</div>
            <!-- <div id="paypal-button-container"></div> -->
            <!-- <v-btn class="my-btn option-btn blue-btn" color="#3067F2" :disabled="disabledBtn" tile depressed block @click="Pay">Place Order</v-btn> -->
          </div>
        </div>
        <div class="text">
          <p><img class="icon" src="/imgs/transport.png" alt="icon" />Free shipping over US $69.00</p>
          <p><img class="icon" src="/imgs/30.png" alt="icon" />30-day Return&Exchange</p>
          <p><img class="icon" src="/imgs/repair2.png" alt="icon" />Customized by Craftsmen with Care</p>
        </div>
      </div>
    </div>
    <!-- <div class="flexd-option">
      <div class="count">
        <span class="left">Grand Total</span>
        <span class="right">{{ currencyIcon }}{{ total.subtotal }}</span>
      </div>
      <div class="btn-box">
        <div id="paypal-button-container"></div>
        <v-btn class="my-btn option-btn blue-btn" color="#3067F2" :disabled="disabledBtn" tile depressed block>Place Order</v-btn>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { add } from '@/assets/utils/decimal'
import listItem from '@/components/Lpayment/count/itemList'
const couponPc = () => import('@/components/Lpayment/count/couponCom/couponPc.vue')
const couponMobile = () => import('@/components/Lpayment/count/couponCom/couponMobile.vue')
export default {
  name: 'countCom',
  components: {
    listItem
  },
  props: {
    deliveryFee: {
      type: Object,
      default: () => {
        return {
          name: 'Advanced Shipping',
          price: 0
        }
      }
    }
  },
  data() {
    return {
      // -------------
      showItem: true,
      itemList: [],
      // 折扣明细
      discountList: [],
      // 已选商品 数量,价格
      total: {
        // 小计
        subtotal: undefined,
        // 折扣
        discountTotal: undefined,
        // 总价
        total: undefined,
        // 数量
        sub_num: undefined
      },
      note: ''
    }
  },
  computed: {
    ...mapGetters('system', ['currencyIcon']),
    disabledBtn() {
      // 获取父级数据
      // 选择配送方式和支付方式后  解除
      const data = this.$parent.getInfo()
      // if (!data.express_id || !data.pay_way) {
      if (!data.express_id) {
        return true
      } else {
        return false
      }
    },
    addShippingTotal() {
      const total = this.total.total
      if (JSON.stringify(this.deliveryFee) !== '{}' && this.deliveryFee) {
        // const deliveryFee = this.deliveryFee.price
        if (total) {
          return add(this.total.total, this.deliveryFee.price)
        }
      }

      return total
    },
    // 根据宽度显示组件
    renderCode() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return couponMobile
      } else {
        return couponPc
      }
    }
  },
  watch: {
    note(val) {
      this.$emit('userNote', val)
    }
  },
  mounted() {
    this.getGoodsInfo('init')
    // const flexdDom = document.querySelector('.flexd-option')
    // const countDom = document.querySelector('#count-content')
    // const observer = new IntersectionObserver(
    //   entries => {
    //     if (this.$vuetify.breakpoint.width <= 960) {
    //       entries.forEach(entry => {
    //         if (entry.isIntersecting) {
    //           flexdDom.style.display = 'none'
    //         } else {
    //           flexdDom.style.display = 'block'
    //         }
    //       })
    //     }
    //   },
    //   { threshold: 0.25 }
    // )
    // observer.observe(countDom)
  },
  methods: {
    errorTips() {
      this.$tips({ msg: 'Please add your address!' })
    },
    // 使用优惠券
    useCoupon(codeInfo) {
      this.getGoodsInfo('code', codeInfo)
    },
    // 取消优惠
    cancelCoupon() {
      this.getGoodsInfo('cancel')
    },
    // 获取订单商品列表
    getGoodsInfo(type, codeInfo) {
      const list = this.$route.params.list
      if (!list || !list.length) {
        return false
      }
      const params = {
        cart_id: this.$route.params.list
      }
      if (type === 'code' && codeInfo.code) {
        params.coupon_code = codeInfo.code
      }
      this.$api.shoppingCart.payProductList(params).then(res => {
        if (res.code === 200) {
          this.itemList = res.data
          this.discountList = res.discountList
          this.total.subtotal = res.subtotal
          this.total.discountTotal = res.discountTotal
          this.total.total = res.total
          this.total.sub_num = res.sub_num
          if (type !== 'init') {
            if (!res.coupon_error) {
              if (type === 'code') {
                this.$emit('codeCoupon', codeInfo.code)
                this.$refs.coupon.couponState = 'result'
                setTimeout(() => {
                  this.$refs.coupon.sheet = false
                }, 400)
              } else if (type === 'cancel') {
                this.$emit('codeCoupon', undefined)
                this.$refs.coupon.couponState = 'wait'
              }
            } else {
              this.$refs.coupon.couponState = 'wait'
              this.$tips({ msg: res.coupon_error })
            }
          }
        }
      })
    },
    payFn() {
      this.$emit('toPay')
    },
    goHome() {
      this.$router.push({
        name: 'index'
      })
    }
    // Pay() {
    //   // this.$loading().open()
    //   const info = this.$parent.getInfo()
    //   info.cart_id = this.$route.params.list
    //   this.$api.shoppingCart
    //     .orderSubmit(info)
    //     .then(res => {
    //       if (res.code === 200) {
    //         console.log(res.data)
    //       }
    //     })
    //     .finally(() => {
    //       // this.$loading().close()
    //     })
    //   // console.log(this.$parent.getInfo())
    // }
  }
}
</script>

<style lang="scss" scoped>
// @media screen and (min-width: 1280px) {
//   .count-content {
//     position: sticky;
//     top: 0;
//   }
// }
.btn-box {
  position: relative;
}
#cover-btn {
  position: absolute;
  background: transparent;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: -99;
  &.cover-index {
    z-index: 10;
  }
}
#pay-button-container {
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  background: #000;
  padding: 3%;
  border-radius: 5px;
  cursor: pointer;
}
.item-num {
  display: flex;
  align-items: center;
  // font-weight: 600;
  color: #2c2a2a;
  font-size: 16px;
  cursor: pointer;
}
.item-box {
  position: relative;
}
.rotate {
  transform: rotate(180deg);
}
.toggle-icon {
  width: 12px;
  transition: 0.3s;
  margin-left: 6px;
}
.product {
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 25px 25px 0 25px;
  margin-bottom: 16px;
  .left {
    font-size: 16px;
    color: #2c2a2a;
    // font-weight: 600;
  }
  &.close {
    padding: 15px 25px;
  }
}
.header-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
// .list-box {
//   position: absolute;
//   z-index: 2;
//   top: 25px;
// }
.count-body {
  width: 430px;
  .flexd-option {
    display: none;
  }
}

.count-box {
  padding: 25px 25px;
  background: #fff;
}
.list {
  list-style: none;
  padding-left: 0 !important;
  li {
    font-size: 16px;
    color: #2c2a2a;
    // font-weight: 600;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }
}
.discount-box {
  flex-direction: column;
  .list-discount {
    padding-left: 10px;
    color: #ff0000;
    p {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}
.option {
  padding-top: 15px;
  padding-bottom: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #2b2e33;
  font-weight: 600;
  margin-bottom: 15px;
}
.count .right {
  font-size: 18px;
  font-weight: 600;
}

.note {
  margin-bottom: 15px;
}
.note-input {
  width: 100%;
  height: 75px;
  padding: 8px;
  color: #817e7e;
  border: 1px solid #e0e0e0;
  outline: none;
}
.option-btn {
  height: 52px !important;
}
.blue-btn {
  color: #fff !important;
  margin-bottom: 10px;
}
.white-btn {
  color: #3067f2 !important;
}
.text {
  overflow: hidden;
  margin-top: 16px;
  p {
    font-size: 14px;
    // font-weight: 600;
    color: #2b2e33;
    margin-top: 15px;
    margin-bottom: 0;
  }
  .icon {
    height: 18px;
    margin-right: 5px;
    position: relative;
    top: 4px;
  }
}
/*设置动画*/
.fade-enter,
.fade-leave-to,
.fade-leave {
  transform: rotate3d(180, 0, 0, 90deg);
}
// .fade-leave {
//   transition: 0.8s;
//   display: none;
// }
.fade-enter-active {
  transition: 0.3s;
}
.fade-leave-active {
  transition: 0.3s;
}
.fade-enter-to {
  transform: rotate3d(180, 0, 0, 0deg);
}
// .fade-enter,
// .fade-leave-to,
// .fade-leave {
//   transform-origin: top;
//   transform: rotate3d(180, 0, 0, 90deg);
// }
// .fade-leave {
//   display: none;
// }
// .fade-enter-active,
// .fade-leave-active {
//   transition: 0.5s;
// }
// .fade-enter-to {
//   transform-origin: top;
//   transform: rotate3d(180, 0, 0, 0deg);
// }
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .count-body {
    width: 100%;
    .count {
      margin-bottom: 20px;
    }
    .flexd-option {
      display: block;
      position: fixed;
      bottom: 0;
      padding: 12px;
      width: 100%;
      background: #fff;
    }
  }
  .item-num {
    font-size: 14px;
  }
  .product {
    padding: 12px;
    margin-bottom: 10px;
    &.close {
      padding: 15px;
    }
  }
  .count-box {
    padding: 30px 12px;
  }
}
</style>
