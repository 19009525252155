<template>
  <div class="content">
    <img v-if="$vuetify.breakpoint.smAndDown" class="close-icon" src="/imgs/close.png" alt="close" @click="close" />
    <div class="top">
      <!-- 镜框--------------------- -->
      <div class="item">
        <p class="title-tips">Frame</p>
        <div class="list">
          <p class="left">{{ getInventory.frame.title }}</p>
          <p class="right">{{ currencyIcon }}{{ getInventory.frame.price }}</p>
        </div>
      </div>
      <!-- 用途--------------------- -->
      <div v-if="getInventory.prescription.length" class="item">
        <p class="title-tips">Prescription</p>
        <div v-for="(item, index) in getInventory.prescription" :key="index" class="list">
          <p class="left">{{ replaceType(item.item) }}</p>
          <p v-if="item.price" class="right">{{ currencyIcon }}{{ item.price }}</p>
        </div>
      </div>
      <!-- 镜片--------------------- -->
      <div v-if="getInventory.lens.length" class="item">
        <p class="title-tips">Lens</p>
        <div v-for="(item, index) in getInventory.lens" :key="index" class="list">
          <p class="left">{{ item.item }}</p>
          <p v-if="item.price" class="right">{{ currencyIcon }}{{ item.price }}</p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="item list">
        <p class="left title-tips">Subtotal</p>
        <p class="right">{{ currencyIcon }}{{ getInventory.total }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { replaceSunglasses } from '@/assets/utils/renderData'
export default {
  name: 'priceList',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('productConfig', ['btnName', 'getInventory']),
    ...mapGetters('system', ['currencyIcon']),
    replaceType() {
      return function (val) {
        return replaceSunglasses(val)
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$parent.showPrice = false
    }
  }
}
</script>

<style lang="scss" scoped>
.close-icon {
  height: 16px;
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}
.content {
  position: fixed;
  z-index: 5;
  width: 468px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e5e7ec;
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.05);
}
.item {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 0;
  }
  .title-tips {
    font-weight: 600;
    font-size: 18px;
  }
  .list,
  &.list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.top {
  padding-bottom: 12px;
}
.bottom {
  padding-top: 12px;
  border-top: 1px solid #e4e4e4;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 600px) {
  .content {
    width: 280px;
  }
}
</style>
