<template>
  <div class="center-body">
    <div class="content-main">
      <div class="content-body">
        <v-tabs v-model="tab">
          <v-tab>Available ({{ list.length }})</v-tab>
          <v-tab>Unavailable ({{ unList.length }})</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="list">
              <template v-if="list.length">
                <div v-for="(item, index) in list" :key="index" class="list-item available" @click="goPage">
                  <div class="price">
                    <!-- <span class="price-top">${{ item.price }}</span>
                    <span>OFF</span> -->
                    <span class="price-top">
                      <template v-if="item.discounts_type === 1"> ${{ item.price }} </template>
                      <template v-else> {{ item.price }}% </template>
                    </span>
                    <span>OFF</span>
                  </div>
                  <div class="info-text">
                    <p class="title-text">{{ item.subtitle }}</p>
                    <p v-if="hasTime(item.use_start_time, item.use_end_time)" class="title-time">{{ item.use_start_time }} - {{ item.use_end_time }}</p>
                    <p v-else>Long Term</p>
                    <p v-if="item.is_first_use" class="tips">Only for first order</p>
                  </div>
                </div>
              </template>
              <!-- <template v-else> There are currently no coupons available </template> -->
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="list">
              <template v-if="unList.length">
                <div v-for="(item, index) in unList" :key="index" class="list-item unavailable">
                  <div class="price">
                    <!-- <span class="price-top">${{ item.price }}</span>
                    <span>OFF</span> -->
                    <span class="price-top">
                      <template v-if="item.discounts_type === 1"> ${{ item.price }} </template>
                      <template v-else> {{ item.price }}% </template>
                    </span>
                    <span>OFF</span>
                  </div>
                  <div class="info-text">
                    <p class="title-text">{{ item.subtitle }}</p>
                    <!-- <p v-if="hasTime(item.use_start_time, item.use_end_time)" class="title-time">{{ item.use_start_time }} - {{ item.use_end_time }}</p> -->
                    <!-- 已过期 -->
                    <template v-if="item.status === 2">
                      <p class="title-time">Expired: {{ item.use_end_time }}</p>
                    </template>
                    <!-- <template v-else-if="item.status === 2">
                      <p class="title-time">Used</p>
                    </template> -->
                  </div>
                </div>
              </template>
              <!-- <template v-else> There are currently no coupons available </template> -->
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'couponsPage',
  data() {
    return {
      tab: 0,
      list: [], // 可用
      unList: [] // 不可用
    }
  },
  computed: {
    hasTime() {
      return function (start, end) {
        if ((!start && !end) || !end) {
          // 无限期
          return 0
        } else {
          return 1
        }
      }
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getCouponList()
  },
  methods: {
    getCouponList() {
      this.$api.userCenter.getCenterCouponList().then(res => {
        if (res.code === 200) {
          this.list = res.data.list
          this.unList = res.data.disqualification
        }
      })
    },
    goPage() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.content-body {
  margin-top: 20px;
}
.list {
  min-height: 475px;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
}
.list-item {
  display: flex;
  width: 45%;
  margin-bottom: 15px;
  height: 100px;
  &.available {
    cursor: pointer;
  }
  .price {
    width: 25%;
    color: #fff;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #3067f2;
    span {
      line-height: 1;
    }
    .price-top {
      margin-bottom: 5px;
    }
  }
  .info-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    background: rgb(146, 193, 255, 0.45);
    position: relative;
    p {
      margin-bottom: 0;
    }
    .title-text {
      font-weight: 600;
    }
    .tips {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: #f5c723;
      border-radius: 5px;
      padding: 0 10px;
    }
  }
  &.unavailable {
    .price {
      background: #a8a8a8;
    }
    .info-text {
      background: #d7d7d7;
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 1480px) {
  // .content-body {
  //   .list-item {
  //     width: 395px;
  //     .price {
  //       left: 35px;
  //     }
  //     .info-text {
  //       left: 135px;
  //     }
  //   }
  // }
}
@media screen and (max-width: 1170px) {
  // .content-body {
  //   .list-item {
  //     width: 368px;
  //     .price {
  //       left: 30px;
  //     }
  //     .info-text {
  //       left: 125px;
  //     }
  //   }
  // }
}
@media screen and (max-width: 1110px) {
  // .content-body {
  //   .list-item {
  //     width: 355px;
  //     .price {
  //       left: 28px;
  //     }
  //     .info-text {
  //       left: 120px;
  //     }
  //   }
  // }
}

@media screen and (max-width: 760px) {
  // .content-body {
  //   .list {
  //     flex-direction: column;
  //     align-items: flex-start;
  //   }
  //   .list-item {
  //     width: 342px;
  //     margin-bottom: 15px;
  //   }
  // }
  .list-item {
    width: 100%;
  }
}
</style>
