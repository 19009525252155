<!-- 菜单列表 -->
<template>
  <div class="menu-box">
    <div class="menu_mobile">
      <div v-for="(item, index) in $store.state.system.menuList" :key="index" class="link" @click="toPage(item)">
        <!-- <nuxt-link to="/control/navType?1">Eyeglasses</nuxt-link> -->
        <div class="menu-name">{{ item }}</div>
        <!-- <span>></span> -->
        <img class="link-icon" :src="more" alt="menuOpen" />
      </div>
      <LmobileNav />
    </div>
  </div>
</template>

<script>
import { typeName } from '@/assets/utils/renderData'
import LmobileNav from '@/components/Lnav/LmobileHelp/index.vue'
import more from '@/static/imgs/more.png'
export default {
  name: 'LmobileMenu',
  components: {
    LmobileNav
  },
  data() {
    return {
      more
    }
  },
  methods: {
    toPage(name) {
      if (name === 'Accessories') {
        this.$router.push({
          name: 'search-accessories',
          params: {
            type: 'accessories'
          }
        })
      }
      if (name === 'Eyeglasses' || name === 'Sunglasses') {
        this.$router.push({
          name: 'control-type',
          params: { type: name }
        })
      } else {
        this.$router.push({
          name: 'search-type',
          params: {
            type: typeName(name)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  font-size: 0;
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
  background: #fff;
  padding: 0 20px;
  border-bottom: 1px solid #dde0e3;

  .menu-name {
    text-decoration: none;
    font-size: 16px;
    color: #000;
  }
  .link-icon {
    width: 13px;
    transform: rotate(270deg);
  }
}
</style>
