<template>
  <v-dialog v-model="dialog" class="reviewDialog" persistent background="#fff">
    <div class="dialog-body">
      <v-toolbar color="#fff">
        <v-spacer />
        <v-btn icon dark @click="val => close(val, false)">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="content">
        <div class="title-dialog">
          <template v-if="!productInfo.state">
            <h4>Add a Review</h4>
          </template>
          <template v-else>
            <h4>My Comments</h4>
          </template>
          <!-- <p>Your email address will not be published. Required fields are marked.<span class="red-text">*</span></p> -->
        </div>
        <p class="line"></p>
        <div class="product-info">
          <div class="left">
            <img :src="productInfo.image" alt="product" />
          </div>
          <div class="right">
            <p class="product-info">{{ productInfo.title }}</p>
            <p class="product-info">Frame: {{ productInfo.code }}</p>
            <p class="product-info">Color: {{ productInfo.color }}</p>
          </div>
        </div>
        <div class="score">
          <v-rating v-model="reviewInfo.score" :length="5" background-color="orange lighten-3" color="orange" />
        </div>
        <div class="formData">
          <div class="item">
            <label for="">Your review</label>
            <div class="item-box">
              <textarea v-model="reviewInfo.content" class="text-dom item-textarea" name="inp" label=" "></textarea>
            </div>
          </div>
          <!-- <div class="item">
            <label for="">E-mail <span class="red-text">*</span></label>
            <div class="item-box">
              <input v-model="reviewInfo.email" class="text-dom item-input" name="inp" label=" " />
            </div>
          </div>
          <div class="item">
            <label for="">Name <span class="red-text">*</span></label>
            <div class="item-box">
              <input v-model="reviewInfo.name" class="text-dom item-input" name="inp" label=" " />
            </div>
          </div> -->
          <!-- :disabled-upload="form.id ? true : false" -->
          <uploadImg ref="imgCom" :online="reviewInfo.file" :disabled-upload="productInfo.state ? true : false" :length="3" />
          <div v-if="!productInfo.state" class="submit-box">
            <button type="button" class="submit-btn" @click="save">
              Submit
              <img src="/imgs/send.png" alt="submit" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import uploadImg from '@/components/toolCom/uploadImg'
import { getFormData } from '@/assets/utils/renderData'

export default {
  name: 'reviewDialog',
  components: {
    uploadImg
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    // 当前评论的商品
    productInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
      reviewInfo: {
        score: 0,
        content: '',
        file: []
        // email: '',
        // name: '',
      }
      // -------------
      // 图片
    }
  },
  computed: {},
  watch: {
    show(newValue) {
      this.dialog = newValue
      this.currentIndex = this.current
    },
    productInfo: {
      handler(val) {
        if (val.state) {
          this.getMyReview()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    close(val, state) {
      this.$emit('close', { id: this.productInfo.id, index: this.productInfo.index, state })
      Object.assign(this.$data, this.$options.data())
    },
    reviewData() {
      this.$emit('reviewData')
    },
    getInfo() {
      let file = null
      file = this.$refs.imgCom.formData
      return { info: this.reviewInfo, file }
    },
    submitData() {
      const obj = this.getInfo()
      obj.info.id = this.productInfo.id
      const data = getFormData(obj.info)
      for (let index = 0; index < obj.file.length; index++) {
        const item = obj.file[index]
        data.append(`file${index + 1}`, item)
      }
      return data
    },
    // 保存评论
    save() {
      const info = this.submitData()
      if (info.get('score') - 0 === 0) {
        this.$tips({ msg: 'Please rate this product.' })
        return false
      } else {
        this.$api.userCenter.productReview(info).then(res => {
          if (res.code === 200) {
            this.$tips({ msg: 'Success Review.' })
            this.close(null, true)
          }
        })
      }
    },
    getMyReview() {
      this.$api.userCenter.productReviewInfo({ id: this.productInfo.id }).then(res => {
        if (res.code === 200) {
          this.reviewInfo = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 评论商品
.product-info {
  display: flex;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 230px;
    border: 1px dashed #c7c7c7;
    img {
      width: 100%;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 10px;
    p {
      margin-bottom: 0;
    }
  }
}
.red-text {
  color: red;
}
.line {
  height: 1px;
  width: 100%;
  border-bottom: 1px dashed #e0e0e0;
  margin: 30px auto;
}
::v-deep .v-dialog {
  background: #fff;
}
.reviewDialog,
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  width: 630px;
  // height: 873px;
  margin: 0 auto;
}
::v-deep .v-toolbar {
  height: 50px;
}
::v-deep .v-rating {
  margin-left: -8px;
}
::v-deep .v-sheet.v-toolbar {
  box-shadow: none !important;
}
::v-deep .v-rating .v-icon {
  padding: 5px !important;
}
.content {
  padding: 0 50px;

  .title-dialog {
    text-align: center;
    & > h4 {
      font-size: 22px;
      font-weight: 500;
      color: #2b2e33;
      line-height: 30px;
    }
    & > p {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 400;
      color: #aaadb2;
      line-height: 30px;
    }
  }
  .score {
    margin-bottom: 25px;
  }
  .formData {
    .item {
      margin-bottom: 25px;
      label {
        font-size: 16px;
        font-weight: 400;
        color: #525861;
        line-height: 30px;
        margin-bottom: 10px;
      }
      .text-dom {
        width: 100%;
        margin-top: 0 !important;
        padding: 5px !important;
        line-height: 20px !important;
        background: #f2f2f2;
        border: none !important;
        outline: none;
        font-family: Ubuntu-Regular, Ubuntu;
      }
      .item-textarea {
        height: 110px;
      }
      .item-input {
        height: 40px;
      }
    }
    .submit-box {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      .submit-btn {
        width: 223px;
        height: 40px;
        background: #3067f2;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        font-size: 16px;
        font-family: Ubuntu-Medium, Ubuntu;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
        img {
          width: 14px;
          height: 14px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .v-dialog__content ::v-deep .v-dialog {
    width: 630px;
    height: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
}
</style>
