<template>
  <!-- 导航栏 -->
  <div class="header system-header-box" @mouseleave="moveType">
    <!-- <Lnotice /> -->
    <!-- pc -->
    <!-- app fixed  -->
    <v-app-bar v-if="$vuetify.breakpoint.lgAndUp" class="nav-box-pc" flat>
      <transition name="fade">
        <LhomeDialog v-show="navDialog" :type="dialogType" @hide="moveType" />
      </transition>
      <div class="system-header">
        <img id="nav-logo" :src="logo" alt="logo" @click="$router.push('/')" @mouseenter="moveType" />
        <!-- 导航目录 -->
        <ul class="tab-box">
          <li v-for="(item, index) in $store.state.system.menuList" :key="index" class="tab-text" :class="{ active: dialogType == item }" @mouseenter="getType(item)" @click="toList(item)">
            {{ item }}
          </li>
        </ul>
        <v-spacer />
        <!-- pc右侧-------------------------------------------------------------------------------- -->
        <!-- 搜索 -->
        <div class="input-search" @mouseover="searchDom('on')" @mouseleave="searchDom('off')" @click="changeCom('search')">
          <img :src="search" class="search-icon" alt="search" />
          <span>Search</span>
        </div>
        <!-- 个人中心 -->
        <div class="option-box">
          <div class="option" @click="toCollection">
            <img class="items img1" src="/imgs/collection.png" alt="collection" />
            <span v-if="CollectionsAndCartsNum.collect" class="flag-num">{{ CollectionsAndCartsNum.collect }}</span>
          </div>
          <div class="option" @click="toCart">
            <img class="items img2" src="/imgs/shopping_cart.png" alt="shopping_cart" />
            <span v-if="CollectionsAndCartsNum.cart" class="flag-num">{{ CollectionsAndCartsNum.cart }}</span>
          </div>
          <v-menu offset-y nudge-left="100" z-index="9999" attach=".system-header-box">
            <template #activator="{ on, attrs }">
              <div class="option" v-bind="attrs" v-on="on"><img class="items img3" src="/imgs/personal.png" alt="personal" /></div>
            </template>
            <div class="center-list">
              <div class="content">
                <template v-if="userToken && userToken != ''">
                  <div class="header-name">
                    <img class="logo-user" src="/imgs/loading-logo.gif" alt="logo-user" />
                    <span>Hi, {{ userName }}</span>
                  </div>
                  <div class="item">
                    <v-btn block depressed class="my-btn" tile color="#fff" @click="toUser('account')">My Account</v-btn>
                    <v-btn block depressed class="my-btn" tile color="#fff" @click="toUser('order')">My Orders</v-btn>
                    <v-btn block depressed class="my-btn footer-option" color="#fff" @click="logOut">Log Out</v-btn>
                  </div>
                </template>
                <template v-else>
                  <div class="top">
                    <v-btn block depressed class="my-btn btn-login" tile color="#3067F2" @click="toLogin(0)">Sign In</v-btn>
                    <v-btn block depressed class="my-btn btn-register" tile color="#fff" @click="toLogin(1)">Create Account</v-btn>
                    <!-- <div class="other-tips">
                      <span class="line"></span>
                      <span class="tips"> Alternatively, login with </span>
                      <span class="line"></span>
                    </div>
                    <div class="other">
                      <span class="login-item item1">
                        <img src="/imgs/facebook2.png" alt="facebook-logo" />
                        Facebook
                      </span>
                      <span class="login-item item2">
                        <img src="/imgs/google.png" alt="facebook-logo" />
                        Google
                      </span>
                    </div> -->
                  </div>
                  <div class="bottom"></div>
                </template>
              </div>
            </div>
          </v-menu>
          <div class="line"></div>
        </div>
        <!-- 切换货币 -->
        <div class="text-center language-box">
          <div class="currency-box" title="Currency">
            <img class="language-icon" :src="`${$store.state.system.currencyList[$store.state.system.currencyIndx].icon}`" alt="country" />
            <span class="language-text">{{ $store.state.system.currencyList[$store.state.system.currencyIndx].value }}</span>
          </div>
          <!-- <v-menu offset-y z-index="9999" attach=".system-header-box">
            <template #activator="{ on, attrs }">
              <div class="language" v-bind="attrs" v-on="on">
                <img class="language-icon" :src="`${$store.state.system.currencyList[$store.state.system.currencyIndx].icon}`" alt="country" />
                <span class="language-text">{{ $store.state.system.currencyList[$store.state.system.currencyIndx].value }}</span>
                <img class="open" src="/imgs/open.png" alt="countrySelect" />
              </div>
            </template>
            <v-list class="currency">
              <v-list-item-group v-model="selectedItem" color="#3067f2">
                <v-list-item v-for="(item, index) in $store.state.system.currencyList" :key="index">
                  <v-list-item-title>
                    <img class="country" :src="`${item.icon}`" :alt="item.value" />
                    {{ item.value }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu> -->
        </div>
      </div>
    </v-app-bar>
    <!-- mobile导航栏-------------------------------------------------------------- -->
    <v-app-bar v-if="$vuetify.breakpoint.mdAndDown" ref="nobileNav" class="nav-box-mobile d-lg-none d-xl-none" flat>
      <div class="system-header">
        <div class="left-nav">
          <img class="menu-iocn" :src="menuIcon" alt="menuIcon" @click="openMenu" />
          <img class="m-search" :src="search" alt="search" @click="$router.push({ path: '/control/search' })" />
        </div>
        <div class="center-nav">
          <img id="logo" ref="logos" class="m-logo" :src="logo" alt="logo" @click="$router.push({ path: '/' })" />
        </div>
        <div class="right-nav">
          <div class="nav-item">
            <img class="cart-icon" src="/imgs/shopping_cart.png" alt="shopping_cart" @click="toCart" />
            <span v-if="CollectionsAndCartsNum.cart" class="flag-num">{{ CollectionsAndCartsNum.cart }}</span>
          </div>
          <div class="nav-item">
            <img class="user-icon" src="/imgs/personal.png" alt="personal" @click="toCenter" />
          </div>
        </div>
        <!-- <div class="option" @click="toCart"></div>
 <div class="option" v-bind="attrs" v-on="on"></div> -->
      </div>
    </v-app-bar>
    <!-- 登录窗口 -->
    <!-- <Llogin :tab="loginTab" /> -->
    <Llogin />
    <resetPassword />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { typeName } from '@/assets/utils/renderData'
import LhomeDialog from '@/components/LhomeDialog/index.vue'
import Llogin from '@/components/Llogin/index.vue'
import resetPassword from '@/components/Llogin/resetPassword.vue'
import menuIcon from '@/static/imgs/menu3.png'
import search1 from '@/static/imgs/search.png'
import search2 from '@/static/imgs/search3.png'
export default {
  name: 'LNavbar',
  components: {
    LhomeDialog,
    Llogin,
    resetPassword
  },
  data() {
    return {
      tab: '',
      text: '',
      logo: require('@/static/imgs/logo.png'),
      menuIcon,
      search: search1,
      // menuList: ['Eyeglasses', 'Sunglasses', 'New Arrivals', 'Flash Sale', 'Best Sellers', 'Accessories'],
      // drawer: false,
      group: null,
      dialogType: null, // 弹窗所属
      dialog: false, // 登录
      // loginTab: 0, // 打开登录窗口模式 (登录 - 注册)
      scrollTop: 0 // 当前滑动距离
    }
  },
  computed: {
    selectedItem: {
      get() {
        return this.$store.state.system.currencyIndx
      },
      set(value) {
        this.$store.commit('system/toggleCurrency', value)
      }
    },
    ...mapState({
      // 导航弹窗
      navDialog: state => state.system.navDialog,
      userName: state => {
        if (state.system.userInfo.firstname && state.system.userInfo.lastname) {
          return state.system.userInfo.firstname + ' ' + state.system.userInfo.lastname || ' '
        } else if (state.system.userInfo.firstname) {
          return state.system.userInfo.firstname
        } else {
          return ''
        }
      },
      userToken: state => state.system.userToken,
      CollectionsAndCartsNum: state => state.system.CollectionsAndCartsNum
    })
  },

  watch: {
    // nav弹窗出现时 幕遮层
    navDialog(newValue) {
      this.$store.commit('system/toggleOverlay', newValue)
    },
    // 系统货币设置
    selectedItem(newValue) {
      this.$store.commit('system/toggleCurrency', newValue)
    }
  },
  methods: {
    logOut() {
      this.$store.commit('system/logOut')
    },
    toList(item) {
      if (item === 'Accessories') {
        this.$router.push({
          name: 'search-accessories',
          params: {
            type: 'accessories'
          }
        })
        return false
      }
      this.$router.push({
        name: 'search-type',
        params: {
          type: typeName(item)
        }
      })
    },
    // 移入导航
    getType(name) {
      const hasDialog = ['Eyeglasses', 'Sunglasses']
      if (hasDialog.includes(name)) {
        this.dialogType = name
        this.$store.commit('system/toggleNavLog', true)
      } else {
        this.moveType()
      }
    },
    // 移除导航
    moveType() {
      this.$store.commit('system/toggleNavLog', false)
      this.dialogType = null
    },
    // 切换导航组件dialog
    changeCom(name) {
      if (name === 'search') {
        this.dialogType = name
        this.$store.commit('system/toggleNavLog', true)
      }
    },
    openMenu() {
      this.$store.commit('system/saveGoFrom', { name: this.$route.name, query: this.$route.query, params: this.$route.params })
      this.$router.push({ path: '/control' })
    },
    // 去购物车
    toCart() {
      // if (this.userToken && this.userToken !== '') {
      //   this.$router.push({
      //     name: 'shoppingCart'
      //   })
      // } else {
      //   this.toLogin()
      // }
      this.$router.push({
        name: 'shoppingCart'
      })
    },
    toCollection() {
      if (this.userToken && this.userToken !== '') {
        this.$router.push({
          name: 'centerMain-personalization-wishlist'
        })
      } else {
        this.toLogin()
      }
    },
    // 打开登录
    toLogin(type = 0) {
      // this.loginTab = type
      this.$store.commit('system/loginDialogTab', type)
      this.$store.commit('system/toggleLogin', true)
    },
    toUser(type) {
      // 登录判断
      // if () {
      //   this.dialog = true
      // }
      let pathName
      // if(type === 'account'){

      // }else
      type === 'account' ? (pathName = 'centerMain-account') : (pathName = 'centerMain-order-order')
      this.$router.push({
        name: pathName
      })
      // window.open(page.href, '_blank')
    },
    toCenter() {
      this.checkAndtoPage(() => {
        this.$router.push({
          name: 'centerMain-account'
        })
      })
    },
    checkAndtoPage(fn) {
      // fn()
      if (this.userToken) {
        fn()
      } else {
        this.$store.commit('system/toggleLogin', true)
      }
    },
    // 打开搜索
    searchDom(type) {
      if (type === 'on') {
        this.search = search2
      } else {
        this.search = search1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .drawer-box::v-deep.v-navigation-drawer {
//   width: 100% !important;
// }
//-----------------------------------------
$paddingNav: 100px;
$paddingMenu: 60px;
#nav-logo {
  width: 137px;
  cursor: pointer;
}
.header {
  position: sticky;
  top: 0;
  z-index: 3;
  // position: relative;
}
.header ::v-deep .nav-box-pc.v-app-bar {
  height: fit-content !important;
  z-index: 2;
  // border-bottom: 1px solid #f0f2f4;
}

.nav-box-pc ::v-deep .v-toolbar__content {
  flex-direction: column;
  background-color: #fff;
  height: auto !important;
  padding: 0;
}
.nav-box-pc .system-header {
  width: 100%;
  height: 70px;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0 $paddingNav;
  // max-width: 1720px;
  margin: 0 auto;
}
.tab-box {
  display: flex;
  list-style: none;
  height: 100%;
  align-items: center;
  margin-left: 50px;

  .tab-text {
    // flex: 1;
    position: relative;
    font-size: 16px;
    white-space: nowrap;
    // min-width: 120px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
  }
  .tab-text.active {
    a {
      color: #3067f2 !important;
    }
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background: #3067f2;
      position: absolute;
      top: calc(100% - 2px);
      left: 0;
      // transition: all 0.3s;
    }
  }
  a {
    color: #2b2e33 !important;
    text-decoration: none;
  }
  .tab-text:hover {
    // border-bottom: 1px solid red;
    a {
      color: #3067f2 !important;
    }
    &:after {
      left: 0%;
      width: 100%;
    }
  }
  .tab-text::after {
    content: '';
    width: 0;
    height: 2px;
    background: #3067f2;
    position: absolute;
    top: calc(100% - 2px);
    left: 50%;
    transition: all 0.3s;
  }
}
.input-search {
  height: 38px;
  width: 130px;
  min-width: 100px;
  border-radius: 25px;
  background: rgb(107, 126, 153, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 16px;
  color: #525861;
  .search-icon {
    // width: 10px;
    height: 16px;
    margin: 0 15px;
  }
  span {
    font-size: 15px;
  }
  input {
    width: 194px;
    height: 16px;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    padding: 0 8px;
  }
  input {
    border-radius: 30px;
  }
  &:hover {
    background: #3067f2;
    color: #fff;
  }
}
.option-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 15px;
  .option {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    cursor: pointer;
    padding: 0 10px;
    &:hover {
      background: rgba(157, 158, 158, 0.486);
    }
    .items {
      display: inline-block;
      // margin-left: 26px;
      // margin-right: 2px;
    }
    .img1 {
      width: 22px;
      height: 20px;
    }
    .img2 {
      width: 23px;
      height: 20px;
      margin-right: 4px;
    }
    .img3 {
      width: 22px;
      height: 21px;
    }
  }
  .line {
    height: 14px;
    width: 1px;
    background: #cfd8e5;
    margin: 0 26px;
  }
}
.nav-item {
  position: relative;
  display: flex;
  align-items: center;
}
.flag-num {
  display: block;
  position: absolute;
  top: 14px;
  right: 0px;
  min-width: 18px;
  padding: 0 5px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
  border-radius: 9px;
  background-color: #f05654;
  color: #fff;
}
.center-list {
  min-height: 120px;
  width: 345px;
  background: #fff;
  .content {
    font-size: large;
    text-align: center;
  }
  .my-btn {
    border: 1px solid #979797 !important;
    margin-bottom: 12px;
    height: 44px !important;
  }
  .item > .my-btn:hover {
    background: #3067f2 !important;
    border: 1px solid #3067f2 !important;
    color: #fff !important;
  }
  .logo-user {
    height: 32px;
    margin-bottom: 12px;
  }
  .header-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #3067f2;
    padding: 32px 0;
    line-height: 1;
  }
  .item {
    color: #2b2e33;
    padding: 25px 46px 25px;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    p {
      padding: 8px 0;
      margin-bottom: 2px;
      cursor: pointer;
      transition: all 0.2s;
    }
    p:hover {
      color: #000;
      background: #f3f3f3;
    }
  }
  .footer-option {
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    color: #2b2e33;
  }
  .top {
    padding: 34px 46px 20px;
  }
  .other-tips {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    .line {
      height: 2px;
      width: 100%;
      background: #cacaca;
    }
    .tips {
      flex-shrink: 0;
      font-size: 12px;
      font-weight: 400;
      color: #cacaca;
      margin: 0 12px;
    }
  }
  .other {
    display: flex;
    justify-content: space-between;
    margin: 15px 15px 0;
    .login-item {
      display: flex;
      height: 23px;
      cursor: pointer;
      img {
        height: 100%;
        margin-right: 5px;
      }
    }
  }
  .btn-login {
    color: #fff;
    height: 45px !important;
  }
  .btn-register {
    margin-top: 10px;
    height: 45px !important;
    color: #3067f2 !important;
  }
}
.language {
  display: flex;
  align-items: center;
  justify-content: center;
  .language-icon {
    width: auto;
    height: 14px;
  }
  .open {
    width: 6px;
    height: 3px;
  }
  .language-text {
    font-size: 14px;
    margin: 0 5px;
  }
}
// 货币 (单语言)
.currency-box {
  display: flex;
  align-items: center;
  .language-text {
    font-size: 15px;
    margin-left: 5px;
    line-height: 1;
  }
}
// 切换货币  (多货币)
// .currency {
//   padding-top: 0;
//   padding-bottom: 0;
//   width: 125px;
//   .v-list-item__title {
//     text-align: center;
//   }
//   .v-list-item:hover {
//     background: #dce8ff;
//     color: #3067f2;
//   }
//   .v-list-item--active {
//     background: #dce8ff;
//   }
//   .country {
//     position: relative;
//     top: 2px;
//     width: auto;
//     height: 14px;
//   }
// }
// -------------------------
/*设置动画*/
.fade-enter,
.fade-leave-to,
.fade-leave {
  transform: rotate3d(180, 0, 0, 90deg);
  // opacity: 0;
}
// .fade-leave {
//   transition: 0.8s;
//   display: none;
// }
.fade-enter-active {
  transition: 0.7s;
}
.fade-leave-active {
  transition: 0.4s;
}
.fade-enter-to {
  transform: rotate3d(180, 0, 0, 0deg);
  // opacity: 1;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1600px) {
  .tab-box {
    margin-left: 40px;
    padding-left: 0;
  }
}

@media screen and (max-width: 1460px) {
  .input-search .search-icon {
    margin: 0 10px;
  }
}
@media screen and (max-width: 1440px) {
  .nav-box-pc .system-header {
    padding: 0px 60px;
  }
  .tab-box .tab-text {
    // font-size: 22px;
    margin-right: 22px;
  }
  .input-search .search-icon {
    margin: 0 10px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1280px) {
  .nav-box-pc .system-header {
    padding: 0 15px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1260px) {
  .nav-box-mobile ::v-deep .v-toolbar__content {
    background-color: #ffffff;
    z-index: 2;
  }
  .header ::v-deep .nav-box-mobile.v-app-bar {
    height: fit-content !important;
    z-index: 2;
    border-bottom: 1px solid #f0f2f4;
  }
  .nav-box-mobile ::v-deep .v-toolbar__content {
    flex-direction: column;
    background-color: #fff;
    height: auto !important;
    padding: 0;
  }
  .nav-box-mobile .system-header {
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
  }
  .left-nav,
  .center-nav,
  .right-nav {
    display: flex;
    align-items: center;
  }
  .menu-iocn {
    width: 25px;
    height: 24px;
    margin-right: 22px;
  }
  .m-logo {
    width: 121px;
  }
  .m-search {
    width: 20px;
    height: 19px;
  }
  .cart-icon {
    width: 25px;
    height: 22px;
  }
  .user-icon {
    width: 24px;
    height: 22px;
    margin-left: 22px;
  }
  .flag-num {
    top: -8px;
    right: -8px;
  }
}
</style>
