<template>
  <div class="center-body">
    <!-- <pcWishlist :list="list" /> -->
    <components :is="comName" :list="list" :loading="loading" @remove="remove" />
    <div v-if="setPageLength > 1" class="pagination-box mt-4">
      <v-pagination v-model="option.page" color="#222426" :total-visible="7" :length="setPageLength" @input="change" />
    </div>
  </div>
</template>

<script>
const pcWishlist = () => import('@/components/LcenterMain/personalization/pcWishlist')
const mobileWishlist = () => import('@/components/LcenterMain/personalization/mobileWishlist')
export default {
  name: 'center-index',
  data() {
    return {
      option: {
        page: 1,
        limit: 10,
        count: 10
      },
      list: [],
      loading: true
    }
  },
  computed: {
    setPageLength() {
      if (this.option) {
        return Math.ceil(this.option.count / this.option.limit)
      }
      return 1
    },
    comName() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return mobileWishlist
      } else {
        return pcWishlist
      }
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getWishlist()
  },
  methods: {
    change(val) {
      this.option.page = val
      this.getWishlist()
    },
    getWishlist() {
      this.loading = true
      this.$api.userCenter
        .wishlist({
          page: this.option.page,
          limit: this.option.limit
        })
        .then(res => {
          this.loading = false
          if (res.code === 200) {
            this.list = res.data
            this.option.count = res.count || 10
          }
        })
    },
    remove(val) {
      this.$confirm({ msg: 'Are you sure to delete this item?' }).then(res => {
        if (res === 'success') {
          this.$api.product.setWishlist({ code: val }).then(res => {
            if (res.code === 200) {
              this.getWishlist()
              this.$store.dispatch('system/getCollectionsAndCartsNum', { axios: this.$api })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-box ::v-deep .v-pagination__navigation {
  width: 40px;
  height: 40px;
  font-size: 14px;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .center-body {
    background: #f7f7f7;
  }
}
</style>
