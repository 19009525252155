<template>
  <div class="content-box">
    <h2 class="mode-title">Find Your Perfect Frames</h2>
    <p class="title-tip">Stylish Prescription Glasses, Affordable Prices, High-quality.</p>
    <div class="list">
      <div v-for="(item, index) in list" :key="index" class="item">
        <nuxt-link :to="{ name: item.page, params: { type: item.type, sort: item.sort ? item.sort : 'Default' }, query: { ...(item.search ? { search: item.search } : {}) } }">
          <img :key="item.name" v-lazy="`imgs/nav/${item.src}`" :alt="item.name" />
        </nuxt-link>
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageNav',
  data() {
    return {
      list: [
        { src: 'ww123545987ad.png', name: 'Women', page: 'search-type', type: 'search', search: 'Women' },
        { src: '45zdesddfsadee.png', name: 'Men', page: 'search-type', type: 'search', search: 'Men' },
        { src: '623asd5wasdcae.png', name: 'Kids', page: 'search-type', type: 'search', search: 'Kids' },
        { src: 'asd5ad5w8vsg5.png', name: 'Eyeglasses', page: 'search-type', type: 'eyeglasses' },
        { src: '15124abasdad22.png', name: 'Sunglasses', page: 'search-type', type: 'sunglasses' }
      ]
    }
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.content-box {
  padding-top: 70px;
}
.mode-title {
  margin-bottom: 12px;
}
.title-tip {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
}
.list {
  display: flex;
  justify-content: center;
}
.item {
  width: 16%;
  a {
    display: flex;
    justify-content: center;
  }
  img {
    width: 86%;
  }
  p {
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 0;
    text-align: center;
  }
}
@media screen and (max-width: 960px) {
  .content-box {
    padding-top: 30px;
  }
  .mode-title {
    margin-bottom: 16px;
  }
  .list {
    flex-wrap: wrap;
  }
  .item {
    width: 30%;
    margin-bottom: 10px;
    p {
      font-size: 14px;
    }
  }
}
</style>
