<template>
  <div class="products">
    <div class="left">
      <!-- 搜索历史 -->
      <div v-if="history.length" class="top">
        <div class="history-title">
          <span class="text">History Searches</span>
          <span class="title-icon">
            <img class="clear-icon" src="/imgs/del-icon.png" alt="hot search" @click="clear" />
          </span>
        </div>
        <div class="history-list">
          <p v-for="(item, index) in history" :key="index" class="item" @click="search(item)">{{ item }}</p>
        </div>
      </div>
      <!-- 热门搜索 -->
      <div v-if="hotTag.length" class="bottom">
        <div class="hot-title">
          <span class="text">Popular Searches</span>
          <!-- <span class="clear-icon">
            <img class="icon" src="/imgs/hot.png" alt="hot search" />
          </span> -->
        </div>
        <div class="hot-list">
          <p v-for="(item, index) in hotTag" :key="index" class="item" @click="search(item)">
            {{ item }}
            <img class="hot-icon" src="/imgs/hot.png" alt="hot tag" />
          </p>
        </div>
      </div>
    </div>
    <!-- 商品推荐 -->
    <div v-if="list.type === 'recommend'" class="right">
      <div class="recommend-title">
        <span class="text">Recommend</span>
        <span class="title-icon">
          <img class="recommend-icon" src="/imgs/recommend.png" alt="hot search" />
        </span>
      </div>
      <div v-if="list.data.length && !loading" class="goods-list">
        <div v-for="item in list.data" :key="item.id" class="item" @click="goPage">
          <nuxt-link :to="{ name: 'details-code', params: { code: item.code } }">
            <div class="top">
              <img class="imgs" :src="item.img" :alt="item.content" />
            </div>
            <div class="bottom">
              <span>{{ currencyIcon }}{{ item.price }}</span>
            </div>
          </nuxt-link>
        </div>
      </div>
      <template v-else-if="!list.data.length && !loading">
        <p class="no-product">No recommended products.</p>
      </template>
      <template v-else>
        <v-progress-circular indeterminate color="#3067f2" />
      </template>
    </div>
    <div v-if="list.type === 'search'" class="right">
      <div class="search-title">
        <span class="text">Frames({{ list.length }})</span>
        <span v-show="list.length" class="more" @click="getMore">See All <img src="/imgs/more.png" alt="more" /></span>
      </div>
      <div v-if="list.data.length && !loading" class="goods-list">
        <div v-for="item in list.data" :key="item.id" class="item">
          <nuxt-link :to="{ name: 'details-code', params: { code: item.code } }">
            <div class="top">
              <img class="imgs" :src="item.img" :alt="item.content" />
            </div>
            <div class="bottom">
              <span>{{ currencyIcon }}{{ item.price }}</span>
            </div>
          </nuxt-link>
        </div>
      </div>
      <template v-else-if="!list.data.length && !loading">
        <p class="no-product">Unable to find the product you need.</p>
      </template>
      <template v-else>
        <v-progress-circular indeterminate color="#3067f2" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'myProducts',
  props: {
    // 历史记录
    history: {
      type: Array,
      default: () => []
    },
    // 热门搜索
    hotTag: {
      type: Array,
      default: () => []
    },
    // 商品结果
    list: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('system', ['currencyIcon'])
  },
  watch: {},
  methods: {
    search(item) {
      this.$emit('tagSearch', item)
    },
    clear() {
      this.$emit('clearHistory')
    },
    getMore() {
      this.$emit('getMore')
    },
    goPage() {
      this.$store.commit('system/toggleNavLog', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.no-product {
  text-align: center;
}
.products {
  display: flex;
  .left {
    width: 35%;
    padding-right: 16px;
    flex-shrink: 0;
    border-right: 1px solid #f0f2f5;
  }
  .right {
    position: relative;
    flex: 1;
    margin-left: 16px;
  }
}
.left {
  .top,
  .bottom {
    margin-bottom: 20px;
    .clear-icon {
      width: 18px;
      cursor: pointer;
    }
    .hot-icon {
      width: 12px;
    }
    .history-title,
    .hot-title {
      display: flex;
      align-items: center;
      font-size: 17px;
      margin-bottom: 10px;
      .text {
        margin-right: 8px;
      }
    }
    .history-list,
    .hot-list {
      display: flex;
      flex-wrap: wrap;
      .item {
        padding: 5px 10px;
        border-radius: 10px;
        color: #757575;
        background: #f0f2f5;
        margin-bottom: 8px;
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
}
.recommend-title,
.search-title {
  font-size: 17px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  .text {
    margin-right: 8px;
  }
  .recommend-icon {
    width: 22px;
  }
}
.search-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more {
    font-size: 12px;
    cursor: pointer;
    img {
      transform: rotate(270deg);
      height: 6px;
      position: relative;
      top: -2px;
    }
  }
}
.title-icon {
  display: flex;
}
.goods-list {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    a {
      text-decoration: none;
    }
  }
  .imgs {
    height: 78px;
  }
  .bottom {
    text-align: center;
    margin-top: 5px;
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1536px) {
  .products {
    .left {
      width: 28%;
    }
  }
}
@media screen and (max-width: 960px) {
  .products {
    flex-direction: column;
    .left {
      width: 100%;
      padding-right: 0;
      border-right: none;
    }
    .right {
      margin-left: 0;
    }
  }
  .left {
    .top > .history-title {
      font-size: 15px;
    }
    .bottom > .hot-title {
      font-size: 15px;
    }
  }
  .right > .recommend-title {
    font-size: 15px;
  }
  .goods-list {
    justify-content: space-between;
    .item {
      width: 48%;
      margin-bottom: 12px;
      .top {
        width: 100%;
        height: 88px;
      }
      .imgs {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
