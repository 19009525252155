<template>
  <div class="home">
    <Lcarousels :list="carouselsList" />
    <pageNav />
    <Lgoods id="best-sellers" title="Best Sellers" :list="goodsListBest" :option="bestObj" :all-page="true" @page="changePage($event, 'best')" @getAll="getAll($event, 'best-sellers')" />
    <Lactivity />
    <Lphotowall title="Fans Show" :list="photoList" />
    <Lgoods id="new-arrivals" title="New Arrivals" :list="goodsListNew" :option="bestObj" :all-page="true" @page="changePage($event, 'new')" @getAll="getAll($event, 'new-arrivals')" />
    <Lfaq title="FAQ" />
    <Labout />
  </div>
</template>

<script>
import head from '@/assets/utils/TDK'
import Lcarousels from '@/components/Lhome/Lcarousels/index.vue'
import pageNav from '@/components/Lhome/pageNav/index.vue'
import Lgoods from '@/components/Lgoods/index.vue'
import Lactivity from '@/components/Lactivity/index.vue'
import Lphotowall from '@/components/Lhome/Lphotowall/index.vue'
import Lfaq from '@/components/Lhome/Lfaq/index.vue'
import Labout from '@/components/Lhome/Labout/index.vue'
export default {
  name: 'home',
  components: {
    Lcarousels,
    pageNav,
    Lgoods,
    Lactivity,
    Lphotowall,
    Lfaq,
    Labout
  },
  async asyncData({ $api, error }) {
    const [data, data2, data3, data4] = await Promise.all([
      $api.product.getGoodsList({
        limit: 9,
        type: 'home-hot'
      }),
      $api.product.getGoodsList({
        limit: 9,
        type: 'home-new'
      }),
      $api.home.getPhotoWall(),
      $api.home.getBanner()
    ])
    if (data.code === 200 && data2.code === 200) {
      const { data: goodsListBest, page: bestObjPage, count: bestObjCount, limit: bestObjLimit } = data
      const { data: goodsListNew, page: newObjPage, count: newObjCount, limit: newObjLimit } = data2
      const { data: photoList } = data3
      const { data: carouselsList } = data4
      return {
        goodsListBest,
        bestObj: {
          count: bestObjCount,
          page: bestObjPage,
          limit: bestObjLimit
        },
        goodsListNew,
        newObj: {
          count: newObjCount,
          page: newObjPage,
          limit: newObjLimit
        },
        // -------------照片
        photoList,
        // 轮播
        carouselsList
      }
    } else {
      return {}
    }
  },
  data() {
    return {
      // 商品数据  asyncData
      goodsListBest: [],
      goodsListNew: [],
      bestObj: {
        count: null,
        page: null,
        limit: null
      },
      newObj: {
        count: null,
        page: null,
        limit: null
      }
    }
  },
  head() {
    return {
      title: head('home').title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: head('home').description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: head('home').keywords
        }
      ]
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.recordPage('home')
  },
  methods: {
    // 记录进入页面
    recordPage(type) {
      this.$api.system.track_log({ type })
    },
    async getBestList(page) {
      this.bestObj.page = page
      const {
        data: goodsListBest,
        count,
        limit
      } = await this.$api.product.getGoodsList({
        page,
        limit: 9,
        type: 'home-hot'
      })
      this.goodsListBest = goodsListBest
      this.bestObj.count = count
      this.bestObj.limit = limit
    },
    async getNewList(page) {
      this.bestObj.page = page
      const {
        data: goodsListNew,
        count,
        limit
      } = await this.$api.product.getGoodsList({
        page,
        limit: 9,
        type: 'home-new'
      })
      this.goodsListNew = goodsListNew
      this.newObj.count = count
      this.newObj.limit = limit
    },
    changePage(val, type) {
      if (type === 'best') {
        document.getElementById('best-sellers').scrollIntoView({ behavior: 'smooth' })
        this.getBestList(val, type)
      } else if (type === 'new') {
        document.getElementById('new-arrivals').scrollIntoView({ behavior: 'smooth' })
        this.getNewList(val, type)
      }
    },
    getAll(val, type) {
      this.$router.push({
        name: 'search-type',
        params: {
          type
        }
      })
    }
  }
}
</script>
