<template>
  <!-- 展示一个评论 -->
  <div class="reviews-some" :class="{ space: !info.reviews ? true : false }">
    <!-- 评论头 -->
    <div class="reviews-title">
      <h3>
        Customer Reviews <template v-if="$vuetify.breakpoint.mdAndUp"> ({{ info.reviews }}) </template>
      </h3>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <div class="pc-model">
          <span>{{ info.score }}</span>
          <v-rating :value="info.score" :length="5" readonly half-increments background-color="orange lighten-3" color="orange" />
        </div>
      </template>
      <p v-if="$vuetify.breakpoint.smAndDown" class="show-more" @click="goReviews">
        View more ( {{ info.reviews }} )
        <img class="link-icon" src="/imgs/more.png" alt="View more" />
      </p>
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown && reviews.length" class="review-tag">
      <div class="review-score-box">
        <p class="reviews-score">{{ info.score }}</p>
        <v-rating :value="info.score" :length="5" readonly half-increments background-color="orange lighten-3" color="orange" />
      </div>
    </div>
    <!-- 评论列表 -->
    <div class="reviews-list">
      <template v-if="reviews.length">
        <div class="reviews-list">
          <div class="top">
            <div class="left">
              <div class="user-name">{{ reviews[0].name }}</div>
              <div class="user-score">
                <v-rating :value="reviews[0].score" :length="5" readonly background-color="orange lighten-3" color="orange" />
              </div>
            </div>
            <div class="right">{{ reviews[0].create_time }}</div>
          </div>
          <p class="user-review">{{ reviews[0].content }}</p>
          <div v-if="reviews[0].images.length" class="share-pic">
            <div v-for="(img, index) in reviews[0].images" :key="index" class="pic-item" @click="getFullImg(reviews[0].images, index)">
              <v-img lazy-src="/imgs/imgLazy.jpg" :src="img" contain />
            </div>
          </div>
        </div>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="review-more">
          <v-btn class="my-btn blue-btn" color="#3067F2" tile depressed block @click="goReviews">View More Reviews</v-btn>
        </div>
        <imgDialog :show="imgDiologs" :list="currentImgList" :current="imgCurrent" />
      </template>
      <template v-else>
        <!-- <div class="comment-none"></div> -->
        <p class="no-content">There is no comment on the current product.</p>
      </template>
    </div>
  </div>
</template>

<script>
import imgDialog from '@/components/Ldetails/components/imgDialog.vue'
export default {
  name: 'previewReviews',
  components: {
    imgDialog
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    reviews: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      imgDiologs: false,
      imgCurrent: 0,
      currentImgList: []
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    getFullImg(list, index) {
      this.currentImgList = list
      this.imgCurrent = index
      this.imgDiologs = true
    },
    goReviews() {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss" scoped>
.pc-model {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 20px;
    color: #ff9800;
    font-weight: 600;
    margin-right: 5px;
    line-height: 1;
    margin-top: 3px;
  }
  div {
    font-size: 0;
  }

  ::v-deep .v-rating .v-icon {
    padding: 0 !important;
    font-size: 26px;
  }
}
.reviews-some {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 35px 0;
  background: #f7f8fa;
  &.space {
    padding: 25px 0;
  }
  .reviews-title {
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
    h3 {
      font-size: 30px;
      font-weight: bold;
      color: #2b2e33;
      line-height: 35px;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  // .comment-none {
  //   width: 170px;
  //   height: 130px;
  //   background: url('/imgs/comment-none.png') no-repeat;
  //   background-size: contain;
  //   margin: 45px auto 30px;
  // }
  .no-content {
    text-align: center;
  }
  .reviews-list {
    padding: 0 16px;
    ::v-deep .v-rating .v-icon {
      padding: 4px !important;
      font-size: 15px;
    }
  }
  .reviews-list .top {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    .left {
      display: flex;
    }
    .user-name {
      margin-right: 6px;
    }
  }
  .user-review {
    margin: 5px 0;
    // word-break: break-all;
    // word-wrap: break-word;
  }
  .share-pic {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .pic-item {
    width: 70px;
    height: 80px;
    background: #f7f7f7;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    .v-image {
      width: 100%;
      height: 100%;
    }
  }
  .review-more {
    width: 250px;
    margin: 30px auto 0;
    .blue-btn {
      height: 46px;
      color: #fff !important;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .reviews-some {
    background: #fff;
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px;
    border-radius: 12px;
    .reviews-title {
      h3 {
        font-size: 15px;
        margin-bottom: 0;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      text-align: left;
      .show-more {
        font-size: 14px;
        margin-bottom: 0;
      }
      .link-icon {
        width: 13px;
        transform: rotate(270deg);
      }
    }
    .review-tag {
      background: #f5f5f5;
      margin-bottom: 15px;
      p {
        margin-bottom: 0;
      }
      .review-score-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 0;
        .reviews-score {
          font-size: 22px;
          font-weight: 600;
        }
        ::v-deep .v-rating .v-icon {
          padding: 3px !important;
          font-size: 16px;
        }
      }
    }
    .reviews-list {
      padding: 0;
    }
    .user-review {
      font-size: 14px;
    }
    .share-pic {
      margin-top: 8px;
    }
    .pic-item {
      width: 60px;
      height: 75px;
      margin-bottom: 8px;
    }
  }
}
</style>
