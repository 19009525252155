<template>
  <div class="payment-body">
    <div class="pay-content">
      <div class="left">
        <addressList class="item-com" @addressChange="addressChange" @billing="billingChange" />
        <methodList class="item-com" :medthod-default="methodType" :medthod="medthod" @medthos-type="setMedthosTpye" />
        <!-- <payList class="item-com" @pays-type="setPayType" /> -->
      </div>
      <countInfo class="right" :delivery-fee="deliveryFee" @userNote="userNote" @codeCoupon="codeCoupon" />
    </div>
  </div>
</template>

<script>
import { getRandomString } from '@/assets/utils/toolFn'
import addressList from '@/components/Lpayment/address/list'
import methodList from '@/components/Lpayment/method/list'
// import payList from '@/components/Lpayment/payList/list'
import countInfo from '@/components/Lpayment/count/index'
// const config = {
//   clientId: 'ASMo5HatkXqEbOdSs4A3n-AL3Xw1tTwWU4dQR6Arj_OUdRl8XvQRmhFvZWG_PI99rBQsVromNJ2hU0Ly',
//   secret: 'EBw-dW5MIRsHbaefokcEJ0_BtqxPohYqLjc8R6Qy15HT5Y2GyeH5KlLdRFfaqxkVIrouDHRh0BEz_F2w'
// }
export default {
  name: 'paymentIndex',
  components: {
    addressList,
    methodList,
    // payList,
    countInfo
  },
  validate({ params, redirect, error }) {
    if (!params.list || !params.list.length) {
      // return error({ statusCode: 500 })
      redirect({ name: 'shoppingCart' })
    } else {
      return true
    }
  },
  // middeware({ route, redirect, ...ac }) {
  //   console.log(ac)
  // },
  data() {
    return {
      // 随机码
      getRandomString: getRandomString(64),
      // 地址
      shippingAddress: {},
      billingAddress: {},
      // 派送方式
      methodType: null,
      // 派送方式列表
      medthod: [],
      // 派送费
      deliveryFee: null,
      code: undefined, // 优惠券
      orderNote: undefined,
      //  selectGoods: {
      //   subtotal: undefined,
      //   length: undefined
      // },
      // 付款方式
      payType: 'paypal',
      // 客户端ip
      ClientIp: undefined,
      // 创建成功后的订单编号
      currentOrder: undefined
    }
  },
  // head() {
  //   return {
  //     script: [
  //       {
  //         src: `https://www.paypal.com/sdk/js?client-id=${config.clientId}&commit=true`
  //       }
  //     ]
  //   }
  // },
  computed: {
    payReady() {
      const address = this.shippingAddress
      const deliveryFee = this.deliveryFee
      if (address && JSON.stringify(address) !== '{}' && deliveryFee && JSON.stringify(deliveryFee) !== '{}') {
        return true
      }
      return false
    }
  },
  watch: {
    // 是否填写条件(地址/派送方式)
    payReady(val) {
      if (val) {
        hideCover()
      } else {
        showCover()
      }
    }
  },
  created() {
    // if (process.client) {
    //   const ids = this.$route.params.list
    //   if (!ids || !ids.length) {
    //     this.$tips({ msg: 'The current page is invalid!' })
    //     setTimeout(() => {
    //       this.$router.replace({ name: 'shoppingCart' })
    //     }, 2000)
    //   }
    // }
  },
  mounted() {
    this.$loading().open()
    // 加载paypal
    const script = document.createElement('script')
    if (process.env.NODE_ENV === 'production') {
      script.src = this.GLOBAL.PROD_PAYPAL
    } else {
      script.src = this.GLOBAL.DEV_PAYPAL
    }
    // 获取客户端ip地址
    this.$api.system
      .getIpData()
      .then(res => {
        this.ClientIp = res
      })
      .catch(res => {
        this.ClientIp = undefined
      })
    script.async = true
    script.onload = () => {
      // this.initPaypalButton()
      const getPay = setInterval(() => {
        console.log('listen in-----')
        // eslint-disable-next-line no-undef
        if (paypal) {
          clearInterval(getPay)
          paypalFn(this, {
            goDetail: () => {
              this.$router.replace({ name: 'centerMain-order-order-detail', query: { code: this.currentOrder } })
            },
            goSuccess: () => {
              this.$router.replace({ name: 'payment-success', query: { code: this.currentOrder } })
            },
            goCart: () => {
              this.$router.replace({ name: 'shoppingCart' })
            },
            snapshootStatus: status => {
              this.$api.shoppingCart.snapshootStatus({
                order_id: this.$route.params.payId,
                status,
                order_code: this.currentOrder,
                page: 1
              })
            }
          })
          this.$loading().close()
        }
      }, 400)
    }
    document.head.appendChild(script)
  },
  methods: {
    codeCoupon(code) {
      this.code = code
      this.addressChange(this.shippingAddress)
    },
    userNote(text) {
      this.orderNote = text
    },
    // 地址变动
    addressChange(info) {
      this.shippingAddress = info
      this.$api.shoppingCart
        .payDelivery({
          country: info.country,
          cart_id: this.$route.params.list,
          coupon_code: this.code
        })
        .then(res => {
          if (res.code === 200) {
            this.medthod = res.data
            if (res.data.length) {
              this.methodType = { id: res.data[0].id, name: res.data[0].shipping_method }
              this.setMedthosTpye(this.methodType)
            }
          }
        })
      this.$api.shoppingCart.snapshootShipping({
        ...this.shippingAddress,
        order_id: this.$route.params.payId
      })
    },
    billingChange(info) {
      this.billingAddress = info
    },
    // 设置配送方式  设置运费
    setMedthosTpye(info) {
      const list = this.medthod
      this.methodType = info
      const index = list.findIndex(item => {
        return item.id === info.id
      })
      this.deliveryFee = {
        price: list[index].shipping_fee,
        name: list[index].shipping_method
      }
    },
    setPayType(info) {
      this.payType = info
    },
    getInfo() {
      const shipping = this.shippingAddress
      const billing = this.billingAddress
      // eslint-disable-next-line camelcase
      const express_id = this.methodType.id
      // eslint-disable-next-line camelcase
      const pay_way = this.payType
      // eslint-disable-next-line camelcase
      const submit_code = this.getRandomString
      // eslint-disable-next-line camelcase
      const cart_id = this.$route.params.list
      // eslint-disable-next-line camelcase
      const order_remark = this.orderNote
      // eslint-disable-next-line camelcase
      const coupon_code = this.code
      // eslint-disable-next-line camelcase
      let is_billing = 0
      const ip = this.ClientIp
      if (JSON.stringify(billing) !== '{}') {
        // eslint-disable-next-line camelcase
        is_billing = 1
      }
      const data = {
        shipping,
        billing,
        // eslint-disable-next-line camelcase
        is_billing,
        // eslint-disable-next-line camelcase
        express_id,
        // eslint-disable-next-line camelcase
        cart_id,
        // eslint-disable-next-line camelcase
        pay_way,
        // eslint-disable-next-line camelcase
        submit_code,
        // eslint-disable-next-line camelcase
        order_remark,
        // eslint-disable-next-line camelcase
        coupon_code,
        ip
      }
      return data
    }
  }
}
function paypalFn(that, fnList) {
  // eslint-disable-next-line no-undef
  paypal
    .Buttons({
      style: {
        layout: 'vertical', // horizontal - vertical
        color: 'blue',
        // label: 'Place Order',
        tagline: false
      },
      // 创建订单
      createOrder(data, actions) {
        console.log('startCreateOrder')
        // 点击付款---------------------
        fnList.snapshootStatus(1)
        return that.$api.shoppingCart.createOrder(that.getInfo()).then(res => {
          console.log('createOrderEnd')

          if (res.code !== 200) {
            if (res.code === 6) {
              fnList.goCart()
            }
            // 未成功创建订单
            that.$tips({ msg: res.msg, time: 6000 })
            return false
          } else {
            that.currentOrder = res.data.order_code
          }
          that.$store.dispatch('system/getCollectionsAndCartsNum', { axios: that.$api })
          return res.data.id
          // return false
        })
      },
      // 交易结果后调用
      onApprove(data) {
        console.log('startBusiness')
        return that.$api.shoppingCart
          .capturePayment(data)
          .then(res => {
            console.log('businessEnd')
            if (res.code === 200) {
              fnList.goSuccess()
              fnList.snapshootStatus(2)
            } else {
              fnList.snapshootStatus(3)
              that.$tips({ msg: res.msg, time: 6000 })
              that.$loading().open()
              setTimeout(() => {
                fnList.goDetail()
                that.$loading().close()
              }, 600)
            }
            return res
          })
          .catch(() => {
            that.$tips({ msg: 'Payment timeout...', time: 6000 })
            // 网络未接入，网络中断，服务器无法连接
            fnList.goDetail()
          })
      },
      // 取消操作
      onCancel(data) {
        console.log('Cancel')
        fnList.snapshootStatus(4)
        fnList.goDetail()
      },
      // 错误操作
      onError(data) {
        console.log('Error')
        // fnList.goList()
      }
    })
    .render('#paypal-button-container')
}
/**
 * 显示支付按钮遮罩层
 */
function showCover() {
  const cover = document.querySelector('#cover-btn')
  cover.classList.add('cover-index')
}
/**
 * 隐藏支付按钮遮罩层
 */
function hideCover() {
  const cover = document.querySelector('#cover-btn')
  cover.classList.remove('cover-index')
}
</script>

<style lang="scss" scoped>
.payment-body {
  max-width: 1440px;
  margin: 0 auto 50px;
  overflow: hidden;
  padding: 0 25px;
}
.pay-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.left {
  flex: 1;
  margin-right: 60px;
  .item-com {
    margin-bottom: 20px;
  }
}
// .right {
// }
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .payment-body {
    padding: 0 16px;
  }
  .pay-content {
    flex-direction: column;
  }
  .left {
    margin-right: 0;
  }
}
</style>
