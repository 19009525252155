<template>
  <div class="introduction">
    <v-tabs v-model="tab" background-color="#fff" color="#3067F2">
      <v-tab>Specification</v-tab>
      <v-tab>Reviews</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <reviewPreview v-if="reviews.length" :key="$route.params.id" class="reviewPreview" :info="info" :reviews="reviews" @toggle="jumpReviews" />
        <specifications class="specifications" :info="info" />
        <process class="process-com" :reviews="reviews" />
      </v-tab-item>
      <v-tab-item>
        <reviews :key="$route.params.id" :option="reviewsOption" :reviews="reviews" @page="getReviewsMore" />
      </v-tab-item>
    </v-tabs-items>
    <!-- <div class="">商品推荐待填充.....</div> -->
    <Lgoods title="You May Also Like" :list="goodsList" :hide-page="true" :all-page="true" @page="changePage($event, 'best')" @tryOn="tryGlasses" @getAll="getAll($event, 'best-sellers')" />
  </div>
</template>

<script>
import specifications from '@/components/Ldetails/components/specifications.vue'
import reviewPreview from '@/components/Ldetails/components/reviewPreview.vue'
import process from '@/components/Ldetails/components/process.vue'
import reviews from '@/components/Ldetails/components/reviews.vue'
import Lgoods from '@/components/Lgoods/index.vue'

export default {
  name: 'introDuction',
  components: {
    specifications,
    process,
    reviews,
    reviewPreview,
    Lgoods
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tab: 0,
      reviews: [],
      reviewsOption: {
        page: 1,
        limit: 10,
        count: null
      },
      goodsList: []
    }
  },
  computed: {},
  watch: {
    '$route.params.code': {
      handler() {
        this.tab = 0
        this.getReviews()
      },
      immediate: true
    }
  },
  mounted() {
    // alert(1)
    // this.getReviews()
    this.getgoodsList()
  },
  methods: {
    // 切换到评论
    jumpReviews() {
      this.$emit('jumpReviews')
      // console.log(document.getElementsByClassName('introduction')[0])
    },
    // 获取评论
    getReviews() {
      this.$api.product
        .getReviews({
          code: this.$route.params.code,
          limit: this.reviewsOption.limit,
          page: this.reviewsOption.page
        })
        .then(res => {
          if (res.code === 200) {
            this.reviews = res.data
            this.reviewsOption.count = res.count
          }
        })
    },
    getReviewsMore(val) {
      this.$emit('jumpReviews')
      this.reviewsOption.page = val
      this.getReviews()
    },
    getgoodsList() {
      this.$api.product
        .getGoodsList({
          limit: 9,
          type: 'new-arrivals'
        })
        .then(res => {
          if (res.code) {
            this.goodsList = res.data
          }
        })
    },
    tryGlasses(boj) {
      // this.showTryOn = true
    },
    getAll(val, type) {
      this.$router.push({
        name: 'search-type',
        params: {
          type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-tab {
  font-size: 16px;
  font-weight: 600;
}
@media screen and (min-width: 1280px) {
  .v-tab {
    padding: 0 22px;
  }
}
.introduction {
  font-size: 16px;
  background: #fff;
  margin-top: 80px;
  // margin-bottom: 80px;
  ::v-deep .v-tabs {
    width: calc(100% - 20px);
    margin: 0 auto;
  }
  ::v-deep .v-tabs-slider-wrapper {
    top: 0 !important;
  }
  ::v-deep .v-tabs-bar__content {
    border-bottom: 1px solid #dcdfe5;
  }
  // ::v-deep .v-tab {
  //   border-bottom: 1px solid #dcdfe5;
  // }
  // ::v-deep .v-tab--active {
  //   border-left: 1px solid #dcdfe5;
  //   border-right: 1px solid #dcdfe5;
  // }
  // ::v-deep .v-item-group {
  //   margin-top: 35px;
  // }

  .process-com {
    margin-top: 20px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .v-tab {
    font-size: 13px !important;
  }
  ::v-deep .v-window__container {
    padding: 10px;
    background: #f5f5f5;
  }
  .introduction {
    margin-top: 0;
    background: #f5f5f5;

    ::v-deep .v-item-group {
      margin-top: 5px;
    }
  }

  .v-window-item {
    display: flex;
    flex-direction: column;
  }
  // .specifications {
  //   order: 2;
  // }
  // .reviewPreview {
  //   order: 1;
  // }
  // .process-com {
  //   order: 3;
  // }
}
@media screen and (max-width: 600px) {
  .introduction {
    margin-top: 0;
    ::v-deep .v-item-group {
      margin-top: 0;
    }
    ::v-deep .v-tabs-bar__content {
      border-bottom: none;
    }
  }
}
</style>
