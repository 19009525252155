<template>
  <div v-if="second !== 0" class="countDown">
    <p class="countdown-box">
      <!-- Remaining payment time: -->
      <span v-if="day - 0">{{ day }} : </span>
      <span>{{ hour }} : </span>
      <span>{{ minute }} : </span>
      <span>{{ second }}</span>
    </p>
  </div>
</template>

<script>
import { setHoursTimeNumS } from '@/assets/utils/toolFn'
export default {
  name: 'countDown',
  props: {
    // 接入开始时间
    startTime: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      day: 0, // 天
      hour: 0, // 时
      minute: 0, // 分
      second: 0, // 秒
      timer: null
    }
  },
  watch: {
    startTime: {
      handler(val) {
        if (val) {
          this.initTime()
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    console.log('销毁计时器')
    this.clearTimer()
  },
  methods: {
    clearTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    initTime() {
      // const startTime = new Date(res.data.order_time).getTime()
      //   const startTime = getTimestampFromDateString(this.startTime)
      const startTime = this.startTime
      const endTime = startTime + setHoursTimeNumS(0.5)
      //   const etimer = new Date(endTime).getTime() // 获取过期日期的时间戳
      this.countDown(endTime)
    },
    countDown(time) {
      const that = this
      if (!time) {
        return
      }
      const secondes = time
      that.timer = setInterval(() => {
        const now = Math.floor(Date.now() / 1000)

        if (now < secondes) {
          const diff = parseInt(secondes - now) // 相差多少秒
          let s = diff % 60 // 秒
          let m = parseInt(diff / 60) // 分钟
          let h = 0 // 小时
          let d = 0 // 天
          if (m > 60) {
            h = parseInt(m / 60)
            m = m % 60
          }
          if (h > 24) {
            d = parseInt(h / 24)
            h = h % 24
          }
          if (d >= 0) {
            d = d < 10 ? '0' + d : d
          }
          if (h >= 0) {
            h = h < 10 ? '0' + h : h
          }
          if (h >= 0 || m >= 0) {
            m = m < 10 ? '0' + m : m
          }
          s = s < 10 ? '0' + s : s
          // 时分秒赋值
          that.day = d
          that.hour = h
          that.minute = m
          that.second = s
        } else {
          this.$emit('endTime', true)
          that.clearTimer()
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.countdown-box {
  color: #ff0000;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 4px;
}
@media screen and (max-width: 960px) {
  .countdown-box {
    font-size: 12px;
    color: #ffffff;
    background: rgba(255, 0, 0, 0.68);
    padding: 2px 8px;
    border-radius: 5px;
  }
}
</style>
