<template>
  <transition name="confirm">
    <v-snackbar v-model="show" color="#3067F2" :timeout="config.time">
      {{ config.msg }}
      <template #action="{ attrs }">
        <v-btn color="#fff" text v-bind="attrs" @click="show = false"> <img class="close" src="/imgs/close-white.png" alt="close" /></v-btn>
      </template>
    </v-snackbar>
  </transition>
</template>

<script>
export default {
  name: 'reMind',
  data() {
    return {
      config: { msg: '', time: 3000 },
      show: false
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.confirm-enter,
.confirm-leave-to {
  position: fixed;
  transform: scale(0);
  opacity: 0;
}
.confirm-enter-active,
.confirm-leave-active {
  transition: 0.2s;
}

.confirm-enter-to {
  position: fixed;
  transform: scale(1);

  opacity: 1;
}
.close {
  width: 12px;
}
</style>
