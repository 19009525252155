<template>
  <!-- 首页 about -->
  <div class="body-page">
    <div class="content-box about">
      <h2 v-if="$vuetify.breakpoint.smAndDown" class="mode-title">About Us</h2>
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12" md="5">
            <div class="about-img">
              <v-img lazy-src="/imgs/imgLazy.jpg" :src="`${GLOBAL.URL}/materials/other/pc_about_us.webp`" alt="about" />
              <!-- contain -->
            </div>
          </v-col>
          <v-col cols="12" md="7">
            <div class="about-content">
              <h2 v-if="$vuetify.breakpoint.mdAndUp" class="mode-title">About Us</h2>
              <p class="content-text">
                Stepping into the same old optical shop as you always do, you start to try on those unremarkable frames on the counter at random. One after another, to be honest, you are still not quite satisfied...
              </p>
              <ul class="content-list">
                <li>Affordable spectacles to meet every need and occasion</li>
                <li>Fashionable frames with latest trend</li>
                <li>Self-service of virtual try-on technology</li>
              </ul>
              <div class="d-flex about-btn-box">
                <nuxt-link :to="{ name: 'index-about-us' }">
                  <v-btn class="mobile-btn-more" rounded> Learn More </v-btn>
                </nuxt-link>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LAbout',
  methods: {
    toPage() {
      this.$router.push({ name: 'index-about-us' })
    }
  }
}
</script>

<style lang="scss" scoped>
.body-page {
  padding-top: 70px;
  padding-bottom: 70px;
}
.about-img {
  height: 358px;
  width: 100%;
  .v-image {
    height: 100%;
    width: 100%;
  }
}
.about-content {
  margin: 0 86px;
}
.mode-title {
  margin-bottom: 30px;
}
.content-text {
  font-size: 18px;
  color: #525861;
  text-align: left;
  font-weight: 400;
  line-height: 28px;
}
.content-list {
  font-size: 16px;
  font-weight: normal;
  color: #45484d;
  line-height: 16px;
  li {
    margin-bottom: 12px;
  }
}
.about-btn-box {
  justify-content: center;
  margin-top: 30px;
  a {
    text-decoration: none;
  }
  button {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .about-content {
    margin: 0 48px;
  }
}
@media screen and (max-width: 960px) {
  .about-content {
    margin: 0 10px;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .body-page {
    padding-top: 48px;
    padding-bottom: 48px;
    background: #f7f8fa;
  }
  .content-box {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
  }
  .about {
    // margin-bottom: 0 0 48px 0 !important;
    .about-img {
      height: 192px;
      margin-bottom: 30px;
    }
    .about-content {
      margin: 0;
    }
    .content-text,
    .content-list {
      font-size: 15px;
    }
    .service .item {
      padding-right: 0 !important;
    }
    // .mobile-btn-more {

    // }
    .about-btn-box {
      margin-top: 38px;
      justify-content: center;
    }
  }
}
</style>
