<template>
  <div class="upload-box">
    <div v-if="imgUrlList.length" class="view">
      <div v-for="(item, index) in imgUrlList" :key="index" class="view-item">
        <img class="img-user" :src="item" alt="photo" @click="look(index)" />
        <img v-if="!disabledUpload" class="img-close" src="/imgs/close.png" alt="photo" title="remove" @click="delImg(index)" />
      </div>
    </div>
    <div v-if="showAdd && !disabledUpload" class="item photo">
      <label for="upload" class="upload-label">
        <img src="/imgs/photo.png" alt="photo" />
        <p v-if="upType !== 'prescription'" class="tips-btn">Add Photo</p>
        <p v-else class="tips-btn">Upload RX</p>
      </label>
      <!-- <v-file-input id="upload" v-model="reviewInfo.file" class="myFile" accept="image/*" hide-input multiple append-outer-icon="" @change="onFileChange" /> -->
      <v-file-input id="upload" v-model="file" class="myFile" accept="image/*" hide-input :multiple="upType == 'prescription' ? false : true" append-outer-icon="" @change="onFileChange" />
    </div>
    <imgDialog :show="imgLialogs" :list="imgUrlList" :current="current" @closeDialog="imgLialogs = false" />
  </div>
</template>
<script>
import imgDialog from '@/components/Ldetails/components/imgDialog'
export default {
  name: 'uploadImg',
  components: {
    imgDialog
  },
  // 抛出事件  clear =>
  props: {
    // 显示名称
    upType: {
      type: String,
      default: 'defalut'
    },
    // 显示在线图片
    online: {
      type: Array,
      default: () => []
    },
    // 数量控制
    length: {
      type: Number,
      default: 1
    },
    // 禁用上传  删除
    disabledUpload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgLialogs: false,
      current: 0,
      // 当前数据
      file: [],
      // 预览数据
      imgUrlList: [],
      // 最终数据
      formData: []
    }
  },
  computed: {
    // 是否展示add
    showAdd() {
      const length = this.length
      const current = this.imgUrlList.length
      if (current < length) {
        return true
      }
      return false
    }
  },
  watch: {
    online: {
      handler(val, old) {
        if (this.disabledUpload) {
          this.clearFile()
        }
        if (Object.prototype.toString.call(old) === '[object Array]' && val.toString() === old.toString()) {
          return false
        }
        const list = val
        for (let index = 0; index < list.length; index++) {
          const element = list[index]
          if (element) {
            this.imgUrlList.push(element)
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {},
  destroyed() {
    Object.assign(this.$data, this.$options.data())
  },
  methods: {
    clearFile() {
      this.imgUrlList = []
      this.formData = []
      this.file = []
    },
    // 创建预览图  生成file文件
    createImage(file) {
      const that = this
      // 数量限制
      if (file.length > that.length || that.imgUrlList.length + file.length > that.length) {
        alert('Up to 3 images can be uploaded')
        return false
      }
      for (let index = 0; index < file.length; index++) {
        const reader = new FileReader()
        reader.readAsDataURL(file[index])
        reader.onload = function () {
          that.imgUrlList.push(reader.result)
        }
      }
      // console.log(file, '11111111111111')

      // this.formData = file
      this.formData.push(...file) // 当前为单文件
      // const formData = new window.FormData()
      // formData.append('file', this.formData)
      // formData.append('id', localStorage.getItem('userID'))
      // console.log('formdata', formData.get('file'))
    },
    // 选择文件后...
    onFileChange(file) {
      const res = file instanceof Array
      const fileArr = []
      if (res) {
        fileArr.push(...file)
      } else {
        fileArr.push(file)
      }
      const list = fileArr.filter(item => {
        return item.type.includes('image') // 没做大小限制
      })
      if (!list.length) {
        return false
      }
      this.createImage(list)
    },
    delImg(index) {
      // 删除预览图  / 文件
      this.imgUrlList.splice(index, 1)
      if (this.formData.length >= index) {
        this.formData.splice(index, 1)
      }
      this.$emit('clear')
    },
    look(index) {
      this.current = index
      this.imgLialogs = true
    },
    clearPageData() {
      Object.assign(this.$data, this.$options.data())
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-box {
  display: flex;
  .upload-label {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    & > img {
      width: 24px;
      height: 20px;
    }
  }
  .item.photo {
    width: 110px;
    height: 110px;
    border: 1px dotted #bdbdbd;
    flex-shrink: 0;
  }

  .tips-btn {
    font-size: 14px;
    font-family: Ubuntu-Light, Ubuntu;
    font-weight: 300;
    color: #2b2e33;
    line-height: 30px;
    margin-bottom: 0;
  }
  .myFile {
    position: absolute;
    z-index: -9;
    z-index: -9999999;
    opacity: 0;
  }
  .view {
    display: flex;
    flex-flow: wrap;
    .view-item {
      width: 100px;
      height: 110px;
      position: relative;
      margin: 0 10px 10px;
    }
    .img-user {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .img-close {
      width: 20px;
      height: 20px;
      position: absolute;
      // top: -10px;
      right: 0;
      cursor: pointer;
    }
  }
}
</style>
