<template>
  <v-dialog v-model="dialog" persistent background="#fff" hide-overlay :fullscreen="$vuetify.breakpoint.mobile">
    <div class="dialog-body">
      <v-toolbar color="#fff">
        <p class="dialog-title">How to fill out the prescription?</p>
        <v-spacer />
        <v-btn icon dark @click="close">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="content">
        <div class="item">
          <p class="model-title">1.Single Vision Prescription</p>
          <p class="model-tips">
            The value of prescription are usually provided by your doctor. If your prescription is like the following one, you need first to choose Single Vision type, and then fill in the correct information like the
            example below.
          </p>
          <img class="model-img" src="/imgs/fill-single-tem.png" alt="" />
          <img class="model-img" src="/imgs/single-tem.png" alt="" />
        </div>
        <div class="item">
          <p class="model-title">2.Progressive Prescription</p>
          <p class="model-tips">If your prescription is like the following one, you need first to choose Progressive type, and then fill in the correct information like the example below.</p>
          <img class="model-img" src="/imgs/fill-progressive-tem.png" alt="" />
          <img class="model-img" src="/imgs/progressive-tem.png" alt="" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'illustrateDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {},
  watch: {
    show: {
      handler(newValue) {
        this.dialog = newValue
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$parent.showDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  background: #fff;
}
.dialog-body {
  background: #fff;
  font-size: 16px;
  height: auto;
  min-height: 100%;
}
.v-dialog__content ::v-deep .v-dialog {
  width: 40%;
  height: auto;
  margin: 0 auto;
}
::v-deep .v-toolbar {
  position: sticky;
  top: 0;
  width: 100%;
}
::v-deep .v-rating {
  margin-left: -8px;
}
::v-deep .v-sheet.v-toolbar {
  box-shadow: none !important;
}
::v-deep .v-rating .v-icon {
  padding: 5px !important;
}
.dialog-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.content {
  padding: 0 16px;
}
.model-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}
.model-tips {
  font-size: 12px;
}
.model-img {
  width: 100%;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .v-dialog__content ::v-deep .v-dialog {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}
</style>
