<template>
  <div class="colorFilter">
    <div v-for="val in list[type]" :key="val.id" class="color-list" @click="select(val)">
      <p class="color-item" :class="findData(val) !== -1 ? 'active' : ''">
        <span class="color-text" :style="{ background: `url(${val.icon})` }"></span>
      </p>
      <p class="right">{{ val.name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'colorFilter',
  props: {
    // 筛选类型
    type: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: () => {}
    },
    check: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      current: []
    }
  },
  computed: {},
  watch: {
    check(newValue) {
      if (!newValue[this.type] || !newValue[this.type].length) {
        this.current = []
      }
    },
    deep: true
  },
  created() {},
  mounted() {},
  methods: {
    select(val) {
      const index = this.findData(val)
      if (index !== -1) {
        this.current.splice(index, 1)
      } else {
        this.current.push(val)
      }
      this.$emit('dataFn', { selected: this.current, type: this.type })
    },
    // 查看是否存在 数据
    findData(val) {
      const data = this.current
      const index = data.findIndex(function (item) {
        return item.id === val.id
      })
      return index
    }
  }
}
</script>

<style lang="scss" scoped>
.colorFilter {
  display: flex;
  flex-wrap: wrap;
}
.color-list {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.color-list:hover {
  color: #3067f2;
}
.color-item {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  padding: 3px;
  margin-right: 5px;
  margin-bottom: 0;
  border-radius: 50%;
  cursor: pointer;
}
.color-text {
  display: inline-block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.color-item {
  border: 3px solid #e7e7e7;
}
.color-item.active {
  border: 3px solid #3067f2;
}
.right {
  margin-bottom: 0;
  color: #0009;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .color-list {
    margin-right: 0;
    width: 50%;
  }
}
</style>
