<template>
  <v-dialog :value="$store.state.system.tryOnShow" class="dialogs-com" persistent :fullscreen="$vuetify.breakpoint.mobile">
    <div class="dialog-body">
      <div class="dialog-header d-flex">
        <v-spacer />
        <v-btn icon dark @click="close">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="content-detail">
        <!-- <div class="com-page" draggable="true" @dragend.prevent="dragend"> -->
        <div class="com-page">
          <div id="photo-current" class="photo-current">
            <!-- <img class="user-photo" :src="modelImg" alt="currentBg" draggable="false" />
            <img class="try-img" :src="tryImg" alt="tryImg" draggable="false" /> -->
            <img class="user-photo" :src="modelImg" alt="modelBg" />
            <img id="try-img" class="try-img" :style="frameConfig" :src="tryImg" alt="tryImg" @load="imgLoad" @error="imgError" />
            <v-progress-circular v-if="loading" class="loaidng-com" indeterminate color="#3067f2" />
            <p class="tip">Try on is only for style reference. As for size, please check the Frame Details.</p>
          </div>
          <div class="product-pd">
            <div class="left">PD (mm):</div>
            <div class="right">
              <select v-model="selectPd" class="pd-select" @change="changePd">
                <option v-for="(item, index) in PD" :key="index" :value="item">{{ item }}</option>
              </select>
            </div>
          </div>
          <div class="option">
            <div class="photo-list">
              <div class="list-title">
                <div class="left">Select Image</div>
                <div class="right upload" @click="upload">Upload a picture</div>
              </div>
              <div class="img-box">
                <!-- <div class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div v-for="(item, index) in photolist" :key="item.id" class="swiper-slide item-swiper" :class="currentImgIndex == index ? 'active-img' : ''" @click="currentImgIndex = index">
                <img class="img-main" contain :src="item.src" alt="model" />
              </div>
            </div>
            <div class="swiper-button-next" @click="changeImgView('next')">
              <img class="next-icon" src="/imgs/to.png" alt="next" />
            </div>
            <div class="swiper-button-prev" @click="changeImgView('prev')">
              <img class="prev-icon" src="/imgs/to.png" alt="prev" />
            </div>
          </div> -->
                <div class="swiper-button-next" @click="move('right', 'model')">
                  <img class="next-icon" src="/imgs/to.png" alt="next" />
                </div>
                <div id="userBox" ref="model" class="swiper" :style="{ left: modelLeft }">
                  <div v-for="(item, index) in photolist" :key="item.image_id" class="item-swiper" :class="currentImgIndex == index ? 'active-img' : ''" @click="changeModel(index)">
                    <img class="img-main" contain :src="item.image_path" alt="model" />
                    <template v-if="item.cache || item.online">
                      <img src="/imgs/close-white.png" alt="del" class="del" @click.prevent.stop="delModel(item)" />
                    </template>
                  </div>
                </div>

                <div class="swiper-button-prev" @click="move('left', 'model')">
                  <img class="prev-icon" src="/imgs/to.png" alt="prev" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="$vuetify.breakpoint.smAndDown" class="btn-box">
            <div class="item"><v-btn block tile depressed @click="close">Close</v-btn></div>
            <div class="item"><v-btn block tile depressed color="#3067f2" class="blue-btn" @click="info">More Info</v-btn></div>
          </div>
          <cropperImg v-if="uploadShow" ref="cropperCom" :config="config" :loading="loading" @close="uploadShow = false" @file="upFile" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { getBoxInfo, setFrameConfig, getUserPd, setUserPd, getLocalModelCache, delModelItem, saveModeCache } from '@/assets/utils/tryOn'
import { tryData } from '@/components/toolCom/tryOn/mixin/tryConfig'
import cropperImg from '@/components/toolCom/cropperImg'

// import tryOnModel from '@/static/imgs/tryOnModel.png'
export default {
  name: 'tryOn',
  components: {
    cropperImg
  },
  mixins: [tryData],
  props: {
    // show: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      loading: false,
      uploadShow: false, // 显示裁剪组件
      selectPd: '-Select PD-',
      PD: [], // 镜面数据
      // frameInfo: {
      //   width: 144, // 镜框宽
      //   height: 42, // 镜片高
      //   lenWidth: 54, // 镜片宽
      //   bridgeWidth: 18 // 鼻梁宽
      // },
      // 眼镜定位 缩小 偏移
      frameConfig: {
        display: 'block',
        width: undefined,
        height: undefined,
        top: undefined,
        left: undefined,
        transform: undefined
      },
      modelLeft: '0',
      currentImgIndex: 0,
      config: {
        width: 350,
        height: 400
      }
    }
  },
  computed: {
    tryImg() {
      const img = this.$store.state.system.tryOnImg
      // return tryOnModel
      return img
    },
    modelImg() {
      // console.log(getBoxInfo())
      return this.photolist[this.currentImgIndex].image_path
    }
  },
  watch: {},
  mounted() {
    // eslint-disable-next-line no-new
    // new Swiper('.mySwiper', this.swiperOption)
    this.PD = this.renderData('-Select PD-', 1, 44, 80)
    this.loading = true
    this.setBoxHeight()
    // setBody()
    // this.setPdSelect()
    // setTimeout(() => {
    //   this.positionFrame()
    // }, 1000)
  },
  // destroyed() {
  //   setBody(true)
  // },
  methods: {
    imgError() {
      this.$tips({ msg: 'Failed to load image.' })
    },
    imgLoad() {
      this.getModelList()
    },
    getModelList() {
      if (!this.$store.state.system.userToken) {
        const list = getLocalModelCache()
        this.photolist.unshift(...list)
        this.concatData(list)
        this.loading = false
        this.changeModel(0)
      } else {
        this.$api.system.getModel().then(res => {
          this.loading = false
          if (res.code === 200) {
            // this.photolist.unshift(...res.data)
            this.concatData(res.data)
            this.changeModel(0)
          }
        })
      }
    },
    // 合并数据
    concatData(newlist) {
      const oldData = this.$options.data().photolist
      oldData.unshift(...newlist)
      this.photolist = oldData
    },
    // 设置默认pd
    setPdSelect() {
      // 当前模特
      const currentModelInfo = this.photolist[this.currentImgIndex]
      // 系统模特
      if (currentModelInfo.type) {
        this.selectPd = currentModelInfo.pd
      } else {
        this.selectPd = getUserPd()
      }
    },
    // 设置容器高度
    setBoxHeight() {
      const radio = 0.875
      const box = document.querySelector('#photo-current')
      const width = box.clientWidth
      const height = width / radio
      box.style.height = height + 'px'
    },
    // 切换pd值  缩放眼镜
    changePd() {
      const currentPd = this.selectPd
      setUserPd(currentPd)
      // 使用用户PD
      setTimeout(() => {
        this.positionFrame(currentPd)
      }, 0)
    },
    // 切换模特
    changeModel(index) {
      this.currentImgIndex = index
      this.setPdSelect()
      this.positionFrame()
    },
    // 镜框定位
    positionFrame(pd) {
      const currentModelInfo = this.photolist[this.currentImgIndex]
      // pd 判断
      if (currentModelInfo.type && !pd) {
        pd = currentModelInfo.pd
      } else {
        pd = getUserPd()
      }
      // console.log(pd, 'pd')
      // 图片缩放px基准
      const pxScale = getBoxInfo(currentModelInfo.image_width, currentModelInfo.image_height)
      // 当前两瞳孔位置定位
      const Lx = currentModelInfo.leftEyeX * pxScale.x
      const Ly = currentModelInfo.leftEyeY * pxScale.y
      const Rx = currentModelInfo.rightEyeX * pxScale.x
      const Ry = currentModelInfo.rightEyeY * pxScale.y
      // console.table({
      //   pxScale,
      //   ...{ Lx, Ly, Rx, Ry }
      // })
      const img = document.querySelector('#try-img')
      const divWidth = img.naturalWidth
      const divHeight = img.naturalHeight
      // console.table({
      //   divWidth,
      //   divHeight
      // })
      const config = setFrameConfig(Lx, Ly, Rx, Ry, 0, 0, divWidth, divHeight, pd, 63, [0, 0])
      this.frameConfig = { ...config }
    },
    //  选择自己的图片
    upload() {
      this.uploadShow = true
      // debugger
      // console.log(this.$refs.cropperCom)
      // this.$refs.cropperCom.uploadFile()
      // this..cropperImg.show()
    },
    // 上传识别图片
    upFile(file) {
      this.loading = true
      this.$api.system.faceCheckUp(file).then(res => {
        this.loading = false
        if (res.code === 200) {
          // 关闭上传组件
          this.uploadShow = false
          // 保存本地
          if (!this.$store.state.system.userToken) {
            saveModeCache(res.data)
          }
          setTimeout(() => {
            // 获取图片列表
            this.getModelList()
          }, 0)
        } else {
          // 关闭图片上传
          this.uploadShow = false
        }
        // else {
        //   this.$tips({ msg: 'Image recognition failed' })
        // }
      })
    },
    // 删除用户模特
    delModel(item) {
      if (!this.$store.state.system.userToken) {
        delModelItem(item.image_id)
        this.getModelList()
      } else {
        this.loading = true
        this.$api.system.delModel({ image_id: item.image_id }).then(res => {
          if (res.code === 200) {
            this.getModelList()
          }
          this.loading = false
        })
      }
    },
    // 关闭试戴
    close() {
      // this.$parent.showTryOn = false
      this.$store.commit('system/toggleTryOnShow', { state: false, img: undefined })
    },
    info() {
      this.close()
      const code = this.$store.state.system.tryCode
      this.$route.push({
        name: 'details-code',
        params: { code }
      })
    },
    move(type, dom) {
      // 当前偏移量
      let current
      if (dom === 'model') {
        current = this.modelLeft.split('px')[0] - 0
      } else {
        current = this.colorLeft.split('px')[0] - 0
      }
      // 可视宽度 / 每次移动宽度
      const box = document.querySelector('#userBox')
      const maxWidth = box.scrollWidth
      const length = box.childNodes.length
      // 移动距离
      function move(item) {
        const width = maxWidth / length
        return width * item
      }
      if (type === 'right') {
        // 总宽度
        if (current <= -maxWidth) {
          return false
        }
        //  向左移动
        // this.left = current - maxWidth + 'px'
        // this.setMoveLeft(dom, current - maxWidth + 'px')
        if (dom === 'model') {
          this.modelLeft = current - move(4) + 'px'
        } else {
          this.colorLeft = current - move(4) + 'px'
        }
      } else {
        // 向右移动
        if (current >= 0) {
          return false
        }
        if (dom === 'model') {
          this.modelLeft = current + move(4) + 'px'
        } else {
          this.colorLeft = current + move(4) + 'px'
        }
      }
    },
    getOffset(dom) {
      let x = dom.offsetLeft
      let y = dom.offsetTop
      let parent = dom.offsetParent
      // while判断是否有设有定位的祖先元素
      // parent.offsetParent = null表示找到的是body,则不需要进入while
      // parent.offsetParent != null表示找到的是设有定位的祖先元素
      while (parent.offsetParent != null) {
        x += parent.offsetLeft
        y += parent.offsetTop
        parent = parent.offsetParent
      }
      return { x, y } // {x:x,y:y}
    },
    // 生成镜片数据
    renderData(defaultVla, interval = 0.25, negativeMin = -20, positiveMax = 12) {
      // 数字间隔
      // const interval = 0.25
      // let negativeMin = -18
      // const positiveMax = 10
      const list = []
      if (negativeMin > 0) {
        list.push(defaultVla)
      }
      for (negativeMin; negativeMin <= positiveMax; negativeMin = negativeMin + interval) {
        if (negativeMin === 0 && defaultVla) {
          list.push(defaultVla)
        } else {
          list.push(negativeMin.toFixed(2))
        }
      }
      return list
    },
    // dragend(event) {
    //   const dom = document.querySelector('.com-page')
    //   const height = Math.round(dom.offsetHeight / 2)
    //   const width = Math.round(dom.offsetWidth / 2)
    //   dom.style.top = event.clientY - height + 'px'
    //   dom.style.left = event.clientX - width + 'px'
    //   event.dataTransfer.clearData()
    //   event.preventDefault()
    // },
    changeImgView(type) {
      if (type === 'left' && this.currentImgIndex > 0) {
        this.currentImgIndex--
      }
      if (type === 'right' && this.currentImgIndex < this.photolist.length - 1) {
        this.currentImgIndex++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.del {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  background: rgba(0, 0, 0, 0.39);
  padding: 4px;
  border-radius: 50%;
}
.dialogs-com,
.dialog-body {
  background: #fff;
  font-size: 16px;
  overflow: hidden auto;
  max-height: 80vh;
}
.v-dialog__content ::v-deep .v-dialog {
  background: #fff;
  margin: 0 auto;
}
.v-dialog__content ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  width: auto;
}
::v-deep .v-toolbar {
  height: 50px;
}
.dialog-body {
  position: relative;
}
.dialog-header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}
.loaidng-com.v-progress-circular {
  margin: 40% auto 0;
}
.content-detail {
  // padding: 0 16px;
  background: #fff;
}

.com-page {
  // position: fixed;
  // left: 10px;
  // top: 200px;
  // z-index: 100;
  position: relative;
  width: 350px;
  // background: #fff;
  // border: 1px solid #e5e5e5;
}
.photo-current {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  .user-photo {
    width: 100%;
    height: 100%;
    // vertical-align: middle;
    // object-fit: cover;
  }
  .try-img {
    display: block;
    position: absolute;
    transform-origin: center;
    transition: all 0.3s;
  }
}
.tip {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.56);
  font-size: 13px;
  padding: 5px;
  margin-bottom: 0;
}
.product-pd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  font-size: 16px;
  padding: 0 10px;
  border-bottom: 1px solid #e5e5e5;
  .pd-select {
    width: 131px;
    height: 24px;
    background: rgb(255, 255, 255);
    border: 1px solid #e5e5e5;
    outline: none;
  }
}
.option {
  padding: 0 10px;
}
.photo-list {
  padding-top: 15px;
  border-bottom: 1px solid #e5e5e5;

  .list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin-bottom: 5px;
    // height: 42px;
    .left {
      color: #000;
    }
    .right {
      color: blue;
    }
    .upload {
      cursor: pointer;
    }
  }
}
.product-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  .left {
    display: flex;
    flex-direction: column;
    .top {
      line-height: 1;
      font-size: 16px;
    }
    .bottom {
      line-height: 1;
      font-size: 14px;
    }
  }
}
.btn-box {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 10px 0;
  background: #ffffff;
  z-index: 10;
  .item {
    width: 45%;
    .blue-btn {
      color: #ffffff !important;
    }
  }
}
</style>
<style scoped lang="scss">
.dialog-body {
  max-height: 100%;
}
// swuper
.img-box {
  height: 75px;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  margin: 5px 0;
  .swiper-button-next {
    position: absolute;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background: rgb(85, 85, 85, 0.52);
    height: 100% !important;
    top: 22px !important;
    width: 22px !important;
    outline: none;
    &::after {
      content: none;
    }
  }
  .swiper-button-prev {
    left: 0;
    .prev-icon {
      transform: rotate(180deg);
    }
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper {
    width: calc(100% - 44px);
    height: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
    transition: left 0.5s;
  }

  .item-swiper {
    position: relative;
    flex-shrink: 0;
    width: 80px;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    // margin-right: 20px !important;
    cursor: pointer;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .item-swiper .img-main {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item-swiper {
    // height: 80px;
    box-sizing: border-box;
    // border: 1px solid #dcdee1;
  }
  .active-img {
    border: 1px solid #e5e5e5;
  }
}
.color {
  display: flex;
  flex-direction: row-reverse;
  width: 60%;
  // overflow: hidden;
  .color-icon {
    display: flex;
    align-items: center;
    img {
      height: 22px;
    }
  }
  .color-next {
    transform: rotate(180deg);
  }
  .color-box {
    width: 77%;
    margin: 0 8px;
    overflow: hidden;
  }
  .color-list {
    display: flex;
    position: relative;
    transition: left 0.5s;
  }
  .color-item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    padding: 3px;
    margin-right: 10px;
    margin-bottom: 0;
    border-radius: 50%;
    cursor: pointer;
  }
  .color-text {
    display: inline-block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  .color-item.active {
    border: 3px solid #3067f2;
  }
  @media screen and (min-width: 1280px) {
    .color-item:hover {
      border: 3px solid #3067f2;
    }
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .com-page {
    width: 100%;
  }
  .content-detail {
    margin-bottom: 60px;
  }
}
</style>
