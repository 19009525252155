<template>
  <div class="goods-list">
    <div v-for="item in list" :key="item.id" class="itemList">
      <div class="left">
        <img class="goods-img" :src="item.image" :alt="item.title" />
      </div>
      <div class="right item-info">
        <p class="info-item">{{ item.title }}</p>
        <p class="info-item color-flex">
          <span class="name">Color:</span>
          <span class="text color-flex">
            <!-- <img class="color-icon" :src="item.color.icon" :alt="item.color.name" /> -->
            <span>{{ item.color }}</span>
          </span>
        </p>
        <!-- <p class="info-item">
            <span class="name">Type:</span>
            <span class="text">{{ item.is_lens ? 'Rx' : 'Frame' }}</span>
          </p> -->
        <div class="info-item item-price">
          <p>
            <span class="name">Qty:</span> <span class="text">{{ item.num }}</span>
          </p>
          <p class="price-info">{{ currencyIcon }}{{ item.payable_price }}</p>
        </div>
      </div>

      <!-- <div class="right">
        <span class="price">{{ currencyIcon }}{{ item.payable_price }}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'itemList',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('system', ['currencyIcon'])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.goods-list {
  width: 100%;
  background: #fff;
  padding: 16px 0;
  max-height: 445px;
  overflow-y: auto;
  // transform-origin: top;
}
.goods-list {
  scrollbar-width: none; /* Firefox浏览器的滚动条样式 */
}

/* 隐藏滚动条 */
.goods-list ::-webkit-scrollbar {
  display: none;
}
.itemList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.itemList:not(:last-child) {
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 16px;
}
.left {
  display: flex;
  align-items: center;
}
.right {
  flex: 1;
  margin-bottom: 6px;
}
.item-info {
  margin-left: 10px;
  p {
    font-size: 14px;
    margin-bottom: 6px;
  }
  .color-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .color-flex {
    display: flex;
    align-items: center;
  }
  .name {
    margin-right: 6px;
  }
  .color-icon {
    margin-right: 6px;
  }
}
// .price {
//   font-size: 14px;
//   margin-bottom: 6px;
// }
.goods-img {
  // height: 46px;
  // max-width: 100px;
  width: 100px;
}
.item-price {
  display: flex;
  justify-content: space-between;
  p {
    margin-bottom: 0;
  }
}
.price-info {
  font-weight: 600;
}
</style>
