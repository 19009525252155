<template>
  <div class="center-body">
    <v-form ref="tickets" class="form-box">
      <div class="item">
        <p class="input-label" for="">Add Your Email<span style="color: #ff0000">*</span></p>
        <v-text-field v-model="form.email" class="input-item" hide-details="auto" outlined color="#bdbdbd" />
      </div>
      <div class="item">
        <p class="input-label" for="">Phone Number</p>
        <v-text-field v-model="form.phone" class="input-item" hide-details="auto" outlined color="#bdbdbd" />
      </div>
      <div class="item textarea-box">
        <p class="input-label" for="">Please fill in the problem description<span style="color: #ff0000">*</span></p>
        <v-textarea v-model="form.description" class="input-item" hide-details="auto" outlined color="#bdbdbd" />
      </div>
      <!-- 图片模块 -->
      <div v-if="form.id && form.files && form.files.length" class="item upload-box">
        <p class="input-label" for="">Upload Photo</p>
        <uploadFile ref="imgCom" :online="form.files" :disabled-upload="form.id ? true : false" :length="3" />
      </div>
      <div v-else class="item upload-box">
        <template v-if="!form.id">
          <p class="input-label" for="">Upload Photo</p>
          <uploadFile ref="imgCom" :online="form.files" :disabled-upload="form.id ? true : false" :length="3" />
        </template>
      </div>
      <!-- submit----------------- -->
      <div v-if="!form.id" class="option">
        <v-btn block class="my-btn btn-other" color="#3067f2" @click="submitFn"> Submit </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { getFormData } from '@/assets/utils/renderData'
import uploadFile from '@/components/toolCom/uploadImg.vue'

export default {
  name: 'myTickets',
  components: {
    uploadFile
  },
  inject: {
    info: { value: 'info', default: null }
  },
  data() {
    return {
      form: {
        email: '',
        phone: '',
        description: '',
        flies: []
      },
      // 订单编号列表
      orderList: []
    }
  },
  computed: {
    infoData() {
      try {
        return this.info()
      } catch (error) {
        return null
      }
    }
  },
  watch: {
    infoData: {
      handler(val) {
        this.formDialogData(val)
      },
      deep: true,
      immediate: true
    }
  },
  created() {},

  mounted() {
    this.setDefaultEmail()
  },
  methods: {
    formDialogData(val) {
      if (val) {
        // this.$refs.imgCom.clearPageData()
        // console.log(this.$refs.imgCom.imgUrlList)
        this.form = {}
        this.form = { ...val }
      }
    },
    setDefaultEmail() {
      if (this.form.email) {
        return false
      }
      const store = this.$store.state.system
      if (store.userToken && store.userInfo.email) {
        this.form.email = store.userInfo.email
      }
    },
    getInfo() {
      let file = null
      file = this.$refs.imgCom.formData
      return { info: this.form, file }
    },
    submitData() {
      const obj = this.getInfo()
      const data = getFormData(obj.info)
      for (let index = 0; index < obj.file.length; index++) {
        const item = obj.file[index]
        data.append(`file${index + 1}`, item)
      }
      return data
    },
    submitFn() {
      const info = this.submitData()
      if (!info.get('email') || !info.get('description')) {
        this.$tips({ msg: 'Please fill in the required fields.' })
        return false
      } else {
        this.$api.userCenter.contactSave(info).then(res => {
          if (res.code === 200) {
            this.$tips({ msg: 'Your question has been sent.' })
            this.clearPageData()
            this.$emit('success')
          }
        })
      }
      // 提交后 返回提醒   help页
    },
    clearPageData() {
      Object.assign(this.$data, this.$options.data())
      this.$refs.imgCom.clearPageData()
      this.setDefaultEmail()
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  margin-bottom: 16px;
}
.item {
  margin-bottom: 16px;
}
.input-label {
  margin-bottom: 10px;
}
.input-item {
  height: 45px;
  background: #ffffff;
}
.textarea-box .input-item {
  height: 100px;
}
.form-box {
  .input-item ::v-deep .v-input__slot fieldset {
    border: 1px solid #bdbdbd !important;
  }
}

.option {
  width: 260px;
  margin: 0 auto;
}
.my-btn {
  color: #fff !important;
  height: 42px !important;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .form-box {
    margin: 25px 0;
  }
}
</style>
