<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown" persistent background="#fff">
    <div class="dialog-body">
      <v-toolbar color="#fff">
        <v-spacer />
        <v-btn icon dark @click="close">
          <v-icon color="#000">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="content">
        <configData v-if="dialog" v-bind="$attrs" v-on="$listeners" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import configData from '@/components/LcenterMain/setting/setPrescription'
export default {
  name: 'prescriptionDialog',
  components: {
    configData
  },
  inheritAttrs: false,
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {},
  watch: {
    show(newValue) {
      this.dialog = newValue
    }
  },
  methods: {
    close() {
      Object.assign(this.$data, this.$options.data())
      this.$parent.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  width: 1080px;
  height: auto;
  margin: 0 auto;
  overflow-y: auto;
  background: #fff;
}
.dialog-body {
  font-size: 16px;
  height: 100%;
}
::v-deep .v-toolbar {
  height: 50px;
}
::v-deep .v-rating {
  margin-left: -8px;
}
::v-deep .v-sheet.v-toolbar {
  box-shadow: none !important;
}
::v-deep .v-rating .v-icon {
  padding: 5px !important;
}

.content {
  // height: 88%;
  //   overflow-y: auto;
  padding: 0 20px;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1080px) {
  ::v-deep .v-dialog {
    width: 100% !important;
    height: 100%;
    margin: 0 auto;
    overflow-y: auto;
    background: #fff;
  }
}
</style>
