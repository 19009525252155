<!-- 单项商品数据 -->
<template>
  <div v-if="JSON.stringify(list) !== '{}'" class="item-body" :style="{ opacity: !hasStock ? 0.7 : 1 }">
    <div class="select">
      <v-checkbox v-model="checkbox" :ripple="false" class="input-item" :disabled="!hasStock" color="#3067F2" @change="change" />
      <div v-if="$vuetify.breakpoint.width < 1200" class="right-option">
        <!-- <span v-if="isHasProcess(list.process)" class="edit" @click="goFlow">Edit lens</span> -->
        <img v-if="isHasProcess(list.process) && hasStock" class="edit-icon" src="/imgs/edit-icon.png" alt="edit" @click="goFlow" />

        <span v-if="isHasProcess(list.process)" class="line-h"></span>
        <!-- <span class="remove" @click="remove">Remove</span> -->
        <img class="remove-icon" src="/imgs/del-icon.png" alt="edit" @click="remove" />
      </div>
    </div>
    <div class="left product-img-box">
      <div class="img-box" :class="{ long: isHasProcess(list.process) }">
        <img class="product-img" :src="list.img" alt="product" @click="goPage(list.code)" />
        <p v-if="!hasStock" class="out-sold">Sold Out</p>
      </div>
      <!-- <div v-if="$vuetify.breakpoint.width < 1200 && list.stock <= 10 && hasStock" class="stock">
        <p>ONLY {{ list.stock }} PAIRS LEFT</p>
      </div> -->
    </div>
    <div class="right">
      <div v-if="$vuetify.breakpoint.width > 1200" class="right-option">
        <!-- <span v-if="isHasProcess(list.process)" class="edit" >Edit lens</span> -->
        <!-- color="transparent" -->
        <img v-if="isHasProcess(list.process) && hasStock" class="edit-icon" src="/imgs/edit-icon.png" alt="edit" @click="goFlow" />
        <img class="remove-icon" src="/imgs/del-icon.png" alt="edit" @click="remove" />
        <!-- <span class="remove" @click="remove">Remove</span> -->
      </div>
      <!-- 头部 框架信息-------------------- -->
      <div class="info-box">
        <div class="top">
          <p class="left">
            <span class="keyWord">Frame:</span>
            <span class="value">{{ list.code }}</span>
          </p>
          <p class="right right-text">{{ currencyIcon }}{{ list.price }}</p>
        </div>
        <div class="bottom">
          <p>
            <span class="keyWord">Color:</span>
            <span class="value">{{ list.color }}</span>
            <!-- <img class="value" :style="{ backgroundImage: `url('${list.color.icon}')` }" /> -->
          </p>
        </div>
      </div>
      <!-- 镜片信息------------------------- -->
      <template v-if="isHasProcess(list.process)">
        <div class="detail-box">
          <div class="top">
            <p class="left">
              <span class="keyWord showDetail" @click="openTable = !openTable">Lens Details</span>
              <img class="more" :class="{ active: openTable }" :src="more" alt="more-icon" @click="openTable = !openTable" />
              <!-- <span v-if="$vuetify.breakpoint.width > 1200" class="edit" @click="goFlow">Edit lens</span> -->
            </p>
            <p class="right right-text">{{ currencyIcon }}{{ list.lens_total }}</p>
          </div>
          <!-- table -->
          <div v-if="isHasAnyAttr('prescription') && openTable" class="center">
            <tableEdit v-if="openTable" :list="list.process.prescription.value" />
          </div>
          <div v-if="openTable" class="bottom">
            <div v-if="isHasAnyAttr('lens_use')" class="item">
              <p class="left">
                <span class="keyWord">{{ renderItem('lens_use').name }}:</span>
                <span class="value">{{ renderItem('lens_use').value }}</span>
              </p>
              <p class="right right-text">{{ currencyIcon }}{{ renderItem('lens_use').price }}</p>
            </div>
            <div v-if="isHasAnyAttr('lens_type')" class="item">
              <p class="left">
                <span class="keyWord">{{ renderItem('lens_type').name }}:</span>
                <span class="value">{{ renderItem('lens_type').value }}</span>
              </p>
              <p class="right right-text">{{ currencyIcon }}{{ renderItem('lens_type').price }}</p>
            </div>
            <div v-if="isHasAnyAttr('lens_index')" class="item">
              <p class="left">
                <span class="keyWord">{{ renderItem('lens_index').name }}:</span>
                <span class="value">{{ renderItem('lens_index').value }}</span>
              </p>
              <p class="right right-text">{{ currencyIcon }}{{ renderItem('lens_index').price }}</p>
            </div>
            <div v-if="isHasAnyAttr('magnification')" class="item">
              <p class="left">
                <span class="keyWord">{{ renderItem('magnification').name }}:</span>
                <span class="value">{{ renderItem('magnification').value }}</span>
              </p>
              <p class="right right-text">{{ currencyIcon }}{{ renderItem('magnification').price }}</p>
            </div>
            <div v-if="isHasAnyAttr('add_ones')" class="item">
              <p class="left">
                <span class="keyWord">{{ renderItem('add_ones').name }}:</span>
                <span class="value">{{ renderItem('add_ones').value }}</span>
              </p>
              <p class="right right-text">{{ currencyIcon }}{{ renderItem('add_ones').price }}</p>
            </div>
          </div>
        </div>
      </template>
      <!-- 数量--------------------------- -->
      <div class="num-box">
        <div class="content">
          <span class="keyWord">Qty:</span>
          <inputNumber v-model="num" :disabled="!hasStock" @change="numChange" />
          <!-- <span v-if="$vuetify.breakpoint.width > 1200" class="remove" @click="remove">Remove</span> -->
        </div>
        <!-- <div v-if="$vuetify.breakpoint.width > 1200 && list.stock <= 10 && hasStock" class="stock">
          <p>ONLY {{ list.stock }} PAIRS LEFT</p>
        </div> -->
      </div>
      <!-- 合计 -->
      <div class="count-box">
        <span>Subtotal</span>
        <span>{{ currencyIcon }}{{ totalPrice }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { add, multiply } from '@/assets/utils/decimal'
import { getItem, replaceSunglasses } from '@/assets/utils/renderData'
import more from '@/static/imgs/more.png'
// import edit1 from '@/static/imgs/edit-icon.png'
// import del1 from '@/static/imgs/del-icon.png'
// import edit2 from '@/static/imgs/edit-icon2.png'
// import del2 from '@/static/imgs/del-icon2.png'
import inputNumber from '@/components/toolCom/inputNumber'
import tableEdit from '@/components/LshoppingCart/item/tableEdit'
// 单组件转换汇率   不参与最终总价计算  更改源数据总价
export default {
  name: 'itemCom',
  components: {
    inputNumber,
    tableEdit
  },
  props: {
    list: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      more,
      showT: true,
      checkbox: false,
      num: this.list.num,
      openTable: true
      // editIcon: edit1,
      // delIcon: del1
    }
  },
  computed: {
    ...mapGetters('system', ['currency', 'currencyIcon']),
    totalPrice() {
      const that = this
      const onePrice = add(that.list.price, that.list.lens_total ? that.list.lens_total : 0)
      // this.list.price_total = multiply(onePrice, that.list.num ? that.list.num : 1)
      return multiply(onePrice, that.list.num ? that.list.num : 1)
    },
    // 判断是否有库存
    hasStock() {
      // return this.list.stock > 0
      return true
    },
    // 判断是否有流程配置
    isHasProcess() {
      return function (data) {
        if (Object.prototype.toString.call(data) === '[object Array]') {
          return false
        }
        if (Object.prototype.toString.call(data) === '[object Object]' && JSON.stringify(data) !== '{}') {
          return true
        }
      }
    },
    // 判断是否有某个属性
    isHasAnyAttr() {
      const data = this.list.process
      return function (key) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          return true
        }
        return false
      }
    },
    // 渲染已有流程项目  => 渲染所属项
    renderItem() {
      const data = this.list.process
      return function (key) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (getItem(key) === 'Prescription Type') {
            return {
              name: getItem(key),
              price: data[key].money,
              value: replaceSunglasses(data[key].value)
            }
          }
          return {
            name: getItem(key),
            price: data[key].money,
            value: data[key].value
          }
        } else {
          return false
        }
      }
    }
  },
  watch: {
    'list.select': {
      handler(e) {
        this.checkbox = e
      },
      immediate: true
    },
    'list.num': {
      handler(val) {
        this.num = val
      },
      deep: true
    },
    totalPrice: {
      handler(val) {
        this.$set(this.list, 'price_total', val)
      },
      deep: true
    }
  },
  methods: {
    // editChange(type) {
    //   if (type === 'on') {
    //     this.editIcon = edit2
    //   } else {
    //     this.editIcon = edit1
    //   }
    // },
    // removeChange(type) {
    //   if (type === 'on') {
    //     this.delIcon = del2
    //   } else {
    //     this.delIcon = del1
    //   }
    // },
    numChange(type) {
      this.$emit('numChange', type)
    },
    async setFirstData() {
      this.$loading().open()
      // 当前流程数据
      const flowData = this.list.process
      // 跳过第一步-------------------
      const firstFlow = {
        name: flowData.lens_use.value,
        value: flowData.lens_use.key,
        money: flowData.lens_use.money
      }

      // 获取第一步信息
      const state = await this.$store.dispatch('productConfig/getFlowInfo', { code: this.list.editid, axios: this.$api, edit: true })
      // 切换下一步(步骤号)
      this.$store.commit('productConfig/controlStep', 'next')
      this.$loading().close()
      // 设置默认第一步选项 用于第二次获取信息
      this.$store.commit('productConfig/setSelectData', { data: firstFlow, type: 'lens_use' })
      // // 预设第二步数据
      if (flowData.prescription) {
        const prescription = flowData.prescription.value
        this.$store.commit('productConfig/StagingRX', prescription)
      }
      return state
    },
    goFlow() {
      const state = this.setFirstData()
      // // 获取下一步流程信息
      // this.$store.dispatch('productConfig/getFlowInfo', {
      //   axios: this.$api,
      //   code: this.list.code
      // })

      // // 预设第二步数据
      // if (flowData.prescription) {
      //   const prescription = flowData.prescription.value
      //   this.$store.commit('productConfig/resetData')
      //   this.$router.push({
      //     name: 'product',
      //     query: {
      //       code: this.list.editid
      //     }
      //   })
      // }
      // this.$store.commit('productConfig/resetData')
      // this.$loading().close()
      if (state) {
        this.$router.push({
          name: 'product',
          query: {
            type: 'edit',
            code: this.list.editid
          }
        })
      }
    },
    remove() {
      this.$confirm({ msg: 'Are you sure to delete this item?' }).then(res => {
        if (res === 'success') {
          this.$emit('remove')
        }
      })
    },
    change(e) {
      const that = this
      that.$emit('changeSelect', e)
    },
    goPage(code) {
      this.$router.push({
        name: 'details-code',
        params: { code }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-icon {
  height: 22px;
  cursor: pointer;
}
.remove-icon {
  height: 18px;
  margin-left: 15px;
  cursor: pointer;
}
.item-body {
  width: 100%;
  display: flex;
  background-color: #fff;
  padding: 20px 40px 20px 0;
  position: relative;
  margin-bottom: 30px;
  p {
    line-height: 1.2;
  }
  & > .left {
    width: 360px;
  }
}
.select {
  position: absolute;
  left: 25px;
}
.left {
  // width: 360px;
  // .product-img-box {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   transition: all 0.5s;
  // }
  .img-box {
    display: flex;
    justify-content: center;
    margin-top: 72px;
    position: relative;
  }
  // .img-box.long {
  //   margin-top: 125px;
  // }
  .product-img {
    height: 116px;
    max-width: 100%;
    cursor: pointer;
  }
  .out-sold {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    width: 88%;
    padding: 4px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    background: #717171;
  }
}
.right {
  // width: 60%;
  flex: 1;
  // background: red;
  p {
    margin-bottom: 5px;
  }
  .keyWord {
    font-size: 14px;
    color: #949699;
    margin-right: 10px;
  }
  .value {
    font-size: 14px;
    color: #2b2e33;
  }
  .right-text {
    text-align: right;
  }
}
.info-box {
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  .top {
    display: flex;
  }
}
.detail-box {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  .top,
  .bottom {
    display: flex;
  }
  .center {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .bottom {
    flex-direction: column;
    .item {
      display: flex;
    }
  }
}
.num-box {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  .content {
    display: flex;
    align-items: center;
  }
}
.stock {
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  background: #f7ebeb;
  border-radius: 4px 4px 4px 4px;
  margin-top: 8px;
  p {
    line-height: 1;
    font-size: 14px;
    color: #c79696;
    margin-bottom: 0;
    padding-left: 10px;
  }
}
.count-box {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.more {
  width: 12px;
  margin-bottom: 1px;
  cursor: pointer;
}
.active {
  transform: rotate(180deg);
}
.edit {
  color: #3067f2 !important;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  // margin-left: 10px;
}
.remove {
  font-size: 14px;
  color: #2b2f38 !important;
  cursor: pointer;
  user-select: none;
  // margin-left: 10px;
}
.right .showDetail {
  color: #2b2e33;
  cursor: pointer;
  user-select: none;
}
.right-option {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  .remove {
    margin-left: 15px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .remove-icon {
    margin-left: 0;
  }
  .item-body {
    padding: 15px;
    flex-direction: column;
    & > .left {
      width: 100%;
      margin-top: 55px;
    }
  }
  .select {
    left: 15px;
  }
  .left {
    width: 100%;
    .select {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 36px);
      height: 24px;
      top: 16px;
    }
    .right-option {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      .edit,
      .remove {
        margin-left: 0;
      }
      .line-h {
        width: 2px;
        height: 14px;
        background: #e0e0e0;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .img-box {
      margin-top: 0;
      margin-bottom: 20px;
    }
    .img-box.long {
      margin-top: 20px;
    }
  }
  .stock {
    margin-bottom: 20px;
    margin-top: 0;
  }
  .right {
    .detail-box {
      padding-bottom: 0;
      border-bottom: none;
      .center {
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
    .num-box {
      padding-top: 10px;
    }
  }
}
</style>
